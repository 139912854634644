import gql from 'graphql-tag';
import React from 'react';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import {
  getProBotData as BotData,
  getProBotData_bot,
  getProBotDataVariables as BotDataVariables,
} from './@types/getProBotData';
import {
  isPaidWorkspaceSubscription,
  WorkspaceWithSubscription,
} from '@pages/BotListPage/components/WorkspaceList/Bot/utils';

enum BotProStatuses {
  enabled = 'enabled',
  enabled_with_debt = 'enabled_with_debt',
  trial = 'trial',
  paused = 'paused',
}

type BotWithProData = {
  pro?: {
    status?: string;
    is_paused?: boolean | null;
    manual?: boolean | null;
  } | null;
};

type BotWithPageData = {
  status?: {
    page_info?: {
      id: string;
    } | null;
  } | null;
};

export const isBotHasProStatus = <T extends BotWithProData = getProBotData_bot>(
  statuses: BotProStatuses[],
  bot?: T,
) => {
  if (!bot) {
    return undefined;
  }
  if (!bot.pro) {
    return false;
  }
  return (
    (bot.pro.status && statuses.includes(bot.pro.status as BotProStatuses)) ||
    bot.pro.manual
  );
};

export const isBotPro = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) =>
  !!isBotHasProStatus(
    [BotProStatuses.enabled, BotProStatuses.enabled_with_debt],
    bot,
  );

export const isBotProDebt = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) =>
  bot &&
  bot.pro &&
  bot.pro.status === BotProStatuses.enabled_with_debt &&
  !bot.pro.manual;

export const isBotPaused = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) => !!bot?.pro?.is_paused;

export const isBotTrial = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) => bot?.pro?.status === BotProStatuses.trial;

export const isBotProOrTrial = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) => isBotPro(bot) || isBotTrial(bot);

export const isBotProNoDebt = <T extends BotWithProData = getProBotData_bot>(
  bot?: T,
) => isBotHasProStatus([BotProStatuses.enabled], bot);

export const isBotProInWorkspace = <
  B extends BotWithPageData,
  W extends WorkspaceWithSubscription,
>(
  bot?: B,
  workspace?: W,
) => isPaidWorkspaceSubscription(workspace) && Boolean(bot?.status?.page_info);

const GET_PRO_BOT_DATA = gql`
  query getProBotData($botId: String!) {
    bot(id: $botId) {
      id
      pro {
        status
        manual
      }
    }
  }
`;

export const useBotProData = (botId: string) => {
  const { data, loading, error } = useQuery<BotData, BotDataVariables>(
    GET_PRO_BOT_DATA,
    { variables: { botId } },
  );

  return {
    loading,
    error,
    data,
  };
};

export const useIsBotPro = (botId: string) => {
  const { data, loading, error } = useBotProData(botId);
  return {
    loading,
    error,
    proBot: !!data && isBotPro(data.bot),
  };
};
type BotProDataChildrenArgs = {
  loading: boolean;
  error?: ApolloError;
  proBot: boolean;
};
type BotProDataProps = {
  botId: string;
  children: (params: BotProDataChildrenArgs) => React.ReactNode;
};
export const BotProData: React.FC<BotProDataProps> = (props) => {
  const { proBot, loading, error } = useIsBotPro(props.botId);
  return <>{props.children({ loading, error, proBot })}</>;
};

export const useIsBotProOrTrial = (botId: string) => {
  const { data, loading, error } = useBotProData(botId);
  return {
    loading,
    error,
    proOrTrialBot: !!data && isBotProOrTrial(data.bot),
    trialBot: isBotTrial(data?.bot),
  };
};
