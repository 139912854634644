import moment from 'moment';
import { DateType } from './types';

export namespace DateFormat {
  const SECONDS_PER_MINUTE = 60;

  export const mmss = (date: DateType) => moment(date).format('mm:ss');
  export const HHmm = (date: DateType) => moment(date).format('HH:mm');
  export const ddd = (date: DateType) => moment(date).format('ddd');
  export const dddd = (date: DateType) => moment(date).format('dddd');
  export const DMMMHmm = (date: DateType) => moment(date).format('D MMM, H:mm');
  export const DDMMMMHHmm = (date: DateType) =>
    moment(date).format('DD MMMM [at] HH:mm');
  export const DDMMMHHmm = (date: DateType) =>
    moment(date).format('DD MMM [at] HH:mm');
  export const DDMMMMYYYY = (date: DateType) =>
    moment(date).format('DD MMMM YYYY');
  export const DMMMYY = (date: DateType) => moment(date).format('D MMM YY');
  export const DDMMMM = (date: DateType) => moment(date).format('DD MMMM');
  export const MMMYYYY = (date: DateType) => moment(date).format('MMM YYYY');
  export const MMMMYYYY = (date: DateType) => moment(date).format('MMMM YYYY');
  export const MMMDD = (date: DateType) => moment(date).format('MMM DD');
  export const MMMMDD = (date: DateType) => moment(date).format('MMMM DD');
  export const MMMMDoYYYY = (date: DateType) =>
    moment(date).format('MMMM Do YYYY');
  export const MMMMD = (date: DateType) => moment(date).format('MMMM, D');
  export const YMMDD = (date: DateType) => moment(date).format('Y-MM-DD');
  export const YYYYMMMMDD = (date: DateType) =>
    moment(date).format('YYYY-MMMM-DD');
  export const LT = (date: DateType) => moment(date).format('LT');
  export const ll = (date: DateType) => moment(date).format('ll');
  export const UTC = (timezoneOffset: number) =>
    moment()
      .utcOffset(timezoneOffset / SECONDS_PER_MINUTE)
      .format('Z');
  export const DDMMMMYYYYHHMMSS = (date: DateType) =>
    moment(date).format('DD MMMM YYYY HH:mm:ss');
  export const MMMMDD_ifNeedYYYY = (date: DateType) => {
    const momentDate = moment(date);
    return momentDate.format(
      momentDate.year() !== moment().year() ? 'MMMM DD, YYYY' : 'MMMM DD',
    );
  };
}
