import { Platform } from '@globals';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import {
  WhatsappEnabledQuery,
  WhatsappEnabledQueryVariables,
} from '@utils/Whatsapp/hooks/@types/WhatsappEnabledQuery';
import { WHATSAPP_ENABLED_QUERY } from '@utils/Whatsapp/hooks/useWhatsappEnabled';
import { log } from 'cf-common/src/logger';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import {
  ChangeBotAllowedPlatformsMutation,
  ChangeBotAllowedPlatformsMutationVariables,
} from './@types/ChangeBotAllowedPlatformsMutation';
import { CHANGE_BOT_ALLOWED_PLATFORMS_MUTATION } from './mutations';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';

type Payload = {
  onCompleted?: VoidFunction;
};

export const useChangeBotAllowedPlatformsMutation = (payload?: Payload) => {
  const [firstWABotId, setFirstWABotId] = useServerStorage<String>(
    ServerStorageItemKeys.firstWABotId,
  );
  const [mutation, data] = useMutation<
    ChangeBotAllowedPlatformsMutation,
    ChangeBotAllowedPlatformsMutationVariables
  >(CHANGE_BOT_ALLOWED_PLATFORMS_MUTATION, {
    onCompleted: payload?.onCompleted,
    onError(error) {
      log.error({
        error,
        msg: 'change bot allowed platform',
      });
      showSomethingWentWrongToaster();
    },
  });

  return [
    useCallback(
      (botId: string, allowedPlatforms: Platform[]) =>
        mutation({
          variables: { botId, allowedPlatforms },
          update(store) {
            if (
              allowedPlatforms?.includes(Platform.whatsapp) &&
              !firstWABotId
            ) {
              setFirstWABotId(botId);
            }

            store.writeQuery<
              WhatsappEnabledQuery,
              WhatsappEnabledQueryVariables
            >({
              query: WHATSAPP_ENABLED_QUERY,
              variables: { botId },
              data: {
                bot: {
                  __typename: 'Bot',
                  id: botId,
                  allowedPlatforms,
                },
              },
            });
          },
        }),
      [mutation, setFirstWABotId, firstWABotId],
    ),
    data,
  ] as const;
};
