import { OperationVariables } from 'apollo-client';
import { DataProxy } from 'apollo-cache';
import { clone } from 'ramda';

export const mapQuery = <U, T = OperationVariables>(
  client: DataProxy,
  options: DataProxy.Query<T>,
  fn: (x: U) => U,
) => {
  let oldData: U | null;

  try {
    oldData = client.readQuery<U, T>(options);
  } catch {
    oldData = null;
  }

  if (!oldData) {
    return;
  }

  client.writeQuery<U, T>({
    ...options,
    data: fn(clone(oldData)),
  });
};
