import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { getFirstFlowGroupId } from '../Flow/GroupingFlows/utils';

const BOT_DEFAULT_GROUP_QUERY = gql`
  query BotDefaultGroupQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
      }
    }
  }
`;

export const useBotDefaultFlowGroup = (botId?: string) => {
  const { data, loading, error } = useQuery(BOT_DEFAULT_GROUP_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const botDefaultFlowGroupId = useMemo(
    () => getFirstFlowGroupId(data?.bot),
    [data],
  );

  return {
    botDefaultFlowGroupId,
    botDefaultFlowGroupIdLoading: loading,
    botDefaultFlowGroupIdError: error,
  };
};
