import { QueryHookOptions, useQuery } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import { CURRENT_BOT_QUERY } from './query';
import {
  CurrentBotQuery,
  CurrentBotQueryVariables,
} from './@types/CurrentBotQuery';

export const useCurrentBot = (
  options?: QueryHookOptions<CurrentBotQuery, CurrentBotQueryVariables>,
) => {
  const botId = useCurrentBotId()!;
  const { data, loading, error } = useQuery<
    CurrentBotQuery,
    CurrentBotQueryVariables
  >(CURRENT_BOT_QUERY, {
    variables: { botId },
    skip: !botId,
    /**
     * CurrentBotQuery запрашивает поле pausePricing, которое может выбросить
     * ошибку 403, тк не все роли имеют доступ к этим данным
     */
    errorPolicy: 'all',
    ...options,
  });

  return {
    bot: data?.bot,
    loading,
    error,
  };
};
