import gql from 'graphql-tag';

export const ALL_BLOCKS_TITLES_QUERY = gql`
    query AllBlocksTitlesQuery($botId: String!){
      bot(id: $botId) {
        id
        archiveBlocks {
          id
          title
          removed
          reachable
          is_flow
        }
      }
    }
`;
