let boxEl: HTMLDivElement | undefined;

export const getHtmlElementSize = (html: string) => {
  if (!boxEl) {
    boxEl = document.createElement('div');
    boxEl.style.cssText =
      'position:absolute;left:-4000px;top:0;visibility:hidden;display:flex;';
    document.body.append(boxEl);
  }
  boxEl.innerHTML = html;
  const { clientHeight, clientWidth } = boxEl;
  return { currentHeight: clientHeight, currentWidth: clientWidth };
};
