import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import Maybe from 'graphql/tsutils/Maybe';
import { UPDATE_WHATSAPP_MESSAGING_SETTINGS } from './mutations';
import { WhatsappMessagingSettingsInput } from '@globals';
import {
  UpdateWhatsappMessagingSettingsMutation,
  UpdateWhatsappMessagingSettingsMutationVariables,
} from './@types/UpdateWhatsappMessagingSettingsMutation';
import { log } from 'cf-common/src/logger';
import {
  showErrorToaster,
  showSomethingWentWrongToaster,
} from '@services/MessageService';
import { queriesNeedToBeRefetched } from '@utils/FacebookPages/consts';
import { useBotsTitlesMap } from '../Bot/useBotsTitlesMap';
import { useSafeTranslation } from '../../useSafeTranslation';
import { useHistory } from 'react-router-dom';
import { getDefaultTab } from '../../Routing';
import { sendEventToGA } from '../../Analytics';

export const useUpdateWhatsappSettings = (botId: Maybe<string>) => {
  const botIdToTitle = useBotsTitlesMap();
  const history = useHistory();
  const { t } = useSafeTranslation();
  const [fetch, { loading, called }] = useMutation<
    UpdateWhatsappMessagingSettingsMutation,
    UpdateWhatsappMessagingSettingsMutationVariables
  >(UPDATE_WHATSAPP_MESSAGING_SETTINGS, {
    onError: (error) => {
      log.error({
        error,
        msg: 'update whatsapp messaging settings error',
      });
      const botId = error.graphQLErrors?.[0]?.extensions?.result?.botId;
      if (!botId) {
        showSomethingWentWrongToaster();
        return;
      }
      if (botIdToTitle[botId]) {
        showErrorToaster(
          'pages.BotPage.HomeTab.Whatsapp.toasterPhoneUsedBot',
          false,
          undefined,
          {
            bot: botIdToTitle[botId],
          },
          t('pages.BotPage.HomeTab.Whatsapp.toasterPhoneUsedBotOpenButton'),
          () => {
            history.push(getDefaultTab(botId));
          },
        );
      } else {
        showErrorToaster('pages.BotPage.HomeTab.Whatsapp.toasterPhoneUsed');
      }
    },
  });

  const updateWhatsappSettings = useCallback(
    async (payload: WhatsappMessagingSettingsInput) => {
      if (!botId) return undefined;

      const result = await fetch({
        awaitRefetchQueries: true,
        refetchQueries: queriesNeedToBeRefetched(botId),
        variables: { botId, payload },
      });

      sendEventToGA({
        type: 'whatsapp',
        name: 'connect phone success',
        label: 'success',
      });

      return result;
    },
    [fetch, botId],
  );

  return {
    updateWhatsappSettings,
    loading,
    called,
  };
};

export const useDisconnectPhone = (botId: Maybe<string>) => {
  const { updateWhatsappSettings, loading } = useUpdateWhatsappSettings(botId);

  const disconnectPhone = useCallback(() => {
    updateWhatsappSettings({ phoneId: null, whatsappBusinessAccountId: null });
  }, [updateWhatsappSettings]);

  return { disconnectPhone, loading };
};
