import gql from 'graphql-tag';
import { ASSIGNED_PROMO_CODE_FRAGMENT } from './fragments';

export const ASSIGN_BOT_PROMO_CODE_MUTATION = gql`
  mutation AssignBotPromoCodeMutation($botId: String!, $promoCodeId: String!) {
    assignPromoCode(botId: $botId, promoCodeId: $promoCodeId) {
      ...assignedPromoCodeFragment
    }
  }
  ${ASSIGNED_PROMO_CODE_FRAGMENT}
`;
