import { DataProxy } from 'apollo-cache';
import { BotsListQuery_bots } from '../../../common/services/GQLqueries/@types/BotsListQuery';
import {
  WorkspacesBots,
  WorkspacesBots_workspacesBots,
} from './@types/WorkspacesBots';
import { WORKSPACES_BOTS_QUERY } from './queries';
import Maybe from 'graphql/tsutils/Maybe';
import { getWorkspaceBotMock } from './mocks';

export const findBotsInWorkspacesBots = (
  workspacesBots: WorkspacesBots_workspacesBots,
  workspaceId: Maybe<string>,
) => {
  return workspaceId
    ? workspacesBots.workspaces.find(
        ({ workspace }) => workspace.id === workspaceId,
      )?.bots
    : workspacesBots.botsWithoutWorkspace;
};

export const addToWorkspacesBotsCache = (
  { id, title }: BotsListQuery_bots,
  workspaceId: Maybe<string>,
  store: DataProxy,
) => {
  let workspacesBotsData: Maybe<WorkspacesBots>;

  try {
    workspacesBotsData = store.readQuery({
      query: WORKSPACES_BOTS_QUERY,
    });
  } catch {
    workspacesBotsData = null;
  }

  if (workspacesBotsData) {
    const newWorkspacesBotsData: WorkspacesBots = JSON.parse(
      JSON.stringify(workspacesBotsData),
    );
    const { workspacesBots } = newWorkspacesBotsData;
    const newWorkspaceBot = getWorkspaceBotMock({ id, title });

    findBotsInWorkspacesBots(workspacesBots, workspaceId)?.unshift(
      newWorkspaceBot,
    );

    store.writeQuery<WorkspacesBots>({
      query: WORKSPACES_BOTS_QUERY,
      data: newWorkspacesBotsData,
    });
  }
};
