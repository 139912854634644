import gql from 'graphql-tag';
import { DIALOGS_PRICING_FRAGMENT } from './queries';

export const SWITCH_PRICING_TIER_MUTATION = gql`
  mutation SwitchPricingTierMutation(
    $botId: String!
    $nextTier: TierType!
    $subscriptionPeriod: Period!
    $paymentMethodId: String
  ) {
    switchPricingTier(
      botId: $botId
      nextTier: $nextTier
      subscriptionPeriod: $subscriptionPeriod
      paymentMethodId: $paymentMethodId
    ) {
      ...dialogsPricingFragment
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const SWITCH_PREMIUM_PRICING_TIER_MUTATION = gql`
  mutation SwitchPremiumPricingTierMutation(
    $nextTier: String!
    $subscriptionPeriod: Period!
    $paymentMethodId: String
  ) {
    switchPremiumPricingTier(
      nextTier: $nextTier
      subscriptionPeriod: $subscriptionPeriod
      paymentMethodId: $paymentMethodId
    ) {
      ...dialogsPricingFragment
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const START_CHURN_SURVEY_MUTATION = gql`
  mutation StartChurnSurveyMutation(
    $botId: String!
    $action: ChurnSurveyAction!
    $reasons: [LeaveReason]!
    $tags: [String!]
  ) {
    startChurnSurvey(
      botId: $botId
      action: $action
      reasons: $reasons
      tags: $tags
    ) {
      id
    }
  }
`;

export const FINISH_CHURN_SURVEY_MUTATION = gql`
  mutation FinishChurnSurveyMutation(
    $startId: ID!
    $other: String
    $feedback: String
  ) {
    finishChurnSurvey(startId: $startId, other: $other, feedback: $feedback)
  }
`;

export const CANCEL_PRO_MUTATION = gql`
  mutation CancelProMutation($botId: String!) {
    cancelProPlan(botId: $botId)
  }
`;

export const ACTIVATE_DISCOUNT_MUTATION = gql`
  mutation ActivateDiscountMutation($botId: String!) {
    activateDiscount(botId: $botId)
  }
`;

export const PRO_PLAN_PAUSE_MUTATION = gql`
  mutation ProPlanPauseMutation($botId: String!) {
    pauseProPlan(botId: $botId)
  }
`;

export const UPDATE_WORKSPACES_BILLING_INFO_MUTATION = gql`
  mutation UpdateWorkspacesBillingInfoMutation($details: BillingDetailsInput!) {
    updateWorkspacesBillingDetails(details: $details) {
      id
      city
      company_name
      country
      email
      postcode
      state
      street
      tax_id
    }
  }
`;

export const CHURN_SURVEY_BUILD_BOT_MUTATION = gql`
  mutation ChurnSurveyBuildBotMutation($botId: String!) {
    churnSurveyBuildBot(botId: $botId)
  }
`;
