import { useMutation } from '@apollo/react-hooks';

import { CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY } from '@utils/MarketingApi';
import { BOT_STATUS_DATA_QUERY } from '@utils/Bot';

import { UN_BIND_PAGE_MUTATION } from './queries';

import {
  UnBindPageMutation,
  UnBindPageMutationVariables,
} from './@types/UnBindPageMutation';
import { BOT_LIVELINESS_QUERY } from '@utils/Data/Bot';
import { CURRENT_BOT_QUERY } from '../Data/Bot/CurrentBot/query';

export const useUnbindPage = (botId: string) => {
  const [unbindPageMutation, { loading: unbindPageLoading }] = useMutation<
    UnBindPageMutation,
    UnBindPageMutationVariables
  >(UN_BIND_PAGE_MUTATION, {
    refetchQueries: [
      { query: CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY, variables: { botId } },
      { query: BOT_STATUS_DATA_QUERY, variables: { botId } },
      { query: CURRENT_BOT_QUERY, variables: { botId } },
      { query: BOT_LIVELINESS_QUERY, variables: { botId } },
    ],
  });
  const unbindPage = (pageId: string) => {
    return unbindPageMutation({ variables: { pageId } });
  };
  return { unbindPage, unbindPageLoading };
};
