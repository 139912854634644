import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import { setIntercomTemplateKey } from './setIntercomTemplateKey';
import templateCheckLists from './templateCheckLists.json';

export const applyTemplateCheckList = (templateKey: string) => {
  setIntercomTemplateKey(templateKey);

  if (templateKey in templateCheckLists) {
    serverStorageItemSet(
      ServerStorageItemKeys.templateCheckListId,
      templateCheckLists[templateKey],
    );
  }
  // hide "getting started" block because show checkList
  serverStorageItemSet(
    ServerStorageItemKeys.isClosedBasicInstagramFlowTutorial,
    true,
  );
  serverStorageItemSet(ServerStorageItemKeys.isClosedBasicFlowTutorial, true);
};
