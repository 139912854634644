import './assets/less/switcher-label.less';

import ng from 'angular';
import template from './switcher-label.html';
import controller from './controller';

export default ng
  .module('app.ui.switcherLabel', [])
  .component('switcherLabel', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      value: '<',
      onChange: '&',
      label: '@',
      preventChange: '<',
    },
  }).name;
