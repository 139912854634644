import gql from 'graphql-tag';

export const AI_STATISTICS_QUERY = gql`
  query AiStatisticsQuery($botId: String!) {
    bot(id: $botId) {
      id
      aiStatistics {
        platform
        aiIntents
        unmatchedPhrases
      }
    }
  }
`;
