export enum ImageMimeTypes {
  png = 'image/png',
  jpeg = 'image/jpeg',
  gif = 'image/gif',
  avif = 'image/avif',
}

export enum VideoMimeTypes {
  mp4 = 'video/mp4',
  gpp = 'video/3gpp',
  ogg = 'video/ogg',
  mov = 'video/mov',
  webm = 'video/webm',
  avi = 'video/avi',
}

export enum AudioMimeTypes {
  aac = 'audio/aac',
  mp4 = 'audio/mp4',
  m4a = 'FilesMimeTypes/m4a',
  amr = 'audio/amr',
  mpeg = 'audio/mpeg',
  mp3 = 'audio/mp3',
  ogg = 'audio/ogg',
  wav = 'audio/wav',
  m4ax = 'audio/x-m4a',
}

export enum FilesMimeTypes {
  text = 'text/plain',
  pdf = 'application/pdf',
  ppt = 'application/vnd.ms-powerpoint',
  doc = 'application/msword',
  xls = 'application/vnd.ms-excel',
  odf = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  opf = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  osf = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum GroupMimeTypes {
  all = '*/*',
  audio = 'audio/*',
  video = 'video/*',
}

