import gql from 'graphql-tag';
import { LOCALIZATION_FRAGMENT } from '@components/Plugins/common/PluginQuery';

export const LIVECHAT_SETTINGS_QUERY = gql`
  query LivechatSettingsQuery($botId: String!) {
    bot(id: $botId) {
      id
      livechatSettings {
        config {
          button_text
          timeout_config {
            time_value
            time_period
          }
          dynamic_menu_title
          stop_message
          subtitle
          title
          image_url
        }
        localization {
          ...localizationFragment
        }
        has_livechat_plugins
      }
    }
  }
  ${LOCALIZATION_FRAGMENT}
`;

export const SET_LIVECHAT_CONFIG_MUTATION = gql`
  mutation SetLivechatConfigMutation(
    $botId: String!
    $config: FlowBuilderLiveChatPluginConfigInput!
    $localization: [LocalizationInput!]
  ) {
    setLivechatConfig(
      botId: $botId
      config: $config
      localization: $localization
    ) {
      config {
        button_text
        timeout_config {
          time_value
          time_period
        }
        dynamic_menu_title
        stop_message
        subtitle
        title
        image_url
      }
      localization {
        ...localizationFragment
      }
    }
  }
  ${LOCALIZATION_FRAGMENT}
`;
