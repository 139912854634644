import Maybe from 'graphql/tsutils/Maybe';
import { useWhatsappPhoneEntries } from './useWhatsappPhoneEntries';
import { isWhatsappConnected } from './isWhatsappConnected';
import { useWhatsappSettings } from './useWhatsappSettings';

export const useWhatsappConnected = (botId: Maybe<string>) => {
  const { whatsappSettings, loading: settingsLoading } =
    useWhatsappSettings(botId);
  const { whatsappPhoneEntries, loading: phonesLoading } =
    useWhatsappPhoneEntries(botId);

  return {
    isConnected: isWhatsappConnected(whatsappSettings),
    hasSharedAccounts: whatsappPhoneEntries.length > 0,
    whatsappSettings,
    whatsappPhoneEntries,
    loading: settingsLoading || phonesLoading,
  };
};
