import client from '@common/services/ApolloService';
import {
  AllBlocksTitlesQuery,
  AllBlocksTitlesQueryVariables,
} from './@types/AllBlocksTitlesQuery';
import { ALL_BLOCKS_TITLES_QUERY } from './queries';

export const refetchAllBlocksTitlesQuery = (botId: string) =>
  client.query<AllBlocksTitlesQuery, AllBlocksTitlesQueryVariables>({
    query: ALL_BLOCKS_TITLES_QUERY,
    variables: {
      botId,
    },
    fetchPolicy: 'network-only',
  });
