import { useQuery } from 'react-apollo';
import { useCurrentBotId } from '../../Routing';
import { FLOW_BLOCKS_QUERY } from '.';
import {
  FlowBlocksQuery,
  FlowBlocksQueryVariables,
} from './@types/FlowBlocksQuery';

export const useFlowBlocks = (flowId: string) => {
  const botId = useCurrentBotId()!;
  const { data, ...queryState } = useQuery<
    FlowBlocksQuery,
    FlowBlocksQueryVariables
  >(FLOW_BLOCKS_QUERY, {
    variables: { botId, flowId },
    skip: !botId || !flowId,
  });

  return {
    flowBlocks: data?.bot.flowBlocks,
    ...queryState,
  };
};
