enum WebViewMessageEvents {
  signIn = 'signIn',
  logOut = 'logOut',
  openTermsOfUse = 'openTermsOfUse',
  openURL = 'openURL',
}

type Message =
  | {
      type: Exclude<keyof typeof WebViewMessageEvents, 'openURL'>;
    }
  | {
      type: Extract<keyof typeof WebViewMessageEvents, 'openURL'>;
      payload: {
        url: string;
      };
    };

export const sendMessageToWebView = (message: Message) => {
  /**
   * обрабатывается здесь https://github.com/chatfuel-lab/livechat-webview/blob/ios/src/utils/getOnWebViewMessage.ts
   */
  window.ReactNativeWebView?.postMessage(
    `${message.type}${
      'payload' in message && message.payload
        ? `:${JSON.stringify(message.payload)}`
        : ''
    }`,
  );
};
