import client from '@common/services/ApolloService';
import { BOT_PRICING_CURRENT_USERS_DATA_QUERY } from './queries';
import {
  BotPricingCurrentUsersDataQuery,
  BotPricingCurrentUsersDataQueryVariables,
} from './@types/BotPricingCurrentUsersDataQuery';

export const refetchBotPricingCurrentUsersData = (botId: string) =>
  client.query<
    BotPricingCurrentUsersDataQuery,
    BotPricingCurrentUsersDataQueryVariables
  >({
    query: BOT_PRICING_CURRENT_USERS_DATA_QUERY,
    variables: { botId },
    fetchPolicy: 'network-only',
  });
