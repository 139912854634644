import { isUserHasAuthToken } from '@utils/Auth/isUserHasAuthToken';
import { queryLocaleData } from '@utils/Data/Admin/Locale/ForcedLanguageExperiment/queryLocaleData';

const FALLBACK_LNG = 'en';

export const getDashboardLanguage = async (): Promise<string> => {
  if (!isUserHasAuthToken()) return FALLBACK_LNG;

  try {
    return (await queryLocaleData()) ?? FALLBACK_LNG;
  } catch {
    return FALLBACK_LNG;
  }
};
