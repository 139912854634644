import { Observable } from 'rxjs';
import client from '@common/services/ApolloService';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';
import memoize from 'lodash-es/memoize';
import { INSTAGRAM_ACCOUNT_QUERY } from './queries';
import {
  InstagramAccountQuery,
  InstagramAccountQueryVariables,
} from './@types/InstagramAccountQuery';

export const getConnectedInstagramObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery<InstagramAccountQuery, InstagramAccountQueryVariables>({
      query: INSTAGRAM_ACCOUNT_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: {
        botId,
      },
    }) as ObservableInput<ApolloQueryResult<InstagramAccountQuery>>,
  ).map(({ data }) => {
    return {
      isConnected: !!data?.bot?.instagram?.connected,
      instagramHandle: data?.bot?.instagram?.business_account?.handle,
    };
  }),
);
