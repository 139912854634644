export interface InputLikeElement extends Element {
  setRangeText?: (text: string) => void;
  selectionStart?: number | null;
  selectionEnd?: number | null;
}

/* eslint-disable no-param-reassign */
export const insertText = (text: string, inputElement: InputLikeElement) => {
  const isSuccess = document.execCommand('insertText', false, text);
  // Firefox workaround for input and textarea
  if (!isSuccess && typeof inputElement.setRangeText === 'function') {
    const start = inputElement.selectionStart;
    inputElement.setRangeText(text);
    if (start !== null && start !== undefined) {
      const caretPos = start + text.length;
      inputElement.selectionStart = caretPos;
      inputElement.selectionEnd = caretPos;
    }
    const e = document.createEvent('UIEvent');
    e.initEvent('input', true, false);
    inputElement.dispatchEvent(e);
  }
};
