import { useEffect, useRef } from 'react';

/**
 * флаг для асинхронных событий, чтобы прекращать выполнение после unmount
 */
export const useUnmountRef = () => {
  const unmountRef = useRef(false);
  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    [],
  );

  return unmountRef;
};
