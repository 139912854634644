import { useState } from 'react';
import useVisibilityChange from 'use-visibility-change';

const MIN_TIME_TO_CREATE_PAGE_TIMEOUT = 5 * 1000;

export const useFacebookPageCreate = (onReturnAfterCreate?: () => void) => {
  const [isLeaveToCreatePage, setIsLeaveToCreatePage] = useState<boolean>(
    false,
  );

  useVisibilityChange({
    onShow: ({ lastSeenDate }) => {
      if (!isLeaveToCreatePage || !onReturnAfterCreate) {
        return;
      }
      setIsLeaveToCreatePage(false);
      const creatingPageTime = Date.now() - lastSeenDate;
      if (creatingPageTime > MIN_TIME_TO_CREATE_PAGE_TIMEOUT) {
        onReturnAfterCreate();
      }
    },
  });

  return {
    isLeaveToCreatePage,
    facebookPageCreate: () => {
      setIsLeaveToCreatePage(true);
      window.open(
        'https://www.facebook.com/pages/create/?ref_type=pages_browser',
        '_blank',
      );
    },
  };
};
