import { DomainCookie } from 'cf-common/src/DomainCookie';
import { getTopLevelDomain } from 'cf-common/src/domain';
import { checkWLDomain } from 'cf-common/src/whitelabel';

const COOKIE_NAME = '__chatfuel_signed_in';

const domain = getTopLevelDomain();
const isWLdomain = checkWLDomain(domain);
const domainCookie = isWLdomain ? null : new DomainCookie(domain);

export const setLoginCookie = () => {
  if (domainCookie) {
    domainCookie.set(COOKIE_NAME, '1');
  }
};

export const removeLoginCookie = () => {
  if (domainCookie) {
    domainCookie.delete(COOKIE_NAME);
  }
};
