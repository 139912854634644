import gql from 'graphql-tag';

export const PROMO_CODE_FRAGMENT = gql`
  fragment promoCodeFragment on PromoCode {
    id
    months
    expirationDate
    availableTiers
    availableCountries
    promoCode
    discountPercents
  }
`;

export const ASSIGNED_PROMO_CODE_FRAGMENT = gql`
  fragment assignedPromoCodeFragment on AssignedPromoCode {
    id
    promoCode {
      ...promoCodeFragment
    }
    pageId
    adminId
    status
  }
  ${PROMO_CODE_FRAGMENT}
`;
