import { getLocale } from './helpers';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from './constants';
import { FormatPriceConfig } from './types';

export function formatPrice(price: number, config?: FormatPriceConfig) {
  return new Intl.NumberFormat(getLocale(config), {
    style: 'currency',
    currency: config?.currency ?? DEFAULT_CURRENCY,
    minimumFractionDigits: config?.decimals,
  }).format(price);
}

export function formatPriceEx(price: number, currency: string) {
  return formatPrice(price, { currency });
}

export function formatPricePromo(price: number, config?: FormatPriceConfig) {
  return formatPrice(price, { ...config, decimals: 0 });
}

export const getCurrencySymbolByLocale = (
  currency = DEFAULT_CURRENCY,
  locale = DEFAULT_LOCALE,
) => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

export const getCurrencyFormatOptions = (currency: string = DEFAULT_CURRENCY) =>
  Object.fromEntries(
    new Intl.NumberFormat(getLocale({ currency }), {
      style: 'currency',
      currency,
    })
      .formatToParts(1000000)
      .map(({ type, value }) => [type, value]),
  );
