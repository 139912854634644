import gql from 'graphql-tag';

export const DELETE_INVITE_MUTATION = gql`
  mutation DeleteInviteQuery($inviteId: String!) {
    deleteInvite(inviteId: $inviteId)
  }
`;

export const CREATE_BOT_INVITE_MUTATION = gql`
  mutation CreateBotInviteMutation($botId: String!, $roleId: String!) {
    createBotInvite(botId: $botId, roleId: $roleId)
  }
`;

export const CREATE_WORKSPACE_INVITE_MUTATION = gql`
  mutation CreateWorkspaceInviteMutation(
    $workspaceId: String!
    $roleId: String!
  ) {
    createWorkspaceInvite(workspaceId: $workspaceId, roleId: $roleId)
  }
`;
