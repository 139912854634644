import { QueryHookOptions, useQuery } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';
import {
  AiStatisticsQuery,
  AiStatisticsQueryVariables,
} from './@types/AiStatisticsQuery';
import { AI_STATISTICS_QUERY } from './queries';

export const useAiStatistics = (
  options?: QueryHookOptions<AiStatisticsQuery, AiStatisticsQueryVariables>,
) => {
  const botId = useCurrentBotId()!;
  const { data, ...queryResult } = useQuery<
    AiStatisticsQuery,
    AiStatisticsQueryVariables
  >(AI_STATISTICS_QUERY, {
    variables: {
      botId,
    },
    skip: !botId,
    ...options,
  });

  const instagramStatistics = data?.bot.aiStatistics.find(
    ({ platform }) => platform === Platform.instagram,
  );
  const facebookStatistics = data?.bot.aiStatistics.find(
    ({ platform }) => platform === Platform.facebook,
  );

  return {
    instagramStatistics,
    facebookStatistics,
    ...queryResult,
  };
};
