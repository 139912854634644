import { getCurrentDomain, getSubdomain } from '@utils/UrlUtils/domain';

export const isStagingDomain = () => {
  const subdomain = getSubdomain(getCurrentDomain());
  return subdomain.includes('staging');
};

export const getStagingId = (domain: string) =>
  domain.substring(domain.indexOf('staging'), domain.length);

export const isDemoDomain = () =>
  getSubdomain(getCurrentDomain()) === 'dashboard-demo';

export const isDevDomain = () =>
  /dashboard-\d/.test(getSubdomain(getCurrentDomain()));

export const getDevId = (domain: string) => domain.split('-')[1];

export const isLocalEnv = () =>
  getSubdomain(getCurrentDomain()) === 'localhost';
