import gql from 'graphql-tag';

export const BOT_GROUPING_FLOWS_QUERY = gql`
  query BotGroupingFlowsQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        title
        flows {
          id
          title
        }
      }
    }
  }
`;
