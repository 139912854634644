import { useLayoutEffect } from 'react';
import { useWhiteLabelAgencyStyles } from '@utils/WhiteLabel/useWhiteLabelAgencyStyles';
import { ResetMetaForMobile } from './ResetMetaForMobile';

export const useResetMetaForMobile = () => {
  const { data } = useWhiteLabelAgencyStyles();
  const whiteLabelColor = data?.navigationColor;

  useLayoutEffect(ResetMetaForMobile.viewport, []);

  useLayoutEffect(
    () => ResetMetaForMobile.themeColor(whiteLabelColor),
    [whiteLabelColor],
  );
};
