import { propEq } from 'ramda';
import * as css from './SendToMessengerOverlayService.css';
import {
  FbPluginEventHandlers,
  SendToMessengerButtonOverlay,
  SendToMessengerButtonOverlayProps,
} from './SendToMessengerButtonOverlay';

export class SendToMessengerOverlayService {
  private readonly buttonBox: HTMLDivElement;

  private readonly buttons: SendToMessengerButtonOverlay[] = [];

  constructor() {
    this.buttonBox = document.createElement('div');
    this.buttonBox.classList.add(css.buttonsBox);
    document.body.append(this.buttonBox);
  }

  getButton(dataRef: string) {
    return this.buttons.find(propEq('dataRef', dataRef));
  }

  addButton(
    props: Omit<SendToMessengerButtonOverlayProps, 'parent'>,
    handlers?: FbPluginEventHandlers,
  ) {
    if (this.getButton(props.dataRef)) {
      throw new Error('Button with this data ref already added');
    }
    const button = new SendToMessengerButtonOverlay(
      {
        ...props,
        parent: this.buttonBox,
      },
      handlers,
    );
    this.buttons.push(button);
    return button;
  }

  updateButton(
    dataRef: string,
    props: Partial<Omit<SendToMessengerButtonOverlayProps, 'parent'>> = {},
    handlers?: FbPluginEventHandlers,
  ) {
    const button = this.buttons.find(propEq('dataRef', dataRef));
    button?.update(props, true, handlers);
  }

  resetButton(
    dataRef: string,
    props: Partial<Omit<SendToMessengerButtonOverlayProps, 'parent'>> = {},
    handlers?: FbPluginEventHandlers,
  ) {
    const button = this.buttons.find(propEq('dataRef', dataRef));
    if (button) {
      const buttonProps = button.getProps();
      this.removeButton(dataRef);
      this.addButton(
        {
          ...buttonProps,
          ...props,
        },
        handlers,
      );
    }
  }

  removeButton(dataRef: string) {
    const buttonIndex = this.buttons.findIndex(propEq('dataRef', dataRef));
    if (buttonIndex === -1) {
      return false;
    }
    this.buttons[buttonIndex].destroy();
    this.buttons.splice(buttonIndex, 1);
    return true;
  }
}
