import { MutationHookOptions, useMutation } from 'react-apollo';
import { CONNECT_HUBSPOT_MUTATION } from './mutation';
import {
  ConnectHubSpotMutation,
  ConnectHubSpotMutationVariables,
} from './@types/ConnectHubSpotMutation';

export const useConnectHubSpot = (
  payload?: MutationHookOptions<
    ConnectHubSpotMutation,
    ConnectHubSpotMutationVariables
  >,
) => {
  return useMutation<ConnectHubSpotMutation, ConnectHubSpotMutationVariables>(
    CONNECT_HUBSPOT_MUTATION,
    payload,
  );
};
