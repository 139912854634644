import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { log } from 'cf-common/src/logger';
import { BotTabs, getTabLink } from '@utils/Routing';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { handleShopifyConnectionError } from '@utils/Integrations/Shopify/utils/handleShopifyConnectionError';
import {
  ConfirmShopifyConnectMutation,
  ConfirmShopifyConnectMutationVariables,
} from './@types/ConfirmShopifyConnectMutation';

export const CONFIRM_SHOPIFY_CONNECT_MUTATION = gql`
  mutation ConfirmShopifyConnectMutation(
    $authCode: String!
    $shop: String!
    $queryString: String!
    $stateKey: String!
    $botId: String!
  ) {
    confirmShopifyConnection(
      auth_code: $authCode
      shop: $shop
      query_string: $queryString
      state_key: $stateKey
      botId: $botId
    ) {
      connectedBot {
        id
        connectedShop {
          id
          domain
          myshopify_domain
          name
        }
      }
      state
    }
  }
`;

export interface ConfirmShopifyConnectParams
  extends ConfirmShopifyConnectMutationVariables {
  allBots?: Array<{ id: string }>;
}

export const useConfirmShopifyConnection = () => {
  const history = useHistory();
  const [confirmShopifyConnectRaw, queryResult] = useMutation<
    ConfirmShopifyConnectMutation,
    ConfirmShopifyConnectMutationVariables
  >(CONFIRM_SHOPIFY_CONNECT_MUTATION);

  const confirmShopifyConnect = useCallback(
    ({
      shop,
      botId,
      stateKey,
      queryString,
      authCode,
      allBots,
    }: ConfirmShopifyConnectParams) =>
      confirmShopifyConnectRaw({
        variables: {
          authCode,
          shop,
          queryString,
          stateKey,
          botId,
        },
      })
        .then(() => history.push(getTabLink(BotTabs.home, botId)))
        .catch((errors) => {
          const error = extractGQLErrorData(errors)!;
          log.error({ msg: 'Shopify auth confirmation error', error: errors });
          handleShopifyConnectionError({
            botId,
            connectAccountError: error,
            doesBotBelongToUser: (botId) =>
              Boolean(allBots?.some(({ id }) => botId === id)),
            myshopifyDomain: shop,
          });
        }),
    [confirmShopifyConnectRaw, history],
  );

  return [confirmShopifyConnect, queryResult] as const;
};
