import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocalStorage } from '@utils/LocalStorage/useLocalStorage';
import { LS_SCHEMA } from 'cf-common/src/localStorage';

export function useLocalStorageForId<T>(
  localStorageKey: keyof LS_SCHEMA,
  id: string | undefined,
): [T | undefined, Dispatch<SetStateAction<T>>] {
  const safeId = id || '';
  const [data, setData] = useLocalStorage(localStorageKey);
  const setCallback = useCallback(
    (rawData) => {
      if (safeId) {
        setData({
          ...(data || {}),
          [safeId]: rawData,
        });
      }
      return rawData;
    },
    [data, setData, safeId],
  );
  return [data?.[safeId], setCallback];
}
