/**
 *  @description: A view that uses a spinning-wheel or slot-machine metaphor to show one or more sets of values.
 *                Just single column;
 *
 *  @ ???: should we use ui-{component} prefix for all UIKit components? there will be no confusion?
 *         & {name}-container or {name}-data postfix for data rich components?
 *
 */

import ng from 'angular';
import controller from './controller';
import template from './template.html';
import './ui-picker-view-dropdown.less';

export default ng
  .module('app.ui.uiPickerViewDropdown', [])
  .component('uiPickerViewDropdown', {
    controllerAs: 'vm',
    bindings: {
      /**
       * @property: {string[]}  datasource
       */
      datasource: '<',

      /**
       *  @property: {string}      value - currently selected value;
       */
      value: '<',

      /**
       * @callback: {value, index}      onChange - currently selected value;
       */
      onChange: '&',

      /**
       * @property: {boolean}     cf-open - is opened or not;
       */

      cfOpen: '<',

      /**
       * @callback: {$event} - onClick - useful to track open close state and detect click aways;
       */
      onClick: '&',

      /**
       * @property: {*} popover-with - css prop for popover;
       */
      popoverWidth: '<',

      onKeydown: '&',
    },
    template: () => template,
    controller,
  }).name;
