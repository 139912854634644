import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { clone } from 'ramda';
import { useCallback } from 'react';
import { useCurrentBotId } from '../../../Routing';
import {
  ADD_AI_INTENT_MUTATION,
  AI_INTENTS_LIST_QUERY,
  REMOVE_AI_INTENT_MUTATION,
  UPDATE_AI_INTENT_MUTATION,
} from './queries';
import {
  AiIntentsListQuery,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents as AiIntent,
  AiIntentsListQueryVariables,
} from './@types/AiIntentsListQuery';
import {
  UpdateAiIntentMutation,
  UpdateAiIntentMutationVariables,
} from './@types/UpdateAiIntentMutation';
import { removeTypename } from '../../../GQL/utils';
import { AI_INTENT_FRAGMENT } from '../common/queries';
import {
  RemoveAiIntentMutation,
  RemoveAiIntentMutationVariables,
} from './@types/RemoveAiIntentMutation';
import {
  AddAiIntentMutation,
  AddAiIntentMutationVariables,
} from './@types/AddAiIntentMutation';
import {
  createDefaultIntent,
  getAddAiIntentCacheUpdater,
  getRemoveAiIntentCacheUpdater,
  getUpdateAiIntentCacheUpdater,
} from './helpers';
import { useRolePermission } from '../../../Roles';
import { Permission } from '@common/services/RoleService';
import { BOT_LIVELINESS_QUERY } from '@utils/Data/Bot';
import { Platform } from '@globals';

export const useAiIntents = (aiGroupIndex: number = 0) => {
  const botId = useCurrentBotId();
  const client = useApolloClient();
  const { allowed, loading: permissionLoading } = useRolePermission({
    domain: 'ai',
    can: Permission.VIEW,
  });
  const { data, ...state } = useQuery<
    AiIntentsListQuery,
    AiIntentsListQueryVariables
  >(AI_INTENTS_LIST_QUERY, {
    variables: { botId },
    skip: !botId || !allowed || permissionLoading,
    notifyOnNetworkStatusChange: true,
  });

  const [updateIntentMutation] = useMutation<
    UpdateAiIntentMutation,
    UpdateAiIntentMutationVariables
  >(UPDATE_AI_INTENT_MUTATION, {
    refetchQueries: [{ query: BOT_LIVELINESS_QUERY, variables: { botId } }],
  });

  const [removeIntentMutation] = useMutation<
    RemoveAiIntentMutation,
    RemoveAiIntentMutationVariables
  >(REMOVE_AI_INTENT_MUTATION);

  const [addIntentMutation] = useMutation<
    AddAiIntentMutation,
    AddAiIntentMutationVariables
  >(ADD_AI_INTENT_MUTATION);

  const card = data?.bot.aiBlock.cards?.[0];
  const group = card
    ? (card as AiIntentsListQuery_bot_aiBlock_cards_AIPlugin).config.groups[
        aiGroupIndex
      ]
    : undefined;
  const groupId = group?.id;
  const cardId = card?.id;

  const aiIntents: AiIntent[] | undefined = group ? group.intents : undefined;

  const saveAiIntent = useCallback(
    (intent: AiIntent) => {
      if (!groupId || !cardId || !botId) {
        return undefined;
      }
      return updateIntentMutation({
        variables: {
          botId,
          groupId,
          cardId,
          intent: removeTypename(intent),
        },
        optimisticResponse: { updateAiIntent: [intent] },
        update: getUpdateAiIntentCacheUpdater(groupId),
      });
    },
    [botId, cardId, groupId, updateIntentMutation],
  );

  const updateAiIntentInCache = useCallback(
    (intent: AiIntent) =>
      client.writeFragment({
        id: intent.id,
        fragmentName: 'aiIntentFragment',
        fragment: AI_INTENT_FRAGMENT,
        data: clone(intent),
      }),
    [client],
  );

  const removeAiIntent = useCallback(
    (id: string) => {
      if (!groupId || !cardId) {
        return undefined;
      }
      return removeIntentMutation({
        variables: {
          intentId: id,
          cardId,
          groupId,
        },
        optimisticResponse: {
          removeAiIntent: true,
        },
        update: getRemoveAiIntentCacheUpdater(groupId, id, botId),
      });
    },
    [botId, cardId, groupId, removeIntentMutation],
  );

  const addAiIntent = useCallback(
    (platform: Platform) => {
      if (!groupId || !cardId) {
        return undefined;
      }
      const defaultIntent = createDefaultIntent(platform);
      return addIntentMutation({
        variables: {
          groupId,
          cardId,
          intent: removeTypename(defaultIntent),
        },
        optimisticResponse: {
          addAiIntent: defaultIntent,
        },
        update: getAddAiIntentCacheUpdater(groupId, botId),
      });
    },
    [addIntentMutation, botId, cardId, groupId],
  );

  return {
    aiIntents,
    saveAiIntent,
    updateAiIntentInCache,
    addAiIntent,
    removeAiIntent,
    ...state,
  };
};
