import { DIALOGS_PRICING_FRAGMENT } from '@utils/Data/Pricing/queries';
import gql from 'graphql-tag';

export const BOT_STATUS_DATA_QUERY = gql`
  query BotStatusDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      workspace_id
      pro {
        is_paused
        status
        debt
        days_before_cancel
        millis_before_cancel
        manual
      }
      pricingData {
        currency
        currentPrice
        currentUsers
        reached_limit
        currentMaxUsers
        pricing
      }
      dialogsPricing {
        ...dialogsPricingFragment
      }
      status {
        status
        page_info {
          id
        }
      }
      limits {
        usersLimit
        notificationsLimit
      }
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;
