import { ApolloError } from 'apollo-client';
import { log } from 'cf-common/src/logger';
import { clearMyShopifyDomain } from '../clearMyShopifyDomain';
import client from '../../../../common/services/ApolloService';
import { GET_SHOPIFY_CONNECT_LINK_MUTATION } from '../queries';
import { extractGQLErrorData } from '../../../GQL/utils';
import {
  GetShopifyConnectLinkMutation,
  GetShopifyConnectLinkMutationVariables,
} from '../@types/GetShopifyConnectLinkMutation';
import { redirect } from '../../../UrlUtils';
import { handleShopifyConnectionError } from './handleShopifyConnectionError';

interface GoToConnectShopifyProps {
  domain: string;
  botId: string;
  interruptedHref: string;
  /**
   * При подключении шопифая может возникнуть ошибка что стор уже подключен к другому боту
   * приэтом бот может быть совершенно другого юзера и у текущего юзера может не быть доступа
   * к нему
   */
  doesBotBelongToUser?: (botId: string) => boolean;
}

export const goToConnectShopifyAccount = async ({
  domain,
  botId,
  interruptedHref,
  doesBotBelongToUser,
}: GoToConnectShopifyProps) => {
  const myshopifyDomain = clearMyShopifyDomain(domain);
  const { data, errors } = await client.mutate<
    GetShopifyConnectLinkMutation,
    GetShopifyConnectLinkMutationVariables
  >({
    mutation: GET_SHOPIFY_CONNECT_LINK_MUTATION,
    errorPolicy: 'all',
    variables: {
      botId,
      myshopifyDomain,
      interruptedHref,
    },
  });

  if (errors?.length) {
    const connectAccountError = extractGQLErrorData({
      graphQLErrors: errors,
    } as ApolloError);

    if (connectAccountError) {
      log.error({
        msg: 'Error get Shopify redirect connect link',
        data: { error: connectAccountError },
      });

      handleShopifyConnectionError({
        botId,
        connectAccountError,
        doesBotBelongToUser,
        myshopifyDomain,
      });
    }
  }

  const redirectLink = data?.getShopifyRedirectConnectLink.url;

  if (redirectLink) {
    redirect(redirectLink);
  }
};
