import gql from 'graphql-tag';

export const UPDATE_WHATSAPP_MESSAGING_SETTINGS = gql`
  mutation UpdateWhatsappMessagingSettingsMutation(
    $botId: String!
    $payload: WhatsappMessagingSettingsInput!
  ) {
    updateWhatsappMessagingSettings(botId: $botId, payload: $payload) {
      id
      hasEmbeddedSignupToken
      whatsappBusinessAccount {
        id
        name
        timezone_id
        message_template_namespace
      }
      phone {
        id
        quality_rating
        display_phone_number
        code_verification_status
        normalized_phone_number
        verified_name
        is_on_biz_app
      }
      webhookUrl
      qrCode {
        code
        prefilled_message
        deep_link_url
        qr_image_url
      }
    }
  }
`;
