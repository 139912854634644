import { Editor } from 'slate-react';

export const moveCaretToEndDocumentIfNeed = (editorInstance: Editor) => {
  const firstNode = editorInstance.value.document.getFirstText();
  const { selection } = editorInstance.value;
  if (
    firstNode &&
    firstNode.key === selection.anchor.key &&
    selection.anchor.offset === 0 &&
    selection.isBlurred
  ) {
    editorInstance.moveToEndOfDocument();
  }
  editorInstance.focus();
};
