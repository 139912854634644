import { getBlockTitleByEntryPointId } from '@components/FlowBuilder/views/helpers/getBlockTitle';
import { getShortcutPluginDefaultData } from '@components/Plugins/common/getShortcutPluginDefaultData';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';

import { addCounterSuffix } from '@utils/String/addCounterSuffix';
import { useToaster } from '@ui/Toaster/ToasterProvider';

import { useCurrentBotId } from '@utils/Routing';
import { FLOW_GROUPS_SUGGEST_QUERY } from '@components/BlocksSelector2';
import { Platform } from '@globals';
import {
  FlowGroupTitlesQuery,
  FlowGroupTitlesQueryVariables,
} from './@types/FlowGroupTitlesQuery';
import {
  AddFlowGroupMutation,
  AddFlowGroupMutation_addFlowGroup,
  AddFlowGroupMutationVariables,
} from './@types/AddFlowGroupMutation';
import {
  AddFlowMutation,
  AddFlowMutation_addFlow,
  AddFlowMutationVariables,
} from './@types/AddFlowMutation';

import { getFlowCardDefaultConfigWrapper } from './AddFlowBlock';
import { ADD_FLOW_GROUP_MUTATION, ADD_FLOW_MUTATION } from './AddFlowMutation';
import { FLOW_GROUP_TITLES_QUERY, FLOW_GROUPS_QUERY } from './query';
import { updateCacheAfterAddedFlow } from './updateCacheAfterAddedFlow';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';

export const getDefaultGroupTitle = memoize(() =>
  i18next.t('modernComponents.FlowBuilder.defaultNames.flowGroup'),
);

interface useAddFlowProps {
  onCompleted?: (result: AddFlowMutation_addFlow) => void;
  onError?: () => void;
  refetchQueries?: PureQueryOptions[];
}

export type BlockProps = {
  subtype: string;
  pluginIds: string[];
};

export interface addFlowProps {
  parentGroupId?: string;
  block?: BlockProps;
  title?: string;
  platform?: Platform;
  flowTitle?: string;
}

export const useAddFlow = (addFlowProps?: useAddFlowProps) => {
  const { onCompleted, onError, refetchQueries } = addFlowProps ?? {};
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();

  const [showToaster, setShowToaster] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addFlowMutation, { error }] = useMutation<
    AddFlowMutation,
    AddFlowMutationVariables
  >(ADD_FLOW_MUTATION, {
    onCompleted: ({ addFlow }) => {
      setLoading(false);
      onCompleted?.(addFlow);
    },
    onError: () => {
      setShowToaster(true);
      setLoading(false);
      onError?.();
    },
    refetchQueries: [
      ...(refetchQueries ?? []),
      { query: FLOW_GROUPS_SUGGEST_QUERY, variables: { botId } },
    ],
  });

  const addFlow = useCallback(
    ({
      parentGroupId,
      block,
      platform = Platform.facebook,
      flowTitle,
    }: addFlowProps): Promise<AddFlowMutation | null | undefined> => {
      return new Promise((resolve) => {
        if (botId && parentGroupId) {
          addFlowMutation({
            variables: {
              groupId: parentGroupId,
              block: block
                ? {
                    title: getBlockTitleByEntryPointId(
                      block?.pluginIds[0] || '',
                    ),
                    subtype: block.subtype,
                    cards: block.pluginIds.map((plugin_id) => ({
                      plugin_id:
                        getShortcutPluginDefaultData(plugin_id)?.pluginType ||
                        plugin_id,
                      configWrapper: getFlowCardDefaultConfigWrapper(plugin_id),
                    })),
                  }
                : undefined,
              platform,
              flowTitle,
            },
            update: (cache, { data }) => {
              if (data) {
                updateCacheAfterAddedFlow(
                  cache,
                  botId,
                  data.addFlow,
                  parentGroupId,
                );
              }
              resolve(data!);
            },
          });
          setLoading(true);
        }
      });
    },
    [botId, addFlowMutation],
  );

  useEffect(() => {
    if (showToaster) {
      setShowToaster(false);
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {window.i18next.t('AddFlow-JSXText--180-adding-flow-failed')}
          </div>
        ),
        timeout: 2000,
        closeButton: true,
      });
    }
  }, [showToaster, addToaster]);

  return {
    addFlow,
    loading,
    error,
  };
};

interface useAddFlowGroupProps {
  onCompleted?: (result: AddFlowGroupMutation_addFlowGroup) => void;
  onError?: () => void;
}

export const useAddFlowGroup = ({
  onCompleted,
  onError,
}: useAddFlowGroupProps) => {
  const botId = useCurrentBotId()!;
  const { addToaster } = useToaster();

  const [showToaster, setShowToaster] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: titlesData } = useQuery<
    FlowGroupTitlesQuery,
    FlowGroupTitlesQueryVariables
  >(FLOW_GROUP_TITLES_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
    fetchPolicy: 'cache-only',
  });

  const [addFlowGroupMutation, { error }] = useMutation<
    AddFlowGroupMutation,
    AddFlowGroupMutationVariables
  >(ADD_FLOW_GROUP_MUTATION, {
    refetchQueries: [
      { query: FLOW_GROUPS_QUERY, variables: { botId } },
      { query: FLOW_GROUP_TITLES_QUERY, variables: { botId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: ({ addFlowGroup }) => {
      setLoading(false);
      onCompleted?.(addFlowGroup);
    },
    onError: () => {
      setShowToaster(true);
      setLoading(false);
      onError?.();
    },
  });

  useEffect(() => {
    if (showToaster) {
      setShowToaster(false);
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {window.i18next.t('AddFlow-JSXText-1571-adding-group-failed')}
          </div>
        ),
        timeout: 2000,
        closeButton: true,
      });
    }
  }, [showToaster, addToaster]);

  const addFlowGroup = useCallback(
    (explicitBotId?: string) => {
      let index = 0;

      const titles = titlesData?.bot.flow_groups?.map((flow) => flow.title);
      if (titles) {
        while (
          titles.includes(addCounterSuffix(getDefaultGroupTitle(), index))
        ) {
          index += 1;
        }
      }

      setLoading(true);
      return addFlowGroupMutation({
        variables: {
          botId: explicitBotId || botId,
          title: addCounterSuffix(getDefaultGroupTitle(), index),
          collapsed: true,
          position: 0,
        },
      });
    },
    [botId, addFlowGroupMutation, titlesData],
  );

  return {
    loading,
    error,
    addFlowGroup,
  };
};

export const useAddFlowGroupBasic = () => {
  const [addFlowGroupMutation, queryResult] = useMutation<
    AddFlowGroupMutation,
    AddFlowGroupMutationVariables
  >(ADD_FLOW_GROUP_MUTATION);

  const onAddFlowGroup = useCallback(
    (variables: AddFlowGroupMutationVariables) =>
      addFlowGroupMutation({
        variables,
      }),
    [addFlowGroupMutation],
  );

  return [onAddFlowGroup, queryResult] as const;
};
