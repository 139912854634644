import ng from 'angular';
import keycode from 'keycode';
import {
  merge,
  last,
  is,
  append,
  objOf,
  dropLast,
  pipe,
  always,
  cond,
  both,
  prop,
  T,
  either,
  reject,
  isEmpty,
  remove,
  filter,
} from 'ramda';
import './chip-input.less';
import { position } from 'caret-pos';
import memoize from 'lodash-es/memoize';
// helper

const TEXT_BOX_CLASS = '.js-chip-input-text-filed-box';
/**
 * @description: if this.value is array we thing this is multi value input;
 * @param: { (string|string[]) => boolean }
 */
const isMultiValue = is(Array);
const addEmptyValue = append('');
const valueToOnChangeEvent = objOf('value');
const isFocused = prop('textFieldFocus');
const getValue = prop('value');
const isThisMultiValue = pipe(getValue, isMultiValue);
const bothIsMultiAndFocused = both(isFocused, isThisMultiValue);
const getItemsToDisplay = cond([
  [bothIsMultiAndFocused, pipe(getValue, dropLast(1))],
  [isThisMultiValue, pipe(getValue, filter(Boolean))],
  [T, always(undefined)],
]);
const getValueToEdit = cond([
  [bothIsMultiAndFocused, pipe(getValue, last)],
  [isThisMultiValue, always('')],
  [T, getValue],
]);
/**
 * @description: check if event is key left
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventLeftKey = ($event) => keycode($event) === 'left';
/**
 * @description: check if event is key right
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventRightKey = ($event) => keycode($event) === 'right';
/**
 * @description: check if event is key Enter
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventEnterKey = ($event) => keycode($event) === 'enter';
/**
 * @description: check if event is key backspace
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventDelKey = ($event) => keycode($event) === 'delete';
/**
 * @description: check if event is key del
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventBackspaceKey = ($event) => keycode($event) === 'backspace';
/**
 * @description: check if event is key del or backspace
 * @param {HTMLKeyEvent} $event -
 * @return {boolean} -
 */
const isEventDeleteOrBackspace = either(isEventBackspaceKey, isEventDelKey);
const isEventRightOrLeftKey = either(isEventLeftKey, isEventRightKey);
const getDirectionForEvent = cond([
  [isEventLeftKey, always(-1)],
  [isEventEnterKey, always(1)],
  [isEventRightKey, always(1)],
  [T, always(0)],
]);

/**
 * @description: in replace last element is array
 * @example updateLastElement(1,[2,3,4]) -> [2,3,1];
 * @param {*} elem - elements to replace
 * @param {Array} array - replace in array;
 * @return {*} - return new array;
 */
const updateLastElement = (elem, array) =>
  pipe(dropLast(1), append(elem))(array);

const defaultTypesConfig = {
  active: 'active',
  error: 'error',
  hover: '',
  normal: '',
};

class ChipInputCtrl {
  /**
   * @description -constructor ;
   * @param {*}$element-
   * @param {*} $scope-
   * @param {*} $timeout -
   * @param {*} CollectionUtils -
   * @param {*} DOMService -
   */
  constructor(
    $element,
    $scope,
    $timeout,
    $window,
    CollectionUtils,
    DOMService,
  ) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.textFieldHover = false;
    this.textFieldFocus = false;
    this.touched = false;
    this.ctx = this.ctx || {};
    this.ctx.focus = this._focus.bind(this);
    this.ctx.blur = this._blur.bind(this);
    this.ctx.selectAll = this.selectAll.bind(this);
    this.ctx.scrollToEnd = this.scrollToEnd.bind(this);
    this.textFieldCtx = {};
    this.CollectionUtils = CollectionUtils;
    this.DOMService = DOMService;

    /**
     * @description: to support multi value input we check if value is array or not;
     * if yes only last value is editable other just printed;
     * _getEditValue = (value: string | string[]) => string?
     * @private
     */
    this._getEditValue = memoize(getValueToEdit, getValue);
    this._getItemsToDisplay = memoize(getItemsToDisplay, getValue);
    this._getSomeItemsToDisplay = () => {
      const itemsToDisplay = this._getItemsToDisplay(this);
      return itemsToDisplay && itemsToDisplay.length;
    };
  }

  /**
   * @description: add listners on mouseenter to handle over status;
   * @return {void}-
   */
  $onInit() {
    this.$element.on('mouseenter', () => {
      this._setHoverStatus(true);
    });
    this.$element.on('mouseleave', () => {
      this._setHoverStatus(false);
    });
  }

  _showCommanForIndex($index) {
    return isFocused(this) || this._itemsToDisplay.length - 1 !== $index;
  }

  /**
   * @description: clean up
   * @return {void} -
   */ $onDestroy() {
    this.$element.off('mouseenter');
    this.$element.off('mouseleave');
  }

  /**
   * @description: on value change; just notify parent or and may be hack last element in array;
   * @param {Array<String> | String} value - new value of the input;
   * @private
   * @return {void} -
   */
  _onChange({ value }) {
    const { value: currentValue, onChange } = this;
    if (isMultiValue(currentValue)) {
      value = updateLastElement(value, currentValue);
    }
    onChange({ value });
  }

  /**
   * @description self explanatory
   * @param {boolean} _textFieldHover -
   * @private
   * @return {*} -
   */
  _setHoverStatus(_textFieldHover) {
    this.$scope.$evalAsync(() => {
      this.textFieldHover = _textFieldHover;
    });
  }

  /**
   * @description: get input and scroll parent div to it; useful to scroll to input for example when multivalue intput and focusing
   * @private
   * @return {void} -
   */
  _scrollToInput() {
    const {
      DOMService: { scrollToElement },
    } = this;
    const inputContainer = this.$element.find('.js-input-container')[0];
    if (inputContainer) {
      scrollToElement(inputContainer);
    }
  }

  /**
   * @description: input has texfield box and it can be different colors and types; get config of text-field-box
   * @return {*} - config of text field box {
   *  active: 'active',
   *  error: 'error',
   *  hover: '',
   *  normal: '',
   *  }
   * @private
   */ _getTypes() {
    return merge(defaultTypesConfig, this.types || {});
  }

  /**
   * @description: check whether to show text box as an error;
   * @return {string} -
   * @private
   */ _shouldBeErrorType() {
    const { error } = this._getTypes();
    return this.touched && !!this.error ? error : '';
  }

  /**
   * @description: check whether to show text box as an hover;
   * @return {string} -
   * @private
   */ _shouldBeHoverType() {
    const { hover } = this._getTypes();
    return this.textFieldHover === true ? hover : '';
  }

  /**
   * @description: check whether to show text box as an active (focus);
   * @return {string} -
   * @private
   */ _shouldBeActiveType() {
    const { active } = this._getTypes();
    const { textFieldFocus, selectedItemIndex } = this;
    return textFieldFocus === true || selectedItemIndex != null ? active : '';
  }

  /**
   * @description: check whether to show text box as a normal;
   * @return {string} -
   * @private
   */ _shouldBeNormal() {
    return this._getTypes().normal;
  }

  /**
   * @description: imperative method to blur input;
   * @private
   * @return {void} -
   */ _blur() {
    this.log('_blur');
    this.setTextFieldFocus(false);
  }

  /**
   * @description: imperative method to focus input;
   * @private
   * @return {void} -
   */ _focus() {
    // fixme @p2 refactor same way blur; do not blur if cfDisabeld + add _blur;
    if (!this.cfDisabled) {
      this.selectedItemIndex = null;
      this.setTextFieldFocus(true);
    }
  }

  /**
   * @description: simple getter for text-box
   * @return {HTMLElement|null} -
   * @private
   */
  _getTextFieldBoxDOMElement() {
    return this.$element.find(TEXT_BOX_CLASS)[0];
  }

  /**
   * @description: to set un set focus state;
   * @param {*} value -
   * @return {void} -
   * @todo: what is difference with  _focus _blur ?
   */
  setTextFieldFocus(value) {
    this.log('setTextFieldFocus', value);
    // @note: you should to clear cache because _getItemsToDisplay && _getEditValue should be memoized to value andtextFieldFocusthis._getEditValue.cache.clear();
    this._getItemsToDisplay.cache.clear();

    if (!value && this.textFieldCtx.deselect) {
      this.textFieldCtx.deselect();
    }

    if (value && this.textFieldCtx.caretToEnd && this.textFieldFocus) {
      this.textFieldCtx.caretToEnd();
    }

    this.textFieldFocus = value;
  }

  /**
   * @description: you listen for keydown and in case of multi value you:
   *               1) if you in the start of the input and press left you should focus last multivalue;
   *               2) if enter you andd new value;
   *               3) you should delete selected multivalue on delete
   * @param {HTMLKeydownEvent} $event -
   * @private
   * @return {void} -
   */
  _onKeydown({ $event }) {
    this.touched = true;
    if (isEventLeftKey($event) || isEventDeleteOrBackspace($event)) {
      const input = this._getInput();
      const { pos: currentCarretPosition } = position(input);
      // you are in the input start and press left;
      // you should select first item in
      if (currentCarretPosition === 0) {
        this.setSelectedItemForDirection(-1);
      }
      // TODO: do not notfiy parent?
      return;
    }
    if (
      isEventEnterKey($event) &&
      isMultiValue(this.value) &&
      last(this.value)
    ) {
      this._addNewValueToMultiInput();
      $event.preventDefault();
    }
    this.onKeydown({ $event });
  }

  /**
   * @description: if this is multivalue input you should add new value in the end to be able to edit it on focus;
   * @private
   * @return {void} -
   */
  _addNewValueToMultiInput() {
    const { value, onChange } = this;
    if (isMultiValue(value)) {
      this.log('_addNewValueToMultiInput');
      this._resetSelectedIndex();
      // TODO: why I need to push?
      // value.push('');
      pipe(addEmptyValue, valueToOnChangeEvent, onChange)(value);

      this.scrollToEnd();
    }
  }

  /**
   * @description: simpel getter for html
   * @return {HTMLElement | null} -
   * @private
   */
  _getInput() {
    return this.$element.find('.js-editable-element')[0];
  }

  /**
   * @description: focus handle should be self explanatory;
   * @param {HTMLFocusEvent} $event -
   * @private
   * @return {void} -
   */
  _onFocus($event) {
    this.setTextFieldFocus(true);
    this._addNewValueToMultiInput();
    this._scrollInputInTextBox();
    this.onFocus({ $event });
  }

  _scrollInputToTop() {
    const textfield = this._getTextFieldBoxDOMElement();
    textfield.scrollTop = 0;
  }

  /**
   * @description: scroll to down fo the textbox ????
   * @return {void} -
   * @private
   */
  _scrollInputInTextBox() {
    const textfield = this._getTextFieldBoxDOMElement();
    textfield.scrollTop = textfield.scrollHeight;
  }

  /**
   * @description: blur handle should be self explanatory;
   * @param {HTMLFocusEvent} $event -
   * @private
   * @return {void} -
   */
  _onBlur({ $event }) {
    this.log('_onBlur');
    const { value, onChange } = this;
    if (isMultiValue(value)) {
      pipe(reject(isEmpty), valueToOnChangeEvent, onChange)(value);
    }
    this.setTextFieldFocus(false);
    this.onBlur({ $event });
  }

  /**
   * @description: you should focus on click on any part of the textfield;
   * @param {HTMLClickEvent} $event -
   * @private
   * @return {void} -
   */
  _onTextFieldBoxClick($event) {
    this.log('_onTextFieldBoxClick');

    $event.stopPropagation();
    if (!$event.target.classList.contains('js-editable-element')) {
      $event.preventDefault();
    }

    if (this.allowLinkTransition && $event.target.nodeName === 'A') {
      this.$window.open($event.target.href);
      return;
    }

    this._focus($event);
    this.onClick({ $event });
  }

  /**
   * @description: multivalue singl item click handler; when you click on multivalue you should select it;
   * @param {HTMLClickEvent} $event -
   * @param {number} $index -
   * @return {void} -
   * @private
   */
  _onValueItemClick({ $event, $index }) {
    if (isFocused(this) || this.selectedItemIndex != null) {
      $event.preventDefault();
      $event.stopPropagation();
      this._setSelectedItemIndex($index);
    }
    this.onClick({ $event });
  }

  /**
   * @description: ???
   * @param {HTML} $event -
   * @return {void} -
   * @private
   */
  _onTextFieldBoxKeydown({ $event }) {
    this.log('_onTextFieldBoxKeydown');
    const { value, selectedItemIndex, onChange } = this;
    if ($event.target === this._getTextFieldBoxDOMElement()) {
      if (isEventRightOrLeftKey($event) || isEventEnterKey($event)) {
        // prevent view from scrolling left and right;
        $event.preventDefault();
        if (
          (isEventEnterKey($event) || isEventRightKey($event)) &&
          isMultiValue(value) &&
          this.selectedItemIndex === value.length - 1
        ) {
          // focus on last element;
          this._focus();
          return;
        }

        if (
          isEventLeftKey($event) &&
          isMultiValue(value) &&
          this.selectedItemIndex === 0
        ) {
          return;
        }

        const direction = getDirectionForEvent($event);
        this.setSelectedItemForDirection(direction);
      }
      if (
        isEventDeleteOrBackspace($event) &&
        isMultiValue(value) &&
        selectedItemIndex != null
      ) {
        pipe(
          remove(selectedItemIndex, 1),
          valueToOnChangeEvent,
          onChange,
        )(value);
        this._setSelectedItemIndex(Math.max(selectedItemIndex - 1, 0));
        if (value.length === 1) {
          // last element remove;
          this._focus();
        }
      }
    }
  }

  /**
   * @description: select next or previous multivalue in cycle;
   * @return {void} -
   * @param {number} direction - can be -1 or 1
   */
  setSelectedItemForDirection(direction) {
    const {
      CollectionUtils: { nextIndex },
      selectedItemIndex,
      _getItemsToDisplay,
    } = this;
    const _itemsToDisplay = _getItemsToDisplay(this);
    if (_itemsToDisplay && _itemsToDisplay.length !== 0) {
      const currentSelectedIndex =
        selectedItemIndex == null ? _itemsToDisplay.length : selectedItemIndex;
      const next = nextIndex(direction, currentSelectedIndex, _itemsToDisplay);
      this._setSelectedItemIndex(next);
    }
  }

  /**
   * @description: on click away we should reset selected multivalue;
   * @private
   * @return {void} -
   */
  _onTextFieldClickAway() {
    this._resetSelectedIndex();
  }

  /**
   * @description: self explonatary
   * @private
   * @return {void} -
   */
  _resetSelectedIndex() {
    this._setSelectedItemIndex(null);
  }

  /**
   * @description: simple html getter;
   * @param {number} index -;
   * @return {HTMLElement|null} -
   * @private
   */
  _getHighliterAtIndex(index) {
    return this.$element.find('.js-highliter-container')[index];
  }

  /**
   * @description: you can scroll to one the multivalue (that is covered in highliter element;
   * @param {number} index - scroll to element at index;
   * @private
   * @return {void} -
   */
  _scrollToHighliterAtIndex(index) {
    const highlitedElement = this._getHighliterAtIndex(index);
    if (highlitedElement) {
      this.DOMService.scrollToElement(highlitedElement);
    }
  }

  /**
   * @description: self explonatary;
   * @param {number} index -
   * @return {void} -
   * @private
   */
  _setSelectedItemIndex(index) {
    if (index !== null) {
      const textBox = this._getTextFieldBoxDOMElement();
      textBox.focus();
      this._scrollToHighliterAtIndex(index);
      // you should fire this event after blur event;
      // fixme @p2 looks like hack;
      this.$timeout(() => this.onMultiValueItemSelect({ index }));
    }
    this.selectedItemIndex = index;
  }

  /**
   * @description: helper to debug; just logs any passed in items;
   *                usefull to enabled all debug info in component just by adding console.log here;
   * @param {any} e -
   * @return {void} -
   */
  log(...e) {
    // console.log('[chip-input]', ...e);
  }

  onDoubleClick() {
    this.selectAll();
  }

  selectAll() {
    const { textFieldCtx } = this;

    if (textFieldCtx) {
      textFieldCtx.selectAll();
    }
  }

  scrollToEnd() {
    const elInputsBox = this.$element.find(TEXT_BOX_CLASS)[0];
    setImmediate(() => {
      // DOM ready on next tic
      elInputsBox.scrollTop = elInputsBox.scrollHeight;
    });
  }
}

export default ng.module('app.ui.chipInput', []).component('chipInput', {
  controllerAs: 'vm',
  bindings: {
    chipType: '@',
    value: '<',
    error: '<',
    placeholder: '@',
    cfDisabled: '<',
    onFocus: '&',
    onBlur: '&',
    onKeydown: '&',
    onKeyup: '&',
    onChange: '&',
    onClick: '&',
    types: '<', // input has 3 states normal active (during focus) and error; here we put text field box types for each state;
    ctx: '<', // angular костыль. I will add focus method to ctx so you will be able to focus me;
    multiline: '<',
    multilineItems: '<',
    allowLinkTransition: '<',
    textFieldClass: '@',
    bordless: '<',
    onMultiValueItemSelect: '&',
    ellipsis: '@',
    noWrap: '<',
  },
  template: `
       <text-field-box
          data-testid="text-field-box"
          on-click-away="vm._onTextFieldClickAway({$event:$event})"
          class="js-chip-input-text-filed-box text-field-box_shape disabled-scroll-bars"
          ng-class="{
            'text-field-box_paddings':vm._getSomeItemsToDisplay(),
            'items-box_height': vm.multilineItems || vm.multiline,
            'items-box_block': vm.multilineItems,
          }"
          ng-keydown="vm._onTextFieldBoxKeydown({$event:$event})"
          tabindex="-1"
          type="{{vm._shouldBeErrorType() || vm._shouldBeActiveType() || vm._shouldBeHoverType() || vm._shouldBeNormal()}}"
          disabled="{{vm.disabled}}"
          ng-mousedown="vm._onTextFieldBoxClick($event)">
                <div
                  ng-class="{
                    'items-box_height': vm.multilineItems,
                    'items-no-wrap': vm.noWrap,
                  }"
                  class="input_container disabled-scroll-bars"
                >
                  <span
                    class="no-wrap js-highliter-container"
                    ng-class="{'items-box_height': vm.multilineItems}"
                    ng-repeat="v in vm._itemsToDisplay = vm._getItemsToDisplay(vm) track by  $index"
                  >
                    <highliter type="{{$index === vm.selectedItemIndex ? 'main-selected': 'main-normal'}}"
                    class="flex-none"
                    ng-mousedown="vm._onValueItemClick({$event:$event, $index:$index})"
                    >{{v}}</highliter><span class="body-3 commaa-margin" ng-if="vm._showCommanForIndex($index)">,</span>
                  </span>
                  <button
                    bordless="vm.bordless"
                    class="chip js-input-container"
                    ng-class="{
                      'multi-items-input_position':vm.multilineItems,
                      'remove-left-border':vm._getSomeItemsToDisplay()
                    }"
                    narrow="true"
                    multiline="{{vm.multiline}}"
                    type="{{vm.value ? vm.chipType : 'transparent'}}">
                    <text-field
                      text-field-class="{{vm._getSomeItemsToDisplay() ? 'transparent-placeholder':vm.textFieldClass}}"
                      class="js-text-field"
                      error="{{vm.touched && vm.textFieldFocus === false && !!vm.error}}"
                      focus="vm.textFieldFocus"
                      cf-disabled="{{vm.cfDisabled}}"
                      value="vm._getEditValue(vm)"
                      placeholder="{{!vm._getSomeItemsToDisplay() ? vm.placeholder || 'default' : 'x'}}"
                      on-change="vm._onChange({value:value})"
                      on-focus="vm._onFocus($event)"
                      on-blur="vm._onBlur({$event:$event})"
                      on-keydown="vm._onKeydown({$event:$event})"
                      on-keyup="vm.onKeyup({$event:$event});"
                      multi-line="vm.multiline"
                      ng-dblclick="vm.onDoubleClick()"
                      ctx="vm.textFieldCtx"
                      ></text-field>
                  </button>
          </div>
          <!--<span class="counter_shape" ng-if="vm._itemsToDisplay && vm._itemsToDisplay.length > 1 && !vm.textFieldFocus"><span>{{vm._itemsToDisplay.length}}</span></span>-->
       </text-field-box>
   `,
  controller: ChipInputCtrl,
}).name;
