import React, { forwardRef } from 'react';
import { DND_PLACEHOLDER_CLASS, useDnd } from './DndContext';

interface DndDraggableProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  id: string;
  hidePlaceholder?: boolean;
}

export const DndDraggable = forwardRef<HTMLDivElement, DndDraggableProps>(
  ({ type, id, children, hidePlaceholder, ...props }, ref) => {
    const { dndState } = useDnd();

    return (
      <>
        <div id={id} ref={ref} data-dnd-draggable-type={type} {...props}>
          {children}
        </div>
        {dndState.draggingId === id && !hidePlaceholder && (
          <div className={DND_PLACEHOLDER_CLASS}>{children}</div>
        )}
      </>
    );
  },
);

interface DndDraggableAnchorProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
}

export const DndDraggableAnchor: React.FC<DndDraggableAnchorProps> = ({
  children,
  type,
  ...props
}) => {
  return (
    <div data-dnd-draggable-type={type} {...props}>
      {children}
    </div>
  );
};
