import { fromPairs } from 'ramda';
import { Level, log } from 'cf-common/src/logger';
import { redirect } from './redirect';

const isSameDomainUrl = (url: string) => {
  const { hostname, origin, protocol } = window.location;
  try {
    const { hostname: urlHostname, origin: urlOrigin } = new URL(
      `${url.startsWith('//') ? protocol : ''}${url}`,
    );
    return hostname === urlHostname || origin === urlOrigin;
  } catch (error) {
    if (error instanceof Error) {
      log.warn({ error, msg: 'Not valid url' });
    }
    return false;
  }
};

const urlStartsWithProtocolRegexp = new RegExp('^(?:[a-z]+:)?//', 'i');
export const isUrlWithProtocol = (url: string) =>
  urlStartsWithProtocolRegexp.test(url);

const validUrlRegexp = new RegExp(
  '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?].+)$',
  'i',
);
export const isValidUrl = (url: string) => validUrlRegexp.test(url);

const relativeUrlRegexp = /(^\/[^/])|(^\/$)|(^#)/;
const isRelativePath = (url: string) => relativeUrlRegexp.test(url);

export const isSafeCFDomainUrl = (url: string) => {
  if (!isRelativePath(url)) {
    return isSameDomainUrl(url);
  }
  return true;
};

export const isAbsoluteCFDomainUrl = (url: string) => {
  return !isRelativePath(url) && isSameDomainUrl(url);
};

export const isUrlExternal = (urlString?: string) => {
  if (!urlString || isRelativePath(urlString)) {
    return false;
  }
  const urlStringWithProtocol = isUrlWithProtocol(urlString)
    ? urlString
    : `https://${urlString}`;
  return !isSameDomainUrl(urlStringWithProtocol);
};

export const isSafeUrl = (url: string) => {
  return isSafeCFDomainUrl(url) || isUrlExternal(url);
};

export const safeRedirect = (url: string, fallback = '/') => {
  if (isSafeCFDomainUrl(url)) {
    redirect(url);
  } else {
    log({
      msg: 'Trying to redirect to not safe URL!',
      level: Level.warn,
      data: {
        url,
      },
    });
    redirect(fallback);
  }
};

export const searchStringToObject = (searchString: string) => {
  const searchParams = new URLSearchParams(searchString);

  return searchParams
    ? fromPairs(Array.from(searchParams.entries()))
    : undefined;
};
