import { useMemo } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { useUserWhatsappPhoneEntries } from '@utils/Data/Whatsapp/useUserWhatsappPhoneEntries';
import { useWhatsappSettings } from '@utils/Data/Whatsapp/useWhatsappSettings';
import {
  WhatsappPhonesQuery_whatsappPhoneNumbers_phones as WhatsappPhone,
  WhatsappPhonesQuery_whatsappPhoneNumbers_businessAccount as WhatsappBusinessAccount,
  WhatsappPhonesQuery_whatsappPhoneNumbers as WhatsappPhoneEntry,
} from './@types/WhatsappPhonesQuery';

export { WhatsappPhone, WhatsappBusinessAccount, WhatsappPhoneEntry };

export const useWhatsappPhoneEntries = (botId: Maybe<string>) => {
  const {
    whatsappPhoneEntries: userPhoneEntries,
    loading: phonesLoading,
    error: userPhoneErrors,
  } = useUserWhatsappPhoneEntries(botId);
  const { whatsappSettings, loading: settingsLoading } =
    useWhatsappSettings(botId);

  /**
   * Если у текущего пользователя в доступных телефонах нет текущего подключенного
   * к боту, мы добавляем его
   */
  const whatsappPhoneEntries: WhatsappPhoneEntry[] = useMemo(() => {
    const entries: WhatsappPhoneEntry[] = userPhoneEntries ?? [];

    const connectedPhone: Maybe<WhatsappPhone> = whatsappSettings?.phone;
    if (!connectedPhone) return entries;
    const connectedAccount = whatsappSettings?.whatsappBusinessAccount!;
    const connectedEntry: WhatsappPhoneEntry = {
      __typename: 'WhatsappBusinessAccountToPhoneNumber',
      phones: [connectedPhone],
      businessAccount: connectedAccount,
    };

    const foundConnectedAccount = entries.find(
      ({ businessAccount }) => businessAccount.id === connectedAccount.id,
    );
    const hasConnectedPhone = foundConnectedAccount?.phones?.some(
      ({ id }) => id === connectedPhone.id,
    );

    if (foundConnectedAccount && hasConnectedPhone) return entries;

    if (foundConnectedAccount)
      return [
        ...entries.filter(
          ({ businessAccount }) => businessAccount.id !== connectedAccount.id,
        ),
        {
          ...foundConnectedAccount,
          phones: [...(foundConnectedAccount.phones ?? []), connectedPhone],
        },
      ];

    return [...entries, connectedEntry];
  }, [whatsappSettings, userPhoneEntries]);

  const whatsappAccounts = useMemo(() => {
    return whatsappPhoneEntries.map(({ businessAccount }) => businessAccount);
  }, [whatsappPhoneEntries]);

  const whatsappPhones = useMemo(() => {
    return whatsappPhoneEntries.flatMap(({ phones }) => phones ?? []);
  }, [whatsappPhoneEntries]);

  return {
    whatsappAccounts,
    whatsappPhones,
    whatsappPhoneEntries,
    loading: settingsLoading || phonesLoading,
    noFBToken: userPhoneErrors?.graphQLErrors?.[0].message === 'NO_FB_TOKEN',
  };
};
