import { createNewBlankBotMutation_createBlankBot } from '@common/services/GQLqueries/@types/createNewBlankBotMutation';
import { DataProxy } from 'apollo-cache';
import { log } from 'cf-common/src/logger';
import { lightBotPermissionsFragment } from './@types/lightBotPermissionsFragment';
import { DEFAULT_ADMIN_CURRENT_ROLE, DEFAULT_PRO_DATA } from './constants';
import { LIGHT_BOT_PERMISSIONS_FRAGMENT } from './queries';

export const addToBotListPermissionsCache = (
  bot: createNewBlankBotMutation_createBlankBot,
  store: DataProxy,
) => {
  const newBotWithDefaultPro = {
    ...bot,
    pro: DEFAULT_PRO_DATA,
    currentRole: DEFAULT_ADMIN_CURRENT_ROLE,
  };

  const fragmentId = `Bot:${bot.id}`;
  const fragmentName = 'lightBotPermissionsFragment';

  try {
    store.writeFragment<lightBotPermissionsFragment>({
      fragment: LIGHT_BOT_PERMISSIONS_FRAGMENT,
      id: fragmentId,
      data: newBotWithDefaultPro,
      fragmentName,
    });
  } catch (error) {
    log.error({
      error,
      data: { label: 'write lightBotPermissionsFragment' },
    });
  }
};
