import ng from 'angular';
import './avatar-stack.less';

export default ng.module('app.ui.avatarStack', []).component('avatarStack', {
  controllerAs: 'vm',
  transclude: true,
  bindings: {},
  template: `
        <div class="inline-avatar-box" data-ng-transclude></div>
   `,
}).name;
