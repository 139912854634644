import Maybe from 'graphql/tsutils/Maybe';
import { TierType } from '@globals';
import { PricingPlanTiersQuery_bot_pricingPlanTiers as Tier } from './@types/PricingPlanTiersQuery';
import { DialogsPricingQuery_bot_dialogsPricing } from './@types/DialogsPricingQuery';

export function calcPlanLimitReached(
  pricing: DialogsPricingQuery_bot_dialogsPricing | undefined,
  loading: boolean,
): boolean {
  if (loading || !pricing) return false;

  const planLimit = pricing.current_tier_limit;
  const reachedLimit = pricing.reached_limit;

  if (!planLimit || !reachedLimit) {
    return false;
  }

  return planLimit <= reachedLimit;
}

export function getPlanLimitReached(
  pricing?: DialogsPricingQuery_bot_dialogsPricing,
): boolean {
  if (!pricing) return false;
  const { current_tier_limit, reached_limit } = pricing;
  return current_tier_limit
    ? current_tier_limit <= (reached_limit ?? 0)
    : false;
}

export const getMoreExpensiveTier = (
  tiers: Array<Tier>,
  currentTier: Maybe<string>,
) => {
  const currentTierIndex = tiers.findIndex((item) => currentTier === item.type);
  return currentTierIndex !== -1
    ? tiers[currentTierIndex + (currentTier === TierType.trial ? 1 : 0)]
    : null;
};
