import { useEffect } from 'react';
import { subscribePaymentUpdate } from './paymentUpdateEmitter';

export const usePaymentUpdateSubscriber = (
  paymentUpdateHandler: () => void,
) => {
  useEffect(() => {
    return subscribePaymentUpdate(paymentUpdateHandler);
  }, [paymentUpdateHandler]);
};
