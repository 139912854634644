import { useHistory } from 'react-router-dom';
import { PermissionGroup } from '@utils/Permissions';
import {
  goToGetInstagramPermissions,
  goToGetFullAndMarketingPermissions,
  goToGetInstagramAndFullMarketingPermissions,
  goToGetFullPermissions,
  goToGetInstagramAuthPermissions,
  goToGetMarketingPermissions,
  goToGetMinimalPermissions,
  goToGetPermissions,
} from './goToGetPermissions';

export const useGoToGetPermissions = () => {
  const history = useHistory();
  const redirectFn = (url: string) => {
    history.push(url);
  };
  return {
    goToGetInstagramAndFullMarketingPermissions: (reason?: string) =>
      goToGetInstagramAndFullMarketingPermissions(reason, redirectFn),
    goToGetInstagramPermissions: (reason?: string) =>
      goToGetInstagramPermissions(reason, redirectFn),
    goToGetFullAndMarketingPermissions: (reason?: string) =>
      goToGetFullAndMarketingPermissions(reason, redirectFn),
    goToGetFullPermissions: (reason?: string) =>
      goToGetFullPermissions(reason, redirectFn),
    goToGetInstagramAuthPermissions: (reason?: string) =>
      goToGetInstagramAuthPermissions(reason, redirectFn),
    goToGetMarketingPermissions: (reason?: string) =>
      goToGetMarketingPermissions(reason, redirectFn),
    goToGetMinimalPermissions: (reason?: string) =>
      goToGetMinimalPermissions(reason, redirectFn),
    goToGetPermissions: (
      permissionGroup: PermissionGroup,
      reason?: string,
      interruptedHref?: string,
    ) =>
      goToGetPermissions({
        permissionGroup,
        reason,
        interruptedHref,
        redirect: redirectFn,
      }),
  };
};
