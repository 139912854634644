import { LS, LSKey } from 'cf-common/src/localStorage';
import { DEFAULT_LOCALE } from './constants';

const additionalParams = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
} as const;

export const formatDate = (date: Date | number, locale = DEFAULT_LOCALE) => {
  const detailed = LS.get(LSKey.detailedPricingDates);

  return new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    ...(detailed ? additionalParams : {}),
  }).format(date);
};

export const formatDateShort = (date: Date | number, locale = DEFAULT_LOCALE) =>
  new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
  }).format(date);
