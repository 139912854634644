import gql from 'graphql-tag';
import { PERMISSIONS_FRAGMENT } from '../Permissions/fragments';

/**
 * don't expand
 * return get bots/lite
 */
const LIGHT_BOT_FRAGMENT = gql`
  fragment lightBotFragment on Bot {
    id
    title
    last_opened_date_for_current_user
    status {
      status
      charging_entity_type
      page_info {
        id
        title
        picture
      }
    }
    pro {
      days_before_cancel
      status
      manual
    }
    workspace_id
  }
`;

export const LIGHT_BOTS_QUERY = gql`
  query LightBotsQuery {
    lightBots {
      ...lightBotFragment
    }
  }

  ${LIGHT_BOT_FRAGMENT}
`;

export const LIGHT_WORKSPACE_BOTS_QUERY = gql`
  query LightWorkspaceBotsQuery {
    lightWorkspaceBots {
      botsWithoutWorkspace {
        ...lightBotFragment
      }
      workspaces {
        workspace {
          id
          name
          status
        }
        bots {
          ...lightBotFragment
        }
      }
    }
  }

  ${LIGHT_BOT_FRAGMENT}
`;

/**
 * TODO:
 * this request is not productive,
 * due to additional requests to each bot. (currentRole)
 * this request needs to be made on the backend
 */
export const LIGHT_BOT_PERMISSIONS_FRAGMENT = gql`
  fragment lightBotPermissionsFragment on Bot {
    ...lightBotFragment
    currentRole {
      permissions {
        ...permissionsFragment
      }
    }
  }
  ${LIGHT_BOT_FRAGMENT}
  ${PERMISSIONS_FRAGMENT}
`;
/**
 * TODO:
 * this request is not productive,
 * due to additional requests to each bot. (currentRole)
 * this request needs to be made on the backend
 */
export const BOT_LIST_PERMISSIONS_QUERY = gql`
  query BotsListPermissionsQuery {
    lightBots {
      ...lightBotPermissionsFragment
    }
  }
  ${LIGHT_BOT_PERMISSIONS_FRAGMENT}
`;
