import momentTimezone from 'moment-timezone';

export type DateType =
  | Date
  | momentTimezone.Moment
  | moment.Moment
  | number
  | string;

export enum Granularity {
  year = 'year',
  week = 'week',
  day = 'day',
  minute = 'minute',
}

export enum Weekdays {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}
