const DESIRED_SAMPLE_RATE = 48000;
const OUTPUT_SAMPLE_RATE = 44100;
const BIT_DEPTH = 16;
const STREAM_DATA_TIMEOUT = 100;

export const oggOpusToWavConvertor = async (oggData: ArrayBuffer) => {
  const [decoderWorkerUrl, wavWorkerUrl] = await Promise.all([
    // @ts-expect-error
    import('opus-recorder/dist/decoderWorker.min.js'),
    // @ts-expect-error
    import('opus-recorder/dist/waveWorker.min.js'),
    // @ts-expect-error
    import('opus-recorder/dist/decoderWorker.min.wasm'),
  ]);

  return new Promise<Blob>((resolve) => {
    const oggDataArray = new Uint8Array(oggData);
    const decoderWorker = new Worker(decoderWorkerUrl.default);
    const wavWorker = new Worker(wavWorkerUrl.default);
    let dataTimeout: number | undefined;
    let doneSent: boolean = false;

    decoderWorker.onmessage = (e) => {
      if (e.data === null) {
        wavWorker.postMessage({ command: 'done' });
      } else {
        wavWorker.postMessage(
          {
            command: 'encode',
            buffers: e.data,
          },
          e.data.map((typedArray: Float32Array) => typedArray.buffer),
        );
        // костыль на случай файлов без метки окончания
        window.clearTimeout(dataTimeout);
        dataTimeout = window.setTimeout(() => {
          if (doneSent) {
            return;
          }
          doneSent = true;
          decoderWorker.postMessage({ command: 'done' });
        }, STREAM_DATA_TIMEOUT);
      }
    };

    wavWorker.onmessage = (e) => {
      if (e.data.message === 'page') {
        resolve(new Blob([e.data.page], { type: 'audio/wav' }));
        decoderWorker.terminate();
        wavWorker.terminate();
      }
      if (e.data.message === 'ready') {
        decoderWorker.postMessage(
          {
            command: 'decode',
            pages: oggDataArray,
          },
          [oggDataArray.buffer],
        );
      }
    };

    decoderWorker.postMessage({
      command: 'init',
      decoderSampleRate: DESIRED_SAMPLE_RATE,
      outputBufferSampleRate: OUTPUT_SAMPLE_RATE,
    });

    wavWorker.postMessage({
      command: 'init',
      wavBitDepth: BIT_DEPTH,
      wavSampleRate: OUTPUT_SAMPLE_RATE,
    });
  });
};
