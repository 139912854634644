import { PermissionGroup } from '@utils/Permissions';
import { FlowData } from '@components/FlowBuilder/types';
import { AGGREGATED_FLOW_QUERY } from './queries';
import { mapToAggregatedFlowToFlowData, prepareFlowBlocks } from './helpers';
import * as ErrorsUtils from './errors';
import client from '../../../../common/services/ApolloService';
import * as FlowTypes from './@types/AggregatedFlowQuery';

export { FlowTypes };

export const FLOW_FEATURES = [
  PermissionGroup.facebook_analytics,
  PermissionGroup.comments,
  PermissionGroup.instagram_comments,
  PermissionGroup.shops,
  PermissionGroup.ads,
];

export const getAggregatedFlow = async (
  botId: string,
  flowId: string,
  locale: string,
): Promise<FlowData> => {
  const { data, errors } = await client.query<
    FlowTypes.AggregatedFlowQuery,
    FlowTypes.AggregatedFlowQueryVariables
  >({
    query: AGGREGATED_FLOW_QUERY,
    variables: {
      botId,
      flowId,
      flowBlockId: flowId,
      features: FLOW_FEATURES,
      locale,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
  if (errors) {
    const flow410Error = ErrorsUtils.getFlow410Error(errors);
    if (flow410Error) {
      const newFlowId = flow410Error.extensions?.newFlowId;
      if (newFlowId) {
        throw new ErrorsUtils.RecreatedFlowError(newFlowId);
      }
      throw new Error('No newFlowId in 410 response');
    }
    const flow404Error = ErrorsUtils.getFlow404Error(errors);
    if (flow404Error) {
      throw new ErrorsUtils.FlowNotFoundError();
    }
  }
  if (!data?.bot.flow) {
    throw new Error('Flow must be in the response!');
  }
  const flow = mapToAggregatedFlowToFlowData(data);
  await prepareFlowBlocks(flow, data.bot.flowBlocks ?? []);
  return flow as FlowData;
};
