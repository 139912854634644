import React, { useEffect, useState } from 'react';
import { useCurrentBotId } from '@utils/Routing';
import {
  AccessType,
  can as canFn,
  Domain,
  getCurrentGlobalBotId,
  getLoading,
  loadPermissionsMemoized,
  onPermissionsUpdate,
  Permission,
  unPermissionsUpdate,
} from '../common/services/RoleService';

interface Context {
  botId: string;
}

const RoleContext = React.createContext<Context>({
  botId: getCurrentGlobalBotId(),
});

interface ProviderProps {
  botId: string;
}

export class RoleProvider extends React.PureComponent<ProviderProps> {
  componentDidMount() {
    onPermissionsUpdate(this.handleUpdate);
  }

  componentWillUnmount() {
    unPermissionsUpdate(this.handleUpdate);
  }

  handleUpdate = () => {
    this.forceUpdate();
  };

  render() {
    const { botId, children } = this.props;

    return (
      <RoleContext.Provider value={{ botId }}>{children}</RoleContext.Provider>
    );
  }
}

interface ConsumerRenderProps {
  loading: boolean;
  allowed: boolean;
}

interface ConsumerProps {
  domain: Domain;
  can: Permission;
  children:
    | ((renderProps: ConsumerRenderProps) => React.ReactNode)
    | React.ReactNode;
  groupId?: string;
  accessType?: AccessType;
}

export class RoleConsumer extends React.PureComponent<ConsumerProps> {
  render() {
    const { domain, can, groupId, accessType, children } = this.props;

    return (
      <RoleContext.Consumer>
        {({ botId }) => {
          const allowed = canFn(can, domain, {
            groupId,
            botId,
            accessType,
          });

          if (typeof children === 'function') {
            const childrenFn = children as (
              renderProps: ConsumerRenderProps,
            ) => React.ReactNode;

            return childrenFn({ allowed, loading: getLoading(botId) });
          }

          return allowed ? children : null;
        }}
      </RoleContext.Consumer>
    );
  }
}

type UseRolePermissionParams = Omit<ConsumerProps, 'children'> & {
  botId?: string;
};
export const useRolePermission = ({
  domain,
  can,
  groupId,
  accessType,
  botId: specifiedBotId,
}: UseRolePermissionParams) => {
  const queryParamsBotId = useCurrentBotId();
  const botId = specifiedBotId ?? queryParamsBotId;
  const [previousBotId, setPreviousBotId] = useState(botId);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (botId) {
      loadPermissionsMemoized(botId).then(() => {
        setLoading(false);
      });
    }
  }, [botId]);

  if (previousBotId !== botId) {
    setLoading(true);
    setPreviousBotId(botId);
  }

  return {
    allowed: canFn(can, domain, {
      groupId,
      botId,
      accessType,
    }),
    loading: loading || previousBotId !== botId,
  };
};
