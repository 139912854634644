import { LS_SCHEMA, LS, LSKey } from 'cf-common/src/localStorage';
import { log } from 'cf-common/src/logger';
import { useEffect, useState } from 'react';

export { LSKey };

export const useLocalStorage = <K extends keyof LS_SCHEMA>(
  localStorageKey: K,
  initialValue?: LS_SCHEMA[K] | ReturnType<LS_SCHEMA[K]>,
): [LS_SCHEMA[K], React.Dispatch<React.SetStateAction<LS_SCHEMA[K]>>] => {
  const [value, setValue] = useState<LS_SCHEMA[K]>(() => {
    const returnValue = LS.get(localStorageKey);
    if (returnValue !== undefined || !initialValue) {
      return returnValue!;
    }
    if (typeof initialValue === 'function') {
      const valueRes = (initialValue as () => LS_SCHEMA[K])();
      LS.set(localStorageKey, valueRes);
      return valueRes;
    }
    LS.set(localStorageKey, initialValue);
    return initialValue as LS_SCHEMA[K];
  });

  useEffect(() => {
    try {
      LS.set(localStorageKey, value);
    } catch (error) {
      log.warn({ error, msg: `Can't set a data to localStorage` });
    }
  }, [localStorageKey, value]);

  return [value, setValue];
};
