import { Platform } from '@globals';
import { ImageMimeTypes } from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_IMAGE_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [ImageMimeTypes.jpeg, ImageMimeTypes.png],
  [Platform.instagram]: [
    ImageMimeTypes.jpeg,
    ImageMimeTypes.png,
    ImageMimeTypes.gif,
  ],
  [Platform.whatsapp]: [ImageMimeTypes.jpeg, ImageMimeTypes.png],
};

export const IMAGE_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 8,
  [Platform.instagram]: 8,
  [Platform.whatsapp]: 4,
};
