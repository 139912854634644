import { useQuery } from 'react-apollo';
import { LIVECHAT_SETTINGS_QUERY } from './queries';
import {
  LivechatSettingsQuery,
  LivechatSettingsQueryVariables,
} from './@types/LivechatSettingsQuery';

export const useLivechatSettings = (botId: string) => {
  const { data, loading, error } = useQuery<
    LivechatSettingsQuery,
    LivechatSettingsQueryVariables
  >(LIVECHAT_SETTINGS_QUERY, { variables: { botId } });

  return {
    livechatSettings: data?.bot.livechatSettings ?? null,
    loading,
    error,
  };
};
