import { log, LogParamsBase } from 'cf-common/src/logger';

export function assert(
  condition: unknown,
  logParams: LogParamsBase,
): asserts condition {
  if (condition == null) {
    const error = logParams.error || new Error();

    log.error({ ...logParams, error });
  }
}
