import { useQuery } from '@apollo/react-hooks';
import { COUNTRY_DATA_QUERY } from './queries';
import { AdminCountryAndCurrencyDataQuery } from './@types/AdminCountryAndCurrencyDataQuery';

export const useAdminCountryAndCurrency = (skip: boolean = false) => {
  const { data, loading } = useQuery<AdminCountryAndCurrencyDataQuery>(
    COUNTRY_DATA_QUERY,
    {
      skip,
    },
  );

  const localizationData = data?.me.pricing_localization;
  const currency = localizationData?.currency;
  const countryCode = localizationData?.country_code;

  return { currency, countryCode, loading };
};
