import { prop } from 'ramda';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsNeedRedirectToFirstSession } from './useIsNeedRedirectToFirstSession';

export const useBlockedRedirects = () => {
  const history = useHistory();
  const location = useLocation();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const redirectStatuses = [useIsNeedRedirectToFirstSession()];

  const loading = redirectStatuses.some(prop('loading'));
  const { redirectTo } =
    (!loading && redirectStatuses.find(prop('redirectTo'))) || {};

  useEffect(() => {
    if (redirectTo && !redirecting) {
      setRedirecting(true);
      history.push({ ...location, pathname: redirectTo });
      return;
    }
    if (!redirectTo) {
      setRedirecting(false);
    }
  }, [history, location, redirectTo, redirecting]);

  const skipRendering = redirecting || loading;

  return {
    skipRendering,
  };
};
