import gql from 'graphql-tag';

export const CLIPBOARD_DATA_QUERY = gql`
  query ClipboardData {
    clipboardData @client
  }
`;

export const SET_CLIPBOARD_DATA_MUTATION = gql`
  mutation SetClipboardDataMutation($data: String!) {
    clipboardDataSet(data: $data) @client
  }
`;
