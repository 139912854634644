import memoize from 'lodash-es/memoize';

/**
 * !important
 * @note:I dont know how to share varibaled between less code and js code;
 * @cf-menu-item-height is used as code duplication in cf-menu.less;
 * if you want to change it keep in sync with cf-menu.less;
 */
const CF_MENU_ITEM_HEIGH = 36;
const UNKNOWN_ARTIFACT = 2;

/**
 * @description DropDown controller
 */
export default class DropDownController {
  constructor() {
    this.state = { open: null };
    this._getDatasource = memoize(this._getDatasource);
  }

  _getDatasource(options) {
    return (
      options &&
      options.map((i) => {
        return {
          value: i,
          hoverType: 'transparent-white',
          type: 'transparent',
        };
      })
    );
  }

  /**
   * @description do nothing; used to do not filter autocomplete;
   * @param {*} one -
   * @param {*} two -
   * @param {*} three -
   * @return {boolean} true
   */
  noop(one, two, three) {
    return true;
  }

  $onChanges($event) {
    if ($event && $event.cfOpen !== undefined) {
      this.state.open = $event.cfOpen.currentValue;
    }
  }

  // fixme @p3 will work only with strings when you will need something more add it;
  /**
   * @description calculate Y offser
   * @param {[String]} options - array of option items
   * @param {String} value - selected value
   * @return {number} - Y offset
   */
  popoverYOffset(options, value) {
    if (this.yOffsetOff || !options || !Array.isArray(options)) {
      return 0;
    }
    const index = options.indexOf(value);
    if (index === -1) {
      return 0;
    }
    return -(
      CF_MENU_ITEM_HEIGH * index +
      CF_MENU_ITEM_HEIGH +
      UNKNOWN_ARTIFACT
    );
  }

  _onClose($event) {
    this.state.open = false;
    this.onClose($event);
  }

  /**
   * @description On change handler (call external onChange callback)
   * @param {String} value - selected value
   * @param {Int} index - index of selected value
   * @private
   */
  _onChange({ value, index }) {
    if (value === undefined) {
      return;
    }
    this.state.open = false;
    this.onChange({ value: value.value, index });
  }

  _onClick({ $event }) {
    this.state.open = true;
    this.onClick({ $event });
  }
}
