import { Avatar } from '@ui/Avatar';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Manager, Popper, Reference } from 'react-popper';
import { combineRefs } from '@utils/combineRefs';
import { TextEllipsis } from '@ui/TextEllipsis';
import * as css from './ExternalAccountDropdown.css';
import { ExternalAccountsData } from '../ExternalAccount';

export interface ExternalAccountDropdownProps {
  onDisconnectRequest: (accountId: string) => void;
  externalAccountsData: ExternalAccountsData;
}

export const ExternalAccountDropdown = React.forwardRef<
  HTMLDivElement,
  ExternalAccountDropdownProps
>(
  (
    {
      onDisconnectRequest,
      externalAccountsData: { currentAccountId, accounts },
    },
    outerRef,
  ) => {
    const { t } = useSafeTranslation();

    return (
      <Manager>
        <div>
          <Reference>
            {({ ref }) => (
              <div
                ref={ref}
                style={{
                  width: 1,
                  height: 1,
                  opacity: 0,
                }}
              />
            )}
          </Reference>

          <Popper placement="bottom-start">
            {({ ref, style }) => {
              return (
                <div
                  ref={combineRefs([outerRef, ref])}
                  style={style}
                  className={css.box}
                >
                  <div>
                    {accounts.map(({ id, iconSrc, name }) => (
                      <React.Fragment key={id}>
                        <Flex alignItems="center" title={name}>
                          <Flex className={css.avatarBox}>
                            <Avatar src={iconSrc} customSize={20} />
                            {id === currentAccountId && (
                              <Icon
                                size="10px"
                                icon="activeCircle2"
                                className={css.active}
                              />
                            )}
                          </Flex>
                          <Spacer horizontalFactor={2} />
                          <TextEllipsis width={244}>
                            <Type
                              data-testid="external-account_current-account-name"
                              size="15px_DEPRECATED"
                            >
                              {name}
                            </Type>
                          </TextEllipsis>
                        </Flex>
                        <Spacer factor={2} />
                      </React.Fragment>
                    ))}
                  </div>
                  <Spacer factor={2} />
                  {currentAccountId && (
                    <Button
                      intent="secondary"
                      style={{ width: '100%' }}
                      onClick={() => {
                        onDisconnectRequest(currentAccountId);
                      }}
                      icon={<Icon size="16px" icon="disconnect" />}
                      data-testid="external-account_disconnect"
                    >
                      {t('shopify.disconnect')}
                    </Button>
                  )}
                </div>
              );
            }}
          </Popper>
        </div>
      </Manager>
    );
  },
);
