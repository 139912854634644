import {
  LOCALES_FOR_CURRENCIES,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from './constants';
import { FormatPriceConfig } from './types';

export const getLocale = (config?: FormatPriceConfig) => {
  return (
    config?.locale ??
    LOCALES_FOR_CURRENCIES[
      (config?.currency ??
        DEFAULT_CURRENCY) as keyof typeof LOCALES_FOR_CURRENCIES
    ] ??
    DEFAULT_LOCALE
  );
};
