import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import client from '@common/services/ApolloService';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';
import { CURRENCIES_QUERY } from './queries';
import { CurrenciesQuery } from './@types/CurrenciesQuery';

export const getCurrenciesObservable = memoize(() =>
  Observable.from(
    client.watchQuery<CurrenciesQuery>({
      query: CURRENCIES_QUERY,
    }) as ObservableInput<ApolloQueryResult<CurrenciesQuery>>,
  ).map(({ data }) => {
    return data.currencies;
  }),
);
