import { useHistory } from 'react-router-dom';
import { complement, isNil, keys } from 'ramda';
import { globalHistory } from '@utils/Routing';

const historyReplace = (pathname: string, search: string, state: any) => {
  globalHistory.replace({
    pathname,
    search,
    state,
  });
};

const changeSearchParams = (
  cb: (searchParams: URL) => void,
  getLocation = () => globalHistory.location,
  replace: (
    pathname: string,
    search: string,
    state: any,
  ) => void = historyReplace,
) => {
  const url = new URL(window.location.href);
  cb(url);
  const location = getLocation();
  replace(location.pathname, url.search, location.state);
};

export const setQueryParam = <T extends string>(key: T, value: any) => {
  changeSearchParams((url) => {
    url.searchParams.set(key, value);
  });
};
export const deleteQueryParam = <T extends string>(key: T) => {
  changeSearchParams((url) => {
    url.searchParams.delete(key);
  });
};
export const setQueryParams = <T extends object>(params: T) => {
  changeSearchParams((url) => {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  });
};
export const deleteQueryParams = <T extends object>(params: T) => {
  changeSearchParams((url) => {
    Object.keys(params).forEach((key) => {
      url.searchParams.delete(key);
    });
  });
};

const isNotNil = complement(isNil);
export const isParamsMatchesUrlParams = (
  params: any,
  queryParams = Object.fromEntries(
    new URL(window.location.href).searchParams.entries(),
  ),
) => {
  if (params && isNotNil(queryParams)) {
    // queryParams may includes more keys than params, some equals won't fit
    return keys(params).every((paramKey) => {
      return params[paramKey] === queryParams[paramKey as string];
    });
  }
  return false;
};

export const useQueryParams = <T extends object>() => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  return Object.fromEntries(queryParams.entries()) as any as Partial<T>;
};

export const cleanupQueryParams = <T extends string>(
  queryParamsToClean: T[],
) => {
  changeSearchParams((url) => {
    queryParamsToClean.forEach((queryParam) => {
      url.searchParams.delete(queryParam);
    });
  });
};
