import gql from 'graphql-tag';
import { QueryHookOptions } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import {
  FbPermissionsQuery,
  FbPermissionsQuery_me_fbPermissions,
} from './@types/FbPermissionsQuery';

export const FB_PERMISSIONS_QUERY = gql`
  query FbPermissionsQuery {
    me {
      id
      fbPermissions {
        hasManagePagesPermissions
        hasAllPermissions
        hasValidToken
        hasMinimalPermissions
        hasMarketingPermissions
        hasInstagramAuthPermissions
        hasInstagramMessagingPermissions
      }
    }
  }
`;

export const useFbPermissions = (
  options?: QueryHookOptions<FbPermissionsQuery, never>,
) => {
  const { data, loading, refetch } = useQuery<FbPermissionsQuery>(
    FB_PERMISSIONS_QUERY,
    options,
  );
  return {
    loading,
    refreshPermissions: refetch,
    permissions: {
      ...data?.me.fbPermissions,
    } as FbPermissionsQuery_me_fbPermissions,
  };
};
