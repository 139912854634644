import { BotStatusName } from '../../../@types/globalTypes';

export enum BotProStatus {
  disabled = 'disabled',
  enabled = 'enabled',
  enabled_with_debt = 'enabled_with_debt',
  trial = 'trial',
}

export enum BotStatus {
  debt = 'debt',
  expiredDebt = 'expiredDebt',
  noPage = 'noPage',
  elapsing = 'elapsing',
  elapsed = 'elapsed',
  amateur = 'amateur',
  trial = 'trial',
  pro = 'pro',
  premium = 'premium',
  paused = 'paused',
  pausedAndExpiredDebt = 'pausedAndExpiredDebt',
  draft = 'draft',
}

export type Bot = {
  pro: {
    is_paused: boolean | null;
    status: string;
    debt: number | null;
    days_before_cancel: number | null;
    millis_before_cancel: number | null;
    manual: boolean | null;
  } | null;
  pricingData: {
    currentPrice: number;
    currentUsers: number;
    currentMaxUsers: number;
    pricing: number[][] | null;
  } | null;
  dialogsPricing: {
    pricing_plan_id: string | null;
    current_tier: string | null;
    next_tier: string | null;
    currency: string | null;
    reached_limit: number | null;
    stripe_error: {
      description: string;
      code: string;
      decline_code: string;
    } | null;
  } | null;
  status: {
    status: BotStatusName;
    page_info: any | null;
  } | null;
  limits: {
    usersLimit: number;
    notificationsLimit: number;
  };
};
