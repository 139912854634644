import gql from 'graphql-tag';

const INSTAGRAM_FRAGMENT = gql`
  fragment Instagram on Bot {
    id
    instagram {
      connected
      connection_problem
      business_account {
        id
        name
        profile_picture_url
        handle: username
        followers_count
      }
      automation_enabled
      fb_error {
        code
        message
      }
    }
  }
`;

export const INSTAGRAM_ACCOUNT_QUERY = gql`
  query InstagramAccountQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...Instagram
    }
  }
  ${INSTAGRAM_FRAGMENT}
`;

export const CONNECT_INSTAGRAM_ACCOUNT_MUTATION = gql`
  mutation ConnectInstagramAccount($botId: String!) {
    connectInstagram(botId: $botId) {
      ...Instagram
    }
  }
  ${INSTAGRAM_FRAGMENT}
`;
export const DISCONNECT_INSTAGRAM_ACCOUNT_MUTATION = gql`
  mutation DisconnectInstagramAccount($botId: String!) {
    disconnectInstagram(botId: $botId) {
      ...Instagram
    }
  }
  ${INSTAGRAM_FRAGMENT}
`;
