import { GraphQLError } from 'graphql';

export const getFlow404Error = (errors: Readonly<GraphQLError[]>) => {
  return errors.find((error) => error.message === '404: FLOW_DOESNT_EXIST');
};

export const getFlow410Error = (errors: Readonly<GraphQLError[]>) => {
  return errors.find((error) => error.message === '410: FLOW_DOESNT_EXIST');
};

export class FlowNotFoundError {}

export class RecreatedFlowError {
  recreatedFlowId: string;
  constructor(recreatedFlowId: string) {
    this.recreatedFlowId = recreatedFlowId;
  }
}
