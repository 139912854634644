import gql from 'graphql-tag';
import { WORKSPACE_FRAGMENT } from './fragments';

export const MOVE_TO_WORKSPACE_MUTATION = gql`
  mutation MoveToWorkspaceMutation($workspaceId: String!, $botId: String!) {
    moveToWorkspace(workspaceId: $workspaceId, botId: $botId)
  }
`;

export const CHANGE_WORKSPACE_MUTATION = gql`
  mutation ChangeWorkspaceMutation($workspaceId: String!, $name: String!) {
    changeWorkspace(workspaceId: $workspaceId, name: $name) {
      ...workspaceFragment
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const REMOVE_FROM_WORKSPACE_MUTATION = gql`
  mutation RemoveFromWorkspaceMutation($botId: String!) {
    removeFromWorkspace(botId: $botId)
  }
`;
