import { Observable } from 'rxjs/Rx';
import { ObservableInput } from 'rxjs/Observable';
import memoize from 'lodash-es/memoize';
import { curry, pathOr, prop } from 'ramda';
import debounce from 'lodash-es/debounce';
import client from '../../common/services/ApolloService';
import { AttributeValuesQuery } from './@types/AttributeValuesQuery';
import {
  AttributesQuery,
  AttributesQuery_bot_variableSuggest as Attribute,
  AttributesQuery_bot_variableSuggest,
  AttributesQueryVariables,
} from './@types/AttributesQuery';
import { ATTRIBUTES_QUERY } from './AttributesUtilsGQL';
import { VariablesType, VariableSuggestType } from './AttributesUtilsTypes';
import { Platform } from '@globals';
import { normalizeSpaces } from '../String/normalizeSpaces';

const ATTRIBUTES_PLATFORM_VARIANTS = [
  Platform.facebook,
  Platform.instagram,
  Platform.whatsapp,
  null,
];
const SUGGEST_TYPE_VARIANTS = [
  VariableSuggestType.template,
  VariableSuggestType.omnibox,
];

const attributesPath = pathOr<Attribute[]>(
  [],
  ['data', 'bot', 'variableSuggest'],
);

export const attributeIdFromNameAndType = curry(
  (type, name) => `${normalizeSpaces(name || '')}_${type}`,
);

export const getAttributesQueryObservable = memoize(
  (
    botId: string,
    suggestType: VariableSuggestType = VariableSuggestType.template,
    platform: Platform | null,
  ) => {
    if (!botId) {
      return new Observable();
    }
    const attributesQueryObservable = client.watchQuery<
      AttributesQuery,
      AttributesQueryVariables
    >({
      query: ATTRIBUTES_QUERY,
      variables: {
        botId,
        suggestType,
        platform,
      },
    });
    attributesQueryObservable.getLastResult();
    return Observable.from(
      attributesQueryObservable as ObservableInput<AttributeValuesQuery>,
    ).map(attributesPath);
  },
  (botId, suggestType, platform) => `${botId}${suggestType}${platform}`,
);

export const refetchAttributesQueryObservableBySuggestType = (
  botId: string,
  suggestType: VariableSuggestType = VariableSuggestType.template,
  platform: Platform | null,
) => {
  return client.query({
    query: ATTRIBUTES_QUERY,
    fetchPolicy: 'network-only',
    variables: {
      botId,
      suggestType,
      platform,
    },
  });
};

export const refetchCommonAttributesQueryObservablesImmediately = (
  botId: string,
) =>
  Promise.all(
    ATTRIBUTES_PLATFORM_VARIANTS.map((platformItem) => [
      refetchAttributesQueryObservableBySuggestType(
        botId,
        VariableSuggestType.template,
        platformItem,
      ),
      refetchAttributesQueryObservableBySuggestType(
        botId,
        VariableSuggestType.omnibox,
        null,
      ),
    ]).flat(),
  );

export const refetchCommonAttributesQueryObservables = debounce(
  refetchCommonAttributesQueryObservablesImmediately,
  500,
);

export const refetchAttributesQueryObservables = debounce(
  (botId: string, platform: Platform | null) =>
    refetchAttributesQueryObservableBySuggestType(
      botId,
      VariableSuggestType.template,
      platform,
    ),
  500,
);

export const getAttributesAsync = (
  botId: string,
  suggestType: VariableSuggestType = VariableSuggestType.template,
  platform: Platform | null,
) => {
  return getAttributesQueryObservable(botId, suggestType, platform)
    .take(1)
    .toPromise();
};

const getCustomAttributeId = (name: string) =>
  `${name}_${VariablesType.custom}`;

const addAttributeToGQLCacheBySuggestType = (
  name: string | string[],
  botId: string,
  suggestType: VariableSuggestType,
  platform: Platform | null,
) => {
  const queryOptions = {
    query: ATTRIBUTES_QUERY,
    variables: {
      botId,
      suggestType,
      platform,
    },
  };

  const attributesNames = (Array.isArray(name) ? name : [name])
    .map((name) => name.trim())
    .filter(Boolean);

  if (attributesNames.length === 0) {
    return;
  }

  let data: AttributesQuery | null | undefined;

  try {
    data = client.readQuery<AttributesQuery, AttributesQueryVariables>(
      queryOptions,
    );
  } catch {
    data = null;
  }

  if (data) {
    const {
      bot: { variableSuggest },
    } = data;
    const currentAttributesNames = variableSuggest.map(prop('name'));

    const newAttributes = attributesNames
      .filter((name) => !currentAttributesNames.includes(name))
      .map(
        (name) =>
          ({
            __typename: 'BotUserVariable',
            name,
            id: getCustomAttributeId(name),
            type: VariablesType.custom,
            count: 0,
          } as AttributesQuery_bot_variableSuggest),
      );

    variableSuggest.push(...newAttributes);
    client.writeQuery<AttributesQuery, AttributesQueryVariables>({
      ...queryOptions,
      data,
    });
  }
};

export const addAttributeToGQLCaches = (
  botId: string,
  name: string | string[],
  platform: Platform | null,
) => {
  SUGGEST_TYPE_VARIANTS.forEach((suggestType) => {
    addAttributeToGQLCacheBySuggestType(name, botId, suggestType, platform);
  });
};

export const addCommonAttributeToGQLCaches = (
  botId: string,
  name: string | string[],
) =>
  ATTRIBUTES_PLATFORM_VARIANTS.forEach((platform) => {
    addAttributeToGQLCaches(botId, name, platform);
  });

export const filterAttributes = (
  attributes: Attribute[],
  customAttributesOnly: boolean,
) =>
  customAttributesOnly
    ? attributes.filter(({ type }) => type === VariablesType.custom)
    : attributes;

const removeAttributeFromGQLCacheBySuggestType = (
  name: string | string[],
  botId: string,
  suggestType: VariableSuggestType,
  platform: Platform,
) => {
  const queryOptions = {
    query: ATTRIBUTES_QUERY,
    variables: {
      botId,
      suggestType,
      platform,
    },
  };
  const removedAttributesNames = (Array.isArray(name) ? name : [name])
    .map((name) => name.trim())
    .filter(Boolean);
  if (removedAttributesNames.length === 0) {
    return;
  }
  let data: AttributesQuery | null | undefined;
  try {
    data = client.readQuery<AttributesQuery, AttributesQueryVariables>(
      queryOptions,
    );
  } catch {
    data = null;
  }
  if (data) {
    data.bot.variableSuggest = data.bot.variableSuggest.filter(
      ({ name }) => !removedAttributesNames.includes(name),
    );
    client.writeQuery<AttributesQuery, AttributesQueryVariables>({
      ...queryOptions,
      data,
    });
  }
};

const removeAttributeFromGQLCaches = curry(
  (platforms: Platform[], botId: string, name: string | string[]) => {
    platforms.forEach((platformItem) => {
      SUGGEST_TYPE_VARIANTS.forEach((suggestType) => {
        removeAttributeFromGQLCacheBySuggestType(
          name,
          botId,
          suggestType,
          platformItem,
        );
      });
    });
  },
);

export const removeCommonAttributeFromGQLCaches = removeAttributeFromGQLCaches([
  Platform.facebook,
  Platform.instagram,
]);
