import { useQuery } from 'react-apollo';
import Maybe from 'graphql/tsutils/Maybe';
import { WHATSAPP_PHONES } from './queries';
import {
  WhatsappPhonesQuery,
  WhatsappPhonesQueryVariables,
} from './@types/WhatsappPhonesQuery';
import { useCallback, useState } from 'react';
import client from '@common/services/ApolloService';

export const useUserWhatsappPhoneEntries = (botId: Maybe<string>) => {
  const { data, ...rest } = useQuery<
    WhatsappPhonesQuery,
    WhatsappPhonesQueryVariables
  >(WHATSAPP_PHONES, {
    skip: !botId,
    variables: {
      botId: botId!,
    },
  });
  const whatsappPhoneEntries = data?.whatsappPhoneNumbers;

  return { whatsappPhoneEntries, ...rest };
};

export const useRefetchWhatsappPhoneEntries = (botId: Maybe<string>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setLoading(true);
    await client.query({
      query: WHATSAPP_PHONES,
      fetchPolicy: 'network-only',
      variables: {
        botId: botId!,
      },
    });
    setLoading(false);
  }, [botId]);
  return { refetch, loading };
};
