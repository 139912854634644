import ng from 'angular';
import keycode from 'keycode';
import select from 'selection-range';

import './textField.less';

class ChipCtrl {
  /**
   * @description constructor
   * @param {*} $element -
   * @param {*} $window -
   * @param {*} ClipboardService -
   */
  constructor($element, $window, ClipboardService) {
    'ngInject';

    this.$el = $element.find('div');
    this.$window = $window;
    this.ClipboardService = ClipboardService;

    this.ctx = this.ctx || {};
    this.ctx.selectAll = this.selectAll.bind(this);
    this.ctx.deselect = this.deselect.bind(this);
    this.ctx.caretToEnd = this.caretToEnd.bind(this);
  }

  log(s) {
    // eslint-disable-next-line no-console
    console.log(s);
  }

  /**
   * @description init callback
   */
  $onInit() {
    this._value = this.value;

    this.$el.on('paste', this._onPaste.bind(this));
  }

  /**
   * @description destroy callback
   */
  $onDestroy() {
    this.$el.off('paste');
  }

  /**
   * @description bind objects change callback
   * @param {Object} $event -
   */
  $onChanges($event) {
    if ($event) {
      if ($event.value) {
        if (!$event.value.isFirstChange()) {
          const { currentValue } = $event.value;
          const valueFromParentChanged = currentValue !== this._value;
          if (valueFromParentChanged) {
            this._value = currentValue;
          }
        }
      }

      if (
        $event.focus &&
        $event.focus.currentValue !== $event.focus.previousValue
      ) {
        if ($event.focus.currentValue) {
          this._focus();
        } else {
          this._blur();
        }
      }
    }
  }

  /**
   * @description Set focus to textfield
   * @private
   */
  _focus() {
    // timeout for one moment focus (immediately after the component is rendered)
    setTimeout(() => {
      this._placeCaretAtEnd(this.$el[0]);
    }, 1);
  }

  /**
   * @description Remove focus from textfield
   * @private
   */
  _blur() {
    this.$el.blur();
  }

  /**
   * @description place input caret to end in El
   * @param {Object} el -
   * @private
   */
  _placeCaretAtEnd(el) {
    el.focus();
    if (
      typeof this.$window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = this.$window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  /**
   * @description on key down handler, calling outer callback
   * @param {Object} e -
   * @private
   */
  _onKeyDown(e) {
    this._preventMultiLine(e);
    this._limitByMaxLength(e);

    if (this.onKeydown) {
      this.onKeydown({ $event: e });
    }
  }

  /**
   * @description prevent wrap line on Enter button in single line mode
   * @param {Object} e -
   * @private
   */
  _preventMultiLine(e) {
    const enterWasPressed = e && e.keyCode === 13;
    const multiLineIsNotAllowed = this.multiLine !== true;
    if (enterWasPressed && multiLineIsNotAllowed) {
      e.preventDefault();
    }
  }

  /**
   * @description call outer onChange callback
   * @param {String} value -
   * @private
   */
  _updateParentOnInternalValueChanged(value) {
    this.onChange({ value });
  }

  /**
   * @description limit input by maxLength
   * @param {Event} e -
   * @private
   */
  _limitByMaxLength(e) {
    const isLimitReached =
      this.maxLength && this.$el[0].innerText.length >= this.maxLength;
    const { codes } = keycode;
    const allowedKeyInLimit = [
      codes.left,
      codes.right,
      codes.down,
      codes.up,
      codes.backspace,
      codes.delete,
    ];
    const isLimitAllowedKey = allowedKeyInLimit.includes(e.keyCode);

    if (isLimitReached && !isLimitAllowedKey) {
      e.preventDefault();
    }
  }

  /**
   * @description on paste handler (remove tags and special chars, crop text by maxLength)
   * @param {Event} e -
   * @private
   */
  _onPaste(e) {
    this.ClipboardService.safePaste(e);
  }

  selectAll() {
    const el = this.$el[0];
    const {
      innerText: { length },
    } = el;

    if (length) {
      select(el, {
        start: 0,
        end: length,
      });
    }
  }

  deselect() {
    window.getSelection().removeAllRanges();
  }

  caretToEnd() {
    const el = this.$el[0];
    const {
      innerText: { length },
    } = el;

    if (length) {
      select(el, {
        start: length,
        end: length,
      });
    }
  }
}

export default ng.module('app.ui.textField', []).component('textField', {
  controllerAs: 'vm',
  bindings: {
    textFieldClass: '@',
    value: '<',
    focus: '<',
    multiLine: '<',
    placeholder: '@',
    onFocus: '&',
    onChange: '&',
    onBlur: '&',
    onKeydown: '&',
    onKeyup: '&',
    cfDisabled: '@',
    hideFocusOutline: '<',
    maxLength: '<',
    ctx: '<',
  },
  template: `
        <div
          data-testid="text-field"
          contenteditable="{{vm.cfDisabled !== 'true'}}"
          class="text-field js-editable-element"
          multiline="{{vm.multiLine}}"
          art-contenteditable
          ng-change="vm._updateParentOnInternalValueChanged(vm._value)"
          ng-model="vm._value"
          ng-focus="vm.onFocus({$event:$event});"
          ng-blur="vm.onBlur({$event:$event});"
          ng-keydown="vm._onKeyDown($event)"
          ng-keyup="vm.onKeyup({$event:$event})"
          ng-class="[vm.textFieldClass]"
          placeholder="{{vm.placeholder}}"
        >
        </div>
   `,
  controller: ChipCtrl,
}).name;
