import keycode from 'keycode';

const CF_MENU_ITEM_HEIGH = 30;
const JS_MENU_CLASS = '.js-cf-menu';

export default class UIPickerViewDropDown {
  constructor($element, $timeout, CollectionUtils) {
    'ngInject';

    this.$element = $element;
    this.$timeout = $timeout;
    this.state = {};
    this.CollectionUtils = CollectionUtils;
  }

  log(...e) {}

  $onInit() {
    // on init is is not mounted still....
    this.$timeout(() => this.setMenuOffset());
  }

  $onChanges() {
    this.setMenuOffset();
  }

  indexOfValue(value) {
    return this.datasource.indexOf(value);
  }

  selectedIndex() {
    return this.indexOfValue(this.value);
  }

  valueByIndex(index) {
    return this.datasource[index];
  }

  setMenuOffset() {
    const menu = this.$element.find(JS_MENU_CLASS)[0];
    const offset = this.offset(this.selectedIndex());
    if (menu) menu.style.transform = `translate(0, ${offset}px)`;
  }

  _onFocus(e) {
    const { value } = this;
    this.$timeout(() => {
      if (this.value === value) {
        this.state.open = true;
        this.state.selectedIndex = this.datasource.indexOf(this.value);
      }
    }, 200); // I do not know why it is not workig without timeout; (also it should be at least 200);
  }

  _onClickAway($event) {
    this.state.open = false;
  }

  offset(selectedIndex) {
    return -(CF_MENU_ITEM_HEIGH * selectedIndex) + CF_MENU_ITEM_HEIGH;
  }

  _onItemClick({ index, value, $event }) {
    this.state.selectedIndex = index;
    // $event.stopPropagation();
    // @note: angular is trying to open popup; but at the same time it buble event to click away witch close popup;
    // have no idea why;
    // you can $event.preventDefault(); -- but not working when you trying to open this popup when another popup open
    // --> another will not close due to event prevented;
    this.$timeout(() => {
      this.onClick({ $event });
      if (value === this.value) {
        this.state.open = true;
      }
      this.notifyParentIfChange({ index, value, $event });
    });
  }

  _onKeydown({ $event }) {
    if (keycode($event) === 'up') {
      this._changeCurrentIndexInDirection(-1);
      $event.preventDefault();
    }

    if (keycode($event) === 'down') {
      this._changeCurrentIndexInDirection(1);
      $event.preventDefault();
    }

    if (keycode($event) === 'enter') {
      const {
        state: { selectedIndex },
      } = this;
      this._onMenuItemClick({
        index: this.state.selectedIndex,
        value: this.valueByIndex(selectedIndex),
        $event,
      });
    }

    if (keycode($event) === 'esc') {
      this._onClickAway();
    }

    this.onKeydown({ $event });
  }

  _changeCurrentIndexInDirection(direction) {
    const {
      state: { selectedIndex },
      datasource,
      CollectionUtils: { nextIndex },
    } = this;
    this.state.selectedIndex = nextIndex(direction, selectedIndex, datasource);
  }

  notifyParentIfChange({ index, value, $event }) {
    if (value !== this.value) {
      this.onChange({ index, value, $event });
    }
  }

  _getPopoverOffset() {
    return (
      -(CF_MENU_ITEM_HEIGH * this.selectedIndex()) - CF_MENU_ITEM_HEIGH - 10
    );
  }

  _onMenuItemClick({ index, value, $event }) {
    this.state.open = false;
    this.notifyParentIfChange({ index, value, $event });
  }
}
