import { PaywallStatus } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';
import client from '@common/services/ApolloService';
import { PAGE_PAYWALL_STATUS_QUERY } from './queries';
import {
  PagePaywallStatusQuery,
  PagePaywallStatusQueryVariables,
} from './@types/PagePaywallStatusQuery';

export const isPaywallActive = (
  paywallStatus: Maybe<PaywallStatus>,
): paywallStatus is PaywallStatus => {
  if (!paywallStatus) return false;

  return [PaywallStatus.nonSkippable, PaywallStatus.skippable].includes(
    paywallStatus,
  );
};

export const updatePaywallStatusCache = (
  pageId: string,
  paywallStatus: PaywallStatus,
) => {
  client.writeQuery<PagePaywallStatusQuery, PagePaywallStatusQueryVariables>({
    query: PAGE_PAYWALL_STATUS_QUERY,
    data: {
      page: {
        __typename: 'Page',
        id: pageId,
        paywall_status: paywallStatus,
      },
    },
  });
};
