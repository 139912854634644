import './assets/less/switcher.less';

import ng from 'angular';
import template from './switcher.html';
import controller from './controller';

export default ng.module('app.ui.switcher', []).component('switcher', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    value: '<',
    onChange: '&',
    preventChange: '<',
  },
}).name;
