import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { log } from 'cf-common/src/logger';
import { Messages, toaster } from '../../services/MessageService';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';
import { extractGQLErrorData, getRequestIdFromApolloError } from '../GQL/utils';
import {
  FacebookSyncedSegmentsQuery,
  FacebookSyncedSegmentsQueryVariables,
} from './@types/FacebookSyncedSegmentsQuery';
import {
  SyncFacebookSegmentMutation,
  SyncFacebookSegmentMutationVariables,
  SyncFacebookSegmentMutation_syncFacebookSegment_segment as FacebookSyncedSegment,
} from './@types/SyncFacebookSegmentMutation';
import { SyncingState, SyncingActionType } from '../../../@types/globalTypes';
import { WatchQueryFetchPolicy } from 'apollo-client';

export const SYNCED_SEGMENTS_QUERY = gql`
  query FacebookSyncedSegmentsQuery($botId: String!) {
    bot(id: $botId) {
      id
      facebookSyncedSegments {
        id
        name
        last_synced
        state
        status_description
        status_code
        synced_users_count
      }
    }
  }
`;

export const useFacebookSyncedSegments = (
  botId: string,
  fetchPolicy?: WatchQueryFetchPolicy,
) => {
  const { data, ...state } = useQuery<
    FacebookSyncedSegmentsQuery,
    FacebookSyncedSegmentsQueryVariables
  >(SYNCED_SEGMENTS_QUERY, {
    variables: {
      botId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  const syncedSegments = data?.bot.facebookSyncedSegments || [];

  return {
    syncedSegments,
    syncingSegments: syncedSegments.filter(
      ({ state }) => state === SyncingState.SYNCING,
    ),
    ...state,
  };
};

export const SYNC_SEGMENT_MUTATION = gql`
  mutation SyncFacebookSegmentMutation(
    $botId: String!
    $segmentId: String!
    $action: SyncingActionType!
  ) {
    syncFacebookSegment(botId: $botId, segmentId: $segmentId, action: $action) {
      userErrors {
        message
      }
      segment {
        id
        name
        last_synced
        state
        status_description
        status_code
        synced_users_count
      }
    }
  }
`;

export const useSyncFacebookSegment = (botId: string) => {
  const [
    syncSegment,
    { loading: segmentSyncing, called: segmentSyncCalled, error },
  ] = useMutation<
    SyncFacebookSegmentMutation,
    SyncFacebookSegmentMutationVariables
  >(SYNC_SEGMENT_MUTATION, {
    onError: (error) => {
      const { message } = extractGQLErrorData(error) || {};
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message:
            typeof message === 'string' ? message : Messages.somethingWentWrong,
        },
      });
      log.error({
        error,
        data: {
          label: 'fb_sync_segments',
          requestId: getRequestIdFromApolloError(error),
        },
      });
    },
  });

  return {
    syncSegment: (segment: FacebookSyncedSegment, action: SyncingActionType) =>
      syncSegment({
        variables: { botId, segmentId: segment.id, action },
        optimisticResponse: {
          syncFacebookSegment: {
            __typename: 'FacebookSyncSegmentPayload',
            segment: {
              ...segment,
              status_description:
                action === SyncingActionType.PAUSE
                  ? segment.status_description
                  : null,
              state:
                action === SyncingActionType.PAUSE
                  ? SyncingState.PAUSED
                  : SyncingState.SYNCING,
            },
            userErrors: [],
          },
        },
      }),
    segmentSyncing,
    segmentSyncCalled,
    error,
  };
};
