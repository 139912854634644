import { useQuery } from 'react-apollo';
import { HUBSPOT_INTEGRATION_STATUS_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';
import { QueryHookOptions } from '@apollo/react-hooks';
import {
  HubSpotIntegrationStatusQuery,
  HubSpotIntegrationStatusQueryVariables,
} from './@types/HubSpotIntegrationStatusQuery';

export const useHubSpotIntegrationStatus = (
  payload?: QueryHookOptions<
    HubSpotIntegrationStatusQuery,
    HubSpotIntegrationStatusQueryVariables
  >,
) => {
  const botId = useCurrentBotId();
  return useQuery<
    HubSpotIntegrationStatusQuery,
    HubSpotIntegrationStatusQueryVariables
  >(HUBSPOT_INTEGRATION_STATUS_QUERY, {
    variables: {
      botId: botId!,
    },
    ...payload,
  });
};
