/**
 *  @description: A view that uses a spinning-wheel or slot-machine metaphor to show one or more sets of values.
 *                Just single column;
 *
 *  @ ???: should we use ui-{component} prefix for all UIKit components? there will be no confusion?
 *         & {name}-container or {name}-data postfix for data rich components?
 *
 */

import ng from 'angular';
import controller from './controller';
import template from './template.html';
import UIPickerDropDown from './ui-picker-dropdown';
import './ui-picker-view.less';

export default ng
  .module('app.ui.uiPickerView', [UIPickerDropDown])
  .component('uiPickerView', {
    controllerAs: 'vm',
    bindings: {
      /**
       *
       * @typedef: Datasource
       * @type: { (string[] | {value:string, subheader: string}[]}
       *
       * @callback onChange
       * @param {string} value;
       * @param {number} index;
       *
       * @typedef: PickerViewColumn
       * @type:     {object}
       * @property: {string}      type - 'dropdown' | 'input' what components to use - input values or dropdown;
       * @property: {boolean}     [allowFreeInput = true] - applied only to 'input' type; allow to set values not from datasource;
       * @property: {Datasource}  datasource
       * @property: {string}      value - currently selected value;
       * @callback: {string}onChange- by column    onChangeevent;
       *
       * @bindings: {PickerViewColumn[]} data
       *
       */
      data: '<',
      /**
       * @callback onValueChange;
       * @param {number} newValueIndex - index of new value in datasource;
       * @param {number} uiPickerViewIndex - index of PickerViewColumn
       * @param {*}      value - new value;
       */
      onValueChange: '&',

      /**
       * @callback onMouseenter / onMouseleave
       * @param {HTMLMouseEvent} $event - mouse event
       * @param {number} index - index on what element mouse is hovered
       * @description: fire whan mouse is hovered on specific section of ui-picker view;
       */
      onMouseenter: '&',
      onMouseleave: '&',
    },
    template: () => template,
    controller,
  }).name;
