import {
  AudioMimeTypes,
  FilesMimeTypes,
  GroupMimeTypes,
  ImageMimeTypes,
  VideoMimeTypes,
} from '@utils/PlatformSupportedFiles/mimeGroups';

export type UploadMimeTypes =
  | ImageMimeTypes
  | VideoMimeTypes
  | FilesMimeTypes
  | AudioMimeTypes
  | GroupMimeTypes;

export enum FileAttachmentType {
  video = 'video',
  image = 'image',
  audio = 'audio',
  file = 'file',
  pdf = 'pdf',
  mp4 = 'mp4',
}
