import { extractGQLErrorData } from '@utils/GQL/utils';
import { MutationHookOptions, useMutation } from 'react-apollo';
import {
  AssignBotPromoCodeMutation,
  AssignBotPromoCodeMutationVariables,
} from './@types/AssignBotPromoCodeMutation';
import { ASSIGN_BOT_PROMO_CODE_MUTATION } from './mutations';

export enum AssignPromoCodeError {
  promo_code_not_found = 'promo_code_not_found',
  promo_code_expired = 'promo_code_expired',
  promo_code_used = 'promo_code_used',
  discount_applied = 'discount_applied',
  wrong_region = 'wrong_region',
  not_trial_page = 'not_trial_page',
  page_absent = 'page_absent',
  wrong_owner = 'wrong_owner',
}

export const useAssignBotPromoCodeMutation = (
  options?: MutationHookOptions<
    AssignBotPromoCodeMutation,
    AssignBotPromoCodeMutationVariables
  >,
) => {
  const [assignBotPromoCode, { error, ...result }] = useMutation<
    AssignBotPromoCodeMutation,
    AssignBotPromoCodeMutationVariables
  >(ASSIGN_BOT_PROMO_CODE_MUTATION, options);

  const errorMessage: AssignPromoCodeError | undefined =
    extractGQLErrorData(error)?.message;

  return { assignBotPromoCode, errorMessage, ...result };
};
