import { useCallback } from 'react';
import { log } from 'cf-common/src/logger';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Messages, toaster } from '../../services/MessageService';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';
import { extractGQLErrorData, getRequestIdFromApolloError } from '../GQL/utils';
import { SYNCED_SEGMENTS_QUERY } from './useFacebookSyncedSegments';
import {
  ConnectedFacebookAdAccountQuery,
  ConnectedFacebookAdAccountQueryVariables,
} from './@types/ConnectedFacebookAdAccountQuery';
import {
  ConnectedFacebookAdAccountWithToSQuery,
  ConnectedFacebookAdAccountWithToSQueryVariables,
} from './@types/ConnectedFacebookAdAccountWithToSQuery';
import {
  ConnectFacebookAdAccountMutation,
  ConnectFacebookAdAccountMutationVariables,
} from './@types/ConnectFacebookAdAccountMutation';

export const CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY = gql`
  query ConnectedFacebookAdAccountQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedFacebookAdAccount {
        id
        name
      }
    }
  }
`;

export const CONNECTED_FACEBOOK_AD_ACCOUNT_WITH_TOS_QUERY = gql`
  query ConnectedFacebookAdAccountWithToSQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedFacebookAdAccount {
        id
        name
        tos_accepted {
          custom_audience_tos
        }
      }
    }
  }
`;

export const CONNECT_FACEBOOK_AD_ACCOUNT_MUTATION = gql`
  mutation ConnectFacebookAdAccountMutation(
    $botId: String!
    $adAccountId: String!
  ) {
    bindFacebookAdAccount(botId: $botId, adAccountId: $adAccountId) {
      id
      connectedFacebookAdAccount {
        id
        name
        tos_accepted {
          custom_audience_tos
        }
      }
    }
  }
`;

export const DISCONNECT_FACEBOOK_AD_ACCOUNT_MUTATION = gql`
  mutation DisconnectFacebookAdAccountMutation($botId: String!) {
    unbindFacebookAdAccount(botId: $botId) {
      id
      connectedFacebookAdAccount {
        id
        name
      }
    }
  }
`;

const useConnectFacebookAdAccount = (botId: string | undefined) => {
  const onError = useCallback((error) => {
    const { message } = extractGQLErrorData(error) || {};
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message:
          typeof message === 'string' ? message : Messages.somethingWentWrong,
      },
    });
    log.error({
      error,
      data: {
        label: 'automate_fb_ads',
        requestId: getRequestIdFromApolloError(error),
      },
    });
  }, []);

  const [
    bindFacebookAdAccount,
    {
      loading: bindFacebookAdAccountLoading,
      called: bindFacebookAdAccountCalled,
    },
  ] = useMutation<
    ConnectFacebookAdAccountMutation,
    ConnectFacebookAdAccountMutationVariables
  >(CONNECT_FACEBOOK_AD_ACCOUNT_MUTATION, {
    onError,
    refetchQueries: [{ query: SYNCED_SEGMENTS_QUERY, variables: { botId } }],
  });

  const [
    unbindFacebookAdAccount,
    {
      loading: unbindFacebookAdAccountLoading,
      called: unbindFacebookAdAccountCalled,
    },
  ] = useMutation(DISCONNECT_FACEBOOK_AD_ACCOUNT_MUTATION, {
    onError,
    variables: { botId },
    refetchQueries: [{ query: SYNCED_SEGMENTS_QUERY, variables: { botId } }],
  });

  return {
    unbindFacebookAdAccount,
    unbindFacebookAdAccountCalled,
    bindFacebookAdAccount: (adAccountId: string) =>
      botId
        ? bindFacebookAdAccount({ variables: { botId, adAccountId } })
        : { data: undefined, errors: undefined },
    bindFacebookAdAccountCalled,
    loading: bindFacebookAdAccountLoading || unbindFacebookAdAccountLoading,
  };
};

export const useConnectedFacebookAdAccount = (botId: string | undefined) => {
  const { data, loading, error } = useQuery<
    ConnectedFacebookAdAccountQuery,
    ConnectedFacebookAdAccountQueryVariables
  >(CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
  });

  const mutationData = useConnectFacebookAdAccount(botId);

  return {
    ...mutationData,
    connectedFacebookAdAccount: data?.bot.connectedFacebookAdAccount,
    loading: loading || mutationData.loading,
    error,
  };
};

export const useConnectedFacebookAdAccountWithToS = (botId: string) => {
  const { data, loading, error, refetch } = useQuery<
    ConnectedFacebookAdAccountWithToSQuery,
    ConnectedFacebookAdAccountWithToSQueryVariables
  >(CONNECTED_FACEBOOK_AD_ACCOUNT_WITH_TOS_QUERY, {
    variables: { botId },
  });

  const mutationData = useConnectFacebookAdAccount(botId);

  return {
    ...mutationData,
    connectedFacebookAdAccount: data?.bot.connectedFacebookAdAccount,
    loading: loading || mutationData.loading,
    refetch,
    error,
  };
};
