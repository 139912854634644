import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  FacebookAdToSAcceptedQuery,
  FacebookAdToSAcceptedQueryVariables,
} from './@types/FacebookAdToSAcceptedQuery';
import {
  FacebookAdHasSyncedBeforeQuery,
  FacebookAdHasSyncedBeforeQueryVariables,
} from './@types/FacebookAdHasSyncedBeforeQuery';

export const FACEBOOK_AD_TOS_ACCEPTED_QUERY = gql`
  query FacebookAdToSAcceptedQuery($adAccountId: String!) {
    facebookAdToSAccepted(adAccountId: $adAccountId)
  }
`;

export const useFacebookAdToSStatus = (adAccountId = '') => {
  const { data, ...state } = useQuery<
    FacebookAdToSAcceptedQuery,
    FacebookAdToSAcceptedQueryVariables
  >(FACEBOOK_AD_TOS_ACCEPTED_QUERY, {
    variables: {
      adAccountId,
    },
    skip: !adAccountId,
  });

  return {
    facebookAdToSAccepted: data?.facebookAdToSAccepted,
    ...state,
  };
};

export const FACEBOOK_AD_HAS_SYNCED_BEFORE_QUERY = gql`
  query FacebookAdHasSyncedBeforeQuery($adAccountId: String!) {
    facebookAdHasSyncedBefore(adAccountId: $adAccountId)
  }
`;

export const useFacebookAdHasSyncedBefore = (adAccountId = '') => {
  const { data, ...state } = useQuery<
    FacebookAdHasSyncedBeforeQuery,
    FacebookAdHasSyncedBeforeQueryVariables
  >(FACEBOOK_AD_HAS_SYNCED_BEFORE_QUERY, {
    variables: {
      adAccountId,
    },
    skip: !adAccountId,
  });

  return {
    facebookAdHasSyncedBefore: data?.facebookAdHasSyncedBefore,
    ...state,
  };
};
