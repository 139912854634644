import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { isChromeBrowser } from 'cf-common/src/utils/isChromeBrowser';
import * as css from './getSlateFixClassName.css';

export const getSlateFixClassName = () => {
  if (!isMobileDevice() && isChromeBrowser()) {
    return css.fix;
  }
  return undefined;
};
