export enum PaymentInterval {
  day,
  week,
  month,
  year,
}

export const paymentIntervalToEnum = (interval: string) => {
  switch (interval) {
    case 'day':
      return PaymentInterval.day;
    case 'week':
      return PaymentInterval.week;
    case 'month':
      return PaymentInterval.month;
    case 'year':
      return PaymentInterval.year;
    default:
      return PaymentInterval.month;
  }
};
export const paymentIntervalToString = () => ({
  [PaymentInterval.day]: window.i18next.t('paymentInterval-string-3704-day'),
  [PaymentInterval.week]: window.i18next.t('paymentInterval-string-3704-week'),
  [PaymentInterval.month]: window.i18next.t('paymentInterval-string-3704-mo'),
  [PaymentInterval.year]: window.i18next.t('paymentInterval-string-3704-year'),
});
