import { getApiUrl } from './getApiUrl';
import { directFetch } from './directFetch';
import Maybe from 'graphql/tsutils/Maybe';
import { getAuthToken } from '../Auth/getAuthToken';

export const directPost = async (path: string, body: Maybe<BodyInit>) => {
  const result = await directFetch(`${getApiUrl()}${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body,
  });

  const jsonString = await result.json();

  if (result.status !== 200) {
    throw new Error(jsonString);
  }

  return jsonString;
};
