import gql from 'graphql-tag';

export const WHATSAPP_SETTINGS_QUERY = gql`
  query WhatsappSettingsQuery($botId: String!) {
    whatsappMessagingSettings(botId: $botId) {
      id
      hasEmbeddedSignupToken
      whatsappBusinessAccount {
        id
        name
        timezone_id
        message_template_namespace
        account_review_status
        business_verification_status
        on_behalf_of_business_info {
          id
          name
          status
          type
        }
      }
      phone {
        id
        quality_rating
        display_phone_number
        code_verification_status
        normalized_phone_number
        is_on_biz_app
        verified_name
        bot_id
        paymentInformation {
          status
        }
      }
      webhookUrl
      qrCode {
        code
        prefilled_message
        deep_link_url
        qr_image_url
      }
    }
  }
`;

export const WHATSAPP_PHONES = gql`
  query WhatsappPhonesQuery($botId: String!) {
    whatsappPhoneNumbers(botId: $botId) {
      businessAccount {
        id
        name
        timezone_id
        message_template_namespace
        account_review_status
        business_verification_status
      }
      phones {
        id
        bot_id
        quality_rating
        display_phone_number
        code_verification_status
        verified_name
        normalized_phone_number
        is_on_biz_app
        paymentInformation {
          status
        }
      }
    }
  }
`;
