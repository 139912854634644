import { RefObject, Ref, MutableRefObject } from 'react';

/**
 * Combines many refs into one
 * https://github.com/facebook/react/issues/13029
 */
type RefType<T> =
  | ((instance: T | null) => void)
  | RefObject<T>
  | MutableRefObject<T>
  | null
  | undefined;

export const combineRefs = <T extends any>(refs: RefType<T>[]): Ref<T> => (
  element: T,
) =>
  refs.forEach((ref) => {
    if (!ref) {
      return;
    }

    // Ref can have two types - a function or an object. We treat each case.
    if (typeof ref === 'function') {
      ref(element);
    } else {
      /* eslint-disable no-param-reassign */
      // As per https://github.com/facebook/react/issues/13029 it should be fine to set current this way.
      (ref as any).current = element;
      /* eslint-enable no-param-reassign */
    }
  });
