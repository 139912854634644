import gql from 'graphql-tag';
import { AI_INTENT_FRAGMENT } from '../../common/queries';

export const WELCOME_MESSAGE_FRAGMENT = gql`
  fragment welcomeMessageFragment on Bot {
    aiBlock {
      id
      cards {
        id
        ... on AIPlugin {
          config {
            welcome_message_intent {
              ...aiIntentFragment
            }
          }
        }
      }
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const WELCOME_MESSAGE_QUERY = gql`
  query WelcomeMessageQuery($botId: String) {
    bot(id: $botId) {
      id
      ...welcomeMessageFragment
    }
  }
  ${WELCOME_MESSAGE_FRAGMENT}
`;

export const UPDATE_WELCOME_MESSAGE_MUTATION = gql`
  mutation UpdateWelcomeMessageMutation(
    $botId: String!
    $cardId: String!
    $defaultIntent: AiIntentInput!
  ) {
    updateWelcomeMessageIntent(
      botId: $botId
      cardId: $cardId
      defaultIntent: $defaultIntent
    ) {
      ...aiIntentFragment
    }
  }
  ${AI_INTENT_FRAGMENT}
`;
