import { ObservableQuery, ApolloCurrentResult } from 'apollo-client';
import { Folder, FromType } from '@globals';
import client from '../../common/services/ApolloService';
import { DialogsQuery_livechatConversations_items as Dialog } from '../../pages/LiveChat/common/@types/DialogsQuery';
// TODO delete this subscription at all at remove notifications from this file
import { playLiveChatNotificationSound } from '../../pages/LiveChat/utils/liveChatNotificationSound';
import { sequentialPolling } from './sequentialPolling';
import { insertUpdatedItems } from './insertUpdatedItems';
import { IS_PAGE_CONNECTED_QUERY } from '@utils/FacebookPages/usePageConnected';
import { extractGQLErrorData } from '@utils/GQL/utils';
import httpStatus from 'http-status';
import { log } from 'cf-common/src/logger';

function differenceBy<T>(arr1: T[], arr2: T[], iteratee: (item: T) => any) {
  return arr1.filter((el1) => {
    return !arr2.some((el2) => iteratee(el1) === iteratee(el2));
  });
}

function isDataReady<T>(data: T | {}, key: keyof T): data is T {
  return key in <T>data;
}

interface Observer<T> {
  next?(value: T): void;
  error?(errorValue: any): void;
  complete?(): void;
}

export interface Value<T> {
  queryResult: ApolloCurrentResult<T>;
  fetchOlderItems: () => Promise<any>;
}

interface CursorPaginatedList {
  cursors: {
    before: string | number | null;
    after: string | number | null;
  };
  items: any[];
}

interface PollingOptions<T> {
  query: any;
  getVariables: (currentResult: T, timestamp: number) => object;
  resultsKey: string;
}

interface DataSourceOptions<T> {
  botId?: string;
  query: any;
  variables: object;
  pollingOptions?: PollingOptions<T>;
  filter?: (item: any) => boolean;
  resultsKey: keyof T;
  expectedResponseSorting?: 'chronological' | 'reverse-chronological';
  updatesMergeStrategy?: {
    strategy: 'merge' | 'append';
    sortedByKey: string;
  };
  onError?: (err: any) => void;
}

export interface DataSource<T> {
  subscribe: (observer: Observer<Value<T>>) => {
    unsubscribe: () => void;
  };
  getValue(): Value<T>;
}

export function createDataSource<T>({
  botId,
  query,
  variables,
  resultsKey,
  pollingOptions,
  expectedResponseSorting = 'chronological',
  updatesMergeStrategy,
  onError,
}: DataSourceOptions<T>) {
  const creationDate = Date.now();
  const isPageConnectedObservable = client.watchQuery({
    query: IS_PAGE_CONNECTED_QUERY,
    variables: { botId },
  });
  const queryObservable = client.watchQuery({
    query,
    variables,
  }) as ObservableQuery<T>;

  let isClosed = true;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  function fetchNewerItems() {
    // TODO:
    // Currently `fetchNewerItems` is responsible for two things:
    // 1. Querying pagination data after "cursors.after" (atm not really used)
    // 2. Querying "update data" (which we call "pollingOptions" here)
    //
    // The second query might be better written as an explicit separate method.
    // Also, currently it impossible to handle a list with "forward" pagination
    // that needs a separate query for "update" information
    // (although we don't have a list that needs this, yet).
    // But if we make a separate method, it will be possible.
    //
    // NOTE: Ideas for naming: "fetchUpdateData", "fetchUpdateItems", ...
    //
    const currentData = queryObservable.currentResult();
    const { loading, data } = currentData;
    if (loading || !isDataReady(data, resultsKey)) {
      return Promise.resolve();
    }
    const currentListData = data[resultsKey] as unknown as CursorPaginatedList;
    const { cursors } = currentListData;
    let nextQuery;
    let nextVariables;
    let nextResultsKey: keyof T;
    if (pollingOptions) {
      nextQuery = pollingOptions.query;
      nextVariables = pollingOptions.getVariables(data, creationDate);
      nextResultsKey = pollingOptions.resultsKey as any;
    } else {
      nextQuery = query;
      nextVariables = {
        ...variables,
        from: cursors.after,
      };
      nextResultsKey = resultsKey;
    }
    return client
      .query({
        query: nextQuery,
        variables: nextVariables,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (isClosed) {
          // subscription closed, discarding result
          return;
        }
        if (!data || !(nextResultsKey in data)) {
          return;
        }
        queryObservable.updateQuery((prev) => {
          const afterListData = data[
            nextResultsKey
          ] as unknown as CursorPaginatedList;
          const currentItems = currentListData.items;
          const afterItems = afterListData.items;
          if (!afterItems.length) {
            return prev;
          }

          const dialogHasNewMessages = (dialog: Dialog) =>
            currentItems.some(
              (currentItem) =>
                currentItem.id === dialog.id &&
                currentItem.last_message?.id !== dialog.last_message?.id &&
                dialog.last_message?.from.type === FromType.user,
            );

          const dialogIsNew = (dialog: Dialog) =>
            !currentItems.some((currentItem) => currentItem.id === dialog.id);

          const dialogInInbox = (dialog: Dialog) =>
            dialog.folder === Folder.inbox;

          if (
            resultsKey === 'livechatConversations' &&
            afterItems.some(
              (item) =>
                dialogInInbox(item) &&
                (dialogHasNewMessages(item) || dialogIsNew(item)),
            )
          ) {
            playLiveChatNotificationSound(botId);
          }

          let mergedItems;
          if (
            updatesMergeStrategy &&
            updatesMergeStrategy.strategy === 'merge'
          ) {
            mergedItems = insertUpdatedItems({
              currentItems,
              newItems: afterItems,
              sorting: expectedResponseSorting,
              sortedByKey: updatesMergeStrategy.sortedByKey,
              endOfListReached: cursors.before == null,
            });
          } else {
            const currentItemsWithoutNewItems = differenceBy(
              currentItems,
              afterItems,
              (x: any) => x.id,
            );
            mergedItems =
              expectedResponseSorting === 'chronological'
                ? [...currentItemsWithoutNewItems, ...afterItems]
                : [...afterItems, ...currentItemsWithoutNewItems];
          }

          const result = {
            ...(prev as any),
            [resultsKey]: {
              ...currentListData,
              cursors: {
                ...currentListData.cursors,
                after: afterListData.cursors.after,
              },
              items: mergedItems,
            },
          };

          return result;
        });
      })
      .catch((e) => {
        log.error({
          msg: 'createDataSource fetchNewerItems updateQuery failed',
          data: { error: e },
        });

        /**
         * Ожидаемая ошибка.
         * Если юзер зашел в live chat, то начинается поллинг этой функцией и если выйти из
         * лайв чата, то поллинг продолжится (хз баг или фича) и юзер сможет дисконнектнуть
         * страницу в home tab, следовательно, поллить сообщения страницы мы не сможем и
         * будут валиться 400ые ошибки.
         *
         * Поэтому перед поллингом надо проверять подключена ли страница (см использование
         * подписки на изменение страницы {@see isPageConnectedObservable}). Но даже так
         * ошибка сможет произойти из-за рейс кондишена поллинга и запроса на дисконнект
         * страницы
         */
        if (extractGQLErrorData(e)?.status === httpStatus.BAD_REQUEST) {
          return;
        }

        if (onError) {
          onError(e);
        } else {
          throw e;
        }
      });
  }

  function fetchOlderItems() {
    const currentData = queryObservable.currentResult();
    const { data } = currentData;
    if (!isDataReady(data, resultsKey)) {
      return Promise.resolve();
    }
    const currentListData = data[resultsKey] as unknown as CursorPaginatedList;
    const { items, cursors } = currentListData;
    if (!items.length) {
      return Promise.resolve();
    }
    if (!cursors.before) {
      throw new Error('Cannot request older items: No "before" cursor found');
    }
    return client
      .query({
        query,
        variables: {
          ...variables,
          to: cursors.before,
        },
      })
      .then(({ data }) => {
        if (isClosed) {
          // subscription closed, discarding result
          return;
        }
        if (!data || !(resultsKey in data)) {
          return;
        }
        queryObservable.updateQuery((prev) => {
          const currentListData = prev[
            resultsKey
          ] as unknown as CursorPaginatedList;
          const beforeListData = data[resultsKey] as CursorPaginatedList;
          const currentItems = currentListData.items;
          const beforeItems = beforeListData.items;

          const result = {
            ...(prev as any),
            [resultsKey]: {
              ...currentListData,
              cursors: {
                ...currentListData.cursors,
                before: beforeListData.cursors.before,
              },
              items:
                expectedResponseSorting === 'chronological'
                  ? [...beforeItems, ...currentItems]
                  : [...currentItems, ...beforeItems],
            },
          };

          return result;
        });
      })
      .catch((e) => {
        log.error({
          msg: 'createDataSource fetchOlderItems updateQuery failed',
          data: { error: e },
        });

        if (onError) {
          onError(e);
        } else {
          throw e;
        }
      });
  }

  let firstResponseResolver: Function;
  const firstResponseIsReceived = new Promise((resolve) => {
    firstResponseResolver = resolve;
  });

  function createCurrentValue(currentResult: ApolloCurrentResult<T>) {
    // NOTE:
    // Maybe it makes sense to not create a new object with a new function
    // on each call, but instead create an { fetchOlderItems, queryResult } object
    // and mutate its `queryResult` property and return the object.
    return {
      fetchOlderItems() {
        return firstResponseIsReceived.then(() => {
          return fetchOlderItems.apply(arguments); // eslint-disable-line prefer-rest-params
        });
      },
      queryResult: currentResult,
    };
  }

  return {
    subscribe: (observer: Observer<Value<T>>) => {
      let stopPolling: Function | undefined;
      const subscription = queryObservable.subscribe({
        next: (data) => {
          firstResponseResolver();
          if (observer.next) {
            observer.next(createCurrentValue(data));
            if (!stopPolling) {
              stopPolling = sequentialPolling({
                pollingFn: () => {
                  const pageConnection =
                    isPageConnectedObservable.currentResult();

                  if (!pageConnection.data.bot.status.page) {
                    return Promise.resolve();
                  }

                  return fetchNewerItems();
                },
                interval: 60000,
              });
            }
          }
        },
        error: (error) => {
          log.error({
            msg: 'createDataSource subscrption failed',
            data: { error },
          });
          if (onError) {
            onError(error);
          }
          if (observer.error) {
            observer.error(error);
          }
        },
      });
      isClosed = false;

      return {
        unsubscribe: () => {
          isClosed = true;
          if (stopPolling) {
            stopPolling();
          }
          subscription.unsubscribe();
        },
      };
    },

    getValue(): Value<T> {
      return createCurrentValue(queryObservable.currentResult());
    },
    queryObservable,
  };
}
