import { ApolloQueryResult } from 'apollo-client';
import { useCallback } from 'react';
import gql from 'graphql-tag';
import memoize from 'lodash-es/memoize';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from '@apollo/react-common';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import client from '../../common/services/ApolloService';
import {
  GET_SERVER_STORAGE_QUERY as GET_SERVER_STORAGE_QUERY_TYPE,
  GET_SERVER_STORAGE_QUERYVariables,
} from './@types/GET_SERVER_STORAGE_QUERY';
import {
  SET_SERVER_STORAGE_MUTATION as SET_SERVER_STORAGE_MUTATION_TYPE,
  SET_SERVER_STORAGE_MUTATIONVariables,
} from './@types/SET_SERVER_STORAGE_MUTATION';
import { ServerStorageItemKeys } from './serverStorageKeys';

export class ServerStorageError extends Error {
  constructor(message: string) {
    super(message);

    this.message = message;
    this.name = 'ServerStorageError';
  }
}

const SET_SERVER_STORAGE_MUTATION = gql`
  mutation SET_SERVER_STORAGE_MUTATION($id: ID!, $data: String!) {
    serverStorageItemSet(id: $id, data: $data) {
      id
      data
    }
  }
`;

const GET_SERVER_STORAGE_QUERY = gql`
  query GET_SERVER_STORAGE_QUERY($id: ID!) {
    serverStorageItemGet(id: $id) {
      id
      data
    }
  }
`;

const PRELOAD_SERVER_STORAGE_ITEMS = [
  ServerStorageItemKeys.countOfOneBlockSelectorPopup,
];

export const serverStorageItemGet = async (id: string) => {
  const {
    data: {
      serverStorageItemGet: { data },
    },
  } = await client.query<GET_SERVER_STORAGE_QUERY_TYPE>({
    query: GET_SERVER_STORAGE_QUERY,
    variables: {
      id,
    },
  });

  return data ? JSON.parse(data) : null;
};

export const serverStorageItemSet = async (id: string, data: any) => {
  const jsonData = JSON.stringify(data);
  await client.mutate<
    SET_SERVER_STORAGE_MUTATION_TYPE,
    SET_SERVER_STORAGE_MUTATIONVariables
  >({
    mutation: SET_SERVER_STORAGE_MUTATION,
    variables: {
      id,
      data: jsonData,
    },
    optimisticResponse: {
      serverStorageItemSet: {
        id,
        __typename: 'ServerStorageItem',
        data: jsonData,
      },
    },
  });
};

export const preloadServerStorage = () =>
  PRELOAD_SERVER_STORAGE_ITEMS.forEach((key) => serverStorageItemGet(key));

export type SetCallbackType<T> = (
  rawData: T,
) => Promise<ExecutionResult<SET_SERVER_STORAGE_MUTATION_TYPE>>;

export function useServerStorage<T = any>(
  serverStorageKey: ServerStorageItemKeys,
  skip?: boolean,
  onCompleted?: (data: GET_SERVER_STORAGE_QUERY_TYPE) => void,
) {
  const { data, loading, networkStatus } = useQuery<
    GET_SERVER_STORAGE_QUERY_TYPE,
    GET_SERVER_STORAGE_QUERYVariables
  >(GET_SERVER_STORAGE_QUERY, {
    variables: {
      id: serverStorageKey as string,
    },
    skip,
    onCompleted,
  });
  const [setServerStorageMutation, { loading: saving }] = useMutation<
    SET_SERVER_STORAGE_MUTATION_TYPE,
    SET_SERVER_STORAGE_MUTATIONVariables
  >(SET_SERVER_STORAGE_MUTATION);
  const setCallback = useCallback<SetCallbackType<T>>(
    (rawData) => {
      const data = JSON.stringify(rawData);
      const id = serverStorageKey as string;
      return setServerStorageMutation({
        variables: {
          id,
          data,
        },
        optimisticResponse: {
          serverStorageItemSet: {
            id,
            __typename: 'ServerStorageItem',
            data,
          },
        },
      });
    },
    [serverStorageKey, setServerStorageMutation],
  );

  return [
    data &&
      (JSON.parse(data.serverStorageItemGet.data || 'null') as T | undefined),
    setCallback,
    {
      loading,
      saving,
      dataNetworkStatus: networkStatus,
    },
  ] as const;
}

export function useServerStorageForGroup<T>(
  serverStorageKey: ServerStorageItemKeys,
  groupKey?: string,
  skip?: boolean,
): [
  T | undefined,
  SetCallbackType<T>,
  {
    loading: boolean;
    saving: boolean;
  },
] {
  const notNullGroupKey = groupKey ?? '';
  const [data, setData, info] = useServerStorage(serverStorageKey, skip);

  const setCallback = useCallback<SetCallbackType<T>>(
    (rawData) => {
      const newData = {
        ...(data || {}),
        [notNullGroupKey]: rawData,
      };
      return setData(newData);
    },
    [data, setData, notNullGroupKey],
  );

  return [data?.[notNullGroupKey], setCallback, info];
}

export function useServerStorageForBot<T>(
  serverStorageKey: ServerStorageItemKeys,
  botId?: string,
  skip?: boolean,
) {
  return useServerStorageForGroup<T>(serverStorageKey, botId, skip);
}

export function useServerStorageForFlow<T>(
  serverStorageKey: ServerStorageItemKeys,
  flowId?: string,
  skip?: boolean,
) {
  return useServerStorageForGroup<T>(serverStorageKey, flowId, skip);
}

export const getServerStorageObservable = memoize(
  (serverStorageKey: ServerStorageItemKeys) =>
    Observable.from(
      client.watchQuery<
        GET_SERVER_STORAGE_QUERY_TYPE,
        GET_SERVER_STORAGE_QUERYVariables
      >({
        query: GET_SERVER_STORAGE_QUERY,
        variables: {
          id: serverStorageKey as string,
        },
      }) as ObservableInput<ApolloQueryResult<GET_SERVER_STORAGE_QUERY_TYPE>>,
    ).map(
      ({
        data: {
          serverStorageItemGet: { data },
        },
      }) => {
        return data ? JSON.parse(data) : null;
      },
    ),
);
