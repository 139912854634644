import gql from 'graphql-tag';

export const BOT_PRICING_CURRENT_USERS_DATA_QUERY = gql`
  query BotPricingCurrentUsersDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      pricingData {
        currentUsers
        reached_limit
      }
    }
  }
`;
