import gql from 'graphql-tag';

export const BOT_PRO_STATUS_QUERY = gql`
  query BotProStatusQuery($botId: String!) {
    bot(id: $botId) {
      id
      pro {
        days_before_cancel
        millis_before_cancel
        status
        manual
        is_paused
      }
    }
  }
`;
