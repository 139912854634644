import ng from 'angular';
import controller from './controller';
import template from './template.html';
import './assets/less/dropdown-menu.less';

/**
 *
 * @description: drop down menu allow you select on of the options;
 *
 */

export default ng.module('app.ui.dropDownMenu', []).component('dropDownMenu', {
  controllerAs: 'vm',
  bindings: {
    /**
     * @property: {object} [textFieldTypes] - see chip-input types;
     */
    textFieldTypes: '<',

    /**
     * @bindings: placeholder:string;
     */

    placeholder: '@',

    /**
     *
     * @type Option {label:string,sublabel:string, value:any}
     * @value: will be used to see what value currently selected by ===;
     *
     * @binding options: Options[], @required array of options;
     */
    options: '<',
    /**
     *
     * @binding: value : any, currently selected value; see options;
     *
     */
    value: '<',
    /**
     *
     * @property: value : disable Y offset for open dropdown;
     *
     */
    yOffsetOff: '<',
    /**
     *
     * @property: value : text color theme;
     *
     */
    textColorTheme: '@',
    /**
     *
     * @description: on value changed will be fired; on every change;
     * onChange: (value: any)=> void;
     *
     */
    onChange: '&',
    /**
     *
     * @binding: open: boolean; controlled open property whether or not show you menu with options;
     *
     */
    cfOpen: '<',

    /**
     *
     * @binding: onClick: (e:HTMLClickEvent) => void;
     *
     */
    onClick: '&',

    /**
     * @bindings: fullWidth: string (true); is popup is same width as this.element;
     */

    fullWidth: '@',

    /**
     * @bindings: onClose: onOpen - notify when it is trying to close/open; usefull to synck open close state;
     */

    onClose: '&',
    onOpen: '&',
  },
  template: () => template,
  controller,
}).name;
