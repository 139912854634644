export const isValidJSON = (jsonStr: string) => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (_) {
    return false;
  }
};

export const isValidURLSearchParamsString = (searchStr: string) => {
  try {
    // eslint-disable-next-line no-new
    new URLSearchParams(searchStr);
    return true;
  } catch (_) {
    return false;
  }
};
