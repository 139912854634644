import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { DiscountType } from '@globals';
import {
  DiscountStatusQuery,
  DiscountStatusQueryVariables,
} from './@types/DiscountStatusQuery';

const DISCOUNT_STATUS_QUERY = gql`
  query DiscountStatusQuery($botId: String!, $discountType: DiscountType!) {
    discountStatus(botId: $botId, discountType: $discountType)
  }
`;
export const useDiscountStatus = (
  botId: string,
  discountType: DiscountType,
) => {
  const { data, loading } = useQuery<
    DiscountStatusQuery,
    DiscountStatusQueryVariables
  >(DISCOUNT_STATUS_QUERY, {
    variables: { botId, discountType },
    skip: !botId,
  });
  return {
    discountStatus: data?.discountStatus,
    discountStatusLoading: loading,
  };
};
