import { PluginType } from '@components/FlowBuilder/types';
import gql from 'graphql-tag';
import { FLOW_BLOCK_FRAGMENT } from '@components/Plugins/common/PluginGQL';
import { useQuery } from '@apollo/react-hooks';
import {
  FlowBlocksDataQuery,
  FlowBlocksDataQuery_bot_flowBlocks_cards_InboundLinksEntryPoint,
  FlowBlocksDataQueryVariables,
} from './@types/FlowBlocksDataQuery';
import { useCurrentBotId } from '../../Routing';
import { getEntryPointCard } from '@components/FlowBuilder/views/entry-points/common/utils';
import { isMongoObjectId } from 'cf-common/src/utils/mongo/isMongoObjectId';

export const FLOW_BLOCKS_DATA_QUERY = gql`
  query FlowBlocksDataQuery($botId: String!, $flowId: MongoObjectId!) {
    bot(id: $botId) {
      flowBlocks(id: $flowId) {
        ...FlowBlockFragment
      }
    }
  }
  ${FLOW_BLOCK_FRAGMENT}
`;

export const useFlowInboundsLinks = (flowId: string | undefined) => {
  const botId = useCurrentBotId();
  const { data, ...rest } = useQuery<
    FlowBlocksDataQuery,
    FlowBlocksDataQueryVariables
  >(FLOW_BLOCKS_DATA_QUERY, {
    skip: !botId || !flowId || !isMongoObjectId(flowId),
    variables: {
      botId: botId || '',
      flowId: flowId || '',
    },
  });

  const inboundLinksEntryPoint = data?.bot.flowBlocks
    ?.map(({ cards }) => getEntryPointCard(cards))
    .find(
      (entryPoint) =>
        entryPoint?.plugin_id === PluginType.inbound_links_entry_point,
    ) as
    | FlowBlocksDataQuery_bot_flowBlocks_cards_InboundLinksEntryPoint
    | undefined;

  const inboundLinks = inboundLinksEntryPoint?.config;
  const { blocks = [], flows = [], broadcasts = [] } = inboundLinks || {};

  return {
    inboundLinks,
    qtyInboundLinks: blocks.length + flows.length + broadcasts.length,
    ...rest,
  };
};
