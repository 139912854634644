import {
  STRIPE_CONNECT_CONFIRM_MUTATION,
  STRIPE_CONNECT_URL_QUERY,
  STRIPE_DISCONNECT_MUTATION,
  STRIPE_STATUS_QUERY,
} from '../common/queries';
import { StripeConnectUrlQuery } from '../common/@types/StripeConnectUrlQuery';
import { useMutation, useQuery } from 'react-apollo';
import {
  StripeConnectConfirmMutation,
  StripeConnectConfirmMutationVariables,
} from '../common/@types/StripeConnectConfirmMutation';
import {
  StripeDisconnectMutation,
  StripeDisconnectMutationVariables,
} from '../common/@types/StripeDisconnectMutation';
import { useCurrentBotId } from '../../Routing';
import { useCallback, useRef, useState } from 'react';
import {
  StripeStatusQuery,
  StripeStatusQueryVariables,
} from '../common/@types/StripeStatusQuery';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { Level, log } from 'cf-common/src/logger';
import {
  STRIPE_CODE_PARAM_NAME,
  STRIPE_CONFIRM_POST_MESSAGE_SPLITTER,
} from './consts';
import Maybe from 'graphql/tsutils/Maybe';
import { removeEntity } from '@components/FlowBuilder/components';
import { useSafeTranslation } from '../../useSafeTranslation';
import { usePostMessage } from 'cf-common/src/utils/DOM/usePostMessage';

interface StripeAccountArgs {
  onConnected?: VoidFunction;
}

export const useStripeAccount = ({ onConnected }: StripeAccountArgs = {}) => {
  const botId = useCurrentBotId();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const stripeWindowRef = useRef<Maybe<Window>>();
  const { t } = useSafeTranslation();
  const { data, loading: stripeStatusLoading } = useQuery<
    StripeStatusQuery,
    StripeStatusQueryVariables
  >(STRIPE_STATUS_QUERY, {
    variables: {
      botId: botId!,
    },
    skip: !botId,
  });
  const { data: stripeConnectUrlData } = useQuery<StripeConnectUrlQuery>(
    STRIPE_CONNECT_URL_QUERY,
  );
  const [stripeConnectConfirmMutation] = useMutation<
    StripeConnectConfirmMutation,
    StripeConnectConfirmMutationVariables
  >(STRIPE_CONNECT_CONFIRM_MUTATION, {
    onError: (error) => {
      showSomethingWentWrongToaster();
      log({
        msg: 'StripeConnectConfirmMutation error!',
        level: Level.warn,
        data: {
          error,
        },
      });
    },
  });
  const [stripeDisconnectMutation, { loading: stripeDisconnectLoading }] =
    useMutation<StripeDisconnectMutation, StripeDisconnectMutationVariables>(
      STRIPE_DISCONNECT_MUTATION,
      {
        onError: (error) => {
          showSomethingWentWrongToaster();
          log({
            msg: 'StripeDisconnectMutation error!',
            level: Level.warn,
            data: {
              error,
            },
          });
        },
      },
    );

  usePostMessage('confirm', async (message) => {
    if (!inProgress) {
      return;
    }
    if (!botId) {
      setInProgress(false);
      return;
    }

    stripeWindowRef.current?.close();
    stripeWindowRef.current = null;
    const [, href] = message.split(STRIPE_CONFIRM_POST_MESSAGE_SPLITTER);
    const code = href && new URL(href).searchParams.get(STRIPE_CODE_PARAM_NAME);
    if (!code) {
      setInProgress(false);
      return;
    }
    await stripeConnectConfirmMutation({
      variables: {
        botId,
        code,
      },
    });
    setInProgress(false);
    onConnected?.();
  });

  const stripeConnectUrl = stripeConnectUrlData?.stripeConnectUrl;

  const stripeConnect = useCallback(async () => {
    if (inProgress) {
      return;
    }
    if (!stripeConnectUrl || !botId) {
      showSomethingWentWrongToaster();
      return;
    }
    setInProgress(true);
    stripeWindowRef.current = window.open(stripeConnectUrl, 'stripeWindow');
  }, [botId, inProgress, stripeConnectUrl]);

  const stripeDisconnect = useCallback(() => {
    if (!botId) {
      return;
    }
    removeEntity({
      onSubmit: () => {
        stripeDisconnectMutation({ variables: { botId } });
      },
      renderActionText: () => t('components.settings.Payments.disconnect'),
      renderHeading: () =>
        t('components.settings.Payments.confirmDisconnect.title'),
      renderNoteText: () =>
        t('components.settings.Payments.confirmDisconnect.text'),
    });
  }, [botId, stripeDisconnectMutation, t]);

  return {
    isStripeConnected: data?.bot.payments_stripe,
    stripeConnect,
    stripeDisconnect,
    stripeStatusLoading,
    stripeDisconnectLoading,
    inProgressStripeConnect: inProgress,
  };
};
