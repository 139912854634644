/* eslint-disable no-underscore-dangle */
import { propEq, pickBy } from 'ramda';
import { ApolloError } from 'apollo-client';
import { ConflictingData } from '@components/FlowBuilder/views/entry-points/common/resolveEntryPointConflict';
import introspectionQueryResultData from '../../../@types/gqlFragmentTypes.json';
import httpStatus from 'http-status';

type Primitive =
  | string
  | Function
  | number
  | boolean
  | Symbol
  | undefined
  | null;

type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends Primitive
          ? TP
          : TP extends any[]
          ? DeepOmitArray<TP, K>
          : DeepOmit<TP, K>
        : never;
    };

export type WithoutGQLTypename<GQLType> = DeepOmit<GQLType, '__typename'>;

export function removeTypename(data: {}) {
  return JSON.parse(
    JSON.stringify(data, (k, v) => (k === '__typename' ? undefined : v)),
  );
}

export const removeNullFields = pickBy((value) => value !== null);

export const removeNullFieldsDeep = (data: {}) =>
  JSON.parse(JSON.stringify(data, (_, v) => (v === null ? undefined : v)));

const getPluginFragmentTypes = () =>
  (
    introspectionQueryResultData.__schema.types.find(propEq('name', 'CardI')) ||
    {}
  ).possibleTypes;

export const getPluginTypeByGqlTypename = (typename: string) =>
  (getPluginFragmentTypes().find(propEq('name', typename)) || {}).pluginType;

export const getGqlTypenameByPluginType = (pluginType: string) =>
  (getPluginFragmentTypes().find(propEq('pluginType', pluginType)) || {}).name;

export interface ParsedApolloError {
  message: string;
  status: string;
}

export interface GQLError {
  message: any;
  status: number;
  conflictingData: ConflictingData;
  extensions: any;
}

export const extractGQLErrorData = (
  error: ApolloError | undefined,
): GQLError | undefined => {
  if (!error) {
    return undefined;
  }

  const extensions = error.graphQLErrors?.[0]?.extensions;
  const response = extensions?.response;
  const status = response?.status;
  const successErrorMessage = extensions?.errors?.[0];
  const message = response?.body?.errors?.[0] || successErrorMessage;
  const conflictingData = response?.body?.conflicting_data as ConflictingData;
  return {
    message,
    status,
    conflictingData,
    extensions,
  };
};

export const getRequestIdFromApolloError = (
  error: ApolloError | undefined,
): string | undefined => {
  return error?.graphQLErrors?.[0]?.extensions?.requestId;
};

type MatchFN<T> = () => T;

export class MatchGQLErrorStatus<T> {
  private callback: Array<{ status: number; fn: MatchFN<T> }> = [];
  private defaultCallback?: MatchFN<T>;

  public badRequest(fn: MatchFN<T>) {
    this.callback.push({ status: httpStatus.BAD_REQUEST, fn });
    return this;
  }

  public conflict(fn: MatchFN<T>) {
    this.callback.push({ status: httpStatus.CONFLICT, fn });
    return this;
  }

  public unprocessableContent(fn: MatchFN<T>) {
    this.callback.push({ status: httpStatus.UNPROCESSABLE_ENTITY, fn });
    return this;
  }

  public default(fn: MatchFN<T>) {
    this.defaultCallback = fn;
    return this;
  }

  public match(error: GQLError) {
    const cb = this.callback.find(({ status }) => status === error.status);
    if (!cb) {
      if (this.defaultCallback) {
        return this.defaultCallback();
      }

      return undefined;
    }

    return cb.fn();
  }
}
