import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { removeTypename } from '@utils/GQL/utils';

import {
  ADD_AI_DEFAULT_ANSWER_MUTATION,
  AI_DEFAULT_ANSWER_QUERY,
} from './queries';
import { preparePredefinedIntent } from '../helpers';
import {
  AiDefaultAnswerQuery,
  AiDefaultAnswerQuery_bot_aiBlock_cards_AIPlugin as AiCard,
  AiDefaultAnswerQuery_bot_aiBlock_cards_AIPlugin_config_default_answer_intent as AiDefaultAnswerIntent,
  AiDefaultAnswerQueryVariables,
} from './@types/AiDefaultAnswerQuery';
import {
  UpdateAiDefaultAnswerMutation,
  UpdateAiDefaultAnswerMutationVariables,
} from './@types/UpdateAiDefaultAnswerMutation';
import { UpdatePredefinedIntentArgs } from '../types';

export const useAiDefaultAnswer = (botId?: string) => {
  const { data, ...state } = useQuery<
    AiDefaultAnswerQuery,
    AiDefaultAnswerQueryVariables
  >(AI_DEFAULT_ANSWER_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const [updateAiDefaultIntentMutation] = useMutation<
    UpdateAiDefaultAnswerMutation,
    UpdateAiDefaultAnswerMutationVariables
  >(ADD_AI_DEFAULT_ANSWER_MUTATION);

  const aiCard = data?.bot.aiBlock.cards?.[0];
  const aiDefaultAnswerIntent =
    aiCard && (aiCard as AiCard).config.default_answer_intent;
  const aiDefaultAnswerRespondInterval =
    (aiCard && (aiCard as AiCard).config.default_answer_delay) || 0;

  const aiDefaultAnswerIntentBlockId =
    aiDefaultAnswerIntent &&
    (
      aiDefaultAnswerIntent as AiDefaultAnswerIntent
    ).action.items[0]?.blocks?.find((block) => block.id)?.id;

  const updateAiDefaultIntent = useCallback(
    ({ actionField, blocks, text, type }: UpdatePredefinedIntentArgs) => {
      if (!aiDefaultAnswerIntent || !botId || !aiCard) {
        return undefined;
      }
      const updatedDefaultAnswerIntent = preparePredefinedIntent({
        actionField,
        blocks,
        text,
        type,
        currentIntent: aiDefaultAnswerIntent,
      });
      return updateAiDefaultIntentMutation({
        variables: {
          botId,
          cardId: aiCard.id,
          defaultIntent: removeTypename(updatedDefaultAnswerIntent),
          defaultAnswerDelay: aiDefaultAnswerRespondInterval,
        },
        optimisticResponse: {
          updateDefaultAnswerIntent: {
            __typename: 'AIPlugin',
            id: aiCard.id,
            config: {
              __typename: 'AIPluginConfig',
              default_answer_delay: aiDefaultAnswerRespondInterval,
              default_answer_intent: updatedDefaultAnswerIntent,
            },
          },
        },
      });
    },
    [
      aiDefaultAnswerIntent,
      botId,
      aiCard,
      updateAiDefaultIntentMutation,
      aiDefaultAnswerRespondInterval,
    ],
  );

  const updateAiDefaultAnswerRespondInterval = useCallback(
    (interval: number) => {
      if (!aiDefaultAnswerIntent || !botId || !aiCard) {
        return undefined;
      }
      return updateAiDefaultIntentMutation({
        variables: {
          botId,
          cardId: aiCard.id,
          defaultIntent: removeTypename(aiDefaultAnswerIntent),
          defaultAnswerDelay: interval,
        },
        optimisticResponse: {
          updateDefaultAnswerIntent: {
            __typename: 'AIPlugin',
            id: aiCard.id,
            config: {
              __typename: 'AIPluginConfig',
              default_answer_delay: interval,
              default_answer_intent: aiDefaultAnswerIntent,
            },
          },
        },
      });
    },
    [aiDefaultAnswerIntent, botId, aiCard, updateAiDefaultIntentMutation],
  );

  return {
    aiCard,
    aiDefaultAnswerIntent,
    aiDefaultAnswerRespondInterval,
    aiDefaultAnswerIntentBlockId,
    updateAiDefaultIntent,
    updateAiDefaultAnswerRespondInterval,
    ...state,
  };
};
