import gql from 'graphql-tag';

const ONBOARDING_TOUR_FRAGMENT = gql`
  fragment onboardingTourFragment on OnboardingTour {
    id
    initialStep {
      title
      description
      imageUrl
      modalId
    }
    steps {
      popUpData {
        ... on OnboardingTourCustomPopupData {
          popupId
        }
        ... on OnboardingTourPopupTextData {
          text
          buttonText
          buttonEvent {
            type
            validateValue
          }
        }
      }
      nextStepEvent {
        type
        validateValue
      }
      zoneData {
        focus {
          ... on OnboardingTourFlowBuilderZone {
            blockId
            cardId
            shape
            shapeIndex
          }
          ... on OnboardingTourHTMLZone {
            htmlElementId
            elementIndex
          }
        }
        active {
          ... on OnboardingTourFlowBuilderZone {
            blockId
            cardId
            shape
            shapeIndex
          }
          ... on OnboardingTourHTMLZone {
            htmlElementId
            elementIndex
          }
        }
      }
    }
  }
`;

export const ENTRY_POINT_TIPS_QUERY = gql`
  query EntryPointTipsQuery($botId: String!) {
    entryPointTips(botId: $botId) {
      platform
      sections {
        id
        title
        entryPoints {
          templateFlowGroupId
          title
          description
          hasButton
          onboardingTour {
            ...onboardingTourFragment
          }
          type
          blockId
          flowId
          status
          youTubeUrl
          hasFlowTour
          verificationStatus
          tooltip
        }
      }
    }
  }

  ${ONBOARDING_TOUR_FRAGMENT}
`;

export const FLOW_TOUR_QUERY = gql`
  query FlowTourQuery($flowId: String!) {
    onboardingTour(flowId: $flowId) {
      ...onboardingTourFragment
    }
  }

  ${ONBOARDING_TOUR_FRAGMENT}
`;
