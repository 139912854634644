import { isSomeEnum } from '../isSomeEnum';

export const getUrlSearchParams = (search: string, key: string) => {
  const params = new URLSearchParams(search);
  return params.get(key);
};

export const getUrlSearchParamsEnumSafe = <T extends { [k: string]: string }>(
  search: string,
  key: string,
  list: T,
) => {
  const value = getUrlSearchParams(search, key);

  return isSomeEnum(list, value) ? value : null;
};
