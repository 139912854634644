import { useCurrentBotId } from '@utils/Routing';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useBotFeatures } from '@utils/Data/Bot';

export const useIsBlockStatsEnabled = () => {
  const botId = useCurrentBotId();
  const { isConnected, loading: pageStatusLoading } = usePageConnected(botId);
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);

  const isBlockStatsEnabled = !!botFeatures?.inline_stats_ro && isConnected;

  return {
    loading: pageStatusLoading || botFeaturesLoading,
    isBlockStatsEnabled,
  };
};
