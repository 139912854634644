import linkifyHtml from 'linkifyjs/html';
import { filterXSS } from 'xss';

/**
 * create links in content editable element
 */
export default class LinkHighlighterController {
  /**
   * -{*} -
   * @param {*} $element -
   * @param {*} $attrs -
   * @param {*} $scope -
   * @param {*} $timeout -
   */
  constructor($element, $attrs, $scope, $timeout) {
    'ngInject';

    this.$element = $element;
    this.$attrs = $attrs;
    this.$timeout = $timeout;

    this.linkHighlightEnable = false;
    this.linkBeenHighlighted = false;

    $attrs.$observe('linkHighlighter', (value) => {
      this.linkHighlightEnable = value === 'true';
      this.render();
    });
  }

  /**
   * -
   */
  $onInit() {
    // you should render on init;
    // but on init you can be not in the DOM yet. ussual anugalar staff;
    this.$timeout(() => this.render());
    const editableElementClass = this.$attrs.linkHighlighterClass;
    this.editableElement = editableElementClass
      ? this.$element.find(`.${editableElementClass}`)
      : this.$element;
  }

  /**
   * angular render method
   */
  render() {
    if (this.linkHighlightEnable || this.linkBeenHighlighted) {
      const text = this.editableElement[0].innerText.replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1<br/>$2',
      );
      const htmlString = this.linkHighlightEnable
        ? linkifyHtml(text, {
            attributes: {
              target: '_blank',
              contenteditable: 'false',
            },
            className: 'link',
          })
        : text;

      this.editableElement[0].innerHTML = filterXSS(htmlString);
      this.linkBeenHighlighted = this.linkHighlightEnable;
    }
  }
}
