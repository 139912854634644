import { useQuery } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import { BOT_LIVELINESS_QUERY } from './query';
import {
  BotLivelinessQuery,
  BotLivelinessQueryVariables,
} from './@types/BotLivelinessQuery';
import { BotLivelinessStatus } from '@globals';

export type BotLivelinessStatusParams = ReturnType<
  typeof useBotLivelinessStatus
>;

export const useBotLivelinessStatus = () => {
  const botId = useCurrentBotId()!;
  const { data, loading, error } = useQuery<
    BotLivelinessQuery,
    BotLivelinessQueryVariables
  >(BOT_LIVELINESS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      botId,
    },
  });

  const status = data?.bot?.botLivelinessStatus;
  return {
    status,
    isLive: status === BotLivelinessStatus.LIVE,
    isPaused: status === BotLivelinessStatus.PAUSED,
    loading,
    error,
  };
};
