import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useIsFirstSessionTab,
} from '../../Routing';
import { useShowFirstSession } from '@pages/BotPage/KeywordsPage/FirstSessionPage/hooks/useShowFirstSession';
import { useFirstSessionStep } from '@pages/BotPage/KeywordsPage/FirstSessionPage/hooks/useFirstSessionStep';
import { FirstSessionStep } from '@pages/BotPage/KeywordsPage/FirstSessionPage/consts';
import { isMongoObjectId } from 'cf-common/src/utils/mongo/isMongoObjectId';

export const useIsNeedRedirectToFirstSession = () => {
  const isFirstSessionTab = useIsFirstSessionTab();
  const { isShowFirstSession, showFirstSessionLoading } = useShowFirstSession();
  const botId = useCurrentBotId();
  const [step, _, { loading: stepLoading }] = useFirstSessionStep();

  const loading = stepLoading || showFirstSessionLoading;

  const needRedirect =
    step !== FirstSessionStep.done &&
    !loading &&
    !isFirstSessionTab &&
    isShowFirstSession;

  return {
    loading,
    needRedirect,
    redirectTo:
      needRedirect && botId && isMongoObjectId(botId)
        ? getTabLink(BotTabs.firstSession, botId)
        : undefined,
  };
};
