import { useCurrentBotId } from '../../../Routing';
import { useMutation } from '@apollo/react-hooks';

import { UPDATE_AI_INTENTS_BULK_MUTATION } from './queries';
import { useCallback } from 'react';
import { AiIntentInput } from '@globals';
import {
  UpdateAiIntentsBulkMutation,
  UpdateAiIntentsBulkMutationVariables,
} from './@types/UpdateAiIntentsBulkMutation';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';

export const useUpdateAiIntentsBulk = () => {
  const botId = useCurrentBotId();
  const [updateAiIntentsMutation, state] = useMutation<
    UpdateAiIntentsBulkMutation,
    UpdateAiIntentsBulkMutationVariables
  >(UPDATE_AI_INTENTS_BULK_MUTATION, {
    onError: (error) => {
      log.warn({ error, msg: 'Error update first session intents' });
      showSomethingWentWrongToaster();
    },
  });

  const updateAiIntents = useCallback(
    (intents: AiIntentInput[]) => {
      if (!botId) {
        return undefined;
      }
      return updateAiIntentsMutation({
        variables: {
          botId,
          intents,
        },
      });
    },
    [updateAiIntentsMutation, botId],
  );

  return {
    updateAiIntents,
    ...state,
  };
};
