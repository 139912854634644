import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { useWindowSize } from './useWindowSize';

export const MEDIUM_SCREEN_WIDTH = 1024;
export const SMALL_SCREEN_WIDTH = 720;

export const isMobileDeviceOrLayout = () =>
  isMobileDevice() || window.innerWidth <= SMALL_SCREEN_WIDTH;

export enum ScreenSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface useDeviceMediaResp {
  screenSize: ScreenSizes;
  isSmallScreenSize: boolean;
  isMediumScreenSize: boolean;
  isLargeScreenSize: boolean;
  getValueDueToSize: <T>(s?: T, m?: T, l?: T) => T | undefined;
}

export interface DeviceMediaProps {
  forceMobile?: boolean;
}

export const useDeviceMedia = (
  props?: DeviceMediaProps,
): useDeviceMediaResp => {
  const { width } = useWindowSize();

  if (width <= SMALL_SCREEN_WIDTH)
    return {
      screenSize: ScreenSizes.small,
      isSmallScreenSize: true,
      isMediumScreenSize: false,
      isLargeScreenSize: false,
      getValueDueToSize: props?.forceMobile ? (s) => s : (s?, _m?, _l?) => s,
    };

  if (width > SMALL_SCREEN_WIDTH && width <= MEDIUM_SCREEN_WIDTH)
    return {
      screenSize: ScreenSizes.medium,
      isSmallScreenSize: false,
      isMediumScreenSize: true,
      isLargeScreenSize: false,
      getValueDueToSize: props?.forceMobile
        ? (s) => s
        : (s?, m?, _l?) => m ?? s,
    };

  return {
    screenSize: ScreenSizes.large,
    isSmallScreenSize: false,
    isMediumScreenSize: false,
    isLargeScreenSize: true,
    getValueDueToSize: props?.forceMobile
      ? (s) => s
      : (s?, m?, l?) => l ?? m ?? s,
  };
};
