import gql from 'graphql-tag';

export const SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY = gql`
  query ShopifyConnectedAccountStateQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedShop {
        id
        name
        myshopify_domain
        domain
        currency
        eligible_for_trial
        features {
          id
          enough_permissions
        }
        subscription {
          status
          confirmationUrl
          trialDays
          price
          currency
          trialEndsOn
          botWillBeStoppedAt
          nextPeriodStart
          debtExtraConversations
        }
      }
    }
  }
`;

export const GET_SHOPIFY_CONNECT_LINK_MUTATION = gql`
  mutation GetShopifyConnectLinkMutation(
    $botId: String!
    $myshopifyDomain: String!
    $interruptedHref: String
  ) {
    getShopifyRedirectConnectLink(
      botId: $botId
      myshopifyDomain: $myshopifyDomain
      interruptedHref: $interruptedHref
    ) {
      url
    }
  }
`;

export const GET_SHOPIFY_CONNECTION_LINK = gql`
  mutation GetShopifyConnectionLink($shopifySearchParams: String!) {
    getShopifyConnectionLink(shopifySearchParams: $shopifySearchParams) {
      url
    }
  }
`;
