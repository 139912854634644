import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import { FetchMoreOptions, ObservableQuery } from 'apollo-client';
import { indexBy, map, pathOr, pipe, prop, propOr } from 'ramda';
import client from '../../common/services/ApolloService';
import { ATTRIBUTE_VALUES_QUERY } from './AttributesUtilsGQL';
import { AttributeValuesQuery } from './@types/AttributeValuesQuery';
import { variableSuggestUpdate } from '../../common/services/UserFilterComponentService';
import OneElementQueue from '../../common/services/OneElementQueueService';

const oneElementQueue = new OneElementQueue();
const createQueueName = (name: string) => `attr_values_suggest_${name}`;

const getSuggests = pipe(pathOr([], ['data', 'bot', 'variableValueSuggest']));
const indexById = indexBy(propOr('', 'id'));
const getValuesArray = map(
  pipe(
    propOr([], 'suggests'),
    map(prop('value')) as any,
  ),
);
export const prepareSuggestsMap = pipe(
  getSuggests,
  indexById,
  getValuesArray as any,
);
const prepareSuggestsMapObservable = (queryObservable: ObservableQuery) =>
  Observable.from(queryObservable as ObservableInput<AttributeValuesQuery>).map(
    prepareSuggestsMap,
  );
const attributeValuesQueryObservable = client.watchQuery({
  query: ATTRIBUTE_VALUES_QUERY,
});

export const getAttributeValuesQueryObservable = (
  attributeName: string,
  value: string,
  botId: string,
) => {
  oneElementQueue.removeQueue(createQueueName(attributeName));
  attributeValuesQueryObservable.setVariables({
    botId,
    prefix: value,
    variable: attributeName,
  });
  return prepareSuggestsMapObservable(attributeValuesQueryObservable);
};

export const updateAttributeValuesQueryObservable = (
  attributeName: string,
  value: string,
  botId: string,
) => {
  const fetchMoreQueue = oneElementQueue.getQueue(
    createQueueName(attributeName),
    (params: FetchMoreOptions) =>
      attributeValuesQueryObservable.fetchMore(params),
  );
  fetchMoreQueue.queue({
    updateQuery: variableSuggestUpdate as ((
      previousResult: {},
      moreResult: {},
    ) => AttributeValuesQuery),
    variables: {
      botId,
      prefix: value,
      variable: attributeName,
    },
  });
  return prepareSuggestsMapObservable(attributeValuesQueryObservable);
};
