import { useMutation } from '@apollo/react-hooks';
import { getBlockTitle } from '@components/FlowBuilder/views/helpers/getBlockTitle';
import { getShortcutPluginDefaultData } from '@components/Plugins/common/getShortcutPluginDefaultData';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { useCallback } from 'react';
import { pipe, prop } from 'ramda';

import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';

import { PLUGIN_DEFAULT_CONFIG as SEND_TO_MESSENGER_PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/SendToMessengerEntryPoint/SendToMessengerEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/PersistentMenuEntryPoint/PersistentMenuEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as BOT_LINK_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/BotLinkEntryPoint/BotLinkEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/ShopifyCustomerChatEntryPoint/ShopifyCustomerChatEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/CommentsAutoreplyEntryPoint/CommentsAutoreplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CTM_CONFIG as FACEBOOK_ADS_ENTRY_POINT_DEFAULT_CTM_CONFIG,
  PLUGIN_DEFAULT_SM_CONFIG as FACEBOOK_ADS_ENTRY_POINT_DEFAULT_SM_CONFIG,
  PLUGIN_DEFAULT_CTM_JSON_CONFIG as FACEBOOK_ADS_ENTRY_POINT_DEFAULT_CTM_JSON_CONFIG,
} from '@components/Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as FACEBOOK_SHOPS_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/FacebookShopsEntryPoint/FacebookShopsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as EXTERNAL_INTEGRATION_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/ExternalIntegrationEntryPoint/ExternalIntegrationEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_ADS_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/InstagramCommentsAutoreplyEntryPoint/InstagramCommentsAutoreplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_STORY_REPLY_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/InstagramStoryReplyEntryPoint/InstagramStoryReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_DIRECT_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/InstagramDirectEntryPoint/InstagramDirectEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_LINK_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/InstagramLinkEntryPoint/InstagramLinkEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as POPUP_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/PopupEntryPoint/PopupEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_EVENT_POINT_DEFAULT_CONFIG } from '@components/Plugins/ShopifyEventEntryPoint/ShopifyEventEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as ZAPIER_IMPORT_CONTENT_PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/ZapierImportContentPlugin/ZapierImportContentPluginConst';
import { PLUGIN_DEFAULT_CONFIG as ZAPIER_EVENT_TRIGGER_PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/ZapierEventTriggerPlugin/ZapierEventTriggerPluginConst';
import { PLUGIN_DEFAULT_CONFIG as QUICK_REPLY_PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { PLUGIN_DEFAULT_CONFIG as AD_COMMENTS_AUTOREPLY_ENTRY_POINT } from '@components/Plugins/AdCommentsAutoreplyEntryPoint/AdCommenntsAutoreplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_WIDGET_ENTRY_POINT_CONFIG } from '@components/Plugins/WhatsappWidgetEntryPoint/WhatsappWidgetEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_DIRECT_ENTRY_POINT_CONFIG } from '@components/Plugins/WhatsappDirectEntryPoint/WhatsappDirectEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_POPUP_ENTRY_POINT_CONFIG } from '@components/Plugins/WhatsappPopupEntryPoint/WhatsappPopupEntryPointConst';

import { useCurrentBotId } from '@utils/Routing';
import { removeNullFieldsDeep, removeTypename } from '../../GQL/utils';
import { ADD_FLOW_BLOCK_MUTATION } from './AddFlowMutation';
import { FLOW_ITEM_QUERY } from './query';
import { FlowItemQuery, FlowItemQueryVariables } from './@types/FlowItemQuery';
import {
  AddFlowBlockMutation,
  AddFlowBlockMutation_addFlowBlock,
  AddFlowBlockMutationVariables,
} from './@types/AddFlowBlockMutation';
import { FlowPositionInput } from '@globals';
import {
  BlockShortcutId,
  getBlockShortcut,
} from '@components/FlowBuilder/views/helpers/blocksShortcuts';
import { isSomeEnum } from '@utils/isSomeEnum';

interface addEntryPointProps {
  onCompleted?: (data: AddFlowBlockMutation) => void;
  forceActivate?: boolean;
}

export const ENTRY_POINT_SUBTYPE = 'entry_point';

const getShopifyEventsShortcutConfig = pipe(
  getShortcutPluginDefaultData,
  prop('config'),
  removeNullFieldsDeep,
  removeTypename,
);

export const getFlowCardDefaultConfigWrapper = (
  pluginId?: string,
  config?: any,
) => {
  switch (pluginId) {
    case PluginType.send_to_messenger_entry_point:
      return {
        sendToMessengerConfig: removeTypename(
          SEND_TO_MESSENGER_PLUGIN_DEFAULT_CONFIG,
        ),
      };
    case PluginType.persistent_menu_entry_point:
    case PluginType.instagram_persistent_menu_entry_point:
      return {
        persistentMenuConfig: removeTypename(
          PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.ref_link_entry_point:
      return {
        botLinkConfig: removeTypename(BOT_LINK_ENTRY_POINT_DEFAULT_CONFIG),
      };
    case PluginType.customer_chat_entry_point:
      return {
        customerChatConfig: removeTypename(
          CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.shopify_customer_chat_entry_point:
      return {
        shopifyCustomerChatConfig: removeTypename(
          SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.comments_autoreply_entry_point:
      return {
        commentsAutoreplyConfig: removeTypename(
          COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.ads_manager_ctm_entry_point:
      return {
        facebookAdsConfig: removeTypename(
          FACEBOOK_ADS_ENTRY_POINT_DEFAULT_CTM_CONFIG,
        ),
      };
    case PluginType.ads_manager_ctm_json_entry_point:
      return {
        facebookAdsConfig: removeTypename(
          FACEBOOK_ADS_ENTRY_POINT_DEFAULT_CTM_JSON_CONFIG,
        ),
      };
    case PluginType.ads_manager_sm_entry_point:
      return {
        facebookAdsConfig: removeTypename(
          FACEBOOK_ADS_ENTRY_POINT_DEFAULT_SM_CONFIG,
        ),
      };
    case PluginType.facebook_shops_entry_point:
      return {
        facebookShopsEntryPointConfig: removeTypename(
          FACEBOOK_SHOPS_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.external_integration_entry_point:
      return {
        externalIntegrationEntryPointConfig: removeTypename(
          EXTERNAL_INTEGRATION_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.instagram_ads_manager_ctm_entry_point:
      return {
        instagramAdsConfig: removeTypename(
          INSTAGRAM_ADS_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.instagram_comments_autoreply_entry_point:
      return {
        instagramCommentsAutoreplyConfig: removeTypename(
          INSTAGRAM_COMMENTS_AUTOREPLY_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.instagram_story_reply_entry_point:
      return {
        instagramStoryReplyEntryPointConfig: removeTypename(
          INSTAGRAM_STORY_REPLY_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.instagram_direct_entry_point:
      return {
        instagramDirectEntryPointConfig: removeTypename(
          INSTAGRAM_DIRECT_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.instagram_bot_link_entry_point:
      return {
        instagramLinkEntryPointConfig: removeTypename(
          INSTAGRAM_LINK_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.popup_entry_point:
      return {
        popupEntryPointConfig: removeTypename(POPUP_ENTRY_POINT_DEFAULT_CONFIG),
      };
    case PluginType.shopify_back_in_stock:
      return {
        shopifyBackInStockEntryPointConfig: removeTypename(
          SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG,
        ),
      };
    case PluginType.shopify_event_entry_point:
      return {
        shopifyEventEntryPointConfig: removeTypename(
          removeNullFieldsDeep(SHOPIFY_EVENT_POINT_DEFAULT_CONFIG),
        ),
      };
    case PluginType.order_confirmation_shortcut:
      return {
        shopifyEventEntryPointConfig: getShopifyEventsShortcutConfig(
          PluginType.order_confirmation_shortcut,
        ),
      };
    case PluginType.order_status_update_shortcut:
      return {
        shopifyEventEntryPointConfig: getShopifyEventsShortcutConfig(
          PluginType.order_status_update_shortcut,
        ),
      };
    case PluginType.product_visit_shortcut:
      return {
        shopifyEventEntryPointConfig: pipe(
          getShortcutPluginDefaultData,
          prop('config'),
          removeNullFieldsDeep,
          removeTypename,
        )(PluginType.product_visit_shortcut),
      };
    case PluginType.integration_zapier_plugin:
      return {
        zapierImportContentPluginConfig: removeTypename(
          ZAPIER_IMPORT_CONTENT_PLUGIN_DEFAULT_CONFIG,
        ),
      };
    case PluginType.event_trigger:
      return {
        zapierEventTriggerPluginConfig: removeTypename(
          ZAPIER_EVENT_TRIGGER_PLUGIN_DEFAULT_CONFIG,
        ),
      };
    case PluginType.quick_reply:
      return {
        quickReplyPluginConfig: removeTypename(
          QUICK_REPLY_PLUGIN_DEFAULT_CONFIG,
        ),
      };
    case PluginType.ad_comments_autoreply_entry_point:
      return {
        adCommentsAutoreplyEntryPointConfig: removeTypename(
          AD_COMMENTS_AUTOREPLY_ENTRY_POINT,
        ),
      };
    case PluginType.whatsapp_widget_entry_point:
      return {
        whatsappWidgetEntryPointConfig: removeTypename(
          WHATSAPP_WIDGET_ENTRY_POINT_CONFIG,
        ),
      };
    case PluginType.whatsapp_direct_entry_point:
      return {
        whatsappDirectEntryPointConfig: removeTypename(
          config || WHATSAPP_DIRECT_ENTRY_POINT_CONFIG,
        ),
      };
    case PluginType.whatsapp_popup_entry_point:
      return {
        whatsappPopupEntryPointConfig: removeTypename(
          config || WHATSAPP_POPUP_ENTRY_POINT_CONFIG,
        ),
      };
    case PluginType.comment:
      return { commentPluginConfig: removeTypename(config) };
    default:
      return {};
  }
};

interface addFlowBlockProps {
  flowId: string;
  pluginIds: string[];
  subtype: string;
  title?: string;
  position?: FlowPositionInput;
  onCompleted?(
    addFlowBlock: AddFlowBlockMutation_addFlowBlock | null | undefined,
  ): void;
}

export const useAddFlowBlock = ({
  onCompleted,
  forceActivate,
}: addEntryPointProps = {}) => {
  const [isBlockAdded, setIsBlockAdded] = useServerStorage(
    ServerStorageItemKeys.isBlockAdded,
  );
  const [addFlowBlockMutation, { loading }] = useMutation<
    AddFlowBlockMutation,
    AddFlowBlockMutationVariables
  >(ADD_FLOW_BLOCK_MUTATION, {
    onCompleted,
  });

  const botId = useCurrentBotId();

  const addFlowBlock = useCallback(
    ({
      flowId,
      pluginIds,
      subtype,
      onCompleted,
      position,
    }: addFlowBlockProps) => {
      const cards = pluginIds.flatMap((plugin_id) => {
        if (isSomeEnum(BlockShortcutId, plugin_id)) {
          return getBlockShortcut(plugin_id).cards.map((c) => ({
            plugin_id: c.plugin_id!,
            enabled: forceActivate,
            forced: forceActivate,
            configWrapper: getFlowCardDefaultConfigWrapper(
              c.plugin_id,
              c.config,
            ),
          }));
        }

        return {
          plugin_id:
            getShortcutPluginDefaultData(plugin_id)?.pluginType || plugin_id,
          enabled: forceActivate,
          forced: forceActivate,
          configWrapper: getFlowCardDefaultConfigWrapper(plugin_id),
        };
      });

      const title = getBlockTitle({ subtype, cards });
      addFlowBlockMutation({
        variables: {
          flowId,
          block: {
            title,
            subtype,
            cards,
            position_in_flow: position,
          },
        },
        update: (cache, { data }) => {
          let flowData: FlowItemQuery | null | undefined;

          try {
            flowData = cache.readQuery<FlowItemQuery, FlowItemQueryVariables>({
              query: FLOW_ITEM_QUERY,
              variables: { flowId, botId: botId || '' },
            });
          } catch {
            flowData = null;
          }

          if (flowData?.bot.flow && data?.addFlowBlock) {
            flowData.bot.flow = {
              ...flowData.bot.flow,
              entry_points: [
                ...(flowData.bot.flow.entry_points || []),
                data?.addFlowBlock,
              ],
            };
            cache.writeQuery({
              query: FLOW_ITEM_QUERY,
              variables: { flowId, botId: botId || '' },
              data: flowData,
            });
          }

          if (!isBlockAdded) {
            setIsBlockAdded(true);
          }

          onCompleted?.(data?.addFlowBlock);
        },
      });
    },
    [addFlowBlockMutation, botId, forceActivate, isBlockAdded, setIsBlockAdded],
  );

  return { addFlowBlock, loading };
};
