import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useCurrentBotId } from '@utils/Routing';
import {
  IsPageConnectedQuery,
  IsPageConnectedQueryVariables,
} from './@types/IsPageConnectedQuery';

export const IS_PAGE_CONNECTED_QUERY = gql`
  query IsPageConnectedQuery($botId: String!) {
    bot(id: $botId) {
      id
      status {
        page
        page_info {
          id
          title
          picture
        }
      }
    }
  }
`;

export const usePageConnected = (customBotId?: string) => {
  const currentBotId = useCurrentBotId();
  const botId = customBotId || currentBotId;
  const { data, ...queryState } = useQuery<
    IsPageConnectedQuery,
    IsPageConnectedQueryVariables
  >(IS_PAGE_CONNECTED_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });
  const page = data?.bot.status?.page;
  return {
    pageId: page,
    isConnected: !!page,
    isRequested: !!data,
    page: data?.bot.status?.page_info,
    ...queryState,
  };
};
