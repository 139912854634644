import gql from 'graphql-tag';

export const GOOGLE_SHEETS_QUERY = gql`
  query GoogleSpreadsheets($botId: String!, $ownerId: String) {
    bot(id: $botId) {
      id
      googleSpreadsheets(ownerId: $ownerId) {
        id
        title
        url
        sheetTitle
        hasItems
        deletedFromGoogle
      }
    }
  }
`;

export const GOOGLE_USER_QUERY = gql`
  query GoogleUserQuery($id: String!) {
    googleUser(id: $id) {
      id
      email
      name
      picture
    }
  }
`;

export const GOOGLE_USERS_QUERY = gql`
  query GoogleUsersQuery($botId: String!) {
    googleUsers(botId: $botId) {
      id
      email
      name
      picture
    }
  }
`;

export const CONFIRM_GOOGLE_USER_MUTATION = gql`
  mutation ConfirmGoogleUser($code: String) {
    confirmGoogleUser(code: $code) {
      user {
        id
      }
    }
  }
`;

export const CREATE_GOOGLE_SHEET_MUTATION = gql`
  mutation CreateGoogleSheetMutation(
    $botId: String!
    $title: String!
    $owner_id: String
  ) {
    createGoogleSpreadsheet(botId: $botId, title: $title, owner_id: $owner_id) {
      spreadsheet {
        id
        title
        url
        sheetTitle
        hasItems
        deletedFromGoogle
      }
    }
  }
`;
