import { useQuery } from '@apollo/react-hooks';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { PAGE_PAYWALL_STATUS_QUERY } from './queries';
import {
  PagePaywallStatusQuery,
  PagePaywallStatusQueryVariables,
} from './@types/PagePaywallStatusQuery';

export const useBotPaywallStatus = () => {
  const { pageId, loading: connectedPageLoading } = usePageConnected();

  const {
    data,
    loading: paywallStatusLoading,
    called,
    ...rest
  } = useQuery<PagePaywallStatusQuery, PagePaywallStatusQueryVariables>(
    PAGE_PAYWALL_STATUS_QUERY,
    {
      skip: !pageId,
      variables: {
        pageId: pageId!,
      },
    },
  );

  return {
    pageId,
    paywallStatus: data?.page?.paywall_status,
    loading: paywallStatusLoading || connectedPageLoading || !pageId,
    requested: !!data?.page,
    ...rest,
  };
};
