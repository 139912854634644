import { log } from 'cf-common/src/logger';

export function exhaustiveCheck(value: never) {
  const error = new Error(`Unreachable ${value}`);

  log.error({
    error,
    msg: `Unhandled enum variant: ${value}`,
  });
}
