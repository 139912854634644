import './assets/less/cf-youtube-video.less';

import ng from 'angular';
import template from './cf-youtube-video.html';
import controller from './controller';

export default ng
  .module('app.ui.cfYoutubeVideo', [])
  .component('cfYoutubeVideo', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    bindings: {
      src: '@',
    },
  }).name;
