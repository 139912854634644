// eslint-disable-next-line import/no-extraneous-dependencies
import { DataProxy } from 'apollo-cache';
import { clone } from 'lodash-es';

import { AddFlowMutation_addFlow } from './@types/AddFlowMutation';
import { FlowsQuery, FlowsQueryVariables } from './@types/FlowsQuery';
import {
  FlowGroupsQuery,
  FlowGroupsQueryVariables,
} from './@types/FlowGroupsQuery';

import { FLOW_GROUPS_QUERY, FLOWS_QUERY } from './query';

export const updateCacheAfterAddedFlow = (
  cache: DataProxy,
  botId: string,
  data: AddFlowMutation_addFlow,
  parentGroupId: string,
): void => {
  let flowsData: FlowsQuery | null;
  try {
    // add flow to flows data
    flowsData = cache.readQuery<FlowsQuery, FlowsQueryVariables>({
      query: FLOWS_QUERY,
      variables: { botId: botId || '' },
    });
  } catch {
    flowsData = null;
  }

  if (flowsData?.bot) {
    flowsData.bot.flows = [...(flowsData?.bot.flows || []), data];
    cache.writeQuery({
      query: FLOWS_QUERY,
      variables: { botId: botId || '' },
      data: clone(flowsData),
    });
  }

  let flowGroupsData: FlowGroupsQuery | null;
  try {
    //  add flow_id to flowGroup flow_ids field
    flowGroupsData = cache.readQuery<FlowGroupsQuery, FlowGroupsQueryVariables>(
      {
        query: FLOW_GROUPS_QUERY,
        variables: { botId: botId || '' },
      },
    );
  } catch {
    flowGroupsData = null;
  }

  if (flowGroupsData?.bot) {
    flowGroupsData.bot.flow_groups =
      flowGroupsData.bot.flow_groups?.map((group) =>
        group.id === parentGroupId
          ? {
              ...group,
              flow_ids: [data.id, ...(group.flow_ids || [])],
            }
          : group,
      ) || null;
    cache.writeQuery({
      query: FLOW_GROUPS_QUERY,
      variables: { botId: botId || '' },
      data: clone(flowGroupsData),
    });
  }
};
