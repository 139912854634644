import { useAdminFeatures } from '../AdminFeatures';

export const useIsCreateBotByPlatform = () => {
  const { adminFeatures, ...requestState } = useAdminFeatures();

  return {
    isCreateBotByPlatform: adminFeatures?.new_bot_for_whatsapp,
    ...requestState,
  };
};
