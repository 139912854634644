import gql from 'graphql-tag';

export const FORCED_LANGUAGE_QUERY = gql`
  query ForcedLanguageQuery {
    me {
      id
      locale
      features {
        forced_language
      }
    }
  }
`;
