import './assets/less/fb-uni-button.less';

import ng from 'angular';
import template from './fb-uni-button.html';
import controller from './controller';

export default ng.module('app.ui.fbUniButton', []).component('fbUniButton', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  transclude: {
    openMessengerButton: '?openmessengerbutton',
  },
  bindings: {
    appId: '<',
    pageId: '<',
    ref: '<',
    resetTimeout: '<',
    theme: '@',
    onRender: '&',
    onClick: '&',
  },
}).name;
