import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useWhiteLabelAgencyData } from './useWhiteLabelAgencyData';

export interface WhiteLabelAgencyStyles {
  navigationColor?: string;
}

export const useWhiteLabelAgencyStyles = (): {
  data: WhiteLabelAgencyStyles | undefined;
  loading: boolean;
} => {
  const isWhiteLabel = isWhiteLabelDomain();
  const { data, loading } = useWhiteLabelAgencyData();

  const styles = isWhiteLabel
    ? {
        navigationColor: data?.whiteLabelAgency?.color || undefined,
      }
    : undefined;

  return { data: styles, loading };
};
