import React, { CSSProperties, forwardRef, useRef } from 'react';
import cn from 'classnames';
import { combineRefs } from '../combineRefs';
import { DND_NOT_DROPPABLE_CONTAINER_CLASS, useDnd } from './DndContext';
import { DndPlaceholder } from './DndPlaceholder';

interface DndDroppableProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  id: string;
  placeholderStyle?: CSSProperties;
  // notDroppable is associated with droppable with the same id
  // (group title for example)
  notDroppable?: boolean;
}

export const DndDroppable = forwardRef<HTMLDivElement, DndDroppableProps>(
  (
    { type, id, children, notDroppable, placeholderStyle, className, ...props },
    ref,
  ) => {
    const currentElementRef = useRef<HTMLDivElement | null>(null);
    const { dndState, setDndState, ref: dndRef } = useDnd();

    const onMouseOver = () => {
      if (dndState.isDragging && currentElementRef.current) {
        setDndState((currentState) => ({
          ...currentState,
          shadowPosition: 0,
          hoveredId: id,
        }));
      }
    };

    return (
      <div
        id={notDroppable ? id : undefined}
        data-dnd-droppable-id={id}
        className={cn(className, {
          [DND_NOT_DROPPABLE_CONTAINER_CLASS]: notDroppable,
        })}
        ref={combineRefs([ref, notDroppable ? currentElementRef : dndRef])}
        data-dnd-droppable-type={type}
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        {...props}
      >
        {children}
        {!notDroppable && (
          <DndPlaceholder
            type={type}
            droppableId={id}
            style={placeholderStyle}
          />
        )}
      </div>
    );
  },
);
