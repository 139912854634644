import './assets/less/sprite-img.less';

import ng from 'angular';
import template from './sprite-img.html';
import controller from './controller';

export default ng.module('app.ui.spriteImg', []).component('spriteImg', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    /**
     * @param {Number} index - index image in sprite
     * @param {Number} total - quantity images in sprite
     */
    index: '<',
    total: '<',
  },
}).name;
