import Maybe from 'graphql/tsutils/Maybe';
import { WhatsappPhone } from './useWhatsappPhoneEntries';

export const isPhoneVerified = (
  phone: Maybe<WhatsappPhone>,
  isWhatsAppBusinessAppIntegration?: boolean,
) => {
  if (isWhatsAppBusinessAppIntegration) {
    return (
      phone?.code_verification_status === 'VERIFIED' || !!phone?.is_on_biz_app
    );
  }
  return phone?.code_verification_status === 'VERIFIED';
};
