import { useLazyQuery } from 'react-apollo';
import { HUBSPOT_OAUTH_URL_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';
import { LazyQueryHookOptions } from '@apollo/react-hooks';
import {
  HubSpotOauthUrlQuery,
  HubSpotOauthUrlQueryVariables,
} from './@types/HubSpotOauthUrlQuery';

export const useHubSpotOauthUrl = (
  payload?: LazyQueryHookOptions<
    HubSpotOauthUrlQuery,
    HubSpotOauthUrlQueryVariables
  >,
) => {
  const botId = useCurrentBotId();
  return useLazyQuery<HubSpotOauthUrlQuery, HubSpotOauthUrlQueryVariables>(
    HUBSPOT_OAUTH_URL_QUERY,
    {
      variables: {
        botId: botId!,
      },
      ...payload,
    },
  );
};
