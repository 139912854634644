import gql from 'graphql-tag';
import { FLOW_FRAGMENT } from './query';

export const ADD_FLOW_GROUP_MUTATION = gql`
  mutation AddFlowGroupMutation(
    $botId: ID!
    $title: String!
    $collapsed: Boolean
    $position: Int!
  ) {
    addFlowGroup(
      botId: $botId
      title: $title
      collapsed: $collapsed
      position: $position
    ) {
      id
      title
    }
  }
`;

export const ADD_FLOW_MUTATION = gql`
  mutation AddFlowMutation(
    $groupId: ID
    $block: FlowBlockInput
    $platform: Platform
    $title: String
    $flowTitle: String
  ) {
    addFlow(
      groupId: $groupId
      block: $block
      platform: $platform
      title: $title
      flowTitle: $flowTitle
    ) {
      id
      ...FlowFragment
    }
  }
  ${FLOW_FRAGMENT}
`;

export const ADD_FLOW_BLOCK_MUTATION = gql`
  mutation AddFlowBlockMutation($flowId: ID!, $block: FlowBlockInput) {
    addFlowBlock(flowId: $flowId, block: $block) {
      id
      title
      entry_point_type
      enabled
    }
  }
`;
