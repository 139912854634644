import { useAuthToken } from '../../Auth/useAuthToken';
import { useSubscription } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { SubscriptionHookOptions } from '@apollo/react-hooks/lib/types';

export function useSubscriptionWithAuth<TData, TVariables>(
  subscription: DocumentNode,
  options?: SubscriptionHookOptions<TData, TVariables>,
) {
  const token = useAuthToken();
  return useSubscription<TData, TVariables>(subscription, {
    ...options,
    skip: !token || options?.skip,
  });
}
