export enum PermissionGroup {
  minimal = 'minimal',
  full = 'full',
  marketing = 'marketing',
  full_and_marketing = 'full,marketing',
  comments = 'comments',
  instagram_comments = 'instagram_comments',
  instagram_auth = 'instagram_auth',
  instagram_messaging = 'instagram_messaging',
  ads = 'ads',
  shops = 'shops',
  facebook_analytics = 'facebook_analytics',
}
