import client from '@common/services/ApolloService';
import { FORCED_LANGUAGE_QUERY } from './queries';
import { ForcedLanguageQuery } from './@types/ForcedLanguageQuery';

export const queryLocaleData = async () => {
  const res = await client.query<ForcedLanguageQuery>({
    query: FORCED_LANGUAGE_QUERY,
  });

  const {
    locale,
    features: { forced_language },
  } = res.data.me;

  return locale ?? forced_language;
};
