import gql from 'graphql-tag';

export const PAGE_PAYWALL_STATUS_QUERY = gql`
  query PagePaywallStatusQuery($pageId: String!) {
    page(pageId: $pageId) {
      id
      paywall_status
    }
  }
`;
