import gql from 'graphql-tag';

export const REQUEST_DEMO_QUERY = gql`
  query RequestDemoQuery {
    requestDemo {
      clid
      email
      name
      industry
      industry_other
      company
      phone
      use_case
      use_case_other
      source
      source_other
    }
  }
`;
