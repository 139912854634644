import './assets/less/checkbox.less';

import ng from 'angular';
import template from './checkbox.html';
import controller from './controller';

export default ng.module('app.ui.checkbox', []).component('checkbox', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    value: '<',
    onChange: '&',
  },
}).name;
