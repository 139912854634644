import gql from 'graphql-tag';

export const ATTRIBUTE_VALUES_QUERY = gql`
  query AttributeValuesQuery(
    $variable: String!
    $prefix: String
    $type: VariablesType
    $botId: String!
  ) {
    bot(id: $botId) {
      id
      variableValueSuggest(variable: $variable, prefix: $prefix, type: $type) {
        id
        suggests {
          id
          value
          count
          type
        }
      }
    }
  }
`;

export const ATTRIBUTES_QUERY = gql`
  query AttributesQuery(
    $botId: String!
    $platform: Platform = null
    $suggestType: VariableSuggestType = template
    $allPlatforms: Boolean = false
  ) {
    bot(id: $botId) {
      id
      variableSuggest(
        suggestType: $suggestType
        platform: $platform
        allPlatforms: $allPlatforms
      ) {
        id
        name
        type
        count
      }
    }
  }
`;
