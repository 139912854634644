import ng from 'angular';
import './avatar.less';

export default ng.module('app.ui.avatar', []).component('avatar', {
  controllerAs: 'vm',
  bindings: {
    pic: '<',
  },
  template: `
        <div class="avatar-pic_makeup" style="{{vm.pic && 'background-image: url(' + vm.pic+')'}}"></div>
   `,
}).name;
