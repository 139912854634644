import Maybe from 'graphql/tsutils/Maybe';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { LS, LSKey } from 'cf-common/src/localStorage';

const dayMonthFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'long',
});

const dayMonthYearFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

const detailed = LS.get(LSKey.detailedPricingDates);

const additionalParams = {
  month: 'long',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
} as const;

const dayShortMonthYearFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  ...(detailed ? additionalParams : {}),
});

export function formatDate(date: number, today: number) {
  const dateObject = new Date(date);

  if (new Date(today).getFullYear() !== dateObject.getFullYear()) {
    return dayMonthYearFormatter.format(date);
  }
  return dayMonthFormatter.format(date);
}

export const formatDateShort = (timestamp: number) =>
  dayShortMonthYearFormatter.format(new Date(timestamp));

const formatPricing = (date: Maybe<number>, format: string) =>
  date ? moment(Number(date)).format(format) : null;

export const formatPricingDate = (date: Maybe<number>) => {
  const detailed = LS.get(LSKey.detailedPricingDates);

  const format = detailed ? 'll LTS' : 'll';

  const formattedDate = formatPricing(date, format);

  return formattedDate;
};
