import { LS } from 'cf-common/src/localStorage';
import { LSKey, useLocalStorage } from './LocalStorage';

export const isLiveChatWebView = () => LS.get(LSKey.isLiveChatWebView);

export const useIsLiveChatWebView = () => {
  const [isLiveChatWebView] = useLocalStorage(LSKey.isLiveChatWebView);
  const [isAudioAvailable] = useLocalStorage(
    LSKey.isLiveChatWebViewAudioAvailable,
  );

  return {
    isLiveChatWebView,
    isAudioAvailable,
  };
};
