import React, { useRef, useState } from 'react';
import { propEq } from 'ramda';
import { ControlDecorator } from '@ui/Input/ControlDecorator';
import * as css from '@components/FlowBuilder/FlowBuilderOverlay/overlays/ButtonPopupOverlay/components/ButtonPopupOverlayShopify/ButtonPopupOverlayShopify.css';
import { ReactComponent as AvatarIcon } from '@components/FlowBuilder/FlowBuilderOverlay/overlays/images/avatar.svg';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Button, ButtonUnstyled } from '@ui/Button';
import useOnClickOutside from 'use-onclickoutside';
import { TextEllipsis } from '@ui/TextEllipsis';
import { ExternalAccountProps } from './types';
import { ExternalAccountDropdown } from '../ExternalAccountDropdown';

export const ExternalAccount: React.FC<ExternalAccountProps> = ({
  connectButtonProps,
  onConnectRequest,
  onDisconnectRequest,
  externalAccountsData: { accounts, currentAccountId },
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  const account = accounts.find(propEq('id', currentAccountId));

  if (!account) {
    return (
      <Button
        style={{
          width: '100%',
        }}
        onClick={() => {
          onConnectRequest();
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          {connectButtonProps.icon}
          <Spacer factor={0} horizontalFactor={2} />
          {connectButtonProps.title}
        </Flex>
      </Button>
    );
  }

  return (
    <ControlDecorator
      render={() => (
        <>
          <ButtonUnstyled
            onClick={() => {
              setShowDropdown(true);
            }}
            style={{
              width: '100%',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              style={{
                height: 36,
              }}
            >
              <AvatarIcon className={css.icon} />
              <Spacer horizontalFactor={2} />
              <TextEllipsis width={200}>
                <Type size="15px" weight="semibold">
                  {account?.name}
                </Type>
              </TextEllipsis>
              <Icon
                icon="triangle"
                style={{
                  justifyItems: 'flex-end',
                }}
              />
            </Flex>
          </ButtonUnstyled>
          {account && showDropdown && (
            <div
              style={{
                marginTop: -1,
              }}
            >
              <ExternalAccountDropdown
                ref={dropdownRef}
                onDisconnectRequest={onDisconnectRequest}
                externalAccountsData={{
                  currentAccountId,
                  accounts,
                }}
              />
            </div>
          )}
        </>
      )}
    />
  );
};
