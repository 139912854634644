export namespace KeyboardEventUtils {
  export type Type = React.KeyboardEvent<HTMLInputElement>;

  export const isNonDigit = (event: Type) =>
    Number.isNaN(Number.parseInt(event.key, 10));

  export const isEditingInput = (event: Type) =>
    ['Enter', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(
      event.key,
    );

  export const isCtrlShortcut = (event: Type) =>
    (event.ctrlKey || event.metaKey) &&
    ['a', 'c', 'x', 'v'].includes(event.key);
}
