import { useCallback, useEffect, useRef } from 'react';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { appendEntryPoint, hasEntryPointBeenAdded } from './utils';

export const useUpdateEntryPointsAdded = () => {
  const [entryPointsAdded, setEntryPointsAdded, { loading, saving }] =
    useServerStorage(ServerStorageItemKeys.entryPointsAdded);

  const pendingPluginIds = useRef<string[]>([]);

  const updateEntryPointsAdded = useCallback(
    (...pluginIds: string[]) => {
      if (pluginIds.length === 0) return;

      if (loading || saving) {
        pendingPluginIds.current.push(...pluginIds);
        return;
      }

      const filteredPluginIds = pluginIds.filter(
        (pluginId) => !hasEntryPointBeenAdded(entryPointsAdded, pluginId),
      );

      if (filteredPluginIds.length !== 0) {
        setEntryPointsAdded(
          appendEntryPoint(entryPointsAdded, ...filteredPluginIds),
        );
      }
    },
    [entryPointsAdded, setEntryPointsAdded, saving, loading],
  );

  useEffect(() => {
    if (saving || loading || pendingPluginIds.current.length === 0) return;
    const uniquePluginIds = new Set(pendingPluginIds.current);
    updateEntryPointsAdded(...uniquePluginIds);
    pendingPluginIds.current = [];
  }, [saving, loading, updateEntryPointsAdded]);

  return updateEntryPointsAdded;
};
