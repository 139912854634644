import i18next from 'i18next';
import { Messages } from '@services/MessageService';
import { Translation } from '@translations';

enum WorkspacesErrors {
  workspace_conflict = 'workspace_conflict',
  workspace_subscription_cancelled = 'workspace_subscription_cancelled',
  workspace_page_limit_reached = 'workspace_page_limit_reached',
  moving_pro_page_to_workspace = 'moving_pro_page_to_workspace',
}

export const getWorkspaceErrorMessage = (type: unknown) => {
  switch (type) {
    case WorkspacesErrors.workspace_subscription_cancelled:
      return i18next.t<Translation>('BotList.workspaceCanceled');
    case WorkspacesErrors.workspace_page_limit_reached:
      return i18next.t<Translation>('BotList.reachedPageLimit');
    case WorkspacesErrors.workspace_conflict:
      return i18next.t<Translation>(
        'BotList.alreadyConnectedToAnotherWorkspace',
      );
    default:
      return Messages.somethingWentWrong;
  }
};
