import gql from 'graphql-tag';

export const BOT_FEATURES_FRAGMENT = gql`
  fragment BotFeaturesFragment on Bot {
    features {
      livechat_v2
      inline_stats_ro
      sync_clone
      facebook_analytics
      flow_builder
      otn_enabled
      flow_stats
      dialogs_pricing_enabled
      hide_analytics_unrecognized_phrases
      show_third_party_analytics
      enable_whatsapp
      enable_ad_comments_reply
      enable_analyze
      kommo
      hubspot
      whatsapp_business_app_integration
    }
  }
`;

export const BOT_FEATURES_QUERY = gql`
  query BotFeaturesQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...BotFeaturesFragment
    }
  }
  ${BOT_FEATURES_FRAGMENT}
`;
