import { useEffect } from 'react';
import { useAdminFeatures } from '@utils/Data/Admin';
import { loadIntercom } from '@utils/Intercom';
import { sendEvent } from '../Analytics';
import { ServerStorageItemKeys, useServerStorage } from '../ServerStorage';
import { useThirdPartyScript } from 'cf-common/src/hooks/useThirdPartyScript';

const getCampaignId = ({
  detail: {
    Campaign: { id: campaign },
  },
}: any) => campaign;

export const useOptinMonster = () => {
  const { adminFeatures } = useAdminFeatures();

  const [, setEmailCollectFromOptinMonster] = useServerStorage<string>(
    ServerStorageItemKeys.emailCollectedFromOptinMonster,
  );

  const skip = !adminFeatures?.enable_optin_monster;

  useThirdPartyScript('https://a.opmnstr.com/app/js/api.min.js', {
    skip,
    attributes: {
      'data-account': '80993',
      'data-user': '72057',
      async: 'async',
    },
  });

  useEffect(() => {
    if (skip) {
      return;
    }
    if (!window.Intercom) {
      loadIntercom(); // this sync prepare data acceptor
    }

    window.Intercom('update', { disabled_intercom_popups: true });
  }, [skip]);

  useEffect(() => {
    if (skip) {
      return;
    }

    document.addEventListener('om.Campaign.show', (event: any) => {
      sendEvent({
        category: 'popup',
        action: 'show',
        propertyBag: {
          campaign: getCampaignId(event),
        },
      });
    });

    document.addEventListener('om.Campaign.close', (event: any) => {
      sendEvent({
        category: 'popup',
        action: 'close',
        propertyBag: {
          campaign: getCampaignId(event),
        },
      });
    });

    document.addEventListener('om.Optin.init.submit', (event: any) => {
      const fields = event.detail.Action?.data?.fields;
      sendEvent({
        category: 'popup',
        action: 'submit',
        propertyBag: {
          campaign: getCampaignId(event),
          ...fields,
        },
      });

      if (window.Intercom && fields.email) {
        window.Intercom('update', {
          email: fields.email,
          email_collect_from_optin_monster: true,
        });
        setEmailCollectFromOptinMonster(fields.email);
      }
    });

    const actionHandler = (event: any) => {
      const {
        detail: { Action: action },
      } = event;
      const title = action?.element?.title;
      const link = action?.options?.url;
      sendEvent({
        category: 'popup',
        action: 'click',
        propertyBag: {
          link,
          campaign: getCampaignId(event),
          'text on button': title,
        },
      });
    };

    document.addEventListener('om.Action.close', actionHandler);
    document.addEventListener('om.Action.redirect', actionHandler);
    document.addEventListener('om.Action.view', actionHandler);
  }, [setEmailCollectFromOptinMonster, skip]);
};
