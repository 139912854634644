import { Platform } from '@globals';
import { VideoMimeTypes } from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_MP4_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [VideoMimeTypes.mp4],
  [Platform.instagram]: [VideoMimeTypes.mp4],
  [Platform.whatsapp]: [VideoMimeTypes.mp4],
};

export const MP4_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 15,
  [Platform.instagram]: 15,
  [Platform.whatsapp]: 14,
};
