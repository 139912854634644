import { useQuery } from '@apollo/react-hooks';
import { useCurrentBotId, useCurrentFlowId } from '../../Routing';
import { FlowItemQuery, FlowItemQueryVariables } from './@types/FlowItemQuery';
import { FLOW_ITEM_QUERY } from './query';
import { FlowPlaceholder } from './types';

interface useFlowParams {
  flowId: string;
  cacheOnly?: boolean;
}

export const useFlow = ({ flowId, cacheOnly }: useFlowParams) => {
  const botId = useCurrentBotId();

  const { data, loading, error } = useQuery<
    FlowItemQuery,
    FlowItemQueryVariables
  >(FLOW_ITEM_QUERY, {
    variables: {
      botId: botId ?? '',
      flowId,
    },
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-first',
    skip:
      !botId ||
      !flowId ||
      [FlowPlaceholder.removed, FlowPlaceholder.new].includes(
        flowId as FlowPlaceholder,
      ),
  });

  return { flow: data?.bot.flow, loading, error };
};

export const useCurrentFlow = () => {
  const flowId = useCurrentFlowId()!;
  return useFlow({ flowId, cacheOnly: true });
};
