import nanoid from 'nanoid';
import {
  findIndex,
  propEq,
  compose,
  contains,
  curry,
  isEmpty,
  propOr,
  toLower,
  clone,
} from 'ramda';
import {
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups as AIIntentsGroup,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents as AiIntent,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents_action_items as AiActionItem,
} from './@types/AiIntentsListQuery';
import { AiIntentActionItemType, AiIntentFilterType, Platform } from '@globals';
import { ACTION_FIELD_BY_PLATFORM } from '../consts';
import { AI_INTENTS_SHORT_FRAGMENT } from './queries';
import { MutationUpdaterFn } from 'apollo-client';
import { UpdateAiIntentMutation } from './@types/UpdateAiIntentMutation';
import { RemoveAiIntentMutation } from './@types/RemoveAiIntentMutation';
import {
  AI_INTENTS_GROUP_FRAGMENT,
  FIRST_SESSION_AI_INTENTS_QUERY,
} from '../common/queries';
import { AddAiIntentMutation } from './@types/AddAiIntentMutation';
import { FirstSessionAiIntentsQuery } from '../common/@types/FirstSessionAiIntentsQuery';

export const EMPTY_ACTION_ITEM: AiActionItem = {
  __typename: 'AiIntentActionItem',
  item_type: AiIntentActionItemType.text,
  text: '',
  blocks: null,
};

export const createDefaultIntent: (
  platform: Platform,
  filterType?: AiIntentFilterType,
) => AiIntent = (platform, filterType) => {
  const tmpIntentId = nanoid();
  const defaultIntent: AiIntent = {
    __typename: 'AiIntent',
    id: tmpIntentId,
    intent_id: tmpIntentId,
    filter_type: filterType || AiIntentFilterType.default,
    lines: [],
    name: null,
    goto_block: null,
    action: {
      __typename: 'AiIntentAction',
      random: false,
      items: [],
    },
    ig_action: {
      __typename: 'AiIntentAction',
      random: false,
      items: [],
    },
    wa_action: {
      __typename: 'AiIntentAction',
      random: false,
      items: [],
    },
  };

  defaultIntent[ACTION_FIELD_BY_PLATFORM[platform]].items.push(
    EMPTY_ACTION_ITEM,
  );

  return defaultIntent;
};

const findIntentIndexById = (id: string, list: AiIntent[]) =>
  findIndex(propEq('id', id), list);

export const getUpdateAiIntentCacheUpdater =
  (groupId: string): MutationUpdaterFn<UpdateAiIntentMutation> =>
  (store, { data: result }) => {
    if (result?.updateAiIntent) {
      const [intent1, intent2] = result.updateAiIntent; // получаем 2 интента в случае, разделения ФБ и инстаграмм вариантов (когда оба ответа в одном интенте)
      if (!intent2) {
        return;
      }
      const intentsData: AIIntentsGroup | null = store.readFragment({
        id: groupId,
        fragment: AI_INTENTS_SHORT_FRAGMENT,
      });
      if (intentsData) {
        const {
          intents: [...intents],
        } = intentsData;
        const index =
          findIntentIndexById(intent1?.id, intents) ||
          findIntentIndexById(intent2?.id, intents);
        intents.splice(index, 1, intent1, intent2);
        intentsData.intents = intents;
        store.writeFragment({
          id: groupId,
          fragment: AI_INTENTS_SHORT_FRAGMENT,
          data: intentsData,
        });
      }
    }
  };

export const getRemoveAiIntentCacheUpdater =
  (
    groupId: string,
    id: string,
    botId: string | undefined,
  ): MutationUpdaterFn<RemoveAiIntentMutation> =>
  (store, { data: result }) => {
    if (!result?.removeAiIntent || !botId) {
      return;
    }
    let intentsData: AIIntentsGroup | null | undefined;
    try {
      intentsData = store.readFragment({
        id: groupId,
        fragment: AI_INTENTS_SHORT_FRAGMENT,
      });
    } catch {
      intentsData = null;
    }
    if (intentsData) {
      const {
        intents: [...intents],
      } = intentsData;
      const index = findIntentIndexById(id, intents);
      intents.splice(index, 1);
      intentsData.intents = intents;
      store.writeFragment({
        id: groupId,
        fragment: AI_INTENTS_SHORT_FRAGMENT,
        data: intentsData,
      });
    }

    let firstSessionAiIntentsQuery:
      | FirstSessionAiIntentsQuery
      | null
      | undefined;
    try {
      firstSessionAiIntentsQuery = store.readQuery<FirstSessionAiIntentsQuery>({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        variables: {
          botId,
        },
      });
    } catch {
      firstSessionAiIntentsQuery = null;
    }
    if (firstSessionAiIntentsQuery) {
      const {
        intents: [...intents],
      } = firstSessionAiIntentsQuery.firstSessionAiIntents;
      const index = findIntentIndexById(id, intents);
      intents.splice(index, 1);
      firstSessionAiIntentsQuery.firstSessionAiIntents.intents = intents;
      store.writeQuery({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        variables: {
          botId,
        },
        data: firstSessionAiIntentsQuery,
      });
    }
  };

export const getAddAiIntentCacheUpdater =
  (
    groupId: string,
    botId: string | undefined,
  ): MutationUpdaterFn<AddAiIntentMutation> =>
  (store, { data }) => {
    if (!data?.addAiIntent || !botId) {
      return;
    }
    const resultNewIntent = data.addAiIntent;
    let intentsFragment: AIIntentsGroup | null | undefined;
    try {
      intentsFragment = store.readFragment({
        id: groupId,
        fragment: AI_INTENTS_GROUP_FRAGMENT,
        fragmentName: 'aiIntentsGroupFragment',
      });
    } catch {
      intentsFragment = null;
    }

    if (intentsFragment) {
      const { intents } = intentsFragment;
      intentsFragment.intents = [resultNewIntent, ...intents];
      store.writeFragment({
        id: groupId,
        fragment: AI_INTENTS_GROUP_FRAGMENT,
        fragmentName: 'aiIntentsGroupFragment',
        data: intentsFragment,
      });
    }

    let firstSessionAiIntentsQuery:
      | FirstSessionAiIntentsQuery
      | null
      | undefined;
    try {
      firstSessionAiIntentsQuery = store.readQuery<FirstSessionAiIntentsQuery>({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        variables: {
          botId,
        },
      });
    } catch {
      firstSessionAiIntentsQuery = null;
    }

    if (firstSessionAiIntentsQuery) {
      const { intents } = firstSessionAiIntentsQuery.firstSessionAiIntents;
      firstSessionAiIntentsQuery.firstSessionAiIntents.intents = [
        resultNewIntent,
        ...intents,
      ];
      store.writeQuery({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        variables: {
          botId,
        },
        data: firstSessionAiIntentsQuery,
      });
    }
  };

const isIntentContainTerm = (intentsFilterTerm: string) =>
  compose(contains(toLower(intentsFilterTerm || '')), toLower);

const titleProp = propOr('', 'title');

export const intentsFilter = curry(
  (
    intentsFilterTermNullable: string | null,
    platform: Platform,
    intent: AiIntent,
  ) => {
    if (
      intent[ACTION_FIELD_BY_PLATFORM[platform]].items.length === 0 &&
      (intent.action.items.length > 0 ||
        intent.ig_action.items.length > 0 ||
        intent.wa_action.items.length > 0)
    ) {
      return false;
    }

    const nonEmptyLines = intent.lines.filter(Boolean);
    const intentsFilterTerm = intentsFilterTermNullable ?? '';
    const containTerm = isIntentContainTerm(intentsFilterTerm);
    const isIntentLinesContainTerm = nonEmptyLines.some(containTerm);
    const isIntentsFilterTermEmpty = isEmpty(intentsFilterTerm);
    const isIntentActionItemsContainTerm = intent[
      ACTION_FIELD_BY_PLATFORM[platform]
    ].items.some(
      (item) =>
        !!item &&
        ((item.text && containTerm(item.text)) ||
          !!(item.blocks && item.blocks.find(compose(containTerm, titleProp)))),
    );
    const isIntentNameContainTerm = containTerm(intent.name ?? '');

    return (
      isIntentsFilterTermEmpty ||
      isIntentLinesContainTerm ||
      isIntentActionItemsContainTerm ||
      isIntentNameContainTerm
    );
  },
);

/**
 * Create platform specific function for update
 * action item in intent by index.
 */
export const curriedUpdateNItemInIntent =
  (platform: Platform) =>
  (
    callback: (item: AiActionItem) => AiActionItem,
    index: number,
    keywordsGroup: AiIntent,
  ) => {
    const updatedAiIntent = clone(keywordsGroup);
    const action = updatedAiIntent[ACTION_FIELD_BY_PLATFORM[platform]];
    const item = action.items[index] || EMPTY_ACTION_ITEM;

    action.items.splice(index, 1, callback(item));

    return updatedAiIntent;
  };
