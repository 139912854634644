import { HEXColors } from '@ui/_common/colors';

const COBALT_COLOR = HEXColors.cobalt;
const WHITE_COLOR = HEXColors.white;

export namespace ResetMetaForMobile {
  export const viewport = () => {
    const metaViewport = document.querySelector('head meta[name=viewport]');
    const metaViewportContent = metaViewport?.getAttribute('content');
    metaViewport?.setAttribute(
      'content',
      'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0, shrink-to-fit=no, viewport-fit=cover',
    );

    return () => {
      metaViewport?.setAttribute('content', metaViewportContent ?? '1200px');
    };
  };

  export const themeColor = (whiteLabelColor?: string) => {
    const metaThemeColor = document.querySelector(
      'head meta[name=theme-color]',
    );
    const metaThemeColorContent = metaThemeColor?.getAttribute('content');
    metaThemeColor?.setAttribute('content', whiteLabelColor ?? COBALT_COLOR);

    return () => {
      metaThemeColor?.setAttribute(
        'content',
        metaThemeColorContent ?? WHITE_COLOR,
      );
    };
  };
}
