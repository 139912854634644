import memoize from 'lodash-es/memoize';
import client from '../../../common/services/ApolloService';
import { GOOGLE_SHEETS_QUERY, GOOGLE_USERS_QUERY } from './queries';
import {
  GoogleSpreadsheets,
  GoogleSpreadsheetsVariables,
} from './@types/GoogleSpreadsheets';
import {
  GoogleUsersQuery,
  GoogleUsersQueryVariables,
} from './@types/GoogleUsersQuery';

export const getGoogleSheetsObservable = memoize(
  (botId: string, ownerId: string) =>
    client.watchQuery<GoogleSpreadsheets, GoogleSpreadsheetsVariables>({
      query: GOOGLE_SHEETS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { botId, ownerId },
    }),
  (botId, ownerId) => `${botId}-${ownerId}`,
);

export const getGoogleUsersObservable = memoize((botId: string) =>
  client.watchQuery<GoogleUsersQuery, GoogleUsersQueryVariables>({
    query: GOOGLE_USERS_QUERY,
    fetchPolicy: 'cache-and-network',
    variables: { botId },
  }),
);
