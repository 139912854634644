import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useShopifyAccount } from '../useShopifyAccount';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useCurrentFlowId,
} from '../../../Routing';
import { ExternalAccount } from '../../common/ExternalAccount';
import { ReactComponent as ShopifyLogo } from './images/shopify_logo.svg';
import { showConnectShopifyAccountDialog } from '../ConnectShopifyAccountDialog';
import { goDisconnectShopifyAccount } from '../utils/goDisconnectShopifyAccount';
import accountSvgSrc from './images/shopify_account.svg';

interface ShopifyAccountButtonProps {
  onClose?: () => void;
}

export const ShopifyAccountButton: React.FC<ShopifyAccountButtonProps> = ({
  onClose,
}) => {
  const { t } = useSafeTranslation();
  const { account } = useShopifyAccount();
  const botId = useCurrentBotId();
  const flowId = useCurrentFlowId();

  return (
    <ExternalAccount
      externalAccountsData={{
        currentAccountId: account?.domain || null,
        accounts: account
          ? [
              {
                id: account.domain,
                name: account.domain,
                iconSrc: accountSvgSrc,
              },
            ]
          : [],
      }}
      connectButtonProps={{
        title: t('shopify.connectShopifyStore'),
        icon: <ShopifyLogo />,
      }}
      onConnectRequest={() => {
        showConnectShopifyAccountDialog({
          interruptedHref: getTabLink(BotTabs.flows, botId, {
            flowId,
          }),
        });
        onClose?.();
      }}
      onDisconnectRequest={() => {
        if (botId) {
          goDisconnectShopifyAccount(botId);
        }
      }}
    />
  );
};
