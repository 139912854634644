/**
 * @description Sprite positioner by image index
 */
export default class SpriteImgController {
  constructor($element) {
    'ngInject';

    this.$element = $element;
  }

  /**
   * @description Handler of change index value, calculate and set sprites position for show image by index
   * @param {object} event - change event
   * @return {void}
   */
  $onChanges(event) {
    if (event.index && window.angular.isNumber(this.index)) {
      // loop sprite img if index > total
      const index =
        this.index > this.total - 1 ? this.index - this.total : this.index;
      this.$element.css({
        'background-position-x': `${index * (100 / (this.total - 1))}%`,
      });
    }
  }
}
