import gql from 'graphql-tag';

export const BLANK_BOT_NAME = 'Untitled';

export const DEFAULT_PRO_DATA = {
  __typename: 'Pro',
  status: '',
  manual: false,
  debt: 0,
  days_before_cancel: 0,
  is_paused: null,
  millis_before_cancel: null,
} as const;

export const DEFAULT_ADMIN_CURRENT_ROLE = {
  permissions: {
    inbox: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    ai: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    people: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    broadcasting: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    configure: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    grow: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    analyze: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    roles: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    groups: {
      permission: 'EDIT',
      whitelistedGroupIds: null,
      __typename: 'GroupsPermission',
    },
    pro: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    bot: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    flows: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    workspaces: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    __typename: 'Permissions',
  },
  __typename: 'CurrentRole',
} as const;

export const BOTS_TITLES_QUERY = gql`
  query BotsTitlesQuery {
    bots {
      title
    }
  }
`;
