import React, { useCallback, useEffect, useRef, useState } from 'react';
import H from 'history';
import type { IScope } from 'angular';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { BotPageRouteParams, BotTabs, ReEngageType } from './Routing';

const angularContext: {
  $angularScope:
    | (IScope & {
        stateParams?: BotPageRouteParams;
        stateLocation?: H.Location;
        stateHistory?: H.History;
        isBotPage?: boolean;
        botPageTab?: BotTabs | string;
        category?: string;
        creator?: string;
      })
    | undefined;
} = {
  $angularScope: undefined,
};

export const useReadyToRender = (tab?: BotTabs | 'common') => {
  const [readyToRender, setReadyToRender] = useState(false);
  const matchReEngageTabCommon = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/broadcast/:blockId/:broadcastId/:broadcastType',
  );
  const matchReEngageTabAuto = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/broadcast/:blockId/auto',
  );
  const matchReEngageTab = matchReEngageTabCommon || matchReEngageTabAuto;
  const matchAutomateTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/structure/:blockId',
  );
  const matchPeopleTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/users/:segmentId',
  );
  const matchBotPage = useRouteMatch<BotPageRouteParams>('/bot/:botId');
  const checkerIntervalRef = React.useRef<ReturnType<typeof setInterval>>();

  React.useEffect(() => {
    switch (tab) {
      case 'common':
        if (
          angularContext?.$angularScope?.stateParams?.botId === undefined ||
          angularContext.$angularScope.stateParams.botId !==
            matchBotPage?.params.botId
        ) {
          setReadyToRender(false);
        }
        if (!readyToRender) {
          checkerIntervalRef.current = setInterval(() => {
            if (
              angularContext?.$angularScope?.stateParams?.botId ===
                matchBotPage?.params.botId &&
              matchBotPage?.params.botId !== undefined
            ) {
              setReadyToRender(true);
            }
          }, 100);
        }
        break;
      case BotTabs.automate:
        if (
          angularContext?.$angularScope?.stateParams?.blockId === undefined ||
          angularContext.$angularScope.stateParams.blockId !==
            matchAutomateTab?.params.blockId
        ) {
          setReadyToRender(false);
        }
        if (!readyToRender) {
          checkerIntervalRef.current = setInterval(() => {
            if (
              angularContext?.$angularScope?.stateParams?.blockId ===
                matchAutomateTab?.params.blockId &&
              matchAutomateTab?.params.blockId !== undefined
            ) {
              setReadyToRender(true);
            }
          }, 100);
        }
        break;
      case BotTabs.reEngage:
        if (
          angularContext?.$angularScope?.stateParams?.blockId === undefined ||
          angularContext.$angularScope.stateParams.blockId !==
            matchReEngageTab?.params.blockId ||
          angularContext.$angularScope.stateParams.broadcastId !==
            matchReEngageTab?.params.broadcastId
        ) {
          setReadyToRender(false);
        }
        if (!readyToRender) {
          checkerIntervalRef.current = setInterval(() => {
            if (
              angularContext?.$angularScope?.stateParams?.blockId ===
                matchReEngageTab?.params.blockId &&
              matchReEngageTab?.params.blockId &&
              angularContext?.$angularScope?.stateParams?.broadcastId ===
                matchReEngageTab?.params.broadcastId
            ) {
              setReadyToRender(true);
            }
          }, 100);
        }
        break;
      case BotTabs.people:
        if (
          angularContext.$angularScope?.stateParams?.segmentId !==
          matchPeopleTab?.params.segmentId
        ) {
          setReadyToRender(false);
        }
        if (!readyToRender) {
          checkerIntervalRef.current = setInterval(() => {
            if (
              angularContext?.$angularScope?.stateParams?.segmentId ===
                matchPeopleTab?.params.segmentId &&
              matchPeopleTab?.params.segmentId
            ) {
              setReadyToRender(true);
            }
          }, 100);
        }
        break;
      default:
        if (!readyToRender) {
          checkerIntervalRef.current = setInterval(() => {
            if (angularContext.$angularScope && !readyToRender) {
              setReadyToRender(true);
            }
          }, 500);
        }
    }
    return () => {
      clearInterval(checkerIntervalRef.current as number | undefined);
    };
  }, [
    readyToRender,
    matchReEngageTab,
    tab,
    matchAutomateTab,
    matchPeopleTab,
    matchBotPage,
  ]);

  return readyToRender;
};

export const setAngularRouterContext = ($angularScope: IScope) => {
  angularContext.$angularScope = $angularScope;
};

export const useAngularScope = () => {
  const [angularScope, setAngularScope] = useState<any>(null);
  const [inited, setInited] = useState(false);
  const initIntervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (inited && initIntervalRef.current) {
      clearInterval(initIntervalRef.current);
    }

    if (!inited) {
      initIntervalRef.current = setInterval(() => {
        if (!inited && angularContext.$angularScope) {
          setAngularScope(angularContext.$angularScope);
          setInited(true);
        }
      }, 100);
    }

    return () => {
      if (initIntervalRef.current) {
        clearInterval(initIntervalRef.current);
      }
    };
  }, [inited]);

  return angularScope;
};

export const React2AngularRouter: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<BotPageRouteParams>();
  const match = useRouteMatch<BotPageRouteParams>('/bot/:botId/:tab');
  const matchAutomateTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/structure/:blockId',
  );
  const matchPeopleTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/users/:segmentId',
  );
  const matchReEngageTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/broadcast/:blockId/:broadcastId/:broadcastType',
  );
  const matchAutopost = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/broadcast/:blockId/auto',
  );
  const matchCatogoriesList = useRouteMatch<{ category: string }>(
    '/templates/category/:category',
  );
  const matchCreatorList = useRouteMatch<{ creator: string }>(
    '/templates/creator/:creator',
  );
  const matchTemplatesList = matchCatogoriesList || matchCreatorList;
  const [inited, setInited] = useState(false);
  const initTimeoutRef = useRef<ReturnType<typeof setInterval>>();

  const rewriteScopeParams = useCallback(() => {
    if (angularContext.$angularScope) {
      angularContext.$angularScope.stateParams = {
        ...params,
        ...(matchAutomateTab ? matchAutomateTab.params : {}),
        ...(matchPeopleTab ? matchPeopleTab.params : {}),
        ...(matchReEngageTab ? matchReEngageTab.params : {}),
        ...(matchAutopost ? matchAutopost.params : {}),
        ...(matchTemplatesList ? matchTemplatesList.params : {}),
        broadcastType: matchReEngageTab
          ? matchReEngageTab.params.broadcastType
          : matchAutopost
          ? ReEngageType.auto
          : undefined,
      };
      angularContext.$angularScope.stateLocation = {
        ...location,
        state: location.state || {},
      };
      angularContext.$angularScope.stateHistory = history;
      angularContext.$angularScope.isBotPage = !!match;
      angularContext.$angularScope.botPageTab = match?.params.tab;
      angularContext.$angularScope.category =
        matchCatogoriesList?.params.category;
      angularContext.$angularScope.creator = matchCreatorList?.params.creator;
    }
  }, [
    matchAutomateTab,
    matchReEngageTab,
    matchAutopost,
    matchTemplatesList,
    matchCatogoriesList,
    matchCreatorList,
    matchPeopleTab,
    location,
    history,
    match,
    params,
  ]);

  React.useEffect(() => {
    window.addEventListener('click', (event) => {
      if (angularContext.$angularScope) {
        angularContext.$angularScope.$broadcast('$angularClick', event);
      }
    });
    window.addEventListener('mouseup', () => {
      if (angularContext.$angularScope) {
        angularContext.$angularScope.$broadcast('$angularMouseUp');
      }
    });
    window.addEventListener('mousedown', () => {
      if (angularContext.$angularScope) {
        angularContext.$angularScope.$broadcast('$angularMouseDown');
      }
    });
  }, []);

  React.useEffect(() => {
    if (!inited) {
      initTimeoutRef.current = setInterval(() => {
        if (angularContext.$angularScope && !inited) {
          rewriteScopeParams();
          setInited(true);
        }
      }, 500);
    }
    return () => {
      if (inited && initTimeoutRef.current) {
        clearInterval(initTimeoutRef.current);
      }
    };
  }, [inited, rewriteScopeParams]);

  React.useEffect(() => {
    if (angularContext.$angularScope) {
      setInited(true);
      rewriteScopeParams();
      angularContext.$angularScope.$evalAsync(() => {
        if (angularContext.$angularScope) {
          angularContext.$angularScope.$broadcast(
            '$stateChangeSuccess',
            location.state,
            params,
          );
        }
      });
    }
  }, [location, params, rewriteScopeParams]);

  return null;
};
