import { Permission } from '@common/services/RoleService';
import { useQuery } from 'react-apollo';
import { WorkspacesQuery } from './@types/WorkspacesQuery';
import { WORKSPACES_QUERY } from './queries';

export const useWorkspacePermission = () => {
  const { data, loading } = useQuery<WorkspacesQuery>(WORKSPACES_QUERY);

  return [
    // at the moment there can't be more than one workspace per user
    data?.workspaces[0]?.permission ?? Permission.FORBIDDEN,
    loading,
  ] as const;
};
