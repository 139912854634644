import gql from 'graphql-tag';
import { ServerStorageItemKeys } from '@utils/ServerStorage';

const SERVER_STORAGE_KEYS_FOR_INTERCOM = {
  [ServerStorageItemKeys.leadsQualificationData]: null,
  [ServerStorageItemKeys.emailCollectedFromOptinMonster]: null,
  [ServerStorageItemKeys.intercomEmails]: null,
  [ServerStorageItemKeys.isBlockAdded]: null,
  [ServerStorageItemKeys.isEntryPointAdded]: null,
  [ServerStorageItemKeys.isEntryPointEnabled]: null,
  [ServerStorageItemKeys.isEntryPointManuallyDisabled]: null,
  [ServerStorageItemKeys.isFlowTested]: null,
  [ServerStorageItemKeys.isBasicTemplateAdded]: null,
  [ServerStorageItemKeys.isAdvancedTemplateAdded]: null,
  [ServerStorageItemKeys.entryPointsAdded]: null,
};

export type USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE = {
  [key in keyof typeof SERVER_STORAGE_KEYS_FOR_INTERCOM]: {
    data: string | null;
  };
};

export const USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY = gql`
  query USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY {
    ${Object.keys(SERVER_STORAGE_KEYS_FOR_INTERCOM).map(
      (key) => `
      ${key}: serverStorageItemGet(id: "${key}") {
        id
        data
      }
    `,
    )}
  }
`;

export const USER_DATA_FOR_INTERCOM_QUERY = gql`
  query USER_DATA_FOR_INTERCOM_QUERY {
    me {
      id
      name
      email
      google_email
      abt_serialized
      HMAC
      date_added
      original_account_type
      facebook_account_connected
      lastCancelProReason {
        reason
        text
        date
      }
      page_stats {
        likes
        connected_pages
        connected_page_likes
        pages
        pro_page_users
      }
      unverified_email
    }
    bots {
      id
      user_limit_reached
    }
  }
`;
