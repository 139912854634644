/**
 * @description tabs uo element controller
 */
export default class TabsController {
  /**
   * @description constructor
   */
  constructor() {
    'ngInject';
  }
}
