import gql from 'graphql-tag';
import {
  useQuery,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/react-hooks';
import { FacebookAdAccountsQuery } from './@types/FacebookAdAccountsQuery';

export const FACEBOOK_AD_ACCOUNTS_QUERY = gql`
  query FacebookAdAccountsQuery {
    facebookAdAccounts {
      id
      name
    }
  }
`;

export const useFacebookAdAccounts = (
  options?: LazyQueryHookOptions<FacebookAdAccountsQuery, {}>,
) => {
  const { data, ...state } = useQuery<FacebookAdAccountsQuery>(
    FACEBOOK_AD_ACCOUNTS_QUERY,
    options,
  );

  return {
    facebookAdAccounts: data?.facebookAdAccounts || [],
    ...state,
  };
};

export const useLazyFacebookAdAccounts = () => {
  const [fetchFacebookAdAccounts, { data, ...state }] = useLazyQuery<
    FacebookAdAccountsQuery
  >(FACEBOOK_AD_ACCOUNTS_QUERY);

  return {
    facebookAdAccounts: data?.facebookAdAccounts || [],
    fetchFacebookAdAccounts,
    ...state,
  };
};
