import faviconPath from '../favicons/zone51/chatfuel/favicon-zone51.png';
import whiteLabelFaviconPath from '../favicons/zone51/white-label/favicon-zone51.png';
import { isWhiteLabelDomain } from './WhiteLabelUtils';

function applyZone51Styles() {
  const style = document.createElement('style');
  document.head.appendChild(style);
  const styleSheet = style.sheet;
  (styleSheet as any).insertRule(
    '.logo, .logo-mini { filter: hue-rotate(96deg); }',
  );
  // Notify if logos are not found:
  if (document.querySelectorAll('.logo, .logo-mini').length === 0) {
    console.error(
      'Zone51: No known logo elements have been found. ' +
        'Please, contact the frontend team.',
    );
  }
}

function applyZone51Favicon() {
  const existingFavicons = document.querySelectorAll("link[rel*='icon']");
  if (existingFavicons.length) {
    Array.from(existingFavicons).forEach((node) =>
      document.head.removeChild(node),
    );
  }
  const link = document.createElement('link');
  link.rel = 'shortcut icon';

  link.href = isWhiteLabelDomain() ? whiteLabelFaviconPath : faviconPath;
  document.head.appendChild(link);
}

export function applyZone51Overrides() {
  try {
    applyZone51Styles();
  } catch (e) {
    console.error('Failed to override styles.');
  }
  try {
    applyZone51Favicon();
  } catch (e) {
    console.error('Failed to override favicon.');
  }
}
