import { sendEvent } from '@utils/Analytics';
import { LSKey, LS } from 'cf-common/src/localStorage';

export const getUserId = () => LS.getRaw(LSKey.savedUserId);

export const getUserIdBeforeLogout = () => LS.getRaw(LSKey.userIdBeforeLogout);

export const setUserId = (userId: string) => {
  const previousUserId = getUserId() || getUserIdBeforeLogout();
  LS.setRaw(LSKey.savedUserId, userId);

  if (previousUserId !== userId) {
    sendEvent(
      {
        category: 'auth',
        action: 'login to another account',
        propertyBag: {
          previousUserId,
          userId,
        },
      },
      true,
    );
  }
};

export const clearUserId = () => {
  const userId = getUserId();
  if (userId) {
    LS.setRaw(LSKey.userIdBeforeLogout, userId);
  }
  LS.remove(LSKey.savedUserId);
};
