import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { useBotFeaturePermissions } from '@utils/Data/Bot/BotFeaturePermissions';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useFbPermissions } from '@utils/FacebookPages/useFbPermissions';

import {
  CONNECT_INSTAGRAM_ACCOUNT_MUTATION,
  DISCONNECT_INSTAGRAM_ACCOUNT_MUTATION,
  INSTAGRAM_ACCOUNT_QUERY,
} from './queries';

import { InstagramAccountQuery } from './@types/InstagramAccountQuery';
import { ConnectInstagramAccount } from './@types/ConnectInstagramAccount';
import { DisconnectInstagramAccount } from './@types/DisconnectInstagramAccount';

export interface InstagramAccountType {
  picture: string;
  name: string;
  handle: string;
  followers: number;
}

export interface FbPageType {
  pageId: string;
  picture: string;
  title: string;
}

interface UseBotInstagramAccountProps {
  onConnected?(): void;
  onConnectedFailed?(error: ApolloError): void;
}

export const useConnectInstagram = (
  botId: string,
  onSuccess?: () => void,
  onError?: (error: any) => void,
) => {
  const [connectInstagram, { loading: instagramIsConnecting }] =
    useMutation<ConnectInstagramAccount>(CONNECT_INSTAGRAM_ACCOUNT_MUTATION, {
      variables: { botId },
      onCompleted: ({ connectInstagram }) => {
        const successfullyConnected = connectInstagram.instagram?.connected;

        if (
          successfullyConnected &&
          !connectInstagram.instagram?.connection_problem
        ) {
          onSuccess?.();
        } else {
          onError?.(connectInstagram.instagram?.connection_problem);
        }
      },
      onError: (error) => {
        onError?.(error);
      },
    });
  return { connectInstagram, instagramIsConnecting };
};

export const useDisconnectInstagram = (botId: string) => {
  const [disconnectInstagram, { loading: instagramIsDisconnecting }] =
    useMutation<DisconnectInstagramAccount>(
      DISCONNECT_INSTAGRAM_ACCOUNT_MUTATION,
      {
        variables: { botId },
      },
    );
  return { disconnectInstagram, instagramIsDisconnecting };
};

export const useInstagramAccount = (botId: string | undefined) => {
  const {
    data,
    loading: instagramDataIsLoading,
    refetch: refetchInstagramAccount,
  } = useQuery<InstagramAccountQuery>(INSTAGRAM_ACCOUNT_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
  });
  const { instagram } = data?.bot ?? {};
  const {
    business_account: instagramBusinessAccount,
    connected: instagramConnected,
    connection_problem: instagramConnectionProblem,
    fb_error: fbError,
    automation_enabled: isInstagramAutomationEnabled,
  } = instagram ?? {};
  const { name, profile_picture_url, handle, followers_count } =
    instagramBusinessAccount ?? {};

  const instagramConnectedAccount: InstagramAccountType = {
    picture: profile_picture_url ?? '',
    name: name ?? '',
    handle: handle ?? '',
    followers: followers_count ?? 0,
  };

  return {
    instagramConnectedAccount,
    instagramBusinessAccount,
    fbError,
    instagramDataIsLoading,
    instagramConnected,
    instagramConnectionProblem,
    isInstagramAutomationEnabled,
    refetchInstagramAccount,
  };
};

export const useInstagramPermissions = () => {
  const {
    permissions: {
      hasInstagramAuthPermissions,
      hasInstagramMessagingPermissions,
    },
    refreshPermissions,
    loading: instagramPermissionsLoading,
  } = useFbPermissions();
  return {
    hasInstagramAuthPermissions,
    hasInstagramMessagingPermissions,
    instagramPermissionsLoading,
    refreshPermissions,
  };
};

export const useBotInstagramAccount = (
  botId: string,
  { onConnected, onConnectedFailed }: UseBotInstagramAccountProps = {},
) => {
  const { loading: pageConnectedLoading, isConnected: fbPageIsConnected } =
    usePageConnected(botId);

  const {
    hasInstagramAuthPermissions,
    hasInstagramMessagingPermissions,
    instagramPermissionsLoading,
  } = useInstagramPermissions();

  const { botFeaturePermissions, botFeaturePermissionsLoading } =
    useBotFeaturePermissions(botId);

  const { connectInstagram, instagramIsConnecting } = useConnectInstagram(
    botId,
    onConnected,
    onConnectedFailed,
  );

  const { disconnectInstagram, instagramIsDisconnecting } =
    useDisconnectInstagram(botId);

  const {
    instagramConnectedAccount,
    instagramDataIsLoading,
    instagramConnected,
    instagramConnectionProblem,
    instagramBusinessAccount,
    isInstagramAutomationEnabled,
    refetchInstagramAccount,
  } = useInstagramAccount(botId);

  const { page, loading: pageLoading } = usePageConnected(botId);
  const fbPage: FbPageType = {
    pageId: page?.id ?? '',
    picture: page?.picture ?? '',
    title: page?.title ?? '',
  };

  const permissionsLoading =
    instagramPermissionsLoading || botFeaturePermissionsLoading;

  const instagramAccountLoading =
    permissionsLoading ||
    pageConnectedLoading ||
    instagramDataIsLoading ||
    pageLoading;

  const instagramConnectionIsChanging =
    instagramIsConnecting || instagramIsDisconnecting;

  const hasInstagramPermissions =
    !!botFeaturePermissions?.instagram_messaging?.granted;

  return {
    instagramAccountLoading,
    instagramConnectionIsChanging,
    hasInstagramAuthPermissions,
    hasInstagramPermissions,
    hasInstagramMessagingPermissions,
    instagramConnectedAccount,
    instagramBusinessAccount,
    fbPage,
    instagramConnected,
    instagramConnectionProblem,
    fbPageIsConnected,
    connectInstagram,
    disconnectInstagram,
    isInstagramAutomationEnabled,
    refetchInstagramAccount,
  };
};
