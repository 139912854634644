import gql from 'graphql-tag';

export const BOT_FEATURE_PERMISSIONS_FRAGMENT = gql`
  fragment BotFeaturePermissionsFragment on Bot {
    featurePermissions {
      facebook_analytics {
        granted
        neededPermissionGroup
      }
      instagram_messaging {
        granted
        neededPermissionGroup
      }
    }
  }
`;

export const BOT_FEATURE_PERMISSIONS_QUERY = gql`
  query BotFeaturePermissionsQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...BotFeaturePermissionsFragment
    }
  }
  ${BOT_FEATURE_PERMISSIONS_FRAGMENT}
`;
