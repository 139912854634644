import gql from 'graphql-tag';

export const ADMIN_FEATURES_FRAGMENT = gql`
  fragment AdminFeaturesFragment on Query {
    me {
      id
      features {
        fixed_price_offer_old_pricing
        show_year_prices
        pricing_starting_from
        limitation_max_users
        blocksMap
        premium
        limitation_notification_users
        work_email_validation_in_lids_qualification_form
        show_ads_manager
        enable_optin_monster
        user_limitation_experiment
        max_bots_count
        sync_clone
        mobile_onboarding
        flow_builder
        flow_builder_tutorial
        entry_points
        comments_autoreply_entry_point
        grow_tiles_v2
        first_session_ep_modal
        first_session_flow_redirect
        first_session_test_template
        flow_templates
        flow_templates_modal
        flow_templates_test_button
        hide_grow_ai_teach
        hide_grow_facebook_messenger_ads
        show_toggle_builtin_block
        deprecate_grow_acquire_users_card
        disable_automate_tab
        disable_automate_tab_started_at

        dialogs_pricing_locales
        dialogs_pricing_enabled

        instagram_1k_promo_gallery
        instagram_test_promo_gallery
        instagram_inbound_links_promo_gallery

        show_confirm_email_after_first_login

        show_keywords_tab
        show_first_session

        first_session_filter_type
        payments_enabled
        payments_native_enabled

        entry_point_tips

        forced_language

        new_bot_for_whatsapp

        get_a_new_number_text
        embedded_sign_up_version
        shopify_v3

        create_wa_template_in_cf_enable
        create_wa_template_for_clean_users_only
        skip_iq_for_ads_campaign
      }
    }
  }
`;

export const ADMIN_FEATURES_QUERY = gql`
  query AdminFeaturesQuery {
    ...AdminFeaturesFragment
  }
  ${ADMIN_FEATURES_FRAGMENT}
`;
