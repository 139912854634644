import { Folder, ParametersOperator, Platform } from '@globals';

export const SEGMENTATION = {
  operation: ParametersOperator.and,
  parameters: [],
};

export const PLATFORMS = [
  Platform.facebook,
  Platform.instagram,
  Platform.whatsapp,
  null,
];

export const FOLDERS = [
  Folder.all,
  Folder.inbox,
  Folder.closed,
  Folder.done,
  Folder.completed,
];
