import { useMutation, useQuery } from 'react-apollo';
import {
  PAYMENTS_CONFIG_QUERY,
  UPDATE_PAYMENTS_CONFIG_MUTATION,
} from './queries';
import {
  PaymentsConfigQuery,
  PaymentsConfigQuery_bot_payments_config,
  PaymentsConfigQueryVariables,
} from './@types/PaymentsConfigQuery';
import { useCurrentBotId } from '../../Routing';
import {
  UpdatePaymentsConfigMutation,
  UpdatePaymentsConfigMutationVariables,
} from './@types/UpdatePaymentsConfigMutation';
import { useCallback } from 'react';
import { removeTypename } from '../../GQL/utils';

export const usePaymentsConfig = () => {
  const botId = useCurrentBotId();
  const { data, ...queryState } = useQuery<
    PaymentsConfigQuery,
    PaymentsConfigQueryVariables
  >(PAYMENTS_CONFIG_QUERY, {
    variables: {
      botId,
    },
    skip: !botId,
  });

  const [updatePaymentsConfigMutation] = useMutation<
    UpdatePaymentsConfigMutation,
    UpdatePaymentsConfigMutationVariables
  >(UPDATE_PAYMENTS_CONFIG_MUTATION);

  const updatePaymentsConfig = useCallback(
    (config: PaymentsConfigQuery_bot_payments_config) =>
      botId &&
      updatePaymentsConfigMutation({
        variables: {
          botId,
          config: removeTypename(config),
        },
        optimisticResponse: {
          updatePaymentsConfig: {
            __typename: 'Bot',
            id: botId,
            payments_config: config,
          },
        },
      }),
    [botId, updatePaymentsConfigMutation],
  );

  return {
    paymentsConfig: data?.bot.payments_config,
    updatePaymentsConfig,
    ...queryState,
  };
};
