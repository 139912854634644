/**
 * https://github.com/coatue-oss/angular2react/issues/12
 */
import { auto } from 'angular';

type Injector = Partial<auto.IInjectorService>;

let $injector: Injector;

export const lazyInjector = {
  get $injector() {
    return {
      get get() {
        return $injector.get;
      },
    };
  },
  set $injector(injector: Injector) {
    $injector = injector;
  },
};
