import { callFacebookSDK } from '../../modern-components/FBPlugins/callFacebookSDK';

const BUTTON_STATES = {
  loading: 'loading',
  sendTo: 'sendTo',
  sendToDisabled: 'sendToDisabled',
  openMessenger: 'openMessenger',
};

const FB_INIT_ATTEMPTS = 50;
const FB_PLUGIN_ELEMENT_SELECTOR = 'fb-button-box';
const SEND_TO_BUTTON_HOVER_CLASS = 'hover';

/**
 * @description fb-uni-button ui component Controller
 */
export default class fbUniButtonController {
  /**
   * @description -
   * @param {*} $scope -
   * @param {*} $timeout -
   * @param {*} $element -
   * @param {*} $transclude -
   */
  constructor($scope, $timeout, $element, $transclude) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$transclude = $transclude;

    this.buttonStates = BUTTON_STATES;
    this.buttonState = BUTTON_STATES.loading;
    this.fbInitAttempts = FB_INIT_ATTEMPTS;

    this.isFbEventsInited = false;

    this.resetTimeoutPromise = null;
  }

  /**
   * @description destroy callback
   */
  $onDestroy() {
    this._destroyFBEventsSubscription();
  }

  /**
   * @description on postLink callback
   */
  $postLink() {
    this.elSendToButton = this.$element.find('send-to-button-box > *');
  }

  /**
   * @description on changes binds callback
   * @param {Object} $changesObject -
   */
  $onChanges($changesObject) {
    const { appId, pageId, ref } = $changesObject;
    if ((appId || pageId || ref) && this.appId && this.pageId) {
      this._watchFbInit();
    }
  }

  /**
   * @description watch FB sdk ready
   * @private
   */
  _watchFbInit() {
    this.isRenderFbButton = false; // reset FB button in DOM
    this.isShowFbButton = false;
    setTimeout(() => {
      if (window.FB) {
        this.$scope.$evalAsync(() => {
          this._initFBEvents();
          this._showFBButton();
        });
      } else if (this.fbInitAttempts > 0) {
        this.fbInitAttempts--;
        this._watchFbInit();
      } else {
        throw new Error("FB sdk don't init!");
      }
    }, 100);
  }

  /**
   * @description init fb SDK events handlers for send_to_messenger plugin
   * @private
   */
  _initFBEvents() {
    if (!this.isFbEventsInited) {
      callFacebookSDK((FB) => {
        FB.Event.subscribe('send_to_messenger', this._onFbEvent.bind(this));
      });
      this.isFbEventsInited = true;
    }
  }

  /**
   * @description destroy FB events subscription
   * @private
   */
  _destroyFBEventsSubscription() {
    if (this.isFbEventsInited) {
      callFacebookSDK((FB) => {
        FB.Event.unsubscribe('send_to_messenger', this._onFbEvent);
      });
    }
  }

  /**
   * @description on FB events handler
   * @param {Object} e -
   * @private
   */
  _onFbEvent(e) {
    if (e.ref === this.ref) {
      // eslint-disable-next-line default-case
      switch (e.event) {
        case 'clicked':
          this.$scope.$evalAsync(() => {
            this.showGoToMessengerPopup = true;
          });
          break;
        case 'rendered':
          if (this.buttonState === this.buttonStates.loading) {
            this.$scope.$evalAsync(() => {
              this.buttonState = BUTTON_STATES.sendTo;
              this.onRender();
            });
          }
          break;
        case 'opt_in':
          this.resetTimeoutPromise = this.$timeout(
            this._watchFbInit.bind(this),
            1000,
          );
          break;
      }
    }
  }

  /**
   * @description render hidden FB button container
   * @private
   */
  _showFBButton() {
    this.isRenderFbButton = true;
    this.isShowFbButton = true;
    this.buttonState = BUTTON_STATES.loading;
    // watch angular rendered DOM ready
    setTimeout(() => {
      this._parceFbWitget();
    }, 0);
  }

  /**
   * @description render FB button (parse from FB service)
   * @private
   */
  _parceFbWitget() {
    const el = this.$element.find(FB_PLUGIN_ELEMENT_SELECTOR)[0];
    if (el) {
      callFacebookSDK((FB) => {
        FB.XFBML.parse(el);
      });
    }
  }

  /**
   * @description on ViewInMessengerButton click handler (open FB messenger in new window)
   * @private
   */
  _openMessengerUrl() {
    window.open(`https://m.me/${this.pageId}`, '_blank');
  }

  /**
   * @description -
   * @private
   * @return {Boolean} -
   */
  _isOpenMessengerButtonProvided() {
    return this.$transclude.isSlotFilled('openMessengerButton');
  }

  /**
   * @description on mouse enter handler (add hover class to send-to-button element, translate hover from parent element)
   * @private
   */
  _onMouseEnter() {
    this.elSendToButton.addClass(SEND_TO_BUTTON_HOVER_CLASS);
  }

  /**
   * @description on mouse leave handler (remove hover class to send-to-button element, translate hover from parent element)
   * @private
   */
  _onMouseLeave() {
    this.elSendToButton.removeClass(SEND_TO_BUTTON_HOVER_CLASS);
  }

  /**
   * @description reset FB button by timeout
   * @private
   */
  _resetFbButtonByTimeout() {
    if (this.resetTimeout) {
      if (this.resetTimeoutPromise) {
        this.$timeout.cancel(this.resetTimeoutPromise);
      }

      this.resetTimeoutPromise = this.$timeout(
        this._watchFbInit.bind(this),
        this.resetTimeout,
      );
    }
  }

  onRequestCloseGoToMessengerPopup = () => {
    this.$scope.$evalAsync(() => {
      this.showGoToMessengerPopup = false;
    });
  };
}
