import { useCurrentBotId } from '@utils/Routing';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useBotFeatures } from '@utils/Data/Bot';

/**
 * Note: Inside Flow Builder use `isFlowStatsEnabled` in
 * `packages/dashboard/src/modern-components/FlowBuilder/views/components/BlockStatsView/utils.ts`
 */
export const useIsFlowStatsEnabled = () => {
  const botId = useCurrentBotId();
  const { isConnected, loading: pageStatusLoading } = usePageConnected(botId);
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);

  const isFlowStatsEnabled = !!botFeatures?.flow_stats && isConnected;

  return {
    loading: pageStatusLoading || botFeaturesLoading,
    isFlowStatsEnabled,
  };
};
