import * as ClipboardUtils from '@utils/Data/Clipboard';
import { toaster } from './MessageService';
import { modalEventEmitter } from './ModalService/events';
import { ModalService } from './ModalService';
import { ClipboardService, ClipboardDataFetcher } from './ClipboardService';

export * from './TestServices';

export const Toaster = toaster;

export const Modal = ModalService.create(modalEventEmitter);

const clipboardDataFetcher: ClipboardDataFetcher = {
  get: ClipboardUtils.getClipboardData,
  set: ClipboardUtils.setClipboardData,
};
export const Clipboard = ClipboardService.create(clipboardDataFetcher);
