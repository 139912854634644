import { PermissionGroup } from '@utils/Permissions';
import { redirect as redirectFn } from '../UrlUtils';

/**
 * WARNING!!!
 * TODO router Need rewrite this after remove angular router
 */
type RedirectFn = (url: string) => void;
type GoToGetPermissionsArgs = {
  permissionGroup: PermissionGroup | PermissionGroup[];
  reason?: string;
  interruptedHref?: string;
  redirect?: RedirectFn;
};
export const goToGetPermissions = ({
  permissionGroup,
  reason,
  interruptedHref,
  redirect = redirectFn,
}: GoToGetPermissionsArgs) => {
  const searchParams = new URLSearchParams();
  searchParams.append(
    'interruptedHref',
    interruptedHref ?? window.location.href,
  );
  searchParams.append(
    'permission_group',
    Array.isArray(permissionGroup)
      ? permissionGroup.join(',')
      : permissionGroup,
  );
  if (reason) {
    searchParams.append('reason', reason);
  }
  redirect(`/autopermissionsrequest?${searchParams.toString()}`);
};

export const goToGetFullPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: [PermissionGroup.full, PermissionGroup.instagram_auth],
    reason,
    redirect,
  });
};

export const goToGetMinimalPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: PermissionGroup.minimal,
    reason,
    redirect,
  });
};

export const goToGetMarketingPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: PermissionGroup.comments,
    reason,
    redirect,
  }); // request PermissionGroup.comments instead of PermissionGroup.marketing as more suitable
};

export const goToGetFullAndMarketingPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: PermissionGroup.full_and_marketing,
    reason,
    redirect,
  });
};

export const goToGetInstagramAndFullMarketingPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: [
      PermissionGroup.full_and_marketing,
      PermissionGroup.instagram_comments,
    ],
    reason,
    redirect,
  });
};

export const goToGetInstagramPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: PermissionGroup.instagram_comments,
    reason,
    redirect,
  });
};

export const goToGetInstagramAuthPermissions = (
  reason?: string,
  redirect?: RedirectFn,
) => {
  goToGetPermissions({
    permissionGroup: PermissionGroup.instagram_auth,
    reason,
    redirect,
  });
};
