// eslint-disable-next-line no-restricted-imports
import { useTranslation, Namespace } from 'react-i18next';
import { StringMap, TOptions } from 'i18next';
import { Translation } from '@translations';
import { useCallback } from 'react';

export type TType<TranslationType extends string = Translation> = (
  key: TranslationType,
  options?: TOptions<StringMap> | string,
) => any;

export const useSafeTranslation = <
  TranslationType extends string = Translation,
>(
  ns?: Namespace,
) => {
  const i18n = useTranslation(ns);
  const t: TType<TranslationType> = useCallback(
    (key: TranslationType, options?: TOptions<StringMap> | string) =>
      i18n.t(key, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { ...i18n, t };
};
