import ng from 'angular';
import './index.less';
import numeral from 'numeral';
// danger it is global;

numeral.locales.en.delimiters.thousands = ' ';

/**
 * format number to string "12" "123" "1 234" "12k" "123k" "1m"
 * @param {int} num -
 * @return {string} -
 */
const format = (num) => {
  const numAbs = Math.abs(num);
  return numAbs > 999
    ? numAbs < 9000
      ? numeral(num).format('0[.]0a')
      : numAbs < 1000000
        ? numeral(num).format('0a')
        : numeral(num).format('0[.]0a')
    : numeral(num).format('0,0');
};

class UiCounterController {
  /**
   * add thousends separator 1000000 -> 1 000 000
   * @param {number} nStr -
   * @return {string} -
   */
  addSpaces(nStr) {
    return format(nStr);
  }
}

export default ng.module('app.ui.uiCounter', []).component('uiCounter', {
  controllerAs: 'vm',
  bindings: {
    title: '@',
    count: '<',
    diff: '<',
  },
  template: () => `
      <div class="count-container">
      <div class="count">{{vm.addSpaces(vm.count)}}</div>
      <div class="diff" ng-if="vm.diff != 0" class="diff" ng-class="{'diff-green':vm.diff > 0, 'diff-red':vm.diff < 0}">{{vm.diff < 0 ? '':'+'}}{{vm.addSpaces(vm.diff)}}</div>
      </div>
      <div class="title">{{vm.title}}</div>
   `,
  controller: UiCounterController,
}).name;
