import { Platform } from '@globals';
import {
  AudioMimeTypes,
  GroupMimeTypes,
  VideoMimeTypes,
} from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_AUDIO_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [GroupMimeTypes.audio],
  [Platform.instagram]: [
    AudioMimeTypes.mp4,
    AudioMimeTypes.wav,
    AudioMimeTypes.m4ax,
  ],
  [Platform.whatsapp]: [
    AudioMimeTypes.aac,
    AudioMimeTypes.amr,
    AudioMimeTypes.mp3,
    AudioMimeTypes.ogg,
    AudioMimeTypes.mp4,
    VideoMimeTypes.mp4,
  ],
};

export const AUDIO_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 15,
  [Platform.instagram]: 15,
  [Platform.whatsapp]: 15,
};
