import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import React, { useCallback } from 'react';
import * as css from './ShopifyDomainInput.css';

const SHOPIFY_DOMAIN = '.myshopify.com';

interface ShopifyDomainInputProps
  extends Omit<React.AllHTMLAttributes<HTMLInputElement>, 'onChange'>,
    TestLocator {
  error?: boolean;
  onChange: (value: string) => void;
  name?: string;
}

const clearMyshopifyDomain = (rawName: string) =>
  rawName.trim().replace(/^https?:\/\/(.+?)/i, '$1');

export const ShopifyDomainInput: React.FC<ShopifyDomainInputProps> = ({
  error,
  className,
  onChange,
  name,
  style,
  ...inputProps
}) => {
  const handleChangeShopName = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = event;
      onChange(clearMyshopifyDomain(value));
    },
    [onChange],
  );

  return (
    <Flex alignItems="center" className={className}>
      <Input
        placeholder={`${window.i18next.t(
          'ShopifyDomainInput-Template--135-e-shop',
        )}${SHOPIFY_DOMAIN}`}
        onChange={handleChangeShopName}
        error={error}
        name={name}
        containerClassName={css.input}
        {...inputProps}
      />
    </Flex>
  );
};
