import svg from '@ui/Icon/icons/chatfuel-logo.svg';
import { HEXColors } from '@ui/_common/colors';
import { QRCodeRendererOptions } from '@classes/QRCodeGenerator';

export const COMMON_OPTIONS: QRCodeRendererOptions = {
  type: 'canvas',
  width: 300,
  height: 300,
  dotsOptions: {
    color: HEXColors.cobalt,
    type: 'rounded',
  },
};

export const QR_CODE_RENDERER_OPTIONS: QRCodeRendererOptions = {
  ...COMMON_OPTIONS,
  image: svg,
  imageOptions: {
    margin: 5,
  },
};

export const QR_CODE_WHITE_LABEL_RENDERER_OPTIONS: QRCodeRendererOptions =
  COMMON_OPTIONS;
