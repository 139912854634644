import gql from 'graphql-tag';
import {
  DomainsWhitelistForBotQuery,
  DomainsWhitelistForBotQueryVariables,
} from '@utils/FacebookPages/@types/DomainsWhitelistForBotQuery';
import {
  UpdateDomainsWhitelistForBotMutation,
  UpdateDomainsWhitelistForBotMutationVariables,
} from '@utils/FacebookPages/@types/UpdateDomainsWhitelistForBotMutation';
import client from '../../common/services/ApolloService';

const DOMAINS_WHITELIST_FOR_BOT_QUERY = gql`
  query DomainsWhitelistForBotQuery($botId: String!) {
    bot(id: $botId) {
      id
      domains_whitelist
      domains_whitelist_max
    }
  }
`;

const UPDATE_DOMAINS_WHITELIST_FOR_BOT_MUTATION = gql`
  mutation UpdateDomainsWhitelistForBotMutation(
    $botId: String!
    $domains: [String!]!
  ) {
    domainsWhitelist(id: $botId, domains: $domains) {
      id
      domains_whitelist
    }
  }
`;

export const EXCLUDE_WHITELISTING_DOMAIN = [
  'localhost',
  'localhost:3000',
  '127.0.0.1',
  '0.0.0.0',
];

export const addCurrentDomainToWhitelistIfNeed = async (botId: string) => {
  const currentDomain = window.location.host;
  if (EXCLUDE_WHITELISTING_DOMAIN.includes(currentDomain)) {
    return false;
  }
  const { data } = await client.query<
    DomainsWhitelistForBotQuery,
    DomainsWhitelistForBotQueryVariables
  >({
    query: DOMAINS_WHITELIST_FOR_BOT_QUERY,
    variables: {
      botId,
    },
  });
  const domainsWhitelist = data.bot.domains_whitelist;
  if (domainsWhitelist && domainsWhitelist.includes(currentDomain)) {
    return true;
  }
  const { data: updateData } = await client.mutate<
    UpdateDomainsWhitelistForBotMutation,
    UpdateDomainsWhitelistForBotMutationVariables
  >({
    mutation: UPDATE_DOMAINS_WHITELIST_FOR_BOT_MUTATION,
    variables: {
      botId,
      domains: [currentDomain, ...(domainsWhitelist || [])],
    },
  });
  const updatedDomainsWhitelist =
    updateData?.domainsWhitelist.domains_whitelist || [];
  return updatedDomainsWhitelist.includes(currentDomain);
};
