import React, { useEffect } from 'react';

const isDevelopment = process.env.NODE_ENV === 'development';

export const logIfDeprecatedPropsPassed = (
  componentName: string,
  deprecatedPropsKeys: string[],
  props: any,
  details?: any,
) => {
  try {
    const usedDeprecatedProps = Object.keys(props).reduce(
      (acc, prop) =>
        deprecatedPropsKeys.includes(prop) && props[prop] !== undefined
          ? [...acc, prop]
          : [...acc],
      [] as string[],
    );
    if (usedDeprecatedProps.length > 0 && isDevelopment) {
      // eslint-disable-next-line no-console
      console.log(
        `Deprecated props [${usedDeprecatedProps.join(
          ', ',
        )}] of ${componentName} component is used`,
        details,
      );
    }
  } catch {} // eslint-disable-line no-empty
};

export function logDeprecatedComponent<T>(
  name: string,
  Component: React.FC<T> | React.ComponentClass<T>,
) {
  return (props: T) => {
    useEffect(() => {
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log(`Deprecated component (${name}) usage detected`);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return <Component {...props} />;
  };
}
