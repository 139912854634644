import { CSSProperties } from 'react';

export const addPxPostfixToPositionStyleObj = (obj: CSSProperties) =>
  Object.keys(obj).reduce((fixedObj, key) => {
    const value = obj[key as keyof CSSProperties];
    if (typeof value === 'number') {
      // eslint-disable-next-line no-param-reassign
      fixedObj[key] = `${value}px`;
    }
    return fixedObj;
  }, {} as Record<string, string>);
