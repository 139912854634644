export default class CfYoutubeVideoController {
  constructor($sce) {
    'ngInject';

    this.$sce = $sce;
  }

  trustSrc() {
    return this.$sce.trustAsResourceUrl(this.src);
  }
}
