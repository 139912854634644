import { useLightBots } from './useLightBots';
import { useMemo } from 'react';

export type BotIdToTitle = Record<string, string>;

export const useBotsTitlesMap = () => {
  const { lightBots } = useLightBots();
  return useMemo<BotIdToTitle>(
    () =>
      Object.fromEntries(lightBots?.map(({ id, title }) => [id, title]) || []),
    [lightBots],
  );
};
