import { useQuery } from 'react-apollo';
import { FLOW_STATS_QUERY } from '@components/FlowBuilder/queries/FollowUpQuery';
import {
  FlowStatsQuery,
  FlowStatsQueryVariables,
} from '@components/FlowBuilder/queries/@types/FlowStatsQuery';

export const useFlowStats = (flowId: string) => {
  const { data, ...queryState } = useQuery<
    FlowStatsQuery,
    FlowStatsQueryVariables
  >(FLOW_STATS_QUERY, {
    variables: { flowId },
  });

  return {
    flowStats: data?.flowStats,
    ...queryState,
  };
};
