import ng from 'angular';
import controller from './controller';

export default ng
  .module('app.ui.linkHighlighter', [])
  .directive('linkHighlighter', () => ({
    controllerAs: '$linkHighlighter',
    controller,
    restrict: 'A',
  })).name;
