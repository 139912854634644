import './assets/less/popup-datepicker.less';

import ng from 'angular';
import template from './popup-datepicker.html';
import controller from './controller';

export default ng
  .module('app.ui.popupDatepicker', [])
  .component('popupDatepicker', {
    template: () => template,
    controllerAs: 'vm',
    controller,
    restrict: 'E',
    bindings: {
      value: '<',
      placeholder: '@',
      onChange: '&',
      onFocus: '&',
    },
  }).name;
