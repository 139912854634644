import { useCallback, useRef, useState } from 'react';
import { useComponentWillUnmount } from 'cf-common/src/utils/hooks';
import { Observable, Subscription } from 'rxjs';
import { scan, take } from 'rxjs/operators';

interface UseTimerInitialParams {
  hours: number;
  minutes: number;
  seconds: number;
}

export const useTimer = ({
  hours,
  minutes,
  seconds,
}: UseTimerInitialParams) => {
  const ref = useRef<Subscription | undefined>();
  const [started, setStarted] = useState(false);
  const getDefaultTime = useCallback(() => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date;
  }, [hours, minutes, seconds]);
  const [time, setTime] = useState(getDefaultTime);

  const startTimer = useCallback(() => {
    setStarted(true);
    if (ref.current) {
      ref.current?.unsubscribe();
    }

    ref.current = Observable.timer(1000, 1000)
      .pipe(
        scan((acc) => acc - 1000, time.valueOf()),
        take(hours * 60 ** 2 + minutes * 60 + seconds),
      )
      .subscribe((value) => {
        setTime(new Date(value));
      })
      .add(() => {
        setStarted(false);

        setTime(getDefaultTime);
      });
  }, [getDefaultTime, hours, minutes, seconds, time]);

  useComponentWillUnmount(() => ref.current?.unsubscribe());

  return { time, started, startTimer };
};
