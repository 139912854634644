import {
  MessagesTemplateCategory,
  WhatsappTemplateCategory,
  WhatsappTemplateQualityScoreEnum,
  WhatsappTemplateStatus,
} from '@globals';
import i18next from 'i18next';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { HEXColors } from '@ui/_common/colors';
import { ValidationError } from '@components/FlowBuilder/types';
import { GetWhatsappTemplates_getWhatsappTemplates_data_quality_score } from '@components/ChooseWhatsappTempalteDialog/hooks/@types/GetWhatsappTemplates';
import { StatusColumnProps } from '@components/ChooseWhatsappTempalteDialog/components/ChooseTemplateContent/components/StatusColumn';
import Maybe from 'graphql/tsutils/Maybe';
import { globalHistory } from '../../Routing';

export interface StatusData {
  status: WhatsappTemplateStatus;
  quality_score: Maybe<GetWhatsappTemplates_getWhatsappTemplates_data_quality_score>;
}

const getScoreQualityRepresentation = (
  score: WhatsappTemplateQualityScoreEnum,
): StatusColumnProps => {
  const name = {
    [WhatsappTemplateQualityScoreEnum.GREEN]: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.templateModal.table.qualityColor.green',
    ),
    [WhatsappTemplateQualityScoreEnum.YELLOW]: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.templateModal.table.qualityColor.yellow',
    ),
    [WhatsappTemplateQualityScoreEnum.RED]: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.templateModal.table.qualityColor.red',
    ),
    [WhatsappTemplateQualityScoreEnum.UNKNOWN]: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.templateModal.table.qualityColor.unknown',
    ),
  }[score];

  return {
    name,
    statusColor: 'accent2Light',
    textColor: 'accent2Dark',
    textHEXColor: HEXColors.accent2Normal,
  };
};

export const getStatusRepresentation = (
  data: StatusData,
): StatusColumnProps => {
  switch (data.status) {
    case WhatsappTemplateStatus.DRAFT:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.draft',
        ),
        statusColor: 'baseLight',
        textColor: 'baseSecondary',
        textHEXColor: HEXColors.accent3Dark,
      };
    case WhatsappTemplateStatus.PENDING:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.pending',
        ),
        statusColor: 'baseLight',
        textColor: 'baseSecondary',
        textHEXColor: HEXColors.accent3Dark,
      };
    case WhatsappTemplateStatus.PAUSED:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.paused',
        ),
        statusColor: 'antiqueWhite',
        textColor: 'accent3Dark',
        textHEXColor: HEXColors.accent3Dark,
      };
    case WhatsappTemplateStatus.REJECTED:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.rejected',
        ),
        statusColor: 'accent4Light',
        textColor: 'accent4Dark',
        textHEXColor: HEXColors.accent4Dark,
      };
    case WhatsappTemplateStatus.APPROVED: {
      return getScoreQualityRepresentation(
        data.quality_score?.score || WhatsappTemplateQualityScoreEnum.UNKNOWN,
      );
    }
    case WhatsappTemplateStatus.IN_APPEAL:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.inAppeal',
        ),
        statusColor: 'black',
        textColor: 'white',
        textHEXColor: HEXColors.accent3Dark,
      };

    case WhatsappTemplateStatus.LIMIT_EXCEEDED:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.limitExceeded',
        ),
        statusColor: 'black',
        textColor: 'white',
        textHEXColor: HEXColors.accent3Dark,
      };
    case WhatsappTemplateStatus.DELETED:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.deleted',
        ),
        statusColor: 'black',
        textColor: 'white',
        textHEXColor: HEXColors.accent4Dark,
      };
    case WhatsappTemplateStatus.DISABLED:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.disabled',
        ),
        statusColor: 'black',
        textColor: 'white',
        textHEXColor: HEXColors.accent4Dark,
      };
    case WhatsappTemplateStatus.PENDING_DELETION:
      return {
        name: i18next.t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.table.statuses.pendingDeletion',
        ),
        statusColor: 'black',
        textColor: 'white',
        textHEXColor: HEXColors.accent4Dark,
      };
    default:
      return exhaustiveCheck(data.status) as any;
  }
};

export const getCategoryName = (
  category: WhatsappTemplateCategory | MessagesTemplateCategory,
): string => {
  switch (category) {
    case WhatsappTemplateCategory.MARKETING:
    case MessagesTemplateCategory.marketing:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.marketing',
      );
    case WhatsappTemplateCategory.ACCOUNT_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.accountUpdate',
      );
    case WhatsappTemplateCategory.ALERT_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.alertUpdate',
      );
    case WhatsappTemplateCategory.APPOINTMENT_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.appointmentUpdate',
      );
    case WhatsappTemplateCategory.AUTHENTICATION:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.authentication',
      );
    case WhatsappTemplateCategory.AUTO_REPLY:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.autoReply',
      );
    case WhatsappTemplateCategory.ISSUE_RESOLUTION:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.issueResolution',
      );
    case WhatsappTemplateCategory.OTP:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.otp',
      );
    case WhatsappTemplateCategory.PAYMENT_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.paymentUpdate',
      );
    case WhatsappTemplateCategory.PERSONAL_FINANCE_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.personalFinanceUpdate',
      );
    case WhatsappTemplateCategory.RESERVATION_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.reservationUpdate',
      );
    case WhatsappTemplateCategory.SHIPPING_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.shippingUpdate',
      );
    case WhatsappTemplateCategory.TICKET_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.ticketUpdate',
      );
    case WhatsappTemplateCategory.TRANSACTIONAL:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.transactional',
      );
    case WhatsappTemplateCategory.TRANSPORTATION_UPDATE:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.transportationUpdate',
      );
    case WhatsappTemplateCategory.UTILITY:
    case MessagesTemplateCategory.utility:
      return i18next.t(
        'pages.BotPage.ReengageWhatsappTab.templateModal.table.category.utility',
      );
    default:
      return exhaustiveCheck(category) as any;
  }
};

export const getStatusErrorMessage = (
  status: WhatsappTemplateStatus,
  botId: string,
  templateId: Maybe<string>,
): ValidationError => {
  switch (status) {
    case WhatsappTemplateStatus.DRAFT:
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.draft',
        ),
        onClick: () => {
          globalHistory.push(`/bot/${botId}/messages-templates/${templateId}`);
        },
      };
    case WhatsappTemplateStatus.PENDING:
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.inReview',
        ),
      };
    case WhatsappTemplateStatus.IN_APPEAL:
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.inAppeal',
        ),
      };
    case WhatsappTemplateStatus.PAUSED:
      return {
        message: `${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.paused',
        )}<br /><br /> ${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.goToWhatsappManager',
        )}`,
        url: 'https://business.facebook.com/wa/manage/message-templates/',
      };
    case WhatsappTemplateStatus.REJECTED:
      return {
        message: `${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.rejected',
        )}<br /><br /> ${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.goToWhatsappManager',
        )}`,
        url: 'https://business.facebook.com/wa/manage/message-templates/',
      };
    case WhatsappTemplateStatus.DISABLED:
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.disabled',
        ),
      };
    case WhatsappTemplateStatus.DELETED:
    case WhatsappTemplateStatus.PENDING_DELETION:
    case WhatsappTemplateStatus.LIMIT_EXCEEDED:
      return {
        message: `${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.genericError',
        )}<br /><br /> ${i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.goToWhatsappManager',
        )}`,
        url: 'https://business.facebook.com/wa/manage/message-templates/',
      };
    case WhatsappTemplateStatus.APPROVED:
      return undefined;
    default:
      return exhaustiveCheck(status) as any;
  }
};
