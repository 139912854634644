import ng from 'angular';
import './cf-auto-complete.less';
import { curry, last, is, trim } from 'ramda';
import { Level, log } from 'cf-common/src/logger';

const isMulitValue = is(Array);

const VALIES_MAX_HEIGHT = 159;
const SUGGEST_MAX_HEIGHT = 137;

class CfAutoCompleteController {
  constructor($scope, CollectionUtils, DOMService, $element) {
    'ngInject';

    this.$scope = $scope;
    this._filter = curry(this.filter || this._strictCaseInsensitiveFilter);
    this.type = this.type || 'text';
    this.CollectionUtils = CollectionUtils;
    this.DOMService = DOMService;
    this.$element = $element;
    this.ctx = {};
    this.ctxBase = {};
    this.isNotTermChanged = false;

    this.VALIES_MAX_HEIGHT = VALIES_MAX_HEIGHT;
    this.SUGGEST_MAX_HEIGHT = SUGGEST_MAX_HEIGHT;

    // internal state;
    this.state = {
      open: this.cfOpen || false,
      selectedIndex: null,
    };
  }

  $onChanges($event) {
    // sync open state with controlled property;
    if (
      $event &&
      $event.cfOpen !== undefined &&
      !$event.cfOpen.isFirstChange()
    ) {
      this.state.open = $event.cfOpen.currentValue;
    }
    if ($event && $event.searchTerm) {
      this.isNotTermChanged = false;
    }
  }

  _onMultiValueItemSelect({ $i }) {
    // this._close();
    this.onMultiValueItemSelect({ $i });
  }

  _handleClickAway() {
    const { open } = this.state;
    if (!open) {
      return;
    }
    this._selectedItemAtIndex(-1);
    this._close();
  }

  _isDisplayingMenuForFilteredResult(filterResult) {
    return !(!filterResult || filterResult.length === 0);
  }

  _close() {
    this.state.open = false;
    setImmediate(() => {
      if (this.ctx.blur) {
        this.ctx.blur();
      }
    });
    this.onClose();
  }

  _onMenuItemClick({ $index, $event }) {
    this._selectedItemAtIndex($index);
    this._closeIfNotMultivalue();
    $event.preventDefault();
  }

  _closeIfNotMultivalue() {
    if (!isMulitValue(this.searchTerm)) {
      this._close();
    }
  }

  _selectedItemAtIndex(index) {
    const { filteredResult = [], datasource = [], onRequestSelect } = this;
    const selectedItem = filteredResult[index];
    const datasourceIndex = datasource.indexOf(selectedItem);
    if (datasourceIndex !== -1) {
      if (onRequestSelect) {
        onRequestSelect({ value: selectedItem, index: datasourceIndex });
      }
    }

    if (this.ctx.scrollToEnd) {
      this.ctx.scrollToEnd();
    }
  }

  _onKeydown($event) {
    this._open(); // what is point not to open when you pressing smth?

    const UP_ARROW_CODE = 38;
    const upArrowWasPressed = UP_ARROW_CODE === $event.keyCode;
    if (upArrowWasPressed) {
      this._moveSelectionUpOrDown(-1);
    }

    const DOWN_ARROW_CODE = 40;
    const downArrowWasPressed = DOWN_ARROW_CODE === $event.keyCode;
    if (downArrowWasPressed) {
      this._moveSelectionUpOrDown(1);
    }

    const ENTER_CODE = 13;
    if (ENTER_CODE === $event.keyCode) {
      this._selectedItemAtIndex(this.state.selectedIndex);
      this._closeIfNotMultivalue();
    }

    const TAB_KEY_KODE = 9;
    if (TAB_KEY_KODE === $event.keyCode) {
      this._close();
    }
    const ESC_KODE = 27;
    if (ESC_KODE === $event.keyCode) {
      this._close();
    }
    this.onKeydown({ $event }); // notify parent;
  }

  _getMenuItemAtIndex(index) {
    return this.$element.find('.js-autocomplete-menu-item')[index];
  }

  _moveSelectionUpOrDown(direction) {
    const {
      state,
      filteredResult,
      CollectionUtils: { nextIndex },
      DOMService: { scrollToElementIfNeeded },
    } = this;
    const { selectedIndex } = state;
    this.state.selectedIndex = nextIndex(
      direction,
      selectedIndex,
      filteredResult,
    );
    const menuItem = this._getMenuItemAtIndex(this.state.selectedIndex);
    scrollToElementIfNeeded(menuItem);
  }

  _strictCaseInsensitiveFilter(searchTerm, isNotTermChanged, value) {
    if (isMulitValue(searchTerm)) {
      searchTerm = last(searchTerm);
    }

    if (!searchTerm || isNotTermChanged) {
      // empty search term// or focused and not start input
      // match every item;
      return true;
    }

    // normalize value;
    if (typeof value !== 'string') {
      // it looks like an object;
      value = value.value || '';
      log({
        level: Level.verbose,
        msg: 'You provide an object with empty object.value prop to auto-complete!',
      });
    }
    searchTerm = String(searchTerm);
    value = trim(value.toLowerCase());
    searchTerm = trim(searchTerm.toLowerCase());
    const res = value.indexOf(searchTerm) !== -1;
    return res;
  }

  _open() {
    if (!this.state.open) {
      this.state.open = true;
      setTimeout(() => {
        if (this.state.open) {
          const { focus, selectAll } = this.ctx;

          if (focus) {
            focus();
          }

          if (selectAll) {
            selectAll();
          }
        }
      }, 10);
      this.onOpen();
    }
  }

  /**
   *
   * @param {HTMLFocusEvent} $event - event is actually... object {$event:$event}
   * @private
   */
  _onChipInputFocus($event) {
    this.isNotTermChanged = true;
    this._open();
    this.onFocus({ $event });
  }

  _onChipInputBlur($event) {
    // this._close();
    this.onBlur({ $event });
  }

  log(e) {
    return e;
  }
}

export default ng
  .module('app.ui.cfAutoComplete', [])
  .component('cfAutoComplete', {
    controllerAs: 'vm',
    bindings: {
      // fixme @p1: add jsdocs to bindings;
      /**
       * @bindings: popoverYOffset: number
       * @descriptions: allow add somevalue to positioning popover;
       */
      popoverYOffset: '<',

      /**
       * @property: {'main'} [menuColor] - color of the menu-items in the popop defaults to gray. main - new blue design;
       */

      menuColor: '@',

      /**
       *  datasource can be - [strings] or [ {value: string, subheader: string?, ...any}]
       */
      datasource: '<',
      cfDisabled: '<',
      placeholder: '@',
      searchTerm: '<',
      // onSearchTermChange:
      onChange: '&',
      onClick: '&',
      onRequestSelect: '&',
      onKeydown: '&',
      onFocus: '&',
      onBlur: '&',
      fullWidth: '@',
      maxHeight: '@',
      /**
       * optional default to transparent.
       */
      chipType: '@',
      /**
       * optional: chip  or text; defaults to text;
       *
       * text data source is simple text;
       * chip: datasource = {value: string, subheader?: string, type?: ChipType = transparent}
       *  value: will be searched for and displayed as main text as a chip;
       *  type: chip type for text;
       *  hoverType: hover type for chip defaults to type
       *  subheader: optional subheader;
       *
       */
      type: '@',
      /**
       * @notes
       * use specific key cfOpen becouse.... angular rocks!
       * you cannot use open derctlly it is bound to angular so you can only set it by ngOpen;
       * and it will not be bindings only attr - and you cannot easily watch attrs... nice ....
       *
       * @description controls if autocomplete open or closed;
       *
       */
      cfOpen: '<',
      /**
       *  notifys owner if components trying to open himself or close him self;
       */
      onClose: '&',
      onOpen: '&',
      cfSelectable: '@',
      /**
       *  @dinding: (searchTerm: string, isNotTermChanged: boolean,value: any) => boolean
       *  @description: to customize filtering logic;
       *  @optional;
       */
      filter: '<',
      /**
       * @property: {*} popover-width - directly set popover width;
       */
      popoverWidth: '<',
      /**
       * @property: {object} [textFieldTypes] - see chip-input types;
       */ textFieldTypes: '<',

      onMultiValueItemSelect: '&',

      isInputOnPopover: '<',
    },
    template: `
      <div  style="position: relative" flex on-click-away="vm._handleClickAway({$event:$event});">
           <chip-input
            flex
            cf-disabled="vm.cfDisabled || (vm.isInputOnPopover && vm.state.open)"
            chip-type="{{vm.chipType}}"
            value="vm.searchTerm"
            placeholder="{{vm.placeholder}}"
            on-change="vm.onChange({value:value})"
            on-focus="vm._onChipInputFocus($event)"
            on-blur="vm._onChipInputBlur($event)"
            on-keydown="vm._onKeydown($event)"
            on-click="vm.onClick({$event:$event})"
            types="vm.textFieldTypes"
            ctx="vm.isInputOnPopover ? vm.ctxBase : vm.ctx"
            on-multi-value-item-select="vm._onMultiValueItemSelect({$i:index})"
            no-wrap="vm.isInputOnPopover"
           >
           </chip-input>
           <cf-popover
            ng-if="vm.state.open"
            width="vm.popoverWidth"
            full-width="{{vm.fullWidth}}"
            cf-open="{{vm.state.open}}"
            popover-y-offset="vm.popoverYOffset"
           >
              <cf-menu
                max-height="{{vm.isInputOnPopover ? null : (vm.maxHeight || 'medium')}}"
                ng-show="vm._isDisplayingMenuForFilteredResult(vm.filteredResult) || vm.isInputOnPopover"
                ng-class="{
                  'menu_no-padding': vm.isInputOnPopover,
                  'menu_outline': vm.isInputOnPopover,
                }"
              >
                 <chip-input
                  ng-if="vm.isInputOnPopover"
                  ng-style="{'max-height': vm.VALIES_MAX_HEIGHT}"
                  cf-disabled="vm.cfDisabled"
                  chip-type="{{vm.chipType}}"
                  value="vm.searchTerm"
                  placeholder="{{vm.placeholder}}"
                  on-change="vm.onChange({value:value})"
                  on-blur="vm._onChipInputBlur($event)"
                  on-keydown="vm._onKeydown($event)"
                  on-click="vm.onClick({$event:$event})"
                  types="{normal: 'borderless', active: 'borderless'}"
                  ctx="vm.ctx"
                  on-multi-value-item-select="vm._onMultiValueItemSelect({$i:index})"
                  multiline-items="true"
                  text-field-class="multi-item-chip_padding"
                  style="display: flex;flex: none;line-height: 28px;"
                 >
                </chip-input>
                <scroll-box
                  ng-style="{'max-height': vm.isInputOnPopover && vm.SUGGEST_MAX_HEIGHT}"
                >
                  <cf-menu-item
                    class="js-autocomplete-menu-item"
                    color="{{vm.menuColor}}"
                    ng-repeat="n in vm.filteredResult = ( vm.datasource | filter : vm._filter(vm.searchTerm, vm.isNotTermChanged) ) track by $index"
                    ng-switch="vm.type"
                    ng-mousedown="vm._onMenuItemClick({$index:$index, $event:$event});"
                    ng-mouseenter="vm.state.selectedIndex = $index"
                    cf-selectable="{{vm.cfSelectable || 'false'}}"
                    cf-selected="{{vm.state.selectedIndex === $index}}"
                    paddings="true"
                  >
                      <div ng-switch-when="chip" class="chip-menu-item_positioning">
                        <button
                          class="chip"
                          type="{{ vm.state.selectedIndex === $index ? (n.hoverType || n.type || 'transparent') : n.type || 'transparent'}}"
                        >{{ n.value === undefined ? n : n.value}}</button>
                        <cf-menu-item-subheader
                        ng-if="n.subheader != null"
                        >{{n.subheader}}</cf-menu-item-subheader>
                      </div>

                      <span ng-switch-default="">
                        {{n}}
                      </span>
                    </cf-menu-item>
                </scroll-box>
              </cf-menu>
           </cf-popover>
       </div>
    `,
    controller: CfAutoCompleteController,
  }).name;
