import { LS, LSKey } from 'cf-common/src/localStorage';

/* utils to handle logins from admin panel */
export const setSyntheticLoginFlag = () => LS.set(LSKey.syntheticLogin, 1);

export const setAutotestLoginFlag = () => LS.set(LSKey.autotestLogin, 1);

export const removeSyntheticLoginFlag = () => {
  LS.remove(LSKey.syntheticLogin);
  LS.remove(LSKey.autotestLogin);
};

export const isSyntheticLogin = () => !!LS.get(LSKey.syntheticLogin);

export const isAutotestLogin = () => !!LS.get(LSKey.autotestLogin);
