import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from 'react-apollo';
import {
  FlowGroupsQuery,
  FlowGroupsQueryVariables,
} from '../@types/FlowGroupsQuery';
import { FLOW_GROUPS_QUERY } from '../query';

export const useFlowGroups = () => {
  const botId = useCurrentBotId()!;

  const queryResult = useQuery<FlowGroupsQuery, FlowGroupsQueryVariables>(
    FLOW_GROUPS_QUERY,
    {
      variables: { botId },
      skip: !botId,
      notifyOnNetworkStatusChange: true,
    },
  );

  return queryResult;
};
