import { useQuery } from 'react-apollo';
import { Level, log } from 'cf-common/src/logger';
import { CURRENCIES_QUERY } from './queries';
import { CurrenciesQuery } from './@types/CurrenciesQuery';
import { useCallback, useMemo } from 'react';
import { calcStripeMinimalAmount } from './calcStripeMinimalAmount';
import { getCurrencyByCode as getCurrency  } from './getCurrencyByCode';

export const useCurrencies = () => {
  const { data, loading, error } = useQuery<CurrenciesQuery>(CURRENCIES_QUERY);
  if (error) {
    log({
      msg: 'Could not fetch currencies!',
      level: Level.error,
    });
  }

  const currencies = data?.currencies ?? [];

  const getCurrencyByCode = useCallback(
    (currencyCode: string) =>
      getCurrency(currencyCode, currencies),
 [currencies] );

  const currenciesItems = useMemo(
    () =>
      currencies.map(({ code }) => ({
        id: code,
        title: code,
      })),
    [currencies],
  );

  const hasMinAmountError = useCallback(
    (amount: number, code: string) =>
      calcStripeMinimalAmount(getCurrencyByCode(code)) > amount,
    [getCurrencyByCode],
  );

  return {
    currencies,
    currenciesItems,
    loading,
    getCurrencyByCode,
    hasMinAmountError,
  };
};
