import gql from 'graphql-tag';

export const PERMISSIONS_FRAGMENT = gql`
  fragment permissionsFragment on Permissions {
    inbox {
      permission
    }
    ai {
      permission
    }
    people {
      permission
    }
    broadcasting {
      permission
    }
    configure {
      permission
    }
    grow {
      permission
    }
    analyze {
      permission
    }
    roles {
      permission
    }
    groups {
      permission
      whitelistedGroupIds
    }
    pro {
      permission
    }
    bot {
      permission
    }
    flows {
      permission
    }
    workspaces {
      permission
    }
  }
`;
