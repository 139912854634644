import { useQuery } from '@apollo/react-hooks';
import { PERMISSIONS_GROUP_QUERY } from './queries';
import {
  PermissionsGroupQuery,
  PermissionsGroupQueryVariables,
} from './@types/PermissionsGroupQuery';
import { PermissionGroup } from '../../Permissions';

export const usePermissionsGroup = (groupName: PermissionGroup) => {
  const { data, ...state } = useQuery<
    PermissionsGroupQuery,
    PermissionsGroupQueryVariables
  >(PERMISSIONS_GROUP_QUERY, { variables: { groupName } });

  return {
    permissionsGroup: data?.permissionsGroup,
    ...state,
  };
};
