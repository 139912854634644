import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import memoize from 'lodash-es/memoize';
import { propEq } from 'ramda';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import client from '../../../common/services/ApolloService';
import {
  IntegrationZapierAccountStateQuery,
  IntegrationZapierAccountStateQueryVariables,
} from './@types/IntegrationZapierAccountStateQuery';

const INTEGRATION_ZAPIER_ACCOUNT_STATE_QUERY = gql`
  query IntegrationZapierAccountStateQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedZapierAccounts {
        id
        admin_id
      }
    }
  }
`;

export const getIntegrationZapierAccountIdObservable = memoize(
  (botId: string, adminId: string) =>
    Observable.from(
      client.watchQuery<
        IntegrationZapierAccountStateQuery,
        IntegrationZapierAccountStateQueryVariables
      >({
        query: INTEGRATION_ZAPIER_ACCOUNT_STATE_QUERY,
        variables: {
          botId,
        },
      }) as ObservableInput<
        ApolloQueryResult<IntegrationZapierAccountStateQuery>
      >,
    ).map(({ data }) => {
      return data?.bot?.connectedZapierAccounts.find(
        propEq('admin_id', adminId),
      )?.id;
    }),
);
