import { useMutation } from 'react-apollo';
import {
  CreateBotInviteMutation,
  CreateBotInviteMutationVariables,
} from './@types/CreateBotInviteMutation';
import { CREATE_BOT_INVITE_MUTATION } from './mutations';

interface UseCreateBotInvitePayload {
  botId: string;
  roleId: string;
}

export const useCreateBotInvite = (payload?: UseCreateBotInvitePayload) => {
  return useMutation<CreateBotInviteMutation, CreateBotInviteMutationVariables>(
    CREATE_BOT_INVITE_MUTATION,
    { variables: payload },
  );
};
