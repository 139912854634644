import { waitFoTheReturnToTab } from '@utils/Event/waitFoTheReturnToTab';
import { useEffect, useRef, MutableRefObject } from 'react';

interface SequentialPollingParams<T = any> {
  pollingFn: (() => Promise<T>) | MutableRefObject<() => Promise<T>>;
  interval?: number;
}

interface UseSequentialPollingParams<T = any> {
  pollingFn: () => Promise<T>;
  interval?: number;
}

const MAX_RETRIES = 3;

export function sequentialPolling({
  pollingFn,
  interval = 1000,
}: SequentialPollingParams) {
  let destroyed = false;
  let isPollingActive = false;
  let timeoutId: number | undefined;
  let rejector: Function | undefined;

  function poll(retries: number = 0) {
    isPollingActive = true;
    const timeoutPromise = new Promise((resolve, reject) => {
      rejector = reject;
      timeoutId = window.setTimeout(resolve, interval);
    });

    return timeoutPromise
      .catch((error) => {
        if (error === 'cancel') {
          // pass
          // this just means we've cancelled polling
          return;
        }

        throw error;
      })
      .then(typeof pollingFn === 'function' ? pollingFn : pollingFn.current)
      .then(() => {
        if (!destroyed) {
          poll();
        }
      })
      .catch((error) => {
        if (!destroyed && retries >= MAX_RETRIES - 1) {
          poll(retries + 1);
          return;
        }

        isPollingActive = false;

        throw error;
      });
  }

  function watchVisibilityChange() {
    waitFoTheReturnToTab(0)
      .then(() => {
        if (!destroyed && !isPollingActive) {
          poll();
        }
      })
      .then(watchVisibilityChange);
  }

  poll();
  watchVisibilityChange();

  return () => {
    clearTimeout(timeoutId);
    destroyed = true;
    isPollingActive = false;
    if (rejector) {
      rejector('cancel');
    }
  };
}

export const useSequentialPolling = ({
  pollingFn,
  interval,
}: UseSequentialPollingParams) => {
  const pollingFnRef = useRef(pollingFn);

  useEffect(() => {
    pollingFnRef.current = pollingFn;
  }, [pollingFn]);

  useEffect(() => {
    return sequentialPolling({
      pollingFn: pollingFnRef,
      interval,
    });
  }, [interval]);
};
