import gql from 'graphql-tag';
import { log } from 'cf-common/src/logger';

import { sendEvent } from './Analytics';
import client from '../common/services/ApolloService';
import { removeTypename } from './GQL/utils';
import { ServerStorageItemKeys, serverStorageItemGet } from './ServerStorage';

const USER_QUERY = gql`
  query USER_QUERY_GTM_EXPOSE {
    me {
      id
      name
      email
      date_added
      abt_serialized
      page_stats {
        likes
        connected_pages
        connected_page_likes
        pages
        users
        pro_pages
        pro_page_users
      }
    }
  }
`;

export async function initCurrentUserVarsInGTM() {
  try {
    const [{ data }, leadsQualificationData] = await Promise.all([
      client.query({
        query: USER_QUERY,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      }),
      serverStorageItemGet(ServerStorageItemKeys.leadsQualificationData),
    ]);

    const { page_stats: pageStats, ...userData } = data.me;
    userData.createdAt = parseInt(data.me.date_added, 10);

    sendEvent(
      removeTypename({
        event: 'user_loaded',
        ...userData,
        ...pageStats,
        ...(leadsQualificationData ?? {}),
      }),
    );
  } catch (error) {
    log.warn({ error, msg: 'Failed to initialize current user vars in GTM' });
  }
}
