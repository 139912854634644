import numeral from 'numeral';
// copied from old bot list:
// danger it is global;
numeral.locales.en.delimiters.thousands = ' ';

/**
 * format number to string "12" "123" "1 234" "12k" "123k" "1m"

 * @param {int} num -
 * @return {string} -
 */
export const humanizeNumber = (num: number) => {
  const numAbs = Math.abs(num);
  return numAbs > 999
    ? numAbs < 9000
      ? numeral(num).format('0[.]0a')
      : numAbs < 1000000
      ? numeral(num).format('0a')
      : numeral(num).format('0[.]0a')
    : numeral(num).format('0,0');
};
