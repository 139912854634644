import gql from 'graphql-tag';

const PAYMENTS_CONFIG_FRAGMENT = gql`
  fragment paymentsConfigFragment on Bot {
    id
    payments_config {
      terms_of_service_url
      merchant_emails
    }
  }
`;

export const PAYMENTS_CONFIG_QUERY = gql`
  query PaymentsConfigQuery($botId: String) {
    bot(id: $botId) {
      ...paymentsConfigFragment
    }
  }
  ${PAYMENTS_CONFIG_FRAGMENT}
`;

export const UPDATE_PAYMENTS_CONFIG_MUTATION = gql`
  mutation UpdatePaymentsConfigMutation(
    $botId: String!
    $config: PaymentsConfigInput!
  ) {
    updatePaymentsConfig(botId: $botId, config: $config) {
      ...paymentsConfigFragment
    }
  }
  ${PAYMENTS_CONFIG_FRAGMENT}
`;
