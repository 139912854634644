import client from '@common/services/ApolloService';
import { DIALOGS_QUERY } from '@pages/LiveChat/common/queries';
import {
  DialogsQuery,
  DialogsQueryVariables,
} from '@pages/LiveChat/common/@types/DialogsQuery';
import { Folder, Platform } from '@globals';
import { FOLDERS, PLATFORMS, SEGMENTATION } from './consts';
import { mapQuery } from '../../../GQL/mapQuery';

const removeDialogsFromGqlCacheByPlatformAndFolder = (
  ids: string[],
  botId: string,
  folder: Folder,
  platform: Platform | null,
) => {
  mapQuery<DialogsQuery, DialogsQueryVariables>(
    client,
    {
      query: DIALOGS_QUERY,
      variables: {
        botId,
        folder,
        platform,
        segmentation: SEGMENTATION,
      },
    },
    (data) => {
      // eslint-disable-next-line no-param-reassign
      data.livechatConversations.items =
        data.livechatConversations.items.filter(
          ({ subscriber: { id } }) => !ids.includes(id || ''),
        );
      return data;
    },
  );
};

export const removeDialogsFromGqlCache = (ids: string[], botId: string) => {
  PLATFORMS.forEach((platform) => {
    FOLDERS.forEach((folder) => {
      removeDialogsFromGqlCacheByPlatformAndFolder(
        ids,
        botId,
        folder,
        platform,
      );
    });
  });
};
