export type EventPayload<T> = T & { [key: string]: any };

export type EventCallback<T, Options> = (
  payload: EventPayload<T>,
  options?: Options,
) => void;

export type UnsubscribeFn = () => void;

export class EventEmitter<
  Options = void,
  Event extends string = string,
  Payload = {},
> {
  private events: Record<string, Array<EventCallback<any, Options>>> = {};

  emit<T>(
    eventId: Event,
    payload?: EventPayload<T & Payload>,
    options?: Options,
  ) {
    if (eventId in this.events) {
      this.events[eventId].forEach((callback) => callback(payload, options));
    }
  }

  on<T>(
    eventId: Event,
    callback: EventCallback<T & Payload, Options>,
  ): UnsubscribeFn {
    if (!(eventId in this.events)) {
      this.events[eventId] = [];
    }
    this.events[eventId].push(callback);

    return () => this.off(eventId, callback);
  }

  off<T>(eventId: Event, callback: EventCallback<T & Payload, Options>) {
    this.events[eventId] = this.events[eventId].filter((eventCb) => {
      return callback !== eventCb;
    });
  }

  destroy() {
    Object.values(this.events).forEach((event) => {
      // eslint-disable-next-line no-param-reassign
      event.length = 0;
    });
  }
}
