import { useCallback, useState } from 'react';
import nanoid from 'nanoid';
import { useCallbackRef } from 'cf-common/src/utils/hooks';

interface ValueHolder<T> {
  id: string;
  value: T;
}

const toValueHolders = <T>(array: T[]): ValueHolder<T>[] => {
  return array.map((value) => ({
    id: nanoid(),
    value,
  }));
};

export const fromValueHolders = <T>(valueHolders: ValueHolder<T>[]): T[] => {
  return valueHolders.map(({ value }) => value);
};

/**
 * Хук необходим для работы со списками у которых элементы не имеют уникальных идентификаторов
 * Для этого сохраняется копия списка {@param array}, где каждому элементу назначен идентификатор
 * Синхронизация между списками происходит с помощью коллбека {@param sync}
 */
export const useStatefulArray = <T>(
  array: T[],
  sync: (nextArray: T[]) => void,
) => {
  const [valueHolders, setValueHolders] = useState(() => toValueHolders(array));
  const syncRef = useCallbackRef(sync);

  const changeItem = useCallback(
    (id: string, update: (prev: T) => T) => {
      setValueHolders((prevValueHolders) => {
        const nextValueHolders = prevValueHolders.map((valueHolder) =>
          valueHolder.id === id
            ? { ...valueHolder, value: update(valueHolder.value) }
            : valueHolder,
        );

        const nextArray = fromValueHolders(nextValueHolders);
        syncRef.current(nextArray);

        return nextValueHolders;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [syncRef],
  );

  const deleteItem = useCallback(
    (id: string, onChange: (nextValues: T[]) => void) =>
      setValueHolders((prevValueHolders) => {
        const nextValueHolders = prevValueHolders.filter(
          (valueHolder) => valueHolder.id !== id,
        );
        const nextArray = fromValueHolders(nextValueHolders);
        syncRef.current(nextArray);
        onChange(nextArray);
        return nextValueHolders;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [syncRef],
  );

  const addItem = useCallback(
    (newValue: T, onChange: (nextValues: T[]) => void) =>
      setValueHolders((prevValueHolders) => {
        const nextValueHolders = [
          ...prevValueHolders,
          { id: nanoid(), value: newValue },
        ];
        const nextArray = fromValueHolders(nextValueHolders);
        syncRef.current(nextArray);
        onChange(nextArray);
        return nextValueHolders;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [syncRef],
  );

  return {
    valueHolders,
    changeItem,
    deleteItem,
    addItem,
  };
};
