import ng from 'angular';

export default ng.module('app.ui.mesIcon', []).component('mesIcon', {
  controllerAs: 'vm',
  template: () => `
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        class="mes-icon"
        d="M9.96983 17.6936L10.1723 16.9715L9.88337 16.8905L9.61833 17.0311L9.96983 17.6936ZM7.80119 18.8442L7.44969 18.1816L7.44969 18.1816L7.80119 18.8442ZM7.50746 16.4589H8.25746V16.0916L7.96731 15.8664L7.50746 16.4589ZM12 18.7206C16.2253 18.7206 19.7501 15.5341 19.7501 11.4853H18.2501C18.2501 14.5999 15.5068 17.2206 12 17.2206V18.7206ZM9.76739 18.4158C10.4755 18.6143 11.2252 18.7206 12 18.7206V17.2206C11.3628 17.2206 10.7493 17.1332 10.1723 16.9715L9.76739 18.4158ZM9.61833 17.0311L7.44969 18.1816L8.15269 19.5067L10.3213 18.3562L9.61833 17.0311ZM7.44969 18.1816C7.81601 17.9873 8.25746 18.2528 8.25746 18.6675H6.75746C6.75746 19.3838 7.51996 19.8424 8.15269 19.5067L7.44969 18.1816ZM8.25746 18.6675V16.4589H6.75746V18.6675H8.25746ZM4.25 11.4853C4.25 13.7365 5.34998 15.7338 7.0476 17.0514L7.96731 15.8664C6.59939 14.8047 5.75 13.2289 5.75 11.4853H4.25ZM12 4.25C7.77478 4.25 4.25 7.43644 4.25 11.4853H5.75C5.75 8.37069 8.49326 5.75 12 5.75V4.25ZM19.7501 11.4853C19.7501 7.43644 16.2253 4.25 12 4.25V5.75C15.5068 5.75 18.2501 8.37069 18.2501 11.4853H19.7501Z"
        id="Combined-Shape"></path>
    </svg>
    `,
}).name;
