import { sendEvent } from '@utils/Analytics';

export const createFlowForMe = () => {
  const { language } = window.i18next;
  let formLink;

  switch (language) {
    case 'es':
      formLink = 'https://chatfuel.com/es/features/bot-building';
      break;
    case 'pt':
      formLink = 'https://chatfuel.com/pt/features/bot-building';
      break;
    case 'en':
    default:
      formLink = 'https://chatfuel.com/features/bot-building';
      break;
  }

  window.open(formLink, '_blank');
  sendEvent({
    action: 'open google docs',
    category: 'create flow for me',
  });
};
