import { Platform } from '@globals';
import { GroupMimeTypes, VideoMimeTypes } from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_VIDEO_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [GroupMimeTypes.video],
  [Platform.instagram]: [
    VideoMimeTypes.ogg,
    VideoMimeTypes.mov,
    VideoMimeTypes.avi,
    VideoMimeTypes.mp4,
    VideoMimeTypes.webm,
  ],
  [Platform.whatsapp]: [VideoMimeTypes.gpp, VideoMimeTypes.mp4],
};

export const VIDEO_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 15,
  [Platform.instagram]: 15,
  [Platform.whatsapp]: 14,
};
