import { FlowData } from '@components/FlowBuilder/types';
import { BLOCK_SUBTYPES, isRootBlock } from '@components/FlowBuilder/consts';
import { createFlowBlock } from '@components/FlowBuilder/api/network';
import { isEntryPoint } from '@components/FlowBuilder/views/entry-points/common/utils';
import { Level, log } from 'cf-common/src/logger';
import * as FlowTypes from './@types/AggregatedFlowQuery';

export const mapToAggregatedFlowToFlowData = ({
  me: user,
  bot,
  flowStats,
  customAiTemplates,
}: FlowTypes.AggregatedFlowQuery): Partial<FlowData> => {
  const flow = bot.flow!;
  return {
    bot,
    user,
    blocksTitles: bot.archiveBlocks,
    botFeatures: bot.features,
    botId: bot.id,
    botStatus: bot.status,
    collapsed: flow.collapsed,
    groups: bot.blocksGroups,
    id: flow.id,
    sourceFlowId: flow.source_flow_id,
    segments: bot.segments ?? [],
    sharingParams: flow.sharing_params,
    title: flow.title,
    verifiedPermissions: bot.verifiedPermissions ?? [],
    uniqStats: flowStats,
    platform: flow.platform,
    customAiTemplates,
  };
};
export interface EventLogData {
  flowId?: string;
  botId?: string;
}

export const prepareFlowBlock = (
  block: FlowTypes.AggregatedFlowQuery_bot_flowBlocks,
  { botId, flowId }: EventLogData = {},
) => {
  const parsedCards =
    (block.jsonCards
      ? (JSON.parse(
          block.jsonCards,
        ) as FlowTypes.AggregatedFlowQuery_bot_flowBlocks_cards[])
      : undefined) ?? [];

  if (block.cards === null && parsedCards.length) {
    log({
      level: Level.warn,
      msg: 'Incorrect schema in one of the FlowBuilder plugins (aggregated query)',
      data: {
        botId,
        flowId,
        block: block.id,
      },
    });
    return { ...block, cards: parsedCards };
  }

  return {
    ...block,
    cards: (block.cards || []).map((card, index) => {
      const parsedCard = parsedCards[index] ?? card;
      // eslint-disable-next-line no-underscore-dangle
      if (card.__typename === 'DefaultCommonTypePlugin') {
        return parsedCard;
      }
      if (isEntryPoint(card.plugin_id)) {
        // eslint-disable-next-line no-param-reassign
        (card as any).enabled = (parsedCard as any).enabled;
      }
      return card;
    }),
  };
};

export const prepareFlowBlocks = async (
  flow: Partial<FlowData>,
  flowBlocks: FlowTypes.AggregatedFlowQuery_bot_flowBlocks[],
) => {
  let flowRootSetted = false;
  /* eslint-disable no-param-reassign */
  const blocks = (flowBlocks ?? [])
    // hack since not all plugins are on gql
    .map((block) => {
      const preparedFlowBlock = prepareFlowBlock(block, {
        botId: flow.botId,
        flowId: flow.id,
      });

      if (isRootBlock(preparedFlowBlock.subtype as string)) {
        flow.root = block;
        flowRootSetted = true;
      }

      return preparedFlowBlock;
    });

  if (!flowRootSetted) {
    throw new Error('Flow must have root block');
  }

  flow.blocks = blocks.length
    ? blocks
    : [
        await createFlowBlock({
          flowId: flow?.id!,
          subtype: BLOCK_SUBTYPES.send_message,
          position: { x: 0, y: 0 },
        }),
      ];
  /* eslint-enable no-param-reassign */
};
