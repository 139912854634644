import { MutationHookOptions, useMutation } from 'react-apollo';
import {
  ConnectKommoMutation,
  ConnectKommoMutationVariables,
} from './@types/ConnectKommoMutation';
import { CONNECT_KOMMO_MUTATION } from './mutation';

export const useConnectKommo = (
  payload?: MutationHookOptions<
    ConnectKommoMutation,
    ConnectKommoMutationVariables
  >,
) => {
  return useMutation<ConnectKommoMutation, ConnectKommoMutationVariables>(
    CONNECT_KOMMO_MUTATION,
    payload,
  );
};
