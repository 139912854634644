import i18next from 'i18next';
import { GQLError, MatchGQLErrorStatus } from '@utils/GQL/utils';
import { redirect } from '@utils/UrlUtils';
import { BotTabs, getTabLink } from '@utils/Routing';
import { IToastConfig, toaster } from '@services/MessageService';
import { isMongoObjectId } from 'cf-common/src/utils/mongo/isMongoObjectId';
import { exhaustiveCheck } from '../../../exhaustiveCheck';

export interface ShopifyConnectionErrorParams {
  connectAccountError: GQLError;
  myshopifyDomain: string;
  botId: string;
  doesBotBelongToUser?: (botId: string) => boolean;
}

export enum ShopifyErrorTypes {
  user_not_found = 'user_not_found',
  auth_validation_failed = 'auth_validation_failed',
  invalid_shop_name = 'invalid_shop_name',
  another_shop_connected_to_bot = 'another_shop_connected_to_bot',
  shop_is_already_connected_to_bot = 'shop_is_already_connected_to_bot',
  bot_is_in_workspace = 'bot_is_in_workspace',
  page_has_active_stripe_subscription = 'page_has_active_stripe_subscription',
}

export const handleShopifyConnectionError = ({
  connectAccountError,
  doesBotBelongToUser,
  botId: connectedBotId,
}: ShopifyConnectionErrorParams) => {
  const maybeBotId = connectAccountError.extensions.message;
  const botId = isMongoObjectId(maybeBotId) ? maybeBotId : connectedBotId;
  const userBot = doesBotBelongToUser?.(botId);
  const errorType =
    connectAccountError.extensions.result?.trim() as ShopifyErrorTypes;

  const getErrorText = (type: ShopifyErrorTypes) => {
    switch (type) {
      case ShopifyErrorTypes.bot_is_in_workspace:
        return i18next.t('shopify.botIsInWorkspace');
      case ShopifyErrorTypes.page_has_active_stripe_subscription:
        return i18next.t('shopify.pageHasActiveStripeSubscription');
      case ShopifyErrorTypes.another_shop_connected_to_bot:
        return i18next.t('shopify.anotherShopAlreadyConnectedError');
      case ShopifyErrorTypes.shop_is_already_connected_to_bot:
        return i18next.t('shopify.connectError');
      case ShopifyErrorTypes.auth_validation_failed:
      case ShopifyErrorTypes.invalid_shop_name:
      case ShopifyErrorTypes.user_not_found:
      default:
        return i18next.t('common.ToasterMessages.somethingWentWrong');
    }
  };

  const getButtonText = (type: ShopifyErrorTypes) => {
    switch (type) {
      case ShopifyErrorTypes.another_shop_connected_to_bot:
        return userBot ? i18next.t('shopify.goToBot') : null;
      case ShopifyErrorTypes.shop_is_already_connected_to_bot:
        return i18next.t('shopify.goToShopify');

      case ShopifyErrorTypes.bot_is_in_workspace:
      case ShopifyErrorTypes.page_has_active_stripe_subscription:
      case ShopifyErrorTypes.auth_validation_failed:
      case ShopifyErrorTypes.invalid_shop_name:
      case ShopifyErrorTypes.user_not_found:
        return null;
      default:
        exhaustiveCheck(type);
    }
    return undefined;
  };

  const buttonLabel = getButtonText(errorType);

  const shopConnectedToAnotherBotHandler = () => ({
    message: getErrorText(errorType),
    buttonLabel,
    timeout: 10000,
    showCloseButton: !buttonLabel,
    onButtonClick: () => {
      if (errorType === ShopifyErrorTypes.another_shop_connected_to_bot) {
        redirect(getTabLink(BotTabs.configure, botId));
        return;
      }
      if (errorType === ShopifyErrorTypes.shop_is_already_connected_to_bot) {
        redirect(getTabLink(BotTabs.configure, botId));
      }
    },
  });

  const payload = new MatchGQLErrorStatus<IToastConfig['payload']>()
    .badRequest(shopConnectedToAnotherBotHandler)
    .conflict(shopConnectedToAnotherBotHandler)
    .unprocessableContent(() => ({
      message: i18next.t('shopify.useValidShopifyDomain'),
    }))
    .default(shopConnectedToAnotherBotHandler)
    .match(connectAccountError)!;

  toaster.error({ payload });
};
