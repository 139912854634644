import './assets/less/fb-button.less';

import ng from 'angular';
import template from './fb-button.html';
import controller from './controller';

export default ng.module('app.ui.fbButton', []).component('fbButton', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    appId: '@',
    pageId: '@',
    ref: '@',
    text: '@',
  },
}).name;
