export const BASE64_MARKER = ';base64,';

export const dataURLToBlob = (fileUrl: string) => {
  if (!fileUrl.includes(BASE64_MARKER)) {
    const parts = fileUrl.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];
    return new Blob([raw], { type: contentType });
  }

  const parts = fileUrl.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
};
