import { useQuery } from 'react-apollo';
import { useLazyQuery } from '@apollo/react-hooks';
import Maybe from 'graphql/tsutils/Maybe';
import { WHATSAPP_SETTINGS_QUERY } from './queries';
import {
  WhatsappSettingsQuery,
  WhatsappSettingsQueryVariables,
} from './@types/WhatsappSettingsQuery';

export const useWhatsappSettings = (botId: Maybe<string>) => {
  const { data, ...rest } = useQuery<
    WhatsappSettingsQuery,
    WhatsappSettingsQueryVariables
  >(WHATSAPP_SETTINGS_QUERY, {
    skip: !botId,
    variables: {
      botId: botId!,
    },
  });
  return { whatsappSettings: data?.whatsappMessagingSettings, ...rest };
};

export const useBillingPaymentMethodsLink = (botId: Maybe<string>) => {
  const { whatsappSettings, ...queryResult } = useWhatsappSettings(botId);

  const businessId =
    whatsappSettings?.whatsappBusinessAccount?.on_behalf_of_business_info?.id ||
    '';
  const assetId = whatsappSettings?.whatsappBusinessAccount?.id || '';

  const link = `https://business.facebook.com/billing_hub/payment_methods?asset_id=${assetId}&business_id=${businessId}`;

  return {
    whatsappSettings,
    link,
    ...queryResult,
  };
};

export const useRefetchWhatsappSettings = (botId: Maybe<string>) => {
  const [refetch, { loading }] = useLazyQuery<
    WhatsappSettingsQuery,
    WhatsappSettingsQueryVariables
  >(WHATSAPP_SETTINGS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      botId: botId!,
    },
  });
  return { refetch, loading };
};
