import { log } from 'cf-common/src/logger';
import client from '../../../../common/services/ApolloService';
import { LOCALE_DATA_QUERY } from './queries';
import { LocaleDataQuery } from './@types/LocaleDataQuery';

export async function queryLocaleData(): Promise<string | undefined> {
  try {
    const res = await client.query<LocaleDataQuery>({
      query: LOCALE_DATA_QUERY,
    });

    const { locale } = res.data.me;

    return locale || undefined;
  } catch (error) {
    log.warn({ error, msg: 'Could not query locale' });

    return undefined;
  }
}
