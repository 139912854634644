import { useAdminFeatures } from '@utils/Data/Admin';
import { useShopifyAccount } from './useShopifyAccount';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const useIsShopifyV3 = () => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();
  const { account, loading } = useShopifyAccount();

  return {
    isShopifyV3:
      (Boolean(account) || adminFeatures?.shopify_v3) && isWhatsappEnabled,
    loading: adminFeaturesLoading || loading,
  };
};
