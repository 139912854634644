import { DomainCookie } from 'cf-common/src/DomainCookie';
import { getTopLevelDomain } from 'cf-common/src/domain';
import { checkWLDomain } from 'cf-common/src/whitelabel';
import { useAdminFeatures } from './Data/Admin';
import { useAdminCountryAndCurrency } from './Data/Admin/Locale/useAdminCountryAndCurrency';
import { useAuthToken } from './Auth/useAuthToken';

const COOKIE_NAME = '__chatfuel_new_pricing';

const domain = getTopLevelDomain();
const isWLdomain = checkWLDomain(domain);
const domainCookie = isWLdomain ? null : new DomainCookie(domain);

const setNewPricingCookie = (isInExperiment: boolean) => {
  if (domainCookie) {
    domainCookie.set(COOKIE_NAME, isInExperiment ? '1' : '0');
  }
};

export const useNewPricingCookie = () => {
  const skip = !useAuthToken();
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures({
    skip,
  });
  const { countryCode, loading: countryCodeLoading } =
    useAdminCountryAndCurrency(skip);

  if (adminFeaturesLoading || countryCodeLoading) {
    return;
  }

  const newPricingLocales = adminFeatures?.dialogs_pricing_locales;
  const isInExperiment =
    !!adminFeatures?.dialogs_pricing_enabled &&
    !!countryCode &&
    Array.isArray(newPricingLocales) &&
    (newPricingLocales.length === 0 || newPricingLocales.includes(countryCode));

  setNewPricingCookie(isInExperiment);
};
