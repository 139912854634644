import gql from 'graphql-tag';

export const BOT_ALLOWED_PLATFORMS_QUERY = gql`
  query BotAllowedPlatformsQuery($botId: String!) {
    bot(id: $botId) {
      id
      allowedPlatforms
    }
  }
`;
