import debounce from 'lodash-es/debounce';
import { Editor } from 'slate-react';
import { moveCaretToEndDocumentIfNeed } from './moveCaretToEndDocumentIfNeed';

export const insertText = debounce((text: string, editorInstance: Editor) => {
  // debounce call for insert emoji after Editor component will be ready
  if (!editorInstance) {
    return;
  }
  moveCaretToEndDocumentIfNeed(editorInstance);
  editorInstance.insertText(text);
});
