import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { usePageConnected } from '@utils/FacebookPages/usePageConnected';

import {
  NotificationMessagesTopicsQuery,
  NotificationMessagesTopicsQueryVariables,
} from './@types/NotificationMessagesTopicsQuery';
import {
  GroupedNotificationMessagesTopicsQuery,
  GroupedNotificationMessagesTopicsQueryVariables,
} from './@types/GroupedNotificationMessagesTopicsQuery';
import { Frequency } from '@globals';
import { useMemo } from 'react';

export const NOTIFICATION_MESSAGES_TOPICS_QUERY = gql`
  query NotificationMessagesTopicsQuery(
    $pageId: String!
    $frequency: Frequency
  ) {
    page(pageId: $pageId) {
      id
      notificationMessagesTopics(frequency: $frequency) {
        id
        name
        frequency
      }
    }
  }
`;

export const GROUPED_NOTIFICATION_MESSAGES_TOPICS_QUERY = gql`
  query GroupedNotificationMessagesTopicsQuery($pageId: String!) {
    page(pageId: $pageId) {
      id
      daily: notificationMessagesTopics(frequency: DAILY) {
        id
        name
        frequency
      }
      weekly: notificationMessagesTopics(frequency: WEEKLY) {
        id
        name
        frequency
      }
      monthly: notificationMessagesTopics(frequency: MONTHLY) {
        id
        name
        frequency
      }
    }
  }
`;

export const useNotificationMessagesTopics = (
  frequency?: Frequency | null,
  options?: QueryHookOptions<
    NotificationMessagesTopicsQuery,
    NotificationMessagesTopicsQueryVariables
  >,
) => {
  const { pageId, loading: pageConnectedLoading } = usePageConnected();
  const { data, loading } = useQuery<
    NotificationMessagesTopicsQuery,
    NotificationMessagesTopicsQueryVariables
  >(NOTIFICATION_MESSAGES_TOPICS_QUERY, {
    variables: { frequency, pageId: pageId! },
    skip: !pageId,
    ...options,
  });
  return {
    notificationMessagesTopics: data?.page?.notificationMessagesTopics,
    notificationMessagesTopicsLoading: pageConnectedLoading || loading,
  };
};

export const useGroupedNotificationMessagesTopics = () => {
  const { pageId, loading: pageConnectedLoading } = usePageConnected();
  const { data, loading } = useQuery<
    GroupedNotificationMessagesTopicsQuery,
    GroupedNotificationMessagesTopicsQueryVariables
  >(GROUPED_NOTIFICATION_MESSAGES_TOPICS_QUERY, {
    variables: { pageId: pageId! },
    skip: !pageId,
  });

  const page = data?.page;

  return useMemo(
    () => ({
      groupedNotificationMessageTopics: page && {
        [Frequency.DAILY]: page.daily,
        [Frequency.WEEKLY]: page.weekly,
        [Frequency.MONTHLY]: page.monthly,
      },
      notificationMessagesTopics: page && [
        ...page.daily,
        ...page.weekly,
        ...page.monthly,
      ],
      notificationMessagesTopicsLoading: pageConnectedLoading || loading,
    }),
    [page, loading, pageConnectedLoading],
  );
};
