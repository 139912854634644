import client from '../../../../common/services/ApolloService';
import { memoize } from 'lodash-es';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentBotId } from '../../../Routing';
import { ApolloQueryResult } from 'apollo-client';
import { intentsFilter } from './helpers';
import { FIRST_SESSION_AI_INTENTS_QUERY } from '../common/queries';
import {
  FirstSessionAiIntentsQuery,
  FirstSessionAiIntentsQueryVariables,
} from '../common/@types/FirstSessionAiIntentsQuery';
import { detectLanguage } from '../../../../i18n';
import { AI_INTENTS_LIST_QUERY } from './queries';
import {
  AiIntentsListQuery,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin,
  AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents,
  AiIntentsListQueryVariables,
} from './@types/AiIntentsListQuery';
import { KeywordsPlacements } from '@pages/BotPage/KeywordsPage/consts';
import { Platform } from '@globals';

const getAllAiIntentsFirstSessionKeywordsSubscription = memoize(
  (platform: Platform, botId: string) =>
    Observable.from(
      client.watchQuery<
        FirstSessionAiIntentsQuery,
        FirstSessionAiIntentsQueryVariables
      >({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        variables: {
          botId,
          locale: detectLanguage(),
        },
      }) as ObservableInput<ApolloQueryResult<FirstSessionAiIntentsQuery>>,
    ).map(({ data }) => {
      const aiIntents = data?.firstSessionAiIntents.intents;
      return (
        aiIntents
          ?.filter(intentsFilter('', platform))
          .flatMap(({ lines }) =>
            lines.map((line: string) => line.toLowerCase()),
          ) || []
      );
    }),
  (...args) => Object.values(args).join('_'),
);

const getAllAiIntentsKeywordsSubscription = memoize(
  (platform: Platform, botId: string) =>
    Observable.from(
      client.watchQuery<AiIntentsListQuery, AiIntentsListQueryVariables>({
        query: AI_INTENTS_LIST_QUERY,
        variables: {
          botId,
        },
      }) as ObservableInput<ApolloQueryResult<AiIntentsListQuery>>,
    ).map(({ data }) => {
      const card = data?.bot.aiBlock.cards?.[0];
      const groups = card
        ? (card as AiIntentsListQuery_bot_aiBlock_cards_AIPlugin).config.groups
        : undefined;
      const aiIntents:
        | AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents[]
        | undefined = groups
        ? groups.flatMap(({ intents }) => intents)
        : undefined;
      return (
        aiIntents
          ?.filter(intentsFilter('', platform))
          .flatMap(({ lines }) =>
            lines.map((line: string) => line.toLowerCase()),
          ) || []
      );
    }),
  (...args) => Object.values(args).join('_'),
);

export const useIsKeywordExist = (
  platform: Platform,
  placement: KeywordsPlacements,
) => {
  const [allKeywords, setAllKeywords] = useState<string[]>([]);
  const botId = useCurrentBotId();

  useEffect(() => {
    if (!botId) {
      return undefined;
    }
    const subscription = (
      placement === KeywordsPlacements.firstSession
        ? getAllAiIntentsFirstSessionKeywordsSubscription(platform, botId)
        : getAllAiIntentsKeywordsSubscription(platform, botId)
    ).subscribe(setAllKeywords);
    return () => {
      subscription.unsubscribe();
    };
  }, [botId, placement, platform]);

  const isKeywordExist = useCallback(
    memoize(
      (value: string) => {
        const keyword = value.toLowerCase();
        return allKeywords.filter((item) => item === keyword).length > 1;
      },
      (value) => value.toLowerCase(),
    ),
    [allKeywords],
  );

  return {
    isKeywordExist,
  };
};
