import { filterXSS } from 'xss';

export const escape = (unsafeText: string) => {
  const div = document.createElement('div');
  div.innerText = unsafeText;
  return div.innerHTML;
};

export const unescape = (text: string) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  const htmlText = doc.documentElement!.textContent;
  return htmlText ? filterXSS(htmlText) : null;
};
