import { BOT_FRAGMENT } from '@common/services/GQLqueries/BotGQService.const';
import gql from 'graphql-tag';

export const COPY_BOT_ASYNC_MUTATION = gql`
  mutation CopyBotMutation(
    $title: String!
    $idFrom: String!
    $workspaceId: String
  ) {
    cloneBotAsync(title: $title, idFrom: $idFrom, workspaceId: $workspaceId) {
      ...botFragment
      workspace_id
    }
  }
  ${BOT_FRAGMENT}
`;

/**
 * Предназначено для новых ботов, подробности https://www.notion.so/chatfuel/69c3648e77f34353ba673e6d96b3915b
 */
export const CHANGE_BOT_ALLOWED_PLATFORMS_MUTATION = gql`
  mutation ChangeBotAllowedPlatformsMutation(
    $botId: String!
    $allowedPlatforms: [Platform!]!
  ) {
    changeBotAllowedPlatforms(
      botId: $botId
      allowedPlatforms: $allowedPlatforms
    )
  }
`;
