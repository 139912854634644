import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useAutomateTabLink, useCurrentBotId, useFlowsTabLink } from './index';
import {
  FlowsListQuery,
  FlowsListQueryVariables,
} from './@types/FlowsListQuery';

const FLOWS_LIST_QUERY = gql`
  query FlowsListQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        flow_ids
      }
    }
  }
`;

export const useGoToEditTab = () => {
  const botId = useCurrentBotId() || '';
  const getAutomateLink = useAutomateTabLink();
  const getFlowsLink = useFlowsTabLink();
  const history = useHistory();
  const { data, loading } = useQuery<FlowsListQuery, FlowsListQueryVariables>(
    FLOWS_LIST_QUERY,
    {
      variables: {
        botId,
      },
      skip: !botId,
    },
  );

  return (id: string) => {
    if (loading) {
      return;
    }
    const isFlow = !!data?.bot.flow_groups?.find(({ flow_ids }) =>
      (flow_ids || []).includes(id),
    );
    history.push((isFlow ? getFlowsLink : getAutomateLink)(id));
  };
};
