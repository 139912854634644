export type FieldNameType =
  | 'seen'
  | 'clicked'
  | 'sent'
  | 'attempted'
  | 'failed';

export const prepareBlockFilterConfig = (
  fieldName: FieldNameType,
  blockId: string,
) => ({
  __typename: 'SegmentationParameter',
  operation: 'and',
  valid: true,
  parameters: [
    {
      type: 'block',
      operation: fieldName,
      values: [blockId],
    },
  ],
});
