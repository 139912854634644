import { ApolloQueryResult } from 'apollo-client';
import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import client from '../../../common/services/ApolloService';
import { NOTIFICATION_MESSAGES_TOPICS_QUERY } from './useNotificationMessagesTopics';
import {
  NotificationMessagesTopicsQuery,
  NotificationMessagesTopicsQueryVariables,
} from './@types/NotificationMessagesTopicsQuery';

export const getNotificationMessagesTopicsObservable = memoize(
  (pageId: string) =>
    Observable.from(
      client.watchQuery<
        NotificationMessagesTopicsQuery,
        NotificationMessagesTopicsQueryVariables
      >({
        query: NOTIFICATION_MESSAGES_TOPICS_QUERY,
        variables: {
          pageId,
        },
      }) as ObservableInput<ApolloQueryResult<NotificationMessagesTopicsQuery>>,
    ).map(({ data }) => {
      return data.page?.notificationMessagesTopics;
    }),
);
