import gql from 'graphql-tag';
import { AI_INTENT_FRAGMENT } from '../../common/queries';

export const AI_DEFAULT_ANSWER_FRAGMENT = gql`
  fragment aiDefaultAnswerFragment on Bot {
    aiBlock {
      id
      cards {
        id
        ... on AIPlugin {
          config {
            default_answer_intent {
              ...aiIntentFragment
            }
            default_answer_delay
          }
        }
      }
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const AI_DEFAULT_ANSWER_QUERY = gql`
  query AiDefaultAnswerQuery($botId: String) {
    bot(id: $botId) {
      id
      ...aiDefaultAnswerFragment
    }
  }
  ${AI_DEFAULT_ANSWER_FRAGMENT}
`;

export const ADD_AI_DEFAULT_ANSWER_MUTATION = gql`
  mutation UpdateAiDefaultAnswerMutation(
    $botId: String!
    $cardId: String!
    $defaultIntent: AiIntentInput!
    $defaultAnswerDelay: Int!
  ) {
    updateDefaultAnswerIntent(
      botId: $botId
      cardId: $cardId
      defaultIntent: $defaultIntent
      defaultAnswerDelay: $defaultAnswerDelay
    ) {
      id
      ... on AIPlugin {
        config {
          default_answer_intent {
            ...aiIntentFragment
          }
          default_answer_delay
        }
      }
    }
  }
  ${AI_INTENT_FRAGMENT}
`;
