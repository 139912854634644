import React, { useContext } from 'react';
import noop from 'lodash-es/noop';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import { clone } from 'ramda';
import { useToaster } from '@ui/Toaster';
import { getRequestIdFromApolloError, removeTypename } from '@utils/GQL/utils';
import {
  LIVECHAT_SETTINGS_QUERY,
  SET_LIVECHAT_CONFIG_MUTATION,
} from './queries';
import {
  LivechatSettingsQuery,
  LivechatSettingsQueryVariables,
  LivechatSettingsQuery_bot_livechatSettings as LivechatSettings,
  LivechatSettingsQuery_bot_livechatSettings_config as LivechatConfig,
} from './@types/LivechatSettingsQuery';
import {
  SetLivechatConfigMutation,
  SetLivechatConfigMutationVariables,
  SetLivechatConfigMutation_setLivechatConfig as LivechatConfigPayload,
} from './@types/SetLivechatConfigMutation';
import { useLivechatSettings } from './useLivechatSettings';

interface LivechatSettingsContextType {
  livechatSettings: LivechatSettings | null;
  loading: boolean;
  updating: boolean;
  setConfig(config: Partial<LivechatConfig>): void;
  setLocalizationKey(key: string, value: string): void;
}

const LivechatSettingsContext =
  React.createContext<LivechatSettingsContextType>({
    livechatSettings: null,
    loading: false,
    updating: false,
    setConfig: noop,
    setLocalizationKey: noop,
  });

export const LivechatSettingsContextProvider: React.FC<{ botId: string }> = ({
  botId,
  children,
}) => {
  const { addToaster } = useToaster();

  const { livechatSettings, loading } = useLivechatSettings(botId);

  const [setLivechatConfig, { loading: updating }] = useMutation<
    SetLivechatConfigMutation,
    SetLivechatConfigMutationVariables
  >(SET_LIVECHAT_CONFIG_MUTATION, {
    onError(error) {
      log.error({
        error,
        msg: 'Error while updating livechat config',
        data: {
          botId,
          requestId: getRequestIdFromApolloError(error),
        },
      });
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            Error while updating livechat config
          </div>
        ),
        timeout: 2000,
        closeButton: true,
      });
    },
  });

  const updateSettings = (updatedConfig: Partial<LivechatSettings>) => {
    const { config, localization } = livechatSettings ?? {};
    setLivechatConfig({
      variables: {
        botId,
        config: removeTypename(updatedConfig.config ?? config ?? {}),
        localization: removeTypename(
          updatedConfig.localization ?? localization ?? [],
        ),
      },
      optimisticResponse: {
        setLivechatConfig: {
          config,
          localization,
          ...updatedConfig,
          __typename: 'LivechatConfigPayload',
        } as LivechatConfigPayload,
      },
      update(cache, { data, errors }) {
        if (errors) {
          return;
        }
        const result = cache.readQuery<
          LivechatSettingsQuery,
          LivechatSettingsQueryVariables
        >({
          query: LIVECHAT_SETTINGS_QUERY,
          variables: { botId },
        });

        if (result?.bot.livechatSettings) {
          const newData = clone({
            bot: {
              __typename: 'Bot' as const,
              id: botId,
              livechatSettings: {
                __typename: 'LivechatSettings' as const,
                config: {
                  ...result.bot.livechatSettings.config,
                  ...data?.setLivechatConfig.config,
                  __typename: 'FlowBuilderLiveChatPluginConfig' as const,
                },
                localization: data?.setLivechatConfig.localization ?? [],
                has_livechat_plugins:
                  result.bot.livechatSettings.has_livechat_plugins,
              },
            },
          });
          cache.writeQuery<
            LivechatSettingsQuery,
            LivechatSettingsQueryVariables
          >({
            query: LIVECHAT_SETTINGS_QUERY,
            variables: { botId },
            data: newData,
          });
        }
      },
    });
  };

  const setConfig = (updatedConfig: Partial<LivechatConfig>) => {
    const config: LivechatConfig = {
      __typename: 'FlowBuilderLiveChatPluginConfig' as const,
      button_text:
        updatedConfig.button_text ??
        livechatSettings?.config.button_text ??
        null,
      image_url:
        updatedConfig.image_url ?? livechatSettings?.config.image_url ?? null,
      stop_message:
        updatedConfig.stop_message ??
        livechatSettings?.config.stop_message ??
        null,
      subtitle:
        updatedConfig.subtitle ?? livechatSettings?.config.subtitle ?? null,
      timeout_config:
        updatedConfig.timeout_config ??
        livechatSettings?.config.timeout_config ??
        null,
      title: updatedConfig.title ?? livechatSettings?.config.title ?? null,
      dynamic_menu_title:
        updatedConfig.dynamic_menu_title ??
        livechatSettings?.config.dynamic_menu_title ??
        null,
    };
    updateSettings({ config });
  };

  const setLocalizationKey = (key: string, value: string) => {
    const localization = clone(livechatSettings?.localization ?? []);
    const valueToUpdate = localization.find((v) => v.key === key);
    if (valueToUpdate) {
      valueToUpdate.value = { __typename: 'LocalizationValue', default: value };
    } else {
      localization.push({
        __typename: 'Localization',
        key,
        value: { __typename: 'LocalizationValue', default: value },
      });
    }
    updateSettings({
      localization,
    });
  };

  return (
    <LivechatSettingsContext.Provider
      value={{
        livechatSettings,
        loading,
        updating,
        setConfig,
        setLocalizationKey,
      }}
    >
      {children}
    </LivechatSettingsContext.Provider>
  );
};

export const useLivechatSettingsContext = () =>
  useContext(LivechatSettingsContext);
