/**
 * The structure of config:
 * Level 1: Constants
 * Level 2: Minimal default config to work (e.g. logging)
 * Level 3: Config from server
 * Level 4: Local overrides
 */
import { LSKey, LS } from 'cf-common/src/localStorage';
import { EnvQuery_env } from './AuthFlow/@types/EnvQuery';
import { resolveGlobalConfigReady } from './globalConfigAsync';

export const CONSTANTS_CONFIG = {
  FB_SDK_VERSION: 'v12.0',
  // @ts-ignore
  CONFIG_BUILD_DATE: CONFIG_BUILD_DATE_STUB, // from webpack
  YA_METRIKA_ID: '38620685',
  FB_SDK_SRC: '//connect.facebook.net/en_US/sdk/xfbml.customerchat.js',
};

const MINIMAL_DEFAULT_CONFIG_TO_WORK = {
  LOG_LEVEL_SEND: 'info',
  LOG_LEVEL_CONSOLE: 'verbose',
};

const getLocalConfigOverrides = () => {
  const config: LocalConfig = {};

  const logLevelConsole = LS.getRaw(LSKey.LOG_LEVEL_CONSOLE);
  if (logLevelConsole) {
    config.LOG_LEVEL_CONSOLE = logLevelConsole;
  }

  const cfEnv = LS.getRaw(LSKey.CF_ENV);
  if (cfEnv) {
    config.CF_ENV = cfEnv;
  }

  const wlDomainMock = LS.getRaw(LSKey.WL_DOMAIN_MOCK);
  if (wlDomainMock) {
    config.WL_DOMAIN_MOCK = wlDomainMock;
  }

  const landingDomainMock = LS.getRaw(LSKey.LANDING_DOMAIN_MOCK);
  if (landingDomainMock) {
    config.LANDING_DOMAIN_MOCK = landingDomainMock;
  }

  return config;
};

window.CHATFUEL_CONFIG = {
  ...CONSTANTS_CONFIG,
  ...MINIMAL_DEFAULT_CONFIG_TO_WORK,
  ...getLocalConfigOverrides(),
};

export const setGlobalConfig = (config: EnvQuery_env) => {
  window.CHATFUEL_CONFIG = {
    ...CONSTANTS_CONFIG,
    ...MINIMAL_DEFAULT_CONFIG_TO_WORK,
    ...config,
    ...getLocalConfigOverrides(),
  };

  const { LOG_LEVEL_SEND, LOG_LEVEL_CONSOLE } = window.CHATFUEL_CONFIG;

  if (!LOG_LEVEL_SEND) {
    // eslint-disable-next-line no-console
    console.log("[CONFIG] Logs won't be sent to the server");
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `[CONFIG] Log level for sending: ${LOG_LEVEL_SEND}. All logs that are lower than this level won't be sent`,
    );
  }

  if (!LOG_LEVEL_CONSOLE) {
    // eslint-disable-next-line no-console
    console.log("[CONFIG] Logs won't be written to the console");
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `[CONFIG] Log level for console: ${LOG_LEVEL_CONSOLE}. All logs that are lower than this level won't be written`,
    );
  }

  resolveGlobalConfigReady(window.CHATFUEL_CONFIG);
};
