import moment from 'moment';

export default class PopupDatepickerController {
  constructor($element, $scope) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;

    this.dateOptions = {
      maxDate: new Date(),
      minDate: null,
      showWeeks: false,
    };
  }

  $onChanges(changesObj) {
    if (changesObj.value) {
      if (changesObj.value.currentValue) {
        this.date = moment(changesObj.value.currentValue).toDate();
      } else {
        this.date = undefined;
      }
    }
  }

  _onFocus($event) {
    this.datepickerOpened = true;
    this.onFocus({ $event });
  }

  _onDateChange($event) {
    if (this.date) {
      this.onChange({
        $value: this.date.valueOf(),
        $dateString: moment(this.date).format('YYYY-MM-DD'),
        $event,
      });
    }
  }
}
