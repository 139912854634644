import gql from 'graphql-tag';

export const JUST_SENT_LIVECHAT_MESSAGES_QUERY = gql`
  query JustSentLivechatMessagesQuery {
    justSentLivechatMessageIds @client
  }
`;

export const JUST_SENT_LIVECHAT_MESSAGES_ADD_MUTATION = gql`
  mutation JustSentLivechatMessagesAddMutation($messageId: String!) {
    justSentLivechatMessageAdd(messageId: $messageId) @client
  }
`;

export const JUST_SENT_LIVECHAT_MESSAGES_REMOVE_MUTATION = gql`
  mutation JustSentLivechatMessagesRemoveMutation($messageId: String!) {
    justSentLivechatMessageRemove(messageId: $messageId) @client
  }
`;
