import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import client from '@common/services/ApolloService';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';
import { ALL_BLOCKS_TITLES_QUERY } from './queries';
import { AllBlocksTitlesQuery, AllBlocksTitlesQueryVariables } from './@types/AllBlocksTitlesQuery';

export const getBlocksTitlesObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery<AllBlocksTitlesQuery, AllBlocksTitlesQueryVariables>({
      query: ALL_BLOCKS_TITLES_QUERY,
      variables: {
        botId,
      },
    }) as ObservableInput<ApolloQueryResult<AllBlocksTitlesQuery>>,
  ).map(({ data }) => ({
    blocks: data?.bot.archiveBlocks,
  }))
);
