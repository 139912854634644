import { Recorder } from 'vmsg';
// @ts-expect-error
import wasmURL from 'vmsg/vmsg.wasm';

export class Mp3Recorder {
  private recordResult: Promise<Blob> | undefined;
  private micRecorder: Recorder;

  constructor() {
    this.micRecorder = new Recorder({ wasmURL });
  }

  async start() {
    await this.micRecorder.init();
    this.micRecorder.startRecording();
  }

  stop() {
    // проверяем наличие открытого потока и если надо завершаем
    // @ts-expect-error
    if (this.micRecorder.stream) {
      this.recordResult = this.micRecorder.stopRecording();
    }
    return this.recordResult;
  }

  getBlob() {
    return this.recordResult || Promise.reject();
  }

  async close() {
    try {
      /**
       *  В библиотеке не корректно закрывался аудио контекст.
       *  Это приводило к ошибке и блокировке закрытия воркера.
       *  Делаем это сами в нужный момент.
       */
      // @ts-expect-error
      await this.micRecorder.audioCtx.close();
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      // @ts-expect-error
      this.micRecorder.audioCtx = null;
      this.micRecorder.close();
    }
  }
}
