import { useQuery } from 'react-apollo';
import {
  BotPromoCodeQuery,
  BotPromoCodeQueryVariables,
} from './@types/BotPromoCodeQuery';
import { BOT_PROMO_CODE_QUERY } from './queries';

export const useBotPromoCodeQuery = (botId: string) =>
  useQuery<BotPromoCodeQuery, BotPromoCodeQueryVariables>(
    BOT_PROMO_CODE_QUERY,
    { variables: { botId }, skip: !botId },
  );
