import { aiIntentFragment as AiIntent } from '../common/@types/aiIntentFragment';
import { UpdatePredefinedIntentArgs } from './types';

export enum ActionField {
  action = 'action',
  igAction = 'ig_action',
  waAction = 'wa_action',
}

interface PrepareUpdatedAiDefaultAnswerIntentArgs
  extends UpdatePredefinedIntentArgs {
  currentIntent: AiIntent;
}

export const preparePredefinedIntent = ({
  currentIntent,
  blocks,
  text,
  type,
  actionField,
}: PrepareUpdatedAiDefaultAnswerIntentArgs): AiIntent => ({
  ...currentIntent,
  [actionField]: {
    __typename: 'AiIntentAction',
    random: false,
    items: [
      {
        __typename: 'AiIntentActionItem',
        item_type: type,
        blocks,
        text,
      },
    ],
  },
});
