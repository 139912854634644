import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { getRelativeUrl } from '@utils/UrlUtils/getRelativeUrl';
import { isInviteUrl } from '@utils/AuthFlow/isInviteUrl';
import { any, equals, once } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Query } from '@apollo/react-components';
import { acceptGdpr, getClid } from 'cf-common/src/analytics';
import { registrationEvent } from 'cf-common/src/conversionTracking';
import { Level, log } from 'cf-common/src/logger';
import { locationToUrl } from 'cf-common/src/utils/URL/locationToUrl';
import { QuickAccessToolbar } from '@components/QuickAccessToolbar';
import { ToasterProvider } from '@ui/Toaster';
import { GlobalLoader } from '@components/GlobalLoader/GlobalLoader';
import { initFbSdkEnvAppId } from '@components/FBPlugins/initFbSdkEnvAppId';
import { AngularApp } from '../../AngularApp';
import { BotListPage } from '@pages/BotListPage';
import { Oops } from '@pages/Oops';
import { YandexMetrikaConfirmPage } from '@pages/BotPage/AnalyzeTab';
import { useOptinMonster } from '../OptinMonster/useOptinMonster';
import { onReadyEnvironment } from '../Environment';
import { setLoginCookie } from '../loginCookie';
import { useSetCurrentGlobalBotId } from '../Routing';
import { React2AngularRouter } from '../AngularRouting';
import { initCurrentUserVarsInGTM } from '../initCurrentUserVarsInGTM';
import { isWhiteLabelDomain } from '../WhiteLabelUtils';
import { BotPage } from '@pages/BotPage';
import { setUserId } from '../UserId';
import { setGlobalConfig } from '../config';
import { sendClientIdViaYaMetrika, sendEvent } from '../Analytics';
import {
  ACCEPT_TERMS_MUTATION,
  ENV_QUERY,
  INIT_QUERY,
  LOCAL_DATA_QUERY,
  LOCAL_SUCCESS_AUTH_MUTATION,
} from './queries';
import { LocalDataQuery } from './@types/LocalDataQuery';
import { AcceptTermsMutation } from './@types/AcceptTermsMutation';
import { InitQuery } from './@types/InitQuery';
import { EnvQuery } from './@types/EnvQuery';
import { useBlockedRedirects } from './hooks/useBlockedRedirects';
import { OriginalAccountType } from '@globals';
import { removeAuthToken } from '@pages/MultiSystemAuth/utils/removeAuthToken';
import { isUserUnderSanctions } from '../sanctions';
import { isAutotestLogin, isSyntheticLogin } from '../syntheticLogin';
import { showRedirectToLiveChatModal } from '@utils/onBeforeBotPageRender';

export interface InitAuthProps extends RouteComponentProps {
  client: ApolloClient<InMemoryCache>;
}

export const MainBotPageRedirect: React.FC<InitAuthProps> = (props) => {
  useSetCurrentGlobalBotId();
  useOptinMonster();
  const { skipRendering } = useBlockedRedirects();

  useEffect(() => {
    showRedirectToLiveChatModal();
  }, []);

  return skipRendering ? (
    <GlobalLoader />
  ) : (
    <>
      <AngularApp />
      <ToasterProvider>
        <Switch>
          <Route path="/bots/spinner" exact>
            <YandexMetrikaConfirmPage />
          </Route>
          <Route path="/bots">
            <BotListPage />
          </Route>
          <Route path="/bot/:botId">
            <BotPage {...props} />
          </Route>
        </Switch>
      </ToasterProvider>
      <QuickAccessToolbar />
      <React2AngularRouter />
    </>
  );
};

interface InitAuthRouteProps extends RouteProps {
  onAuthNeededRenderer?: (
    reason: string,
    interruptedHref: string,
    search: string,
  ) => JSX.Element;
  onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer?: (
    reason: string,
  ) => JSX.Element;
  onPermissionNeededRenderer?: (
    reason: string,
    interruptedHref: string,
  ) => JSX.Element;
  beforeRenderRoute?: () => void;
  onNetworkErrorRenderer?: (reason: string, error: string) => JSX.Element;
  onUnexpectedErrorRenderer?: (reason: string, error: string) => JSX.Element;
  needSkipInitQuery?: boolean;
}

interface InitAuthRouteState {
  isInitNoToken: boolean | undefined;
}

export const getRedirectUrl = () => `${window.location.origin}/auth`;

const getUnexpectedErrorsText = (error: ApolloError | undefined) => {
  const gqlErrors = error?.graphQLErrors;

  const hasUnexpectedErrors =
    Array.isArray(gqlErrors) &&
    any((gqlError) => {
      const code = gqlError?.extensions?.code;

      return !equals(code, 'UNAUTHENTICATED');
    }, gqlErrors);

  if (hasUnexpectedErrors && gqlErrors) {
    return Array.isArray(gqlErrors)
      ? gqlErrors.map((error) => JSON.stringify(error)).toString()
      : // @ts-ignore
        gqlErrors.toString();
  }

  return undefined;
};

const getNetworkErrorText = (error: ApolloError | undefined) => {
  return error?.networkError?.toString();
};

const RedirectToLoginPage: React.FC<{
  reason: string;
  interruptedHref: string;
  search: string;
}> = ({ reason, interruptedHref, search }) => {
  useEffect(() => {
    removeAuthToken();
  }, []);
  return (
    <Redirect
      to={{
        pathname: isWhiteLabelDomain() ? '/login-page' : '/sign-in',
        search,
        state: {
          interruptedHref,
          reason,
        },
      }}
    />
  );
};

const RedirectToSignUpPage: React.FC<{
  reason: string;
  interruptedHref: string;
  search: string;
}> = ({ reason, interruptedHref, search }) => {
  useEffect(() => {
    removeAuthToken();
  }, []);
  return (
    <Redirect
      to={{
        pathname: isWhiteLabelDomain() ? '/login-page' : '/sign-up',
        search,
        state: {
          interruptedHref,
          reason,
        },
      }}
    />
  );
};

const RedirectToAutoLoginPage: React.FC<{
  reason: string;
  interruptedHref: string;
  search: string;
}> = ({ reason, interruptedHref, search }) => {
  useEffect(() => {
    removeAuthToken();
  }, []);
  return (
    <Redirect
      to={{
        pathname: '/sign-in',
        search,
        state: {
          interruptedHref,
          reason,
        },
      }}
    />
  );
};

export const redirectToLoginPageRenderer = (
  reason: string,
  interruptedHref: string,
  search: string,
) => (
  <RedirectToLoginPage
    reason={reason}
    interruptedHref={interruptedHref}
    search={search}
  />
);

export const redirectToSignUpPageRenderer = (
  reason: string,
  interruptedHref: string,
  search: string,
) => (
  <RedirectToSignUpPage
    reason={reason}
    interruptedHref={interruptedHref}
    search={search}
  />
);

export const redirectToAutoLoginPageRenderer = (
  reason: string,
  interruptedHref: string,
  search: string,
) => (
  <RedirectToAutoLoginPage
    reason={reason}
    interruptedHref={interruptedHref}
    search={search}
  />
);

export const renderOopsPage = (reason: string, error: string) => {
  return <Oops reason={reason} errorText={error} />;
};

export const redirectToPermissionPage = (
  reason: string,
  interruptedHref: string,
) => {
  return (
    <Redirect
      to={{
        pathname: '/permissions',
        state: {
          interruptedHref,
          reason,
        },
      }}
    />
  );
};

export const redirectToMainPage = (reason: string) => {
  log({
    msg: `Redirect to main page due to ${reason}`,
    level: Level.warn,
  });

  return <Redirect to="/" />;
};

const AcceptTerms: React.FC = () => {
  // mutation confirm terms and update user's terms_accepted state in GQL cache
  const [acceptTermsMutation, { error }] = useMutation<AcceptTermsMutation>(
    ACCEPT_TERMS_MUTATION,
  );
  useEffect(() => {
    acceptTermsMutation();
  }, [acceptTermsMutation]);

  if (error) {
    return (
      <Oops
        reason="Error Terms of Service accepting"
        errorText={error.message}
      />
    );
  }
  return <GlobalLoader />;
};

interface SuccessAuth {
  networkErrorText?: string;
  unexpectedErrorsText?: string;
  hasAuth: boolean;
  constructorUserId: boolean;
  client: ApolloClient<InMemoryCache>;
  userId: string;
  isNewUser: boolean;
  isInvitedUser: boolean;
  abt: any;
  inviteLink?: string;
}

const handleSuccessAuth = async ({
  networkErrorText,
  unexpectedErrorsText,
  hasAuth,
  constructorUserId,
  client,
  isInvitedUser,
  isNewUser,
  abt,
  userId,
  inviteLink,
}: SuccessAuth) => {
  if (userId) {
    setUserId(userId);
    sendEvent(
      {
        category: 'auth',
        action: 'id-passed',
      },
      true,
    );
  }

  if (hasAuth) {
    sendEvent(
      `${window.location.protocol}//${window.location.hostname}/#facebook_app_id/${constructorUserId}`,
    );
    log({ msg: 'Successfully authorized', level: Level.info });
    const landingVersion =
      // eslint-disable-next-line no-underscore-dangle
      window.__cf_analytics?.getCookie?.('landing_ab_test') || null;
    sendEvent(
      {
        category: 'auth',
        action: 'success',
        label: isNewUser ? 'as new user' : 'as old user',
        propertyBag: {
          isInvitedUser,
          abt,
          landingVersion,
          inviteLink,
        },
      },
      true,
    );
    window.ga?.('send', {
      hitType: 'event',
      eventCategory: 'auth',
      eventAction: 'success',
      eventLabel: isNewUser ? 'as new user' : 'as old user',
    });
    if (isNewUser) {
      window.lintrk?.('track', { conversion_id: 15774282 });
      sendEvent(
        {
          event: 'new_user-registration',
          isInvitedUser,
          clid: getClid(),
          userId,
        },
        true,
      );
      registrationEvent(userId);
    }
  } else {
    log({
      msg: 'NOT successfully authorized',
      level: Level.warn,
      data: {
        hasAuth,
        hasNetworkError: !!networkErrorText,
        hasUnexpectedErrors: !!unexpectedErrorsText,
      },
    });
  }

  acceptGdpr(true);

  await client.mutate({
    mutation: LOCAL_SUCCESS_AUTH_MUTATION,
    refetchQueries: [{ query: LOCAL_DATA_QUERY }],
    awaitRefetchQueries: true,
  });
};

const handleSuccessAuthOnce = once(handleSuccessAuth);

export const getInterruptedHref = ({
  location: { state, search },
}: Pick<RouteComponentProps, 'location'>) => {
  const searchParams = new URLSearchParams(search);
  const interruptedHrefFromGetParams = searchParams.get('interruptedHref');

  if (interruptedHrefFromGetParams) {
    return interruptedHrefFromGetParams;
  }

  if (state?.interruptedHref) {
    return state.interruptedHref;
  }

  return undefined;
};

export const redirectToLoginPageRendererFromOldLoginPath = (
  props: RouteComponentProps,
) =>
  redirectToLoginPageRenderer(
    'old login path',
    getInterruptedHref(props),
    props.location.search,
  );

export const redirectToSignUpPageRendererFromOldLoginPath = (
  props: RouteComponentProps,
) =>
  redirectToSignUpPageRenderer(
    'old login path',
    getInterruptedHref(props),
    props.location.search,
  );

export const getReason = ({ location: { state } }: RouteComponentProps) => {
  return state ? state.reason : undefined;
};

const INIT_QUERY_TIMEOUT = 30 * 1000;

export class InitAuthRoute extends React.Component<
  InitAuthRouteProps,
  InitAuthRouteState
> {
  static defaultProps: Partial<InitAuthRouteProps> = {
    onNetworkErrorRenderer: renderOopsPage,
    onUnexpectedErrorRenderer: renderOopsPage,
  };

  state = {
    isInitNoToken: undefined,
  };

  tryGetInviteLink() {
    const { location } = this.props;
    if (!location) return undefined;
    const interruptedHref =
      getInterruptedHref({ location }) ?? locationToUrl(location);

    return isInviteUrl(getRelativeUrl(interruptedHref))
      ? interruptedHref
      : undefined;
  }

  render() {
    const {
      onAuthNeededRenderer,
      onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer,
      onPermissionNeededRenderer,
      beforeRenderRoute,
      onNetworkErrorRenderer,
      onUnexpectedErrorRenderer,
      needSkipInitQuery,
      ...rest
    } = this.props;
    const { href: interruptedHref, search } = window.location;

    return (
      <Query<LocalDataQuery>
        query={LOCAL_DATA_QUERY}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        onCompleted={({ token }) => {
          if (this.state.isInitNoToken === undefined) {
            this.setState({ isInitNoToken: !token });
          }
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <GlobalLoader />;
          }

          if (error && onUnexpectedErrorRenderer) {
            return onUnexpectedErrorRenderer(
              'Unexpected errors in LOCAL_DATA_QUERY',
              error.toString(),
            );
          }

          const token = data?.token;
          const successAuth = data?.successAuth;

          if (!token && onAuthNeededRenderer) {
            return onAuthNeededRenderer('Empty token', interruptedHref, search);
          }

          if (
            token &&
            onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer &&
            needSkipInitQuery &&
            this.state.isInitNoToken === false
          ) {
            return onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer(
              'Auth is valid on the page where the user must be unauthorized!!!!!!!!',
            );
          }

          return (
            <Query<EnvQuery>
              query={ENV_QUERY}
              context={{ timeout: INIT_QUERY_TIMEOUT }}
              onCompleted={(envData) => {
                const env = envData?.env;
                if (env) {
                  setGlobalConfig(env);
                  onReadyEnvironment();
                  initFbSdkEnvAppId();
                }
              }}
            >
              {({ loading: envLoading, error: envError }) => (
                <Query<InitQuery>
                  query={INIT_QUERY}
                  errorPolicy="all"
                  context={{ timeout: INIT_QUERY_TIMEOUT }}
                  skip={!token || needSkipInitQuery}
                  onCompleted={(data) => {
                    const userId = data?.me?.id;
                    if (userId) {
                      setUserId(userId);
                      sendEvent(
                        {
                          category: 'auth',
                          action: 'id-passed',
                        },
                        true,
                      );
                      sendClientIdViaYaMetrika();
                      initCurrentUserVarsInGTM();
                    }
                  }}
                >
                  {({ data, loading, error, client }) => {
                    if (loading || envLoading) {
                      return <GlobalLoader />;
                    }

                    const networkErrorText = getNetworkErrorText(error);
                    const unexpectedErrorsText = getUnexpectedErrorsText(error);
                    const hasAuth = !!data?.me.id;
                    const hasMinimalPermissions =
                      !!data?.me.fbPermissions?.hasMinimalPermissions;
                    const isInvitedUser = !!data?.me.invited;
                    const isTermsAccepted = data?.me.terms_accepted;
                    const originalAccountType = data?.me.original_account_type;
                    const isFacebookAccountConnected =
                      data?.me.facebook_account_connected;

                    if (hasAuth) {
                      setLoginCookie();
                    }

                    if (
                      isUserUnderSanctions(data?.me?.sanction_status?.status)
                    ) {
                      return <Redirect to="/restricted-user" />;
                    }

                    if (successAuth && !needSkipInitQuery) {
                      const constructorUserId = data?.me.constructor_user_id;
                      const userId = data?.me.id;

                      handleSuccessAuthOnce({
                        networkErrorText,
                        unexpectedErrorsText,
                        hasAuth,
                        constructorUserId,
                        client,
                        isInvitedUser,
                        isNewUser: !isTermsAccepted,
                        abt: data?.me.features,
                        userId,
                        inviteLink: this.tryGetInviteLink(),
                      });
                    }

                    if (envError && onUnexpectedErrorRenderer) {
                      return onUnexpectedErrorRenderer(
                        'Error in ENV_QUERY',
                        envError.toString(),
                      );
                    }

                    if (unexpectedErrorsText && onUnexpectedErrorRenderer) {
                      return onUnexpectedErrorRenderer(
                        'Unexpected errors in INIT_QUERY',
                        unexpectedErrorsText,
                      );
                    }

                    if (networkErrorText && onNetworkErrorRenderer) {
                      return onNetworkErrorRenderer(
                        'Network error in INIT_QUERY',
                        networkErrorText,
                      );
                    }

                    if (!hasAuth && onAuthNeededRenderer) {
                      return onAuthNeededRenderer(
                        'Got unauthenticated',
                        interruptedHref,
                        search,
                      );
                    }

                    if (hasAuth && !isTermsAccepted) {
                      return <AcceptTerms />;
                    }

                    if (
                      hasAuth &&
                      onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer &&
                      !needSkipInitQuery
                    ) {
                      return onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer(
                        'Auth is valid on the page where the user must be unauthorized',
                      );
                    }

                    const hidePermissionWindow =
                      !isAutotestLogin() && isSyntheticLogin();
                    if (
                      onPermissionNeededRenderer &&
                      hasAuth &&
                      !hasMinimalPermissions &&
                      !hidePermissionWindow &&
                      (originalAccountType === OriginalAccountType.facebook ||
                        isFacebookAccountConnected)
                    ) {
                      return onPermissionNeededRenderer(
                        'User does not have minimal permissions',
                        interruptedHref,
                      );
                    }

                    if (beforeRenderRoute) {
                      beforeRenderRoute();
                    }

                    return <Route {...rest} />;
                  }}
                </Query>
              )}
            </Query>
          );
        }}
      </Query>
    );
  }
}
