export enum VariablesType {
  custom = 'custom',
  segment = 'segment',
  sequence = 'sequence',
  system = 'system',
}

export enum VariableSuggestType {
  omnibox = 'omnibox',
  template = 'template',
  abandoned_cart_template = 'abandoned_cart_template',
}
