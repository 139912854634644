import React, { useLayoutEffect, useMemo, useState } from 'react';
import noop from 'lodash-es/noop';
import throttle from 'lodash-es/throttle';

interface WindowSize {
  width: number;
  height: number;
}

export const getWindowSize = (): WindowSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const WindowSizeContext = React.createContext({
  ...getWindowSize(),
  setValue: noop,
});

export const WindowSizeProvider: React.FC = ({ children }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const providerValue = useMemo(
    () => ({
      ...windowSize,
      setValue: (
        value:
          | WindowSize
          | Pick<WindowSize, 'height'>
          | Pick<WindowSize, 'width'>,
      ) => {
        if (
          ('height' in value && value.height !== windowSize.height) ||
          ('width' in value && value.width !== windowSize.width)
        )
          setWindowSize({
            height: 'height' in value ? value.height : windowSize.height,
            width: 'width' in value ? value.width : windowSize.width,
          });
      },
    }),
    [windowSize],
  );

  useLayoutEffect(() => {
    const handleResize = throttle(
      () => {
        setWindowSize(getWindowSize());
      },
      500,
      { leading: false },
    );

    const observer = new ResizeObserver(handleResize);
    const root = document.querySelector('#react-root');
    if (root) observer.observe(root);

    return () => {
      if (root) observer.disconnect();
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={providerValue}>
      {children}
    </WindowSizeContext.Provider>
  );
};
