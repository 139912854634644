import { useAdminFeatures } from '@utils/Data/Admin';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useMemo } from 'react';
import { calculateAutomateEnabled } from './utils';

export const useAutomateEnabled = () => {
  const { adminFeaturesLoading, adminFeatures } = useAdminFeatures();
  const { data, loading: isWhatsappEnabledLoading } = useWhatsappEnabled();

  const isAutomateEnabled = useMemo(() => {
    if (adminFeaturesLoading || isWhatsappEnabledLoading) {
      return false;
    }

    return calculateAutomateEnabled({
      bot: data?.bot,
      adminFeatures,
    });
  }, [adminFeatures, adminFeaturesLoading, data, isWhatsappEnabledLoading]);

  return { isAutomateEnabled };
};
