import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import client from '@common/services/ApolloService';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';
import { STRIPE_STATUS_QUERY } from './common/queries';
import { StripeStatusQuery, StripeStatusQueryVariables } from './common/@types/StripeStatusQuery';

export const getStripeAccountObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery<StripeStatusQuery, StripeStatusQueryVariables>({
      query: STRIPE_STATUS_QUERY,
      variables: {
        botId,
      },
    }) as ObservableInput<ApolloQueryResult<StripeStatusQuery>>,
  ).map(({ data }) => {
    return data.bot.payments_stripe;
  }),
);
