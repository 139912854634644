export enum ServerStorageItemKeys {
  jsonPluginHelpTooltipShowCount = 'json_plugin_help_tooltip_show_count',
  /** @deprecated leads qualification was deleted */
  leadsQualificationData = 'leadsQualificationData',
  countOfOneBlockSelectorPopup = 'countOfOneBlockSelectorPopup',
  countOfSkillAddedPopup = 'countOfSkillAddedPopup',
  /** @deprecated facebook pages block redesigned */
  isClosedConnectingPageTipBar = 'isClosedConnectingPageTipBar',
  isOnboardingWithPendingPermissionsDone = 'isOnboardingWithPendingPermissionsDone',
  abandonedCartSetupHeader = 'abandonedCartSetupHeader',
  isReengageSponsoredMessageBannerDismissed = 'isReengageSponsoredMessageBannerDismissedNew',
  botListSortAndPagination = 'botListSortAndPagination',
  isHideCTMSetupHeadBanner = 'isHideCTMSetupHeadBanner',
  botsListBannerServerState = 'botsListBannerServerState_v2',
  clickToMessengerBotsListBannerCloseTimestamp = 'clickToMessengerBotsListBannerCloseTimestamp_v2',
  /** @deprecated optin monster is not currently using */
  emailCollectedFromOptinMonster = 'emailCollectedFromOptinMonster',
  isBotPageAsideVisible = 'isBotPageAsideVisible_',
  /** @deprecated grid view was deleted */
  botListViewMode = 'botListViewMode',
  proCancelAttempts = 'proCancelAttempts',
  /** @deprecated intercom emails are not currently using  */
  intercomEmails = 'intercomEmails',
  /** @deprecated experiment ended */
  automatedFAQStatus = 'automatedFAQStatus',
  facebookSegmentsIdsWithShownFbAlert = 'facebookSegmentsIdsWithShownFbAlert',
  isFacebookAudienceSyncBannerDismissed = 'isFacebookAudienceSyncBannerDismissed',
  /** @deprecated experiment ended */
  proCustomerSupport = 'proCustomerSupport',
  hasSharedFlowGroup = 'hasSharedFlowGroup',
  hasSharedFlow = 'hasSharedFlow',
  isClosedFBTutorial = 'isClosedFBTutorial',
  isClosedBasicFlowTutorial = 'isClosedBasicFlowTutorial',
  isClosedBasicInstagramFlowTutorial = 'isClosedBasicInstagramFlowTutorial',
  isShowBasicFlowTutorialVideo = 'isShowBasicFlowTutorialVideo_',
  isShowBasicInstagramFlowTutorialVideo = 'isShowBasicInstagramFlowTutorialVideo_',
  isClosedBotLinkFlowTutorial = 'isClosedBotLinkFlowTutorial',
  isClosedCustomerChatFlowTutorial = 'isClosedCustomerChatFlowTutorial',
  isClosedCommentsAutoreplyFlowTutorial = 'isClosedCommentsAutoreplyFlowTutorial',
  isClosedCtmAdsFlowTutorial = 'isClosedCtmAdsFlowTutorial',
  isClosedFacebookPageFlowTutorial = 'isClosedFacebookPageFlowTutorial',
  isClosedFacebookShopsFlowTutorial = 'isClosedFacebookShopsFlowTutorial',
  isClosedPersistentMenuFlowTutorial = 'isClosedPersistentMenuFlowTutorial',
  isClosedSendToMessengerFlowTutorial = 'isClosedSendToMessengerFlowTutorial',
  isClosedSponsoredMessagesFlowTutorial = 'isClosedSponsoredMessagesFlowTutorial',
  isClosedInstagramStoryMentionFlowTutorial = 'isClosedInstagramStoryMentionFlowTutorial',
  isClosedInstagramStoryReplyFlowTutorial = 'isClosedInstagramStoryReplyFlowTutorial',
  isClosedInstagramCommentsAutoreplyFlowTutorial = 'isClosedInstagramCommentsAutoreplyFlowTutorial',
  isClosedInstagramAdsFlowTutorial = 'isClosedInstagramAdsFlowTutorial',
  hasExportedFlow = 'hasExportedFlow',
  isCommentsAutoreplyTileHidden = 'isCommentsAutoreplyTileHidden',
  isBotLinkTileHidden = 'isBotLinkTileHidden',
  isCustomerChatTileHidden = 'isCustomerChatTileHidden',
  defaultTouchAIPhraseGroups = 'defaultTouchAIPhraseGroups',
  isBotHasRealAiPhrases = 'isBotHasRealAiPhrases',
  isClickToMessengerAdsTileHidden = 'isClickToMessengerAdsTileHidden',
  isInstagramDirectTileHidden = 'isInstagramDirectTileHidden',
  isSponsoredMessagesAdsTileHidden = 'isSponsoredMessagesAdsTileHidden',
  isBlockAdded = 'isBlockAdded',
  /** @deprecated extended via entryPointsAdded */
  isEntryPointAdded = 'isEntryPointAdded',
  isEntryPointEnabled = 'isEntryPointEnabled',
  isEntryPointManuallyDisabled = 'isEntryPointManuallyDisabled',
  isFlowTested = 'isFlowTested',
  isBasicTemplateAdded = 'isBasicTemplateAdded',
  isAdvancedTemplateAdded = 'isAdvancedTemplateAdded',
  isFacebookShopsTileHidden = 'isFacebookShopsTileHidden',
  isLiveChatNotificationSoundsDisabled = 'isLiveChatNotificationSoundsDisabled',
  isCustomerChatCardAvailableAtGrowTab = 'isCustomerChatCardAvailableAtGrowTab',
  isZapierTileHidden = 'isZapierTileHidden',
  entryPointsAdded = 'entryPointsAdded',
  isShopifyPopUpTileHidden = 'isShopifyPopUpTileHidden',
  livechatNotificationsPromoHidden = 'livechatNotificationsPromoHidden',
  livechatSendFlowPromoHidden = 'livechatSendFlowPromoHidden',
  flowWizardHidden = 'flowWizardHidden',
  isIntroQuestionsFinished = 'isIntroQuestionsFinished',
  instagramRolloutFlowWarningHidden = 'instagramRolloutFlowWarningHidden',
  defaultAnswerToAiDialogClosed = 'defaultAnswerToAiDialogClosed',
  defaultAnswerAiInitialized = 'defaultAnswerAiInitialized',
  seenFeaturePromoGalleries = 'seenFeaturesGalleries',
  enableFirstInstagramEntryPointTimestamp = 'enableFirstInstagramEntryPointTimestamp',
  enableFirstInstagramEntryPointFeedbackShown = 'enableFirstInstagramEntryPointFeedbackShown',
  introQuestionsStatus = 'introQuestionsStatus',
  isLivechatPluginNewConfigurationRulesNoteHidden = 'isLivechatPluginNewConfigurationRulesNoteHidden',
  /** @deprecated decided to remove */
  shopifyDiscountsPromoShown = 'shopifyDiscountsPromoShown',
  isTemplatesModalForNewUserShowed = 'isTemplatesModalNewUserShowed_1',
  annualPricingCalloutPayload = 'annualPricingCalloutPayload',
  firstSessionStep = 'firstSessionStep_',
  collapsibleWorkspaces = 'collapsibleWorkspaces',
  closeAdjustmentFeePanel = 'closeAdjustmentFeePanel_',
  broadcastsIQStep = 'broadcastsIQStep',
  templateCheckListId = 'templateCheckListId',
  miniOnboardingPopupClosed = 'miniOnboardingPopupClosed',
  referralProgramPocFormFilled = 'referralProgramPocFormFilled',
  firstSessionWAInitialSetup = 'firstSessionWAInitialSetup',
  firstWABotId = 'firstWABotId',
  createWhatsappBotWidgetHidden = 'createWhatsappBotWidgetHidden',
  onboardingOpenState = 'onboardingOpenState',
  copilotPromoBanner = 'copilotPromoBanner',
  hideSendForReviewModal = 'hideSendForReviewModal',
  customAiInfoCalloutHide = 'customAiInfoCalloutHide',
  customAiConnectorsCalloutHide = 'customAiConnectorsCalloutHide',
  admitAdSended = 'admitAdSended',
}
