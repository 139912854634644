export const getBase64FromImage = (
  img: HTMLImageElement,
  width?: number,
  height?: number,
) => {
  const canvas = document.createElement('canvas');
  const targetWidth = width || img.width;
  const targetHeight = height || img.height;
  canvas.width = targetWidth;
  canvas.height = targetHeight;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return null;
  }
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  return canvas.toDataURL('image/png');
};

export const GRAY_ONE_PIXEL_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/B8AAusB9FD6Pn0AAAAASUVORK5CYII=';

export const getBase64FromImageUrl = (
  url: string,
  width?: number,
  height?: number,
) =>
  new Promise<string>((resolve) => {
    const img = document.createElement('img');
    img.crossOrigin = 'anonymous';
    img.addEventListener('load', () => {
      resolve(getBase64FromImage(img, width, height) || GRAY_ONE_PIXEL_PNG);
    });
    img.addEventListener('error', () => {
      resolve(GRAY_ONE_PIXEL_PNG);
    });
    img.src = url;
  });
