import { BotTabs, getTabLink, useCurrentBotId } from '../../Routing';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateShopifySubscriptionMutation,
  CreateShopifySubscriptionMutationVariables,
} from './@types/CreateShopifySubscriptionMutation';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import useVisibilityChange from 'use-visibility-change';
import { useHistory } from 'react-router-dom';

const MIN_TIME_TO_CREATE_TIMEOUT = 1000;
const SHOPIFY_SUBSCRIPTION_CHECK_INTERVAL = 500;
const SHOPIFY_SUBSCRIPTION_CONFIRM_HASH = 'shopify-confirm';
const SHOPIFY_WAIT_TIMEOUT = 2000;

const CREATE_SHOPIFY_SUBSCRIPTION_MUTATION = gql`
  mutation CreateShopifySubscriptionMutation($botId: ID!) {
    createShopifySubscription(botId: $botId) {
      id
      connectedShop {
        id
        subscription {
          confirmationUrl
        }
      }
    }
  }
`;

let shopifyWindow: Window | null = null;

export const useCreateAndConfirmShopifySubscription = () => {
  const [isLeaveToCreateSubscription, setIsLeaveToCreateSubscription] =
    useState<boolean>(false);
  const botId = useCurrentBotId();
  const history = useHistory();

  useVisibilityChange({
    onShow: ({ lastSeenDate }) => {
      if (!isLeaveToCreateSubscription) {
        return;
      }
      setIsLeaveToCreateSubscription(false);
      shopifyWindow?.close();
      const creatingPageTime = Date.now() - lastSeenDate;
      if (creatingPageTime > MIN_TIME_TO_CREATE_TIMEOUT) {
        history.push(getTabLink(BotTabs.billing, botId));
      }
    },
  });
  const [createShopifySubscriptionMutation, queryState] = useMutation<
    CreateShopifySubscriptionMutation,
    CreateShopifySubscriptionMutationVariables
  >(CREATE_SHOPIFY_SUBSCRIPTION_MUTATION);

  const createAndConfirmShopifySubscription = useCallback(async () => {
    if (!botId) {
      return;
    }
    shopifyWindow = window.open('about:blank', '_blank');
    const errorHandler = () => {
      showSomethingWentWrongToaster();
      shopifyWindow?.close();
      shopifyWindow = null;
    };

    try {
      const { data } = await createShopifySubscriptionMutation({
        variables: { botId },
      });
      const url =
        data?.createShopifySubscription.connectedShop?.subscription
          ?.confirmationUrl;

      if (url && shopifyWindow) {
        setIsLeaveToCreateSubscription(true);
        shopifyWindow.location.href = url;
      } else {
        errorHandler();
      }
    } catch {
      errorHandler();
    }
  }, [botId, createShopifySubscriptionMutation]);

  useEffect(() => {
    if (!isLeaveToCreateSubscription || !shopifyWindow) {
      return undefined;
    }
    const interval = window.setInterval(() => {
      if (!shopifyWindow || shopifyWindow.closed) {
        shopifyWindow = null;
        window.clearInterval(interval);
        return;
      }
      try {
        const [hash] = shopifyWindow.location.hash.replace('#', '').split('?');
        if (hash.trim().toLowerCase() === SHOPIFY_SUBSCRIPTION_CONFIRM_HASH) {
          // ждем что бы шопифай точно дернул наш хук о том что подписка прошла
          window.setTimeout(() => {
            shopifyWindow?.close();
            shopifyWindow = null;
          }, SHOPIFY_WAIT_TIMEOUT);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }, SHOPIFY_SUBSCRIPTION_CHECK_INTERVAL);

    return () => {
      window.clearInterval(interval);
    };
  }, [isLeaveToCreateSubscription]);

  return {
    createAndConfirmShopifySubscription,
    ...queryState,
  };
};
