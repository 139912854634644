import { attributesSetter } from '@utils/DOM/attributesSetter';
import { RefObject } from 'react';
import { equals } from 'ramda';
import { EventEmitter } from '@utils/EventEmitter';

import {
  isFbSdkReadyAsync,
  parseFbPluginsTemplates,
} from '@components/FBPlugins/FBPluginsUtils';
import { callFacebookSDK } from '@components/FBPlugins/callFacebookSDK';
import { addPxPostfixToPositionStyleObj } from '@utils/DOM/addPxPostfixToPositionStyleObj';
import {
  FB_SEND_TO_PLUGIN_NAME,
  FBPluginEvent,
} from '@components/FBPlugins/SendToMessengerPluginButton/SendToMessengerPluginButton';
import { sendEvent } from '@utils/Analytics';
import * as css from './SendToMessengerOverlayService.css';

export interface SendToMessengerButtonOverlayProps {
  dataRef: string;
  pageId: string;
  appId: string;
  parent: HTMLDivElement;
}

const RENDER_TIMEOUT = 8000;
const UPDATE_TIMEOUT = 3000;

export enum SendToMessengerPluginEvents {
  optIn = 'opt_in',
  rendered = 'rendered',
  clicked = 'clicked',
  renderError = 'renderError',
}

export interface FbPluginEventHandlers {
  onRendered?: () => void;
}

export class SendToMessengerButtonOverlay extends EventEmitter {
  public elButtonBox: HTMLDivElement | undefined;

  private renderTimeoutHandle: number | undefined;

  private updateTimeoutHandle: number | undefined;

  private isWaitOptIn: boolean = false;

  private attachedToElement: HTMLElement | null = null;

  public isClicked: boolean = false;

  private isAttached: boolean = false;

  public ready: boolean = false;

  constructor(
    private props: SendToMessengerButtonOverlayProps,
    private handlers?: FbPluginEventHandlers,
  ) {
    super();
    this.subscribeToFbPluginEvents();
    this.createButton();
  }

  getProps() {
    return this.props;
  }

  createButton() {
    const { dataRef, pageId, appId, parent } = this.props;
    if (!this.elButtonBox) {
      this.elButtonBox = document.createElement('div');
      this.elButtonBox.classList.add(css.buttonElBox);
      this.hide();
    }
    const el = document.createElement('div');
    attributesSetter(el, {
      'data-ref': dataRef,
      page_id: pageId,
      messenger_app_id: appId,
      color: 'blue',
      size: 'xlarge',
      class: `fb-send-to-messenger ${css.button}`,
    });
    this.elButtonBox.append(el);
    parent.append(this.elButtonBox);
    this.render();
  }

  async render() {
    if (!this.elButtonBox) {
      return;
    }
    await isFbSdkReadyAsync;
    parseFbPluginsTemplates(this.elButtonBox);
    this.renderTimeoutHandle = window.setTimeout(() => {
      this.emit(SendToMessengerPluginEvents.renderError);
      sendEvent({
        category: 'test this bot 2',
        action: 'send to messenger button render error',
      });
    }, RENDER_TIMEOUT);
  }

  async subscribeToFbPluginEvents() {
    await isFbSdkReadyAsync;
    callFacebookSDK((FB) => {
      FB.Event.subscribe(FB_SEND_TO_PLUGIN_NAME, this.handleFbPluginEvents);
    });
  }

  handleFbPluginEvents = (eventData: FBPluginEvent) => {
    const { ref, event } = eventData;
    if (ref !== this.props.dataRef) {
      return;
    }

    if (event === SendToMessengerPluginEvents.rendered) {
      this.handlers?.onRendered?.();
      window.clearTimeout(this.renderTimeoutHandle);
      this.ready = true;
      this.isClicked = false;
      this.emit(event);
      if (this.isAttached) {
        this.show();
      }
      sendEvent({
        category: 'test this bot 2',
        action: 'send to messenger button ready',
      });
    }
    if (event === SendToMessengerPluginEvents.optIn) {
      if (!this.isWaitOptIn) {
        // fb after login send opt_in event for all!!
        return;
      }
      this.isWaitOptIn = false;
      window.clearTimeout(this.updateTimeoutHandle);
      this.updateTimeoutHandle = window.setTimeout(() => {
        this.update(this.props, true);
      }, UPDATE_TIMEOUT);
      this.emit(event);
    }
    if (event === SendToMessengerPluginEvents.clicked) {
      this.isWaitOptIn = true;
      this.isClicked = true;
      // fix cross-domain iframe access error in Safari 12 ...
      // React touch the active element and this threw an exception!
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      this.hide();
      this.emit(event, { attachedToElement: this.attachedToElement });
      sendEvent({
        category: 'test this bot 2',
        action: 'send to messenger button click',
      });
    }
  };

  clear() {
    this.ready = false;
    this.isWaitOptIn = false;
    if (!this.elButtonBox) {
      return;
    }
    this.elButtonBox.innerHTML = '';
  }

  resetElButtonBox() {
    if (!this.elButtonBox) {
      return;
    }
    this.hide();
    Object.assign(
      this.elButtonBox.style,
      addPxPostfixToPositionStyleObj({ top: 0, left: 0 }),
    );
  }

  update(
    props: Partial<SendToMessengerButtonOverlayProps>,
    force: boolean = false,
    handlers?: FbPluginEventHandlers,
  ) {
    this.handlers = handlers ?? this.handlers;
    const updatedProps = {
      ...this.props,
      ...props,
    };
    if (equals(updatedProps, this.props) && !force) {
      return this;
    }
    this.props = updatedProps;
    this.clear();
    this.createButton();
    return this;
  }

  get dataRef() {
    return this.props.dataRef;
  }

  attach({ current: el }: RefObject<any>) {
    if (!el || !this.elButtonBox) {
      return;
    }
    this.attachedToElement = el;
    const { top, left, height, width } = el.getBoundingClientRect();
    Object.assign(
      this.elButtonBox.style,
      addPxPostfixToPositionStyleObj({ top, left, height, width }),
    );
    if (this.ready) {
      this.show();
    }
    this.isAttached = true;
  }

  unAttach() {
    this.isAttached = false;
    this.attachedToElement = null;
    if (!this.elButtonBox) {
      return;
    }
    this.resetElButtonBox();
  }

  show() {
    this.elButtonBox?.classList.remove(css.hide);
  }

  hide() {
    this.elButtonBox?.classList.add(css.hide);
  }

  destroy() {
    super.destroy();
    window.clearTimeout(this.renderTimeoutHandle);
    window.clearTimeout(this.updateTimeoutHandle);
    callFacebookSDK((FB) => {
      FB.Event.unsubscribe(FB_SEND_TO_PLUGIN_NAME, this.handleFbPluginEvents);
    });
    this.elButtonBox?.parentElement?.removeChild(this.elButtonBox);
  }
}
