import { is, values, join, compose, map, concat } from 'ramda';
import memoize from 'lodash-es/memoize';
import moment from 'moment';

const OUT_DATE_FORMAT = 'YYYY-MM-DD';

const INPUT_TEXT_FIELD_TYPES = {
  normal: 'transparent',
};

const PICKERS_SELECTORS = {
  suggest: 'js-cf-auto-complete',
  dropdown: 'js-ui-picker-view-dropdown',
};

const MIN_SUGGEST_WIDTH = 240;
const MAX_SUGGEST_WIDTH = 400;
const MAX_DROPDOWN_WIDTH = 120;

const joinValuesAsSelectors = compose(join(','), map(concat('.')), values);

/**
 * @description: data independent ui component to show on raw of omnibox;
 */
class UIPickerView {
  /**
   * @param {*} $element - ng element
   * @param {*} $timeout - ng $timeout
   */
  constructor($element, $timeout) {
    'ngInject';

    this.$element = $element;
    this.$timeout = $timeout;
    this.INPUT_TEXT_FIELD_TYPES = INPUT_TEXT_FIELD_TYPES;
    // index of opened selection;
    this._openedIndex = null;
    this.dateOptions = {
      showWeeks: false,
      // maxDate: new Date(),
    };
    this._getColumnDatasource = memoize(this._getColumnDatasource.bind(this));
    this._getDateValue = memoize(this._getDateValue.bind(this));
    this.formatDate = memoize(this.formatDate.bind(this));
  }

  /**
   * @param {Array<*>} e - any data to log
   * @return {*} void
   */
  log(...e) {
    // console.log('[ui-pickier-view]',...e);
  }

  recalculateWidthForDropdowns() {
    this.$timeout(() => {
      const pickers = [
        ...this.$element[0].querySelectorAll(
          joinValuesAsSelectors(PICKERS_SELECTORS),
        ),
      ];
      this.pickersWidth = pickers.map((picker) => {
        const { width } = picker.getBoundingClientRect();
        if (picker.classList.contains(PICKERS_SELECTORS.dropdown)) {
          return Math.max(Math.round(width), MAX_DROPDOWN_WIDTH);
        }
        if (picker.classList.contains(PICKERS_SELECTORS.suggest)) {
          return Math.min(
            Math.max(Math.round(width), MIN_SUGGEST_WIDTH),
            MAX_SUGGEST_WIDTH,
          );
        }
        // TODO: what should be returned?
        return undefined;
      });
    }, 150);
  }

  /**
   * @return {*} void
   */
  $onInit() {
    // due to here you do not have element yet;
    // we need width to size popovers in autocomplete and dropdown;
    this.recalculateWidthForDropdowns();
  }

  $onChanges(e) {
    this.recalculateWidthForDropdowns();

    if (e.data.currentValue) {
      e.data.currentValue.forEach((column) => {
        if (column.type === 'date') {
          this.__date = this._getDateValue(column.value[0]);
        }
      });
    }
  }

  /**
   * @description: called when user select one of the value in omnibox from dorpdown
   * @param {string} newValue - selected value
   * @param {number} uiPickerViewIndex - up picker index where this value was selected
   * @param {number} newValueIndex - value index in datasource
   * @param {PickerViewColumn} column - datasource column object (see index file)
   * @private
   * @return {*} void
   */
  _onRequestSelect({ newValue, uiPickerViewIndex, newValueIndex, column }) {
    const { allowFreeInput } = this.data[uiPickerViewIndex];
    if (allowFreeInput && newValueIndex === -1) {
      // do not notify if free input is allowed; and user just close a popup by click away;
      if (this._openedIndex === uiPickerViewIndex) {
        this._openedIndex = null;
      }
      return;
    }
    if (!allowFreeInput && newValueIndex === -1) {
      // if current value exists in datasource do not flush it;
      const { value, datasource } = column;
      const isValueExistInDatasource = datasource.find((v) => {
        return v && v.value && v.value === value;
      });
      if (isValueExistInDatasource) {
        if (this._openedIndex === uiPickerViewIndex) {
          this._openedIndex = null;
        }
        // do not not notify parent
        return;
      }
    }

    const event = { newValue, uiPickerViewIndex, newValueIndex };
    if (is(Array, column.value)) {
      // replace last input ( what is currently inputed as autocompelete;
      // fixme: @p3 may be refucktor this logic to cf-auto-complete?
      // it is just Achtung
      const { value } = newValue;
      event.newValue.value = [...column.value];
      event.newValue.value[
        event.newValue.value.length ? event.newValue.value.length - 1 : 0
      ] = value;
      event.newValue.value.push('');
    }

    this.onValueChange(event);

    if (column && column.onChange) {
      column.onChange(event);
    }
  }

  /**
   * @description: small hack autocomplete currently can be customized per entity type; so ...
   * @param {Datasource} datasrouce - see index file for details
   * @return {Datasource} - returns changed datasource
   * @private
   */
  _getColumnDatasource(datasrouce) {
    return datasrouce.map((column) => ({
      ...column,
      hoverType: 'transparent-white',
    }));
  }

  _getDateValue(date) {
    const momentObj = moment(date);
    return momentObj.isValid() ? momentObj.toDate() : new Date();
  }

  formatDate(dateString) {
    if (!dateString) {
      return '';
    }
    return moment(dateString).format('DD MMMM YYYY');
  }

  _onDateChange(column, index) {
    const out = moment(this.__date).format(OUT_DATE_FORMAT);

    if (column.onChange) {
      column.onChange({
        uiPickerViewIndex: index,
        newValue: { value: out },
      });
    }

    this._openedIndex = null;
  }

  _onFocus(column) {
    this.recalculateWidthForDropdowns();

    if (column.onFocus) {
      column.onFocus();
    }
  }
}

export default UIPickerView;
