import {
  complement,
  groupBy,
  last,
  map,
  pipe,
  prop,
  values,
  compose,
  filter,
  propEq,
} from 'ramda';
import gql from 'graphql-tag';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  MultipleBlocksStatsQuery,
  MultipleBlocksStatsQueryVariables,
} from './@types/MultipleBlocksStatsQuery';

interface IBlock {
  id: string;
  title: string;
}

type BlockProcessor = (blocks: IBlock[]) => IBlock[];

const noRemoved: BlockProcessor = filter(complement(prop('removed')));
const reachable: BlockProcessor = filter(propEq('reachable', true));
const uniqByTitle: BlockProcessor = pipe(
  groupBy(prop('title') as any),
  values,
  map(last) as any,
);
export const getCurrentBlocks = compose(
  uniqByTitle,
  noRemoved,
  reachable,
) as any;

interface Block {
  id: string;
  total_clicks: number | null;
  total_views: number | null;
}

interface BlockStats {
  blockId: string;
  views_broadcast?: number;
  clicks_broadcast?: number;
  views_total?: number;
  clicks_total?: number;
  ctr_broadcast?: number;
  ctr_total?: number;
}

export const getBlockStatsForEvents = <T extends Block>(
  block: T,
): BlockStats => {
  const { id, total_clicks, total_views } = block;
  const result: BlockStats = { blockId: id };

  if (total_clicks) {
    result.clicks_total = total_clicks;
  }

  if (total_views) {
    result.views_total = total_views;
  }

  if (total_clicks && total_views) {
    result.ctr_total = total_clicks / total_views;
  }

  return result;
};

const MULTIPLE_BLOCKS_STATS_QUERY = gql`
  query MultipleBlocksStatsQuery($botId: String!, $blocksIds: [String!]!) {
    bot(id: $botId) {
      id
      blocksStats(blocksIds: $blocksIds) {
        id
        stats {
          sent
          seen
          clicked
          blocked
        }
      }
    }
  }
`;

export const useMultipleBlocksStatsLazy = (
  variables?: MultipleBlocksStatsQueryVariables,
) =>
  useLazyQuery<MultipleBlocksStatsQuery, MultipleBlocksStatsQueryVariables>(
    MULTIPLE_BLOCKS_STATS_QUERY,
    {
      variables,
    },
  );

export const useMultipleBlocksStats = (
  variables?: MultipleBlocksStatsQueryVariables,
  fetchPolicy?: WatchQueryFetchPolicy,
) =>
  useQuery<MultipleBlocksStatsQuery, MultipleBlocksStatsQueryVariables>(
    MULTIPLE_BLOCKS_STATS_QUERY,
    {
      variables,
      fetchPolicy,
    },
  );
