import gql from 'graphql-tag';
import client from '../../../common/services/ApolloService';
import {
  DisconnectIntegrationZapierAccountMutation,
  DisconnectIntegrationZapierAccountMutationVariables,
} from './@types/DisconnectIntegrationZapierAccountMutation';

const DISCONNECT_INTEGRATION_ZAPIER_ACCOUNT_MUTATION = gql`
  mutation DisconnectIntegrationZapierAccountMutation(
    $botId: String!
    $accountId: String!
  ) {
    disconnectIntegrationZapierAccount(botId: $botId, accountId: $accountId) {
      id
      connectedZapierAccounts {
        id
        admin_id
      }
    }
  }
`;

export const disconnectIntegrationZapierAccount = (
  botId: string,
  accountId: string,
) =>
  client.mutate<
    DisconnectIntegrationZapierAccountMutation,
    DisconnectIntegrationZapierAccountMutationVariables
  >({
    mutation: DISCONNECT_INTEGRATION_ZAPIER_ACCOUNT_MUTATION,
    variables: {
      botId,
      accountId,
    },
  });
