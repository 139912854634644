import { applyZone51Overrides } from '../zone51';
import { getCurrentDomain, getSubdomain } from '../UrlUtils';
import { isWhiteLabelDomain } from '../WhiteLabelUtils';

import {
  isStagingDomain,
  getStagingId,
  isDemoDomain,
  isDevDomain,
  getDevId,
  isLocalEnv,
} from './helpers';

let done = false;

export function onReadyEnvironment() {
  if (done) {
    return;
  }
  if (!('CHATFUEL_CONFIG' in window)) {
    console.error('Global config not found');
    return;
  }
  const { CF_ENV } = window.CHATFUEL_CONFIG;
  if (CF_ENV === 'zone51') {
    applyZone51Overrides(); // NOTE: maybe import asynchronously?
    console.log('Specific env rule added.'); // eslint-disable-line no-console
  }
  done = true;
}

export const getLandingHostname = () => {
  if (isWhiteLabelDomain() || isDemoDomain()) {
    return getCurrentDomain();
  }
  if (isLocalEnv()) {
    return 'localhost:8080';
  }
  const domain = getSubdomain(getCurrentDomain());
  if (isDevDomain()) {
    return (
      window.CHATFUEL_CONFIG.LANDING_DOMAIN_MOCK ??
      `dev${getDevId(domain)}.chatfuel.space`
    );
  }
  if (isStagingDomain()) {
    return `${getStagingId(domain)}.chatfuel.space`;
  }
  return 'chatfuel.com';
};

export const getDashboardHostname = () => {
  if (isWhiteLabelDomain()) {
    return getCurrentDomain();
  }
  if (isLocalEnv()) {
    return 'localhost:3000';
  }
  const domain = getSubdomain(getCurrentDomain());
  const isDevEnvWithLanding = domain.startsWith('dev');
  if (isDevEnvWithLanding) {
    const match = domain.match(/\d{1,}/g);
    if (match) {
      return `dashboard-${match[0]}.chatfuel.space`;
    }
  }
  if (isDevDomain() || isDemoDomain()) {
    return getCurrentDomain();
  }
  if (isStagingDomain()) {
    return `dashboard-${getStagingId(domain)}.chatfuel.space`;
  }
  return 'dashboard.chatfuel.com';
};
