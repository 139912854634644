import { TierType } from '@globals';
import {
  getQuantValue,
  getRegularValue,
  QuantIds,
} from '@utils/DateTime/quant';
import { useCurrentBot } from '../Bot';
import { useDialoguesPricing, useTrialStatsQuery } from './hooks';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';

export const useTimeLimitedTrial = (skip: boolean = false) => {
  const { bot, loading } = useCurrentBot({ notifyOnNetworkStatusChange: true });
  const { pricing, pricingLoading } = useDialoguesPricing({
    notifyOnNetworkStatusChange: true,
    skip,
  });
  const { trialStatsData, trialStatsLoading } = useTrialStatsQuery({
    variables: { pageId: bot?.status?.page! },
    skip: !bot?.status?.page || skip,
    notifyOnNetworkStatusChange: true,
  });

  const { allowed, loading: allowedLoading } = useRolePermission({
    domain: 'configure',
    can: Permission.VIEW,
  });

  const isContentShopData = bot?.connectedShop?.id || !allowed;

  const isPaidBot = bot?.pro?.manual || bot?.workspace_id || isContentShopData;

  const currentTierFirst = !pricing?.previous_tier;
  const currentTierTimeLimitedTrial =
    pricing?.current_tier === TierType.trial && bot?.time_limited_trial;
  const endDate =
    currentTierTimeLimitedTrial && !isPaidBot && currentTierFirst
      ? pricing.next_billing_date ??
        getQuantValue(pricing.trialSecondsTotal ?? 0, QuantIds.seconds) +
          Date.now()
      : null;

  const trialRemainingDays = endDate
    ? // когда осталось пол дня мы всеравно пишем остался один день
      // для этого округляем вверх
      Math.ceil(
        getRegularValue(Number(new Date(endDate)) - Date.now(), QuantIds.days),
      )
    : null;

  return {
    trialStatsData: trialStatsData?.trialStats,
    trialExperiment: bot?.time_limited_trial,
    currentTierTimeLimitedTrial,
    currentTierFirst: !pricing?.previous_tier,
    trialRemainingDays,
    trialEndDate: isPaidBot ? null : bot?.status?.page_info?.trial_end_date,
    trialExpired:
      trialRemainingDays === null || isPaidBot ? null : trialRemainingDays <= 0,
    timeLimitedTrialLoading:
      loading || pricingLoading || trialStatsLoading || allowedLoading,
  };
};
