import { SanctionCheckStatus } from '../../@types/globalTypes';

const statusesToBlock: SanctionCheckStatus[] = [
  SanctionCheckStatus.suspicious,
  SanctionCheckStatus.confirmed,
];

export const isUserUnderSanctions = (
  status?: SanctionCheckStatus | null,
): boolean => !!status && statusesToBlock.includes(status);
