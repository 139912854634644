import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { removeTypename } from '@utils/GQL/utils';
import {
  UPDATE_WELCOME_MESSAGE_MUTATION,
  WELCOME_MESSAGE_QUERY,
} from './queries';
import { preparePredefinedIntent } from '../helpers';
import { UpdatePredefinedIntentArgs } from '../types';
import {
  UpdateWelcomeMessageMutation,
  UpdateWelcomeMessageMutationVariables,
} from './@types/UpdateWelcomeMessageMutation';
import {
  WelcomeMessageQuery,
  WelcomeMessageQueryVariables,
} from './@types/WelcomeMessageQuery';
import {
  welcomeMessageFragment_aiBlock_cards_AIPlugin as AiPlugin,
  welcomeMessageFragment_aiBlock_cards_AIPlugin_config_welcome_message_intent as AiIntent,
} from './@types/welcomeMessageFragment';

export const useWelcomeMessage = (botId?: string) => {
  const { data, ...state } = useQuery<
    WelcomeMessageQuery,
    WelcomeMessageQueryVariables
  >(WELCOME_MESSAGE_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const [updateWelcomeMessageMutation] = useMutation<
    UpdateWelcomeMessageMutation,
    UpdateWelcomeMessageMutationVariables
  >(UPDATE_WELCOME_MESSAGE_MUTATION);

  const aiCard = data?.bot.aiBlock.cards?.[0];
  const aiWelcomeMessageIntent =
    aiCard && (aiCard as AiPlugin).config.welcome_message_intent;

  const aiWelcomeMessageIntentBlockId =
    aiWelcomeMessageIntent &&
    (aiWelcomeMessageIntent as AiIntent).action.items[0]?.blocks?.find(
      (block) => block.id,
    )?.id;

  const updateWelcomeMessage = useCallback(
    ({ actionField, blocks, text, type }: UpdatePredefinedIntentArgs) => {
      if (!aiWelcomeMessageIntent || !botId || !aiCard) {
        return undefined;
      }
      const updateWelcomeMessageIntent = preparePredefinedIntent({
        actionField,
        blocks,
        text,
        type,
        currentIntent: aiWelcomeMessageIntent,
      });
      return updateWelcomeMessageMutation({
        variables: {
          botId,
          cardId: aiCard.id,
          defaultIntent: removeTypename(updateWelcomeMessageIntent),
        },
        optimisticResponse: {
          updateWelcomeMessageIntent,
        },
      });
    },
    [aiWelcomeMessageIntent, botId, aiCard, updateWelcomeMessageMutation],
  );

  return {
    aiCard,
    aiWelcomeMessageIntent,
    aiWelcomeMessageIntentBlockId,
    updateWelcomeMessage,
    ...state,
  };
};
