import difference from 'lodash-es/difference';

export function shuffle<T = string>(arr: T[]): T[] {
  return arr
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
}

export const getArrayFrom0ToN = (n: number) => [...Array(n).keys()];

/**
 * @returns {boolean} `true` if the first specified array contains all elements
 * from the second one. `false` otherwise.
 */
export function containsAll<T>(superset: T[], values: T[]): boolean {
  if (values.length === 0) return true;
  return difference(values, superset).length === 0;
}

/**
 * Преобразуем список в ключ-значение объект
 */
export function toRecord<T, V = T>(
  array: Iterable<T>,
  keyMapper: (element: T) => string,
  valueMapper?: (element: T) => V,
): Record<string, V> {
  const result: Record<string, V> = {};

  for (const element of array) {
    result[keyMapper(element)] = valueMapper
      ? valueMapper(element)
      : (element as unknown as V);
  }

  return result;
}

/**
 * Ленивое объединение списка списков, возвращаем итератор
 */
export function* lazyConcat<T>(
  ...arrays: (T[] | undefined | null)[]
): Iterable<T> {
  for (const array of arrays) {
    if (array) {
      yield* array;
    }
  }
}
