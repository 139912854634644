import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { AdminDataQuery } from './@types/AdminDataQuery';
import { RoleIds } from '../../../components/settings/admins/RolesList';

export const ADMIN_DATA_QUERY = gql`
  query AdminDataQuery {
    me {
      id
      name
      roles {
        bot_id
        role_id
      }
      picture
      terms_accepted
      white_label_role
      premium_account_id
      original_account_type
      premiumAccount {
        id
        old_premium
        plan_info {
          id
          amount
          interval
        }
        product_info {
          id
          name
        }
        subscription_info {
          id
          status
          current_period_end
        }
      }
      email
      unverified_email
      user_states {
        user_id
        page_id
      }
    }
  }
`;

export const useAdminData = () => {
  const { data, loading, error, refetch } = useQuery<AdminDataQuery>(
    ADMIN_DATA_QUERY,
    { notifyOnNetworkStatusChange: true },
  );

  const checkRoleByBotId = (role: RoleIds, botId: string) =>
    data?.me.roles?.find(({ bot_id }) => bot_id === botId)?.role_id === role;

  return {
    adminData: data,
    adminDataLoading: loading,
    adminDataError: error,
    adminDataRefetch: refetch,
    checkRoleByBotId,
  };
};
