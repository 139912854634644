import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from 'react-apollo';
import {
  FacebookPagesSetupQuery,
  FacebookPagesSetupQueryVariables,
} from './@types/FacebookPagesSetupQuery';
import { FACEBOOK_PAGES_SETUP_QUERY } from './queries';

export type FacebookPagesHookPayload = ReturnType<typeof useFacebookPages>;

export const useFacebookPages = (
  botId: string | undefined,
  options?: QueryHookOptions<
    FacebookPagesSetupQuery,
    FacebookPagesSetupQueryVariables
  >,
) => {
  const { data, loading, error, called, refetch } = useQuery<
    FacebookPagesSetupQuery,
    FacebookPagesSetupQueryVariables
  >(FACEBOOK_PAGES_SETUP_QUERY, {
    variables: { botId: botId! },
    skip: !botId,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return {
    facebookPagesData: data,
    facebookPagesLoading: loading,
    facebookPagesCalled: called,
    facebookPagesError: error,
    facebookPagesRefetch: refetch,
  };
};
