import ng from 'angular';
import './index.less';
import { sendEvent } from '../../utils/Analytics';

const SHOW_ADMINS = 2;

class CfCommonTileController {
  /**
   * -
   * @param {*} $transclude -
   */
  constructor($transclude, $document) {
    'ngInject';

    this.$transclude = $transclude;
    this.$document = $document;
    this.showAdminsLimit = this.showAdmins || SHOW_ADMINS;
    this._internalEditedText = this.text;
  }

  /**
   * -
   * @param {event} e -
   */
  onIconMenuClick(e) {
    e.stopPropagation();
    this.$document.triggerHandler('click', e.currentTarget); // jump click event over stopPropagation for click-away detection
    this.popoverAnchor = e.currentTarget;
    this.showPopUpMenu = true;
  }

  /**
   * helper to use in template to show if actions menu was filled by the parent via transclude
   * @return {boolean} -
   */
  hasActionsMenu() {
    return this.$transclude.isSlotFilled('actionsMenu');
  }

  goToUpgrade = () => {
    this.onUpgradeToProClick(this.botId);
  };

  goToSettings = () => {
    this.onSettingsClick(this.botId);
  };

  goToPageConnect = () => {
    this.onPageConnectClick(this.botId);
  };

  /**
   * on change you need to sync bot title and internal edited bot title;
   * @param {Event} e -
   */
  $onChanges(e) {
    if (e && e.editTitle && e.editTitle.currentValue === false) {
      this._internalEditedText = this.text;
    }
  }
}

export default ng.module('app.ui.cfCommonTile', []).component('cfCommonTile', {
  controllerAs: 'vm',
  transclude: {
    actionsMenu: '?actionsmenu',
    someImage: '?someimage',
  },
  bindings: {
    users: '<',
    showAdmins: '<',
    thema: '@',
    buttonTheme: '@',
    text: '<',
    subtext: '<',
    buttonName: '<',
    onButtonClick: '&',
    subTextClass: '@',
    textClass: '@',
    reachable: '<',
    reachableDiff: '<',
    active: '<',
    activeDiff: '<',
    loader: '<',
    editTitle: '<',
    onTitleChange: '&',
    onContextMenuClick: '&',
    hideActionsmenu: '<',
    onUpgradeToProClick: '<',
    onSettingsClick: '<',
    onPageConnectClick: '<',
    bot: '<',
    botId: '<',
    botPro: '<',
    botStatus: '<',
    botDisconnectedDate: '<',
  },
  template: () => `<div class="resize-container"></div>
<div class="content-container">

  <bot-notification-tag-with-pricing-data
    ng-if="!vm.editTitle && !vm.loader"
    bot="vm.bot"
    bot-id="vm.botId"
    bot-pro="vm.botPro"
    on-upgrade-click="vm.goToUpgrade"
    on-settings-click="vm.goToSettings"
    on-page-connect-click="vm.goToPageConnect"
    class="bot-notification-tag"
    bot-status="vm.botStatus"
    bot-disconnected-date="vm.botDisconnectedDate"
    reachable="vm.reachable"
  ></bot-notification-tag-with-pricing-data>
  
  <icon-button 
    ng-if="vm.hasActionsMenu() && !vm.hideActionsmenu"
    size="sub-medium"
    ng-click="vm.onContextMenuClick({$event: $event, value: true}); vm.onIconMenuClick($event);"
    on-click-away="vm.showPopUpMenu = false"
    class="icon-menu"
  >
    <icon type="dotted"></icon>
  </icon-button>

  <div class="image-container" data-ng-transclude="someImage"></div>

  <div class="text-container">
    <div ng-if="!vm.editTitle" clamp="3" class="{{vm.textClass}}">
      {{vm.text}}
    </div>
     <text-field-box ng-if="vm.editTitle" style="height:auto; overflow: hidden; max-height: 60px;">
      <text-field
          class="rename-field_layout"
          text-field-class="body-15 rename-input_layout"
          value="vm.text"
          placeholder="Enter bot name"
          on-change="vm._internalEditedText = value"
          focus="true"
        >
        </text-field>
      </text-field-box>


  </div>
  <div class="sub-text-container">
    <div ng-if="!vm.editTitle" class="{{vm.subTextClass}}" clamp="2">
      {{vm.subtext}}
    </div>
  </div>
  <div class="button-container" ng-if="vm.buttonName || vm.editTitle">
    <cf-button
      ng-if="!vm.editTitle"
      style="width: 92px"
      theme="{{vm.buttonTheme}}"
      short="true"
      narrow="true"
      ng-click="vm.onButtonClick({$event:$event})"
    >
      <cf-button-label>{{vm.buttonName}}</cf-button-label>
    </cf-button>

    <cf-button
      ng-if="vm.editTitle"
      style="width: 92px"
      short="true"
      narrow="true"
      ng-click="vm.onTitleChange({$event:$event, value: vm._internalEditedText})"
    >
      <cf-button-label>Done</cf-button-label>
    </cf-button>
  </div>

  <div
    class="counters"
    ng-if="(vm.reachable || vm.active) && !vm.editTitle && !vm.bot.pro.is_paused"
  >
    <ui-counter title="Users" count="vm.reachable" diff="vm.reachableDiff"></ui-counter>
    <ui-counter title="Weekly Active" count="vm.active" diff="vm.activeDiff"></ui-counter>
  </div>
  <div ng-if="vm.loader">
    <div class="loader_container_1"></div>
    <div class="loader_container_2"></div>
  </div>

</div>

<cf-popover 
    popover-origin="{vertical:'top', horizontal:'right'}" 
    anchor-origin="{vertical:'top', horizontal:'right'}" 
    anchor="vm.popoverAnchor" 
    cf-open="{{vm.showPopUpMenu}}" 
    ng-if="vm.hasActionsMenu()"
>
  <cf-menu>
    <scroll-box data-ng-transclude="actionsMenu">
    </scroll-box>
  </cf-menu>
</cf-popover>



   `,
  controller: CfCommonTileController,
}).name;
