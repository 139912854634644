import { sendEvent } from './Analytics';
import { redirect } from './UrlUtils/redirect';

const REDIRECT_DEBOUNCE_TIME = 500;
const MAX_UNAUTH_REQUESTS = 3;
let unauthRequestsCount = 0;

export const handleUnauthDuringSession = () => {
  unauthRequestsCount += 1;

  if (unauthRequestsCount >= MAX_UNAUTH_REQUESTS) {
    sendEvent(
      {
        action: 'unauth',
        category: 'auth',
      },
      true,
    );
    unauthRequestsCount = 0;
    setTimeout(() => redirect('/'), REDIRECT_DEBOUNCE_TIME);
  }
};
