import React from 'react';
import gql from 'graphql-tag';
import { ApolloProvider, Query } from '@apollo/react-components';
import { pathEq } from 'ramda';
import { Redirect } from 'react-router-dom';
import { ApolloError } from 'apollo-client';
import { checkWLDomain } from 'cf-common/src/whitelabel';
import { Level, log } from 'cf-common/src/logger';
import { WhiteLabelUserQuery } from './@types/WhiteLabelUserQuery';
import client from '../common/services/ApolloService';
import { redirect, getCurrentDomain } from './UrlUtils';

export enum WhiteLabelRole {
  admin = 'admin',
  user = 'user',
}

interface IWhiteLabelUserData {
  children: (data: {
    loading: boolean;
    error: ApolloError | undefined;
    isWhiteLabelUser: boolean | undefined;
    isWhiteLabelAdmin: boolean | undefined;
  }) => JSX.Element | null;
}

export const isWhiteLabelAgencyUser = pathEq(
  ['white_label_role'],
  WhiteLabelRole.user,
);

export const isWhiteLabelAgencyAdmin = pathEq(
  ['white_label_role'],
  WhiteLabelRole.admin,
);

const WHITE_LABEL_USER_QUERY = gql`
  query WhiteLabelUserQuery {
    me {
      id
      abt_serialized
      white_label_role
    }
  }
`;

export const WhiteLabelUserData: React.FC<IWhiteLabelUserData> = ({
  children,
}): JSX.Element | null => (
  <Query<WhiteLabelUserQuery> query={WHITE_LABEL_USER_QUERY}>
    {({ data, loading, error }) => {
      const isWhiteLabelUser = data && isWhiteLabelAgencyUser(data.me);
      const isWhiteLabelAdmin = data && isWhiteLabelAgencyAdmin(data.me);

      return children({
        loading,
        error,
        isWhiteLabelUser,
        isWhiteLabelAdmin,
      });
    }}
  </Query>
);

export const WhiteLabelUserDataWithApolloProvider: React.FC<IWhiteLabelUserData> = (
  props,
) => (
  <ApolloProvider client={client}>
    <WhiteLabelUserData {...props} />
  </ApolloProvider>
);

export const isWhiteLabelDomain = (): boolean => {
  const hostname = getCurrentDomain();
  return checkWLDomain(hostname);
};

export const locationRedirectToBot = (botId: string = '') =>
  redirect(`${window.location.origin}/redirect-to-bot/${botId}`);

export const redirectToBot = (reason: string) => {
  log({
    msg: `Redirect to bot due to ${reason}`,
    level: Level.warn,
  });

  return <Redirect to="/redirect-to-bot" />;
};
