import { useCurrentBotId } from '@utils/Routing';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  WhatsappEnabledQuery,
  WhatsappEnabledQueryVariables,
} from './@types/WhatsappEnabledQuery';
import { isWhatsappEnabled } from '../isWhatsappEnabled';

export const WHATSAPP_ENABLED_QUERY = gql`
  query WhatsappEnabledQuery($botId: String!) {
    bot(id: $botId) {
      id
      allowedPlatforms
    }
  }
`;

export const useWhatsappEnabled = () => {
  const botId = useCurrentBotId();
  const { data, ...queryState } = useQuery<
    WhatsappEnabledQuery,
    WhatsappEnabledQueryVariables
  >(WHATSAPP_ENABLED_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  return {
    isWhatsappEnabled: isWhatsappEnabled(data?.bot),
    data,
    ...queryState,
  };
};
