import i18next from 'i18next';
import { getEnumKeys } from '@utils/EnumKeys';
import { Translation } from '@translations';

export enum QuantIds {
  instantly = 'immediately',
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

const QUANT_IDS_ARRAY: QuantIds[] = getEnumKeys(QuantIds).map(
  // @ts-ignore
  (v) => QuantIds[v],
);

export const QUANT_MULTIPLEXER = {
  [QuantIds.instantly]: 0,
  [QuantIds.seconds]: 1000,
  [QuantIds.minutes]: 1000 * 60,
  [QuantIds.hours]: 1000 * 60 * 60,
  [QuantIds.days]: 1000 * 60 * 60 * 24,
};

const QUANT_ITEMS_COMMON: () => QuantItem[] = () => [
  {
    id: QuantIds.seconds,
    title: i18next.t('utils.DateTime.quant.secondsTitle'),
    titleKey: 'utils.DateTime.quant.seconds',
  },
  {
    id: QuantIds.minutes,
    title: i18next.t('utils.DateTime.quant.minutesTitle'),
    titleKey: 'utils.DateTime.quant.minutes',
  },
  {
    id: QuantIds.hours,
    title: i18next.t('utils.DateTime.quant.hoursTitle'),
    titleKey: 'utils.DateTime.quant.hours',
  },
  {
    id: QuantIds.days,
    title: i18next.t('utils.DateTime.quant.daysTitle'),
    titleKey: 'utils.DateTime.quant.days',
  },
];

export const QUANT_ITEMS_WITH_IMMEDIATELY: () => QuantItem[] = () => [
  {
    id: QuantIds.instantly,
    title: i18next.t('utils.DateTime.quant.immediately'),
    titleKey: 'utils.DateTime.quant.immediately',
  },
  ...QUANT_ITEMS_COMMON(),
];

export const QUANT_ITEMS_WITH_NONE: () => QuantItem[] = () => [
  {
    id: QuantIds.instantly,
    title: i18next.t('utils.DateTime.quant.none'),
    titleKey: 'utils.DateTime.quant.none',
  },
  ...QUANT_ITEMS_COMMON(),
];

export interface QuantItem {
  id: QuantIds;
  title: string;
  titleKey: Translation;
}

const FRACTIONAL_PART = 1; // ex.: 0.1, 0.001 etc.

export const getQuantId = (
  quantValue: number,
  fractionalPart: number = FRACTIONAL_PART,
): QuantIds => {
  if (!quantValue) {
    return QuantIds.instantly;
  }
  for (let q = QUANT_IDS_ARRAY.length - 1; q > 0; q--) {
    const multiplexerId = QUANT_IDS_ARRAY[q];
    if (
      quantValue % (QUANT_MULTIPLEXER[multiplexerId] * fractionalPart) ===
      0
    ) {
      return multiplexerId;
    }
  }
  return QuantIds.seconds;
};

export const getRegularValue = (
  quantValue: number,
  quantId: QuantIds,
): number => {
  return quantValue / QUANT_MULTIPLEXER[quantId];
};

export const getQuantValue = (
  reqularValue: number,
  quantId: QuantIds,
): number => {
  return reqularValue * QUANT_MULTIPLEXER[quantId];
};
