import gql from 'graphql-tag';
import { BOT_FRAGMENT } from '../../../../common/services/GQLqueries/BotGQService.const';

export const CURRENT_BOT_QUERY = gql`
  query CurrentBotQuery($botId: String!) {
    bot(id: $botId) {
      ...botFragment
      connectedShop {
        id
      }
      pausePricing
      pricingData {
        currentUsers
        reached_limit
      }
    }
  }
  ${BOT_FRAGMENT}
`;
