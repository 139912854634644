import { isValidUrl } from '@utils/UrlUtils';
import { groupWith, head } from 'ramda';

type Token = {
  type: 'anchor' | 'string';
  data: string;
};

export const tokenizeStringByLink = (str: string): Token[] => {
  const groups = groupWith(
    (s1: string, s2: string) => !isValidUrl(s1) && !isValidUrl(s2),
    str.split(' '),
  );

  return groups.map((group) => {
    if (group.length === 1 && isValidUrl(head(group))) {
      return {
        type: 'anchor',
        data: head(group),
      };
    }

    return {
      type: 'string',
      data: group.join(' '),
    };
  });
};
