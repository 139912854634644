import { DataProxy } from 'apollo-cache';
import { lensPath, over, prepend } from 'ramda';
import { log } from 'cf-common/src/logger';
import {
  BotsListQuery,
  BotsListQuery_bots,
} from '../../../common/services/GQLqueries/@types/BotsListQuery';
import { BOTS_LIST_QUERY } from '../../../common/services/GQLqueries/BotGQService.const';
import { DEFAULT_PRO_DATA } from './constants';
import { BOTS_SELECTOR_QUERY } from './BotsList';
import { BotsSelectorQuery } from './@types/BotsSelectorQuery';
import { LIGHT_BOTS_QUERY, LIGHT_WORKSPACE_BOTS_QUERY } from './queries';
import { LightBotsQuery } from './@types/LightBotsQuery';
import { LightWorkspaceBotsQuery } from './@types/LightWorkspaceBotsQuery';
import Maybe from 'graphql/tsutils/Maybe';

const workspaceBotsLens = (id: string, data: LightWorkspaceBotsQuery) => {
  const workspaceIndex = data?.lightWorkspaceBots?.workspaces.findIndex(
    ({ workspace }) => workspace.id === id,
  );

  return lensPath(['lightWorkspaceBots', 'workspaces', workspaceIndex, 'bots']);
};

const botsWithoutWorkspaceLens = lensPath([
  'lightWorkspaceBots',
  'botsWithoutWorkspace',
]);

export const addToBotListCache = (
  bot: BotsListQuery_bots,
  workspaceId: Maybe<string>,
  isWorkspaceAvailable: Maybe<boolean>,
  store: DataProxy,
) => {
  const newBotWithDefaultPro = {
    ...bot,
    pro: DEFAULT_PRO_DATA,
  };

  try {
    const lightBotsQuery = store.readQuery<LightBotsQuery>({
      query: LIGHT_BOTS_QUERY,
    });

    if (lightBotsQuery) {
      const newData = over(
        lensPath(['lightBots']),
        prepend(bot),
        lightBotsQuery,
      );

      store.writeQuery({
        query: LIGHT_BOTS_QUERY,
        data: newData,
      });
    }
  } catch (error) {
    log.warn({
      error,
      msg: 'Could not read bots list query from cache',
    });
  }

  if (isWorkspaceAvailable && !bot.workspace_id) {
    try {
      const lightWorkspaceBotsQuery = store.readQuery<LightWorkspaceBotsQuery>({
        query: LIGHT_WORKSPACE_BOTS_QUERY,
      });

      if (lightWorkspaceBotsQuery) {
        const newData = over(
          botsWithoutWorkspaceLens,
          prepend(bot),
          lightWorkspaceBotsQuery,
        );

        store.writeQuery({
          query: LIGHT_WORKSPACE_BOTS_QUERY,
          data: newData,
        });
      }
    } catch (error) {
      log.warn({
        error,
        msg: 'Could not read bots list query from cache',
      });
    }
  }

  if (bot.workspace_id) {
    try {
      const lightWorkspaceBotsQuery = store.readQuery<LightWorkspaceBotsQuery>({
        query: LIGHT_WORKSPACE_BOTS_QUERY,
      });

      if (lightWorkspaceBotsQuery) {
        const newData = over(
          workspaceBotsLens(workspaceId!, lightWorkspaceBotsQuery),
          prepend(bot),
          lightWorkspaceBotsQuery,
        );

        store.writeQuery({
          query: LIGHT_WORKSPACE_BOTS_QUERY,
          data: newData,
        });
      }
    } catch (error) {
      log.warn({
        error,
        msg: 'Could not read bots list query from cache',
      });
    }
  }

  let botListQuery: BotsListQuery | undefined | null;

  try {
    botListQuery = store.readQuery<BotsListQuery>({
      query: BOTS_LIST_QUERY,
    });
  } catch {
    botListQuery = null;
  }

  if (botListQuery) {
    store.writeQuery({
      query: BOTS_LIST_QUERY,
      data: {
        ...botListQuery,
        bots: [newBotWithDefaultPro, ...botListQuery.bots],
      },
    });
    return;
  }

  // In some cases, the cache may not have BOTS_LIST_QUERY, so we update the BOTS_SELECTOR_QUERY

  let botSelectorQuery: BotsSelectorQuery | undefined | null;

  try {
    botSelectorQuery = store.readQuery<BotsSelectorQuery>({
      query: BOTS_SELECTOR_QUERY,
    });
  } catch (error) {
    botSelectorQuery = null;
    log.warn({
      error,
      msg: 'Could not read bots list query from cache',
    });
  }

  if (!botSelectorQuery) return;

  store.writeQuery({
    query: BOTS_SELECTOR_QUERY,
    data: {
      ...botSelectorQuery,
      bots: [newBotWithDefaultPro, ...botSelectorQuery.bots],
    },
  });
};

export interface FlatBotsParam<T> {
  botsWithoutWorkspace: Array<T>;
  workspaces: Array<{ bots: Array<T> }>;
}

export const getFlatBots = <T>(workspaces: Maybe<FlatBotsParam<T>>) => [
  ...(workspaces?.botsWithoutWorkspace || []),
  ...(workspaces?.workspaces.flatMap((workspace) => workspace.bots) || []),
];
