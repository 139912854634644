let globalConfigReadyResolve:
  | ((config: typeof window.CHATFUEL_CONFIG) => void)
  | undefined;

export const resolveGlobalConfigReady = (
  config: typeof window.CHATFUEL_CONFIG,
) => globalConfigReadyResolve?.(config);

export const globalConfigAsync = new Promise<typeof window.CHATFUEL_CONFIG>(
  (resolve) => {
    globalConfigReadyResolve = resolve;
  },
);
