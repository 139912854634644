import { useState } from 'react';
import { PaymentFlowState } from '../constants';
import { PaymentFlowErrorData } from 'cf-common/src/utils/Stripe/errors';

interface PaymentFlowStateHolder {
  state: PaymentFlowState;
  error?: PaymentFlowErrorData;
}

const initialState = {
  state: PaymentFlowState.idle,
  error: undefined,
};

export const usePaymentFlowState = () => {
  const [{ state, error }, setPaymentFlowState] = useState<
    PaymentFlowStateHolder
  >(initialState);

  const setFlowState = (nextState: PaymentFlowState) => {
    setPaymentFlowState({ state: nextState });
  };
  const failWith = (error: PaymentFlowErrorData) => {
    setPaymentFlowState({ state: PaymentFlowState.error, error });
  };

  return {
    paymentFlowState: state,
    error,
    setFlowState,
    failWith,
  };
};
