import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  BotWorkspaceIdQuery,
  BotWorkspaceIdQueryVariables,
} from './@types/BotWorkspaceIdQuery';

export const BOT_WORKSPACE_ID_QUERY = gql`
  query BotWorkspaceIdQuery($botId: String!) {
    bot(id: $botId) {
      id
      workspace_id
    }
  }
`;

export const useBotWorkspaceId = (botId: string | undefined) => {
  const { data, loading } = useQuery<
    BotWorkspaceIdQuery,
    BotWorkspaceIdQueryVariables
  >(BOT_WORKSPACE_ID_QUERY, { variables: { botId: botId! }, skip: !botId });

  return [data?.bot.workspace_id, loading] as const;
};
