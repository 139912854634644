import noop from 'lodash-es/noop';
import { getFetchHeaders } from '@common/services/ApolloService';
import { dataURLToBlob } from '../Blob/dataUrlToBlob';

interface FileUploadParams<T> {
  url: string;
  fileUrl?: string;
  fileBlob?: Blob;
  fileFieldName?: string;
  onProgress?(percent?: number): void;
  onLoad?(result: T): void;
  onError?(e?: ProgressEvent | Error): void;
}

export function fileUpload<T = {}>({
  url,
  fileUrl,
  fileBlob,
  onProgress,
  onLoad,
  onError,
  fileFieldName = 'file',
}: FileUploadParams<T>) {
  let blob = fileBlob;
  if (!blob && fileUrl) {
    try {
      blob = dataURLToBlob(fileUrl);
    } catch (e) {
      onError?.(e as any);
      return noop;
    }
  }

  if (!blob) {
    return noop;
  }

  const formData = new FormData();
  formData.append(fileFieldName, blob);

  const xhr = new XMLHttpRequest();
  const { upload } = xhr;
  const headers = getFetchHeaders();
  upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentComplete = Math.ceil(e.loaded / e.total) * 100;
      onProgress?.(percentComplete);
    }
  };

  upload.onerror = (e) => onError?.(e);

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const result = JSON.parse(xhr.response);
        onLoad?.(result.result as T);
      } else {
        onError?.(new Error(`${xhr.status}`));
      }
    }
  };

  xhr.open('POST', `/api${url}`);
  Object.entries(headers).forEach(([header, value]) => {
    xhr.setRequestHeader(header, value);
  });
  xhr.send(formData);
  return xhr.abort;
}
