import { MutationHookOptions, useMutation } from 'react-apollo';
import { DISCONNECT_KOMMO_MUTATION } from './mutation';
import {
  DisconnectKommoMutation,
  DisconnectKommoMutationVariables,
} from './@types/DisconnectKommoMutation';
import { useCurrentBotId } from '@utils/Routing';

export const useDisconnectKommo = (
  payload?: MutationHookOptions<
    DisconnectKommoMutation,
    DisconnectKommoMutationVariables
  >,
) => {
  const botId = useCurrentBotId()!;

  return useMutation<DisconnectKommoMutation, DisconnectKommoMutationVariables>(
    DISCONNECT_KOMMO_MUTATION,
    {
      variables: { botId },
      ...payload,
    },
  );
};
