export interface ClipboardDataFetcher {
  get(): Promise<string | null>;
  set(data: string): Promise<boolean>;
}

export class ClipboardService {
  public static create(dataFetcher: ClipboardDataFetcher) {
    return new ClipboardService(dataFetcher);
  }

  constructor(private dataFetcher: ClipboardDataFetcher) {}

  // https://w3c.github.io/clipboard-apis/#override-copy
  public async overrideClipboardEvent<T>(event: ClipboardEvent, data: T) {
    event.preventDefault(); // Default behavior is to copy any selected text
    await this.dataFetcher.set(this.getStringifiedData<T>(data));
  }

  public async getClipboardData<T>() {
    const text = await this.dataFetcher.get();
    return text ? this.getParsedData<T>(text) : null;
  }

  private getParsedData<T>(str: string): T {
    return JSON.parse(str);
  }

  private getStringifiedData<T>(data: T) {
    return typeof data === 'string' ? data : JSON.stringify(data);
  }
}
