export default class switcherController {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  _onClick() {
    this.animation = true;
    if (!this.preventChange) {
      this.value = !this.value;
    }
    this.$timeout(() => {
      this.animation = false;
    }, 110);

    this.onChange({ $value: this.value });
  }
}
