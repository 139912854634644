import { callFacebookSDK } from '../../modern-components/FBPlugins/callFacebookSDK';

export default class fbButtonController {
  constructor($scope, $timeout, $element) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.isShowFbButton = false;
    this.isLoadingFbButton = false;
    this.isRenderViewInMessengerButton = false;
    this.isShowViewInMessengerButton = false;
  }

  $postLink() {
    callFacebookSDK((FB) => {
      FB.Event.subscribe('send_to_messenger', (e) => {
        if (e.ref === this.ref) {
          // eslint-disable-next-line default-case
          switch (e.event) {
            case 'clicked':
              this.$scope.$evalAsync(() => {
                this._hideFBButton();
                this._showViewInMessengerButton();
              });
              break;
            case 'rendered':
              this.$scope.$evalAsync(() => {
                this.isLoadingFbButton = false;
              });
              break;
          }
        }
      });
      this._showFBButton();
    });
  }

  _showFBButton() {
    this.isShowFbButton = true;
    this.isLoadingFbButton = true;

    // watch angular rendered DOM ready
    setTimeout(() => {
      this._parceFbWitget();
    }, 0);
  }

  _hideFBButton() {
    this.isShowFbButton = false;
    this.isLoadingFbButton = false;
  }

  _showViewInMessengerButton() {
    this.isRenderViewInMessengerButton = true;

    // watch DOM ready after digest
    this.$timeout(() => {
      this.isShowViewInMessengerButton = true;
    });
  }

  _parceFbWitget() {
    const el = this.$element.find('.fb-plugin-box')[0];

    if (el) {
      callFacebookSDK((FB) => {
        FB.XFBML.parse(el);
      });
    }
  }

  _onViewInMessengerButtonClick() {
    window.open('https://m.me/chatfuel', '_blank');
  }
}
