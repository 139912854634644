import nanoid from 'nanoid';
import { EventEmitter } from '@utils/EventEmitter';
import { ModalEvent } from './events';
import * as types from './types';

export class ModalService {
  private modals: Record<
    types.ModalId,
    types.ModalData<types.ModalEventOptions>
  > = {};
  private queue: types.ModalId[] = [];
  private activeModalId?: types.ModalId;

  static create(eventEmitter: EventEmitter<types.ModalEventOptions>) {
    return new ModalService(eventEmitter);
  }

  constructor(
    private modalEventEmitter: EventEmitter<types.ModalEventOptions>,
  ) {}

  show<R, E = void>(
    getModal: types.ModalGetter<R, E>,
    options?: types.ModalEventOptions,
  ): types.ModalChainable<R, E> | null {
    if (
      !options?.allowModalDuplication &&
      ((options?.id && this.queue.includes(options.id)) ||
        (options?.name &&
          this.queue.some((modalId) => modalId.includes(options.name!))))
    ) {
      return null;
    }
    const modal = this.createModal<R, E>(getModal, options);
    this.addToQueue(modal.id);
    return modal;
  }

  addToQueue(modalId: types.ModalId) {
    if (!this.queue.includes(modalId)) {
      this.queue = [...this.queue, modalId];
    }
    this.processQueue();
  }

  showImmediate<R, E = void>(
    getModal: types.ModalGetter<R, E>,
    options?: types.ModalEventOptions,
  ): types.ModalChainable<R, E> {
    const modal = this.createModal<R, E>(getModal, {
      ...options,
      keepPreviousModal: true,
    });
    this.addToQueueTop(modal.id);
    return modal;
  }

  addToQueueTop(modalId: types.ModalId) {
    this.activeModalId = undefined;
    if (!this.queue.includes(modalId)) {
      this.queue = [modalId, ...this.queue];
    } else {
      this.queue = [modalId, ...this.queue.filter((id) => id !== modalId)];
    }
    this.processQueue();
  }

  removeFromQueue(modalId: types.ModalId) {
    if (this.activeModalId === modalId) {
      this.activeModalId = undefined;
    }
    this.queue = this.queue.filter((id) => id !== modalId);
    this.processQueue();
  }

  processQueue() {
    const [modalId] = this.queue;
    if (modalId && !this.activeModalId) {
      this.activeModalId = modalId;
      const modal = this.modals[modalId];
      this.modalEventEmitter.emit<types.ModalData>(
        ModalEvent.show,
        this.modals[modalId],
        modal.options,
      );
    }
  }

  private createModal<R, E = void>(
    getModal: types.ModalGetter<R, E>,
    options?: types.ModalEventOptions,
  ) {
    let onClose: types.CloseHandler;

    const modal = {
      id:
        options?.id || `${options?.name ? `${options.name}-` : ''}${nanoid()}`,
    } as types.ModalChainable<R, E>;

    modal.onClose = (handler) => {
      onClose = handler;
      return modal;
    };

    modal.close = (force: boolean = false) => {
      if (options?.preventDefaultClose && !force) {
        return;
      }
      this.modalEventEmitter.emit(ModalEvent.hide);
      delete this.modals[modal.id];
      onClose?.();
      this.removeFromQueue(modal.id);
    };

    const promise = this.createModalPromise<R, E>(getModal, modal, options);
    modal.then = promise.then.bind(promise);
    modal.catch = promise.catch.bind(promise);

    return modal;
  }

  private createModalPromise<R, E = void>(
    getModal: types.ModalGetter<R, E>,
    modal: types.ModalChainable<R, E>,
    options?: types.ModalEventOptions,
  ) {
    return new Promise<R | undefined>((resolve, reject) => {
      const content = getModal({
        resolve: (r) => {
          modal.close();
          resolve(r);
        },
        reject,
        close: modal.close,
      });

      this.modals[modal.id] = { id: modal.id, _ref: modal, content, options };
    });
  }

  hide(id: types.ModalId) {
    // eslint-disable-next-line no-underscore-dangle
    this.modals[id]?._ref.close();
  }

  hideAll() {
    Object.values(this.modals).forEach(({ _ref }) => _ref.close());
  }
}
