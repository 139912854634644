import { SHOPIFY_REMINDERS_REVENUE_FRAGMENT } from '@components/FlowBuilder/views/components/BlockStatsView/queries';
import { INTEGRATION_CALENDLY_ACCOUNT_STATE_FRAGMENT } from '@components/FlowBuilder/views/plugins/CalendlyIntegration/utils/getIntegrationCalendlyAccountIdObservable';
import gql from 'graphql-tag';
import { FLOW_BLOCK_FRAGMENT } from '@components/Plugins/common/PluginGQL';
import { BLOCK_FRAGMENT } from '@components/Aside/Mutations/GQL';
import {
  BOT_FEATURE_PERMISSIONS_FRAGMENT,
  BOT_FEATURES_FRAGMENT,
} from '@utils/Data/Bot';
import { ADMIN_FEATURES_FRAGMENT } from '@utils/Data/Admin';
import { BlockSelectorQuery } from '@pages/AiSetupPage/BlocksSelector';
import { FLOW_GROUPS_SUGGEST_FRAGMENT } from '@components/ButtonEditorPopup/common/ButtonPopupBlocksSelector';
import { AI_CUSTOM_TEMPLATES_FRAGMENT } from '../../../../modern-components/FlowBuilder/views/plugins/AiCustomTemplates/queries';

export const FLOW_STATS_FRAGMENT = gql`
  fragment flowStatsFragment on FlowBlocksStatsPayload {
    id
    blocks {
      id
      stats {
        sent
        seen
        clicked
        blocked
        failed
        attempted
      }
      counters {
        counterId
        id
        stats {
          sent
          seen
          clicked
          blocked
          failed
          attempted
        }
      }
    }
  }
`;

export const AGGREGATED_FLOW_QUERY = gql`
  query AggregatedFlowQuery(
    $botId: String!
    $flowId: ID!
    $flowBlockId: MongoObjectId!
    $features: [String!]!
    $locale: String
  ) {
    me {
      id
      name
      picture
      fbPermissions {
        hasInstagramAuthPermissions
        hasInstagramMessagingPermissions
      }
    }
    ...AdminFeaturesFragment
    bot(id: $botId) {
      id
      title
      button_whitelist
      timezone_offset
      instagram {
        connected
        connection_problem
        business_account {
          id
        }
        automation_enabled
      }
      status {
        read_only
        status
        page
        page_info {
          id
          title
          picture
          username
        }
        payments_status
      }
      block(id: $flowId) {
        ...blockFragment
      }
      ...BlocksSelector_blocksGroups
      ...FlowGroupsSuggest
      archiveBlocks {
        id
        title
        removed
        is_flow
        reachable
      }
      flow(id: $flowId) {
        id
        source_flow_id
        title
        collapsed
        platform
        sharing_params {
          sharing_enabled
          allow_cloning
        }
      }
      flowBlocks(id: $flowBlockId) {
        ...FlowBlockFragment
      }
      flow_groups {
        id
        title
        position
        collapsed
        flow_ids
        sharing_params {
          sharing_enabled
          allow_cloning
        }
      }
      ...BotFeaturesFragment
      ...BotFeaturePermissionsFragment
      segments {
        id
        name
        removed
        additional_ids
        segmentation {
          operation
          parameters {
            name
            values
            type
            operation
          }
        }
      }
      verifiedPermissions(features: $features) {
        id
        enabled
        permissions_group
      }
      connectedZapierAccounts {
        id
        admin_id
      }
      connectedShop {
        id
        name
        myshopify_domain
        domain
        currency
      }
      ...integrationCalendlyAccountStateFragment
      allowedPlatforms
    }
    flowStats(id: $flowId) {
      ...flowStatsFragment
    }
    shopifyRemindersRevenue(flowId: $flowId) {
      ...shopifyRemindersRevenueFragment
    }
    customAiTemplates(locale: $locale) {
      ...aiCustomTemplatesFragment
    }
  }
  ${ADMIN_FEATURES_FRAGMENT}
  ${BLOCK_FRAGMENT}
  ${BOT_FEATURES_FRAGMENT}
  ${FLOW_BLOCK_FRAGMENT}
  ${BOT_FEATURE_PERMISSIONS_FRAGMENT}
  ${SHOPIFY_REMINDERS_REVENUE_FRAGMENT}
  ${INTEGRATION_CALENDLY_ACCOUNT_STATE_FRAGMENT}
  ${FLOW_STATS_FRAGMENT}
  ${BlockSelectorQuery.blocksGroups}
  ${FLOW_GROUPS_SUGGEST_FRAGMENT}
  ${AI_CUSTOM_TEMPLATES_FRAGMENT}
`;
