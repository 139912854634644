import React from 'react';
import { AiIntentFilterType } from '@globals';
import { Item } from '@ui/SimpleCombobox';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';

interface FilterTypeItem extends Item {
  tooltipContent: React.ReactNode;
}

export const getFilterTypeItems = memoize(
  () =>
    [
      {
        id: AiIntentFilterType.default,
        title: i18next.t('pages.Keywords.KeywordGroups.CloseTo'),
        tooltipContent: i18next.t(
          'pages.Keywords.KeywordGroups.filterType.tooltips.closeTo',
        ),
      },
      {
        id: AiIntentFilterType.contains,
        title: i18next.t('pages.Keywords.KeywordGroups.Contains'),
        tooltipContent: i18next.t(
          'pages.Keywords.KeywordGroups.filterType.tooltips.contains',
        ),
      },
      {
        id: AiIntentFilterType.case_insensitive_match,
        title: i18next.t('pages.Keywords.KeywordGroups.Matches'),
        tooltipContent: i18next.t(
          'pages.Keywords.KeywordGroups.filterType.tooltips.matches',
        ),
      },
    ] as FilterTypeItem[],
);
