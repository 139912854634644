import { MIME_TO_EXT } from './mimeToExt';
import { UploadMimeTypes } from '../UploadService/types';

export const getExtStringByMimeTypes = (
  types: UploadMimeTypes | UploadMimeTypes[],
) =>
  (Array.isArray(types) ? types : [types])
    .map((mime) => {
      const ext = MIME_TO_EXT[mime];
      return Array.isArray(ext) ? ext.join(', ') : ext;
    })
    .join(', ')
    .toUpperCase();
