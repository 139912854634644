import React, { useCallback, useMemo } from 'react';
import { pathOr } from 'ramda';
import { Query } from '@apollo/react-components';
import { ApolloClient, ApolloError } from 'apollo-client';
import { useLazyQuery, useQuery } from 'react-apollo';
import { ATTRIBUTE_VALUES_QUERY, ATTRIBUTES_QUERY } from './AttributesUtilsGQL';
import { VariablesType, VariableSuggestType } from './AttributesUtilsTypes';
import {
  AttributesQuery,
  AttributesQuery_bot_variableSuggest as IAttribute,
  AttributesQueryVariables,
} from './@types/AttributesQuery';
import {
  AttributeValuesQuery,
  AttributeValuesQueryVariables,
} from './@types/AttributeValuesQuery';
import { Platform } from '@globals';

interface IAttributesDataProps {
  botId: string;
  platform?: Platform | null;
  children: (props: {
    loading: boolean;
    loadError: ApolloError | undefined;
    attributes: IAttribute[];
    refetchAttributes: () => void;
  }) => JSX.Element;
  client?: ApolloClient<any>;
}

export const AttributesData: React.FC<IAttributesDataProps> = ({
  children,
  botId,
  client,
  platform,
}) => (
  <Query<AttributesQuery, AttributesQueryVariables>
    query={ATTRIBUTES_QUERY}
    variables={{
      botId,
      platform: platform || Platform.facebook,
    }}
    skip={!botId}
    client={client}
  >
    {({ loading, error: loadError, data, refetch }) =>
      children({
        loading,
        loadError,
        attributes: pathOr(
          [],
          ['bot', 'variableSuggest'],
          data,
        ) as IAttribute[],
        refetchAttributes: () => refetch(),
      })
    }
  </Query>
);

export const useAttributes = (
  botId: string | undefined,
  suggestType: VariableSuggestType,
  platform?: Platform | null,
  excludeAttributes?: string[],
  allPlatforms: boolean = false,
) => {
  const { data, loading, refetch } = useQuery<
    AttributesQuery,
    AttributesQueryVariables
  >(ATTRIBUTES_QUERY, {
    skip: !botId,
    variables: { botId: botId ?? '', suggestType, platform, allPlatforms },
  });
  const attributes = useMemo(
    () =>
      (data?.bot.variableSuggest || []).filter(
        ({ name }) => !excludeAttributes?.includes(name),
      ),
    [data, excludeAttributes],
  );
  return {
    attributes,
    loading,
    refetch,
  };
};

export const useAttributesValues = (
  botId: string,
  type?: VariablesType,
  variable?: string,
  prefix = '',
) => {
  const { data, loading, refetch } = useQuery<
    AttributeValuesQuery,
    AttributeValuesQueryVariables
  >(ATTRIBUTE_VALUES_QUERY, {
    variables: { botId, variable: variable!, prefix, type },
    skip: !variable || !type,
  });

  return {
    attributeValuesSuggests: data?.bot.variableValueSuggest || [],
    loading,
    refetch,
  };
};

export const useAttributesValuesLazy = (
  botId: string,
  type?: VariablesType,
  variable?: string,
  prefix = '',
) => {
  const [getAttributeSuggestsRaw, { data, loading, refetch }] = useLazyQuery<
    AttributeValuesQuery,
    AttributeValuesQueryVariables
  >(ATTRIBUTE_VALUES_QUERY);

  const getAttributeSuggests = useCallback(() => {
    if (!variable || !type) {
      return;
    }

    getAttributeSuggestsRaw({
      variables: { botId, variable: variable!, prefix, type },
    });
  }, [botId, getAttributeSuggestsRaw, prefix, type, variable]);

  return [
    getAttributeSuggests,
    {
      attributeValuesSuggests: data?.bot.variableValueSuggest || [],
      loading,
      refetch,
    },
  ] as const;
};
