import client from '@common/services/ApolloService';
import { DIALOGS_COUNT_QUERY } from '@pages/LiveChat/common/queries';
import {
  DialogsCountQuery,
  DialogsCountQueryVariables,
} from '@pages/LiveChat/common/@types/DialogsCountQuery';
import { Folder, Platform } from '@globals';
import { FOLDERS, PLATFORMS, SEGMENTATION } from './consts';
import { mapQuery } from '../../../GQL/mapQuery';

export const updateDialogsCountInGqlCacheByPlatformAndFolder = (
  delta: number,
  botId: string,
  folder: Folder,
  platform: Platform | null,
) => {
  mapQuery<DialogsCountQuery, DialogsCountQueryVariables>(
    client,
    {
      query: DIALOGS_COUNT_QUERY,
      variables: {
        botId,
        folder,
        platform,
        segmentation: SEGMENTATION,
      },
    },
    (data) => {
      // eslint-disable-next-line no-param-reassign
      data.livechatConversationsCount.count += delta;
      return data;
    },
  );
};

export const updateDialogsCountInGqlCache = (delta: number, botId: string) => {
  PLATFORMS.forEach((platform) => {
    FOLDERS.forEach((folder) => {
      updateDialogsCountInGqlCacheByPlatformAndFolder(
        delta,
        botId,
        folder,
        platform,
      );
    });
  });
};
