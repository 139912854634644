import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { useToaster } from '@ui/Toaster';
import { resizeImage } from '@utils/Image';
import { getArrayFrom0ToN } from '@utils/ArrayUtils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { ImageMimeTypes } from '../PlatformSupportedFiles/mimeGroups';
import { getSupportedTypes } from '../PlatformSupportedFiles/getSupportedTypes';
import { FileAttachmentType, UploadMimeTypes } from '../UploadService/types';
import {
  getFileSizeLimit,
  MB_MULTIPLIER,
} from '../PlatformSupportedFiles/getFileSizeLimit';
import { dataURLToBlob } from '../Blob/dataUrlToBlob';

interface FileData {
  name: string;
  size: number;
  type: string;
}

export interface UploadFileChildrenProps {
  chooseFile: (type: FileAttachmentType) => void;
  ref: MutableRefObject<HTMLInputElement | null>;
  dropState: DropState;
}

export interface UploadFileProps extends TestLocator {
  multiple?: boolean;
  onFileChoose?(file: FileData, attachmentType: FileAttachmentType): void;
  onFileChange?(file: FileData, attachmentType: FileAttachmentType): void;
  onFileProcessed(
    fileData: FileData & { url?: string; blob?: Blob },
    attachmentType: FileAttachmentType,
  ): void;
  onFileSizeExceeded?(file: FileData): void;
  onFileWrongFormat?(files: FileData[]): void;
  children?: (props: UploadFileChildrenProps) => React.ReactNode;
  platform: Platform;
  disabled?: boolean;
  hideToasters?: boolean;
  attachmentTypeInit?: FileAttachmentType;
  className?: string;
}

export enum DropState {
  none = 'none',
  valid = 'valid',
  invalid = 'invalid',
}

const RESIZABLE_TYPES = [ImageMimeTypes.jpeg, ImageMimeTypes.png];

export const UploadFile: React.FC<UploadFileProps> = ({
  onFileChoose,
  onFileChange,
  onFileProcessed,
  onFileSizeExceeded,
  onFileWrongFormat,
  multiple,
  platform,
  hideToasters,
  attachmentTypeInit,
  'data-testid': dataTestId,
  children,
  className,
}) => {
  const { t } = useSafeTranslation();
  const { addToaster } = useToaster();
  const [dropState, setDropState] = useState<DropState>(DropState.none);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const attachmentTypeRef = useRef<FileAttachmentType | null>(null);

  useEffect(() => {
    if (attachmentTypeInit && !attachmentTypeRef.current) {
      attachmentTypeRef.current = attachmentTypeInit;
    }
  }, [attachmentTypeInit]);

  const chooseFile = useCallback(
    (attachmentType: FileAttachmentType) => {
      if (!fileInputRef.current) {
        return;
      }
      attachmentTypeRef.current = attachmentType;
      fileInputRef.current.accept = getSupportedTypes(
        attachmentType,
        platform,
      ).join(', ');
      fileInputRef.current.click();
    },
    [platform],
  );

  const clearFileInputValue = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const fileProcess = useCallback(
    (files: FileList) => {
      if (!attachmentTypeRef.current) {
        return;
      }
      const attachmentType = attachmentTypeRef.current;
      const acceptTypes = getSupportedTypes(attachmentType, platform);
      const fileSizeLimitMb = getFileSizeLimit(attachmentType, platform);

      if (files) {
        getArrayFrom0ToN(files.length).forEach((index) => {
          const file = files[index];
          if (!file) {
            return;
          }
          onFileChange?.(file, attachmentType);

          if (file.size > fileSizeLimitMb * MB_MULTIPLIER) {
            onFileSizeExceeded?.(file);
            if (!hideToasters) {
              addToaster({
                type: 'error',
                content: (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <Trans
                      t={t}
                      i18nKey="modernComponents.FileSelector.fileSizeExceededErrorText"
                      values={{ name: file.name, size: fileSizeLimitMb }}
                    />
                  </div>
                ),
                timeout: 5000,
                closeButton: true,
              });
            }
            clearFileInputValue();
            return;
          }

          if (
            attachmentType === FileAttachmentType.image &&
            !acceptTypes.includes(file.type as ImageMimeTypes)
          ) {
            onFileWrongFormat?.([file]);
            if (!hideToasters) {
              addToaster({
                type: 'error',
                content: (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {t(
                      'modernComponents.FileSelector.fileWrongFormatErrorText',
                      {
                        types: acceptTypes
                          .map((type) => type.split('/')[1])
                          .join(', '),
                      },
                    )}
                  </div>
                ),
                timeout: 5000,
                closeButton: true,
              });
            }

            return;
          }

          onFileChoose?.(file, attachmentType);

          if (RESIZABLE_TYPES.includes(file.type as ImageMimeTypes)) {
            resizeImage({ inputImage: file }).then((fileUrl) => {
              onFileProcessed(
                {
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  url: fileUrl,
                  blob: dataURLToBlob(fileUrl),
                },
                attachmentType,
              );
              clearFileInputValue();
            });
            return;
          }

          file.arrayBuffer().then((arrayBuffer) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], {
              type: file.type,
            });
            onFileProcessed(
              {
                name: file.name,
                size: file.size,
                type: file.type,
                blob,
              },
              attachmentType,
            );
            clearFileInputValue();
          });
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      platform,
      onFileChoose,
      onFileProcessed,
      onFileSizeExceeded,
      onFileWrongFormat,
      addToaster,
    ],
  );

  const handleFileChoose = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (!files) {
        return;
      }
      fileProcess(files);
    },
    [fileProcess],
  );

  const testSupportTypes = useCallback(
    (files: FileList) => {
      if (!attachmentTypeRef.current) {
        return false;
      }
      const allowedTypes = getSupportedTypes(
        attachmentTypeRef.current,
        platform,
      );
      return ![...files].some(
        ({ type }) => !allowedTypes.includes(type as UploadMimeTypes),
      );
    },
    [platform],
  );

  const handleDropFile = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { files } = event.dataTransfer;
      if (!testSupportTypes(files)) {
        setDropState(DropState.invalid);
        onFileWrongFormat?.([...files]);
        return;
      }
      fileProcess(files);
      setDropState(DropState.none);
    },
    [fileProcess, onFileWrongFormat, testSupportTypes],
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDropState(DropState.valid);
    },
    [],
  );

  const handleDragLeave = useCallback(() => {
    setDropState(DropState.none);
  }, []);

  return (
    <div
      className={className}
      onDrop={handleDropFile}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {children?.({ chooseFile, ref: fileInputRef, dropState })}
      <input
        ref={fileInputRef}
        type="file"
        multiple={multiple}
        style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
        onChange={handleFileChoose}
        data-testid={dataTestId}
      />
    </div>
  );
};
