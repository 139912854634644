import { useCallback, useEffect, useRef, useState } from 'react';
import { showInfoToaster } from '@services/MessageService';

const clipboardCopy = require('clipboard-copy');

interface UseCopyTextParams {
  showToaster?: boolean;
}

export const useCopyText = ({ showToaster }: UseCopyTextParams = {}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const timeoutRef = useRef<number | undefined>();

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  const copy = useCallback(
    (text: string) => {
      clipboardCopy(text);

      if (copied) {
        return;
      }

      if (showToaster) {
        showInfoToaster('common.ToasterMessages.copied');
      }

      setCopied(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
    [copied, showToaster],
  );

  return { copied, copy };
};
