import {
  useHistory,
  useRouteMatch,
  RouteProps,
  useLocation,
  useParams,
  matchPath,
} from 'react-router-dom';
import { useEffect } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { setCurrentGlobalBotId } from '../../common/services/RoleService';
import {
  UPDATE_CARD_QUERY_PARAMS,
  UPDATE_PLAN_MODAL_QUERY_PARAMS,
} from '@utils/Data/Pricing/consts';
import { assert } from '@utils/Assert';
import { Period, TierType, Platform } from '@globals';
import {
  DeepLinksMode,
  DeepLinksQueryParam,
} from '../../pages/DeepLinks/types';
import { PricingPlanType } from '../../pages/Plans/types';
import { ONBOARDING_TOUR } from '@components/MiniOnboarding/MiniOnboardingDialog/consts';

export enum BotTabs {
  automate = 'structure',
  flows = 'flows',
  liveChat = 'livechat',
  ai = 'ai-setup-page',
  people = 'users',
  contacts = 'contacts',
  reEngage = 'broadcast',
  configure = 'settings',
  grow = 'grow',
  home = 'home',
  analyze = 'statistics',
  upgrade = 'upgrade-page',
  plans = 'plans',
  billing = 'billing',
  keywords = 'keywords',
  firstSession = 'first-session',
  broadcastsIQ = 'broadcasts-IQ',
  broadcastsPage = 'broadcasts-page',
  createBroadcast = 'create-broadcast',
  broadcastPage = 'broadcast-page',
  paywall = 'paywall',
  referral = 'referral',
  broadcasts = 'broadcasts',
  waMigrationSetup = 'whatsapp-migration-setup',
  messagesTemplates = 'messages-templates',
}

export enum ReEngageType {
  auto = 'auto',
  now = 'now',
  sequence = 'sequence',
  schedule = 'schedule',
  sent = 'sent',
  flow = 'flow',
}

export const getTabLink = (
  tab: BotTabs,
  botId?: string,
  params?: BotPageRouteParams,
) => {
  const innerParams = params || {};
  if (!botId) {
    return '';
  }
  const cardId = innerParams.cardId ? `#${innerParams.cardId}` : '';
  switch (tab) {
    case BotTabs.automate:
      return `/bot/${botId}/${BotTabs.automate}/${
        innerParams.blockId || ''
      }${cardId}`;
    case BotTabs.flows: {
      const newSearchParams = new URLSearchParams();
      if (innerParams.blockId)
        newSearchParams.append('blockId', innerParams.blockId);
      if (innerParams.onboardingTour && innerParams.flowId)
        newSearchParams.append(ONBOARDING_TOUR, innerParams.flowId);
      return `/bot/${botId}/${BotTabs.flows}/${
        innerParams.flowId || ''
      }?${newSearchParams}`;
    }
    case BotTabs.reEngage:
      if (!innerParams.broadcastType) {
        return `/bot/${botId}/${BotTabs.reEngage}`;
      }
      if (innerParams.broadcastType === ReEngageType.sent) {
        return `/bot/${botId}/${BotTabs.reEngage}/sent`;
      }
      if (innerParams.broadcastType === ReEngageType.flow) {
        return `/bot/${botId}/${BotTabs.reEngage}/${innerParams.broadcastId}/flow/${innerParams.flowId}`;
      }
      return `/bot/${botId}/${BotTabs.reEngage}/${innerParams.blockId}/${
        innerParams.broadcastType === ReEngageType.auto
          ? ''
          : innerParams.broadcastType === ReEngageType.now
          ? '0/'
          : `${innerParams.broadcastId}/`
      }${innerParams.broadcastType}${cardId}`;
    case BotTabs.people:
      return `/bot/${botId}/${BotTabs.people}/${innerParams.segmentId || ''}`;
    case BotTabs.keywords:
      return `/bot/${botId}/${BotTabs.keywords}/${innerParams.platform || ''}${
        innerParams.page ? `/${innerParams.page}` : ''
      }`;
    case BotTabs.firstSession:
      return `/bot/${botId}/${BotTabs.firstSession}/${
        innerParams.platform || ''
      }`;
    case BotTabs.home: {
      const newSearchParams = new URLSearchParams();
      if (innerParams.dlMode)
        newSearchParams.append('dlMode', innerParams.dlMode);
      if (innerParams.onboardingTour)
        newSearchParams.append(ONBOARDING_TOUR, innerParams.onboardingTour);
      return `/bot/${botId}/${BotTabs.home}?${newSearchParams}`;
    }
    case BotTabs.broadcastsIQ:
      return `/bot/${botId}/${BotTabs.broadcastsIQ}`;
    case BotTabs.broadcastsPage:
      return `/bot/${botId}/${BotTabs.broadcastsPage}`;
    case BotTabs.createBroadcast:
      return `/bot/${botId}/${BotTabs.createBroadcast}`;
    case BotTabs.broadcastPage:
      return `/bot/${botId}/${BotTabs.broadcastPage}/${
        params?.broadcastId ?? ''
      }`;
    case BotTabs.waMigrationSetup:
      return `/bot/${botId}/${BotTabs.waMigrationSetup}`;
    default:
      return `/bot/${botId}/${tab}`;
  }
};

export const getBillingPageUrl = (
  botId: string,
  isDialogPricing: boolean,
  query?: string,
) =>
  isDialogPricing
    ? `/bot/${botId}/billing${query ? `?${query}` : ''}`
    : `/bot/${botId}/settings/scroll-to=monetization${
        query ? `?${query}` : ''
      }`;

export const getEntryPointsModalLink = (
  botId: string,
  flowId: string,
  platform: Maybe<Platform>,
) => {
  const params = new URLSearchParams({
    [DeepLinksQueryParam.mode]: DeepLinksMode.entryPointsModal,
    [DeepLinksQueryParam.platform]: platform || Platform.facebook,
    [DeepLinksQueryParam.flowId]: flowId,
  });
  const url = `/bot/${botId}/${BotTabs.flows}/${flowId}?${params.toString()}`;

  return url;
};

export const getDefaultTab = (botId: string) => getTabLink(BotTabs.home, botId);

export const useCurrentTab = () => {
  const match = useRouteMatch<BotPageRouteParams>('/bot/:botId/:tab');
  if (match && match.params) {
    return match.params.tab;
  }
  return undefined;
};

export const useReEngageRouteParams = () => {
  const match = useRouteMatch<BotPageRouteParams>(
    `/bot/:botId/${BotTabs.reEngage}/:blockId/:broadcastId/:broadcastType`,
  );
  if (!match) {
    return {};
  }
  return {
    blockId: match.params.blockId,
    broadcastId: match.params.broadcastId,
    broadcastType: match.params.broadcastType as ReEngageType,
  };
};

export const useCurrentBotId = () => {
  const matchBotPage = useRouteMatch<{ botId?: string }>('/bot/:botId');
  const matchOauth = useRouteMatch<{ botId?: string }>(
    '/oauth/:integration/:botId',
  );
  return matchBotPage?.params.botId || matchOauth?.params.botId || undefined;
};

const BROADCAST_PATH = '/bot/:botId/broadcast/:broadcastId';

export const useCurrentBroadcastId = () => {
  const matchBotPage = useRouteMatch<{ broadcastId?: string }>(BROADCAST_PATH);
  return matchBotPage?.params.broadcastId;
};

const MESSAGE_TEMPLATE_PATH = `/bot/:botId/${BotTabs.messagesTemplates}/:broadcastId`;

export const useCurrentMessageTemplateId = () => {
  const matchBotPage = useRouteMatch<{ broadcastId?: string }>(
    MESSAGE_TEMPLATE_PATH,
  );
  return matchBotPage?.params.broadcastId;
};

export const getCurrentBroadcastId = () =>
  matchPath<{ broadcastId?: string }>(window.location.pathname, {
    path: BROADCAST_PATH,
  })?.params.broadcastId;

export const useCurrentFlowId = () => {
  const matchFlowId = useRouteMatch<{ flowId?: string }>(
    '/bot/:botId/flows/:flowId',
  );
  return matchFlowId?.params.flowId;
};

export const useCurrentContactId = () => {
  const matchFlowId = useRouteMatch<{ contactId?: string }>(
    `/bot/:botId/${BotTabs.contacts}/:contactId`,
  );
  return matchFlowId?.params.contactId;
};

export const useCurrentRoutePath = (match: string | RouteProps) => {
  const { path } = useRouteMatch<BotPageRouteParams>(match) || { path: '' };
  return path;
};

export const useCurrentBlockId = () => {
  const { blockId } = useParams<{ blockId?: string }>();
  return blockId;
};

export const useFlowIdParam = () => {
  const { flowId } = useParams<{ flowId?: string }>();
  return flowId;
};

export const usePlatformParam = () => {
  const { platform } = useParams<{ platform?: Platform }>();
  return platform as Maybe<Platform>;
};

export const useFlowGroupIdParam = () => {
  const { flowGroupId } = useParams<{ flowGroupId?: string }>();
  return flowGroupId;
};

export const useAutomateTabLink = () => {
  const botId = useCurrentBotId();
  if (!botId) {
    return () => '';
  }
  return (blockId?: string) =>
    `/bot/${botId}/${BotTabs.automate}/${blockId || ''}`;
};

export const useFlowsTabLink = () => {
  const botId = useCurrentBotId();
  if (!botId) {
    return () => '';
  }
  return (blockId?: string) =>
    `/bot/${botId}/${BotTabs.flows}/${blockId || ''}`;
};

export const getPageToUpgrade = (
  botId: string,
  isDialogPricing: boolean,
  query?: string,
) =>
  isDialogPricing
    ? `/bot/${botId}/plans${query ? `?${query}` : ''}`
    : `/bot/${botId}/settings/scroll-to=monetization${
        query ? `?${query}` : ''
      }`;

export const useFlowTabParams = () => {
  const match = useRouteMatch<{ botId: string; flowId: string }>(
    '/bot/:botId/flows/:flowId',
  );
  return match?.params;
};

export const useAutomateTabParams = () => {
  const match = useRouteMatch<{ botId: string; blockId: string }>(
    '/bot/:botId/structure/:blockId',
  );
  return match?.params;
};

export const useWhatsappReEngageTabParams = () => {
  const match = useRouteMatch<{
    botId: string;
    broadcastId: string;
    flowId: string;
  }>('/bot/:botId/broadcast/:broadcastId/flow/:flowId');
  return match?.params;
};

export const useGoToTab = (botIdInit?: string) => {
  const botId = useCurrentBotId();
  const history = useHistory();
  return (tab: BotTabs) => {
    history.push(`/bot/${botIdInit || botId}/${tab}`);
  };
};

interface UseGoToPlansParams {
  plan: PricingPlanType;
  isDialogsPricing: boolean;
  botIdInit?: string;
}

export const useGoToPlans = ({
  botIdInit,
  plan,
  isDialogsPricing,
}: UseGoToPlansParams) => {
  const currentBotId = useCurrentBotId();
  const botId = botIdInit || currentBotId;
  const history = useHistory();

  return () => {
    assert(botId, { msg: 'botId should be defined' });

    const url = getPageToUpgrade(botId, isDialogsPricing, `plan=${plan}`);

    history.push(url);
  };
};

export const useGoToWhatsappBroadcast = () => {
  const history = useHistory();
  const botId = useCurrentBotId()!;

  return (broadcastId: string, flowId: string) => {
    history.push(`/bot/${botId}/broadcast/${broadcastId}/flow/${flowId}`);
  };
};

export const useGoToWhatsappBroadcastPage = () => {
  const history = useHistory();
  const botId = useCurrentBotId()!;

  return () => {
    history.push(`/bot/${botId}/broadcast`);
  };
};

export const useGoToUpdateCardInfo = () => {
  const history = useHistory();
  const botId = useCurrentBotId()!;

  return (isDialogsPricing: boolean) => {
    history.push(
      getBillingPageUrl(
        botId,
        isDialogsPricing,
        `${UPDATE_CARD_QUERY_PARAMS}=true`,
      ),
    );
  };
};

export const useSetCurrentGlobalBotId = () => {
  const botId = useCurrentBotId();
  useEffect(() => {
    if (botId) {
      setCurrentGlobalBotId(botId);
    }
  }, [botId]);
};

export function useLocationParams<T extends object>(): T {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return Object.fromEntries(params) as T;
}

export const useKeywordsTabParams = () => {
  const match = useRouteMatch<{
    botId: string;
    platform?: Platform;
    page?: string;
  }>('/bot/:botId/keywords/:platform/:page?');
  return {
    platform: undefined,
    ...match?.params,
    page: parseInt(match?.params.page || '1', 10), // первая страница имеет индекс 1 для более комфортного восприятия юзером в url
  };
};

export const useFirstSessionTabParams = () => {
  const match = useRouteMatch<{ botId: string; platform?: Platform }>(
    '/bot/:botId/first-session/:platform',
  );
  return match?.params || { platform: undefined };
};

export const useIsFirstSessionTab = () =>
  !!useRouteMatch('/bot/:botId/first-session');

export interface BotPageRouteParams {
  botId?: string;
  blockId?: string;
  tab?: BotTabs | string;
  broadcastId?: string;
  broadcastType?: ReEngageType | string;
  pageTabId?: string;
  segmentId?: string;
  createdElementType?: string;
  flowId?: string;
  cardId?: string;
  platform?: string;
  page?: string;
  dlMode?: string;
  onboardingTour?: string;
}

export const getPlansPageUrl = (
  botId: string,
  isDialogPricing: boolean,
  from?: string,
  tierPayload?: { tier: TierType; period?: Period },
) => {
  const query = new URLSearchParams();
  if (tierPayload)
    query.set(UPDATE_PLAN_MODAL_QUERY_PARAMS.tier, tierPayload.tier);
  if (tierPayload?.period)
    query.set(UPDATE_PLAN_MODAL_QUERY_PARAMS.period, tierPayload.period);
  const queryString = query.toString();

  return isDialogPricing
    ? `/bot/${botId}/plans${queryString ? `?${queryString}` : ''}`
    : `/bot/${botId}/upgrade-page${from ? `?from=${from}` : ''}`;
};

export const getPremiumPlansPageUrl = (tierPayload?: {
  tier: TierType;
  period?: Period;
}) => {
  const query = new URLSearchParams();
  if (tierPayload)
    query.set(UPDATE_PLAN_MODAL_QUERY_PARAMS.tier, tierPayload.tier);
  if (tierPayload?.period)
    query.set(UPDATE_PLAN_MODAL_QUERY_PARAMS.period, tierPayload.period);
  const queryString = query.toString();

  return `/premium-plans${queryString ? `?${queryString}` : ''}`;
};
