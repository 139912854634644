import { useWhatsappTemplates } from '@components/ChooseWhatsappTempalteDialog/hooks/useWhatsappTemplates';
import { useAdminFeatures } from '../../Data/Admin';
import { useWhatsappEnabled } from './useWhatsappEnabled';

export const useCreateWhatsappTemplateInCfEnable = () => {
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();
  const { isWhatsappEnabled, loading: isWhatsappEnabledLoading } =
    useWhatsappEnabled();
  const { templates, loading: templatesLoading } = useWhatsappTemplates();

  return {
    isCreateWhatsappTemplateInCfEnable:
      isWhatsappEnabled &&
      adminFeatures &&
      adminFeatures.create_wa_template_in_cf_enable &&
      (!adminFeatures.create_wa_template_for_clean_users_only ||
        templates.length === 0),
    loading:
      adminFeaturesLoading || templatesLoading || isWhatsappEnabledLoading,
  };
};
