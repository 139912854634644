import { useCallback } from 'react';
import { log } from 'cf-common/src/logger';
import { loadUserLocale } from '../../../../i18n';

export const useSetUserLocale = () => {
  return useCallback(async () => {
    try {
      await loadUserLocale();
    } catch (error) {
      log.warn({ error, msg: 'Could not set locale' });
    }
  }, []);
};
