import ng from 'angular';
import './popover.less';
import raf from 'raf';
import ResizeHandler from 'resize-sensor';
import template from './template.html';

const PASSIVE_CAPTURE_OPTIONS = {
  passive: true,
  capture: true,
};

const ALIGN = {
  left: 'left',
  right: 'right',
  bottom: 'bottom',
  top: 'top',
};

const DEFAULT_ANCHOR_ALIGN = {
  vertical: ALIGN.bottom,
  horizontal: ALIGN.left,
};

const DEFAULT_POPOVER_ALIGN = {
  vertical: ALIGN.top,
  horizontal: ALIGN.left,
};

class PopoverController {
  /**
   * --
   * @param {*} $element -
   * @param {*} $document -
   * @param {*} $window -
   * @param {*} $log -
   * @param {*} EventListenerService -
   */
  constructor($element, $document, $window, $log, EventListenerService) {
    'ngInject';

    this.log = $log.log.bind($log, 'cf-popover');
    this.$document = $document;
    this.$window = $window;
    this.$element = $element;
    this.EventListener = EventListenerService;

    // need to use ()=> here to have this ref;
    // called on resize, scroll etc to reposition self to anchor;
    let handling = false;
    /**
     * throttledNeedToRepositionSelfEventHandler - you need to reposition self on resize and other events; and you need to throtle it
     * for example on throttle;
     */
    this.throttledNeedToRepositionSelfEventHandler = () => {
      if (!handling && this.cfOpen === 'true' && this._getAnchor()) {
        handling = true;
        /**
         * try to update on animation frame to minimize flicking; esplecially on safari
         */
        raf(() => {
          this._setSameWidthIfFullWidth();
          const offsetToAnchor = this._getElementOffsetToAnchor(
            this.$element[0],
            this._getAnchor(),
            this.popoverOrigin,
            this.anchorOrigin,
          );
          if (this.popoverYOffset) {
            offsetToAnchor.y += this.popoverYOffset;
          }
          this.$element[0].style.left = `${Math.round(offsetToAnchor.x)}px`;
          this.$element[0].style.top = `${Math.round(offsetToAnchor.y)}px`;
          handling = false;
        });
      }
    };

    this._sensor = new ResizeHandler(
      this._getAnchor(),
      this.throttledNeedToRepositionSelfEventHandler,
    );
  }

  /**
   *
   */
  $onChanges() {
    // reposition self on any vars change;
    if (this.throttledNeedToRepositionSelfEventHandler) {
      // TODO:
      // NOTE:
      // No idea how it's possible for this condition to be false, but it is.
      // Investigate, it's probably a peculiarity of the way $onChanges
      // is called
      this.throttledNeedToRepositionSelfEventHandler();
    }
  }

  /**
   *   probably anchor is reinserted so this.anchor = $element[0].parentNode is not working
   * -> onInit() => this.anchor - ok. but then on scroll event this.anchor is undefined....
   * @return {HTMLElement} -
   * @private
   */
  _getAnchor() {
    return this.anchor || (this.$element[0] && this.$element[0].parentNode);
  }

  /**
   *
   */
  $onInit() {
    const {
      EventListener,
      $window,
      throttledNeedToRepositionSelfEventHandler,
    } = this;
    this.throttledNeedToRepositionSelfEventHandler();
    // TODO: add events listener only on open event;
    // and remove on close;
    EventListener.addEventListener(
      $window,
      'scroll resize',
      throttledNeedToRepositionSelfEventHandler,
      PASSIVE_CAPTURE_OPTIONS,
    );
  }

  /**   * if you get full width resize self to match anchor;
   * @private
   */
  _setSameWidthIfFullWidth() {
    const { fullWidth, width, $element } = this;
    const element = $element[0];
    if (fullWidth === 'true') {
      const element = $element[0];
      const anchor = this._getAnchor();
      this._setSameWidthForElementAsAnchor(element, anchor);
    }
    if (width !== undefined) {
      element.style.width = `${width}px`;
    }
  }

  /**
   * --
   * @param {HTMLElement} element -
   * @param {HTNLEelmenet} anchor -
   * @private
   */
  _setSameWidthForElementAsAnchor(element, anchor) {
    // we set element same width only if element (popover) is less then anchor
    // so we only stretch popover; many we need to doc this?
    if (element && anchor) {
      const { width: anchorWidth } = anchor.getBoundingClientRect();
      const { width: elementWidth } = element.getBoundingClientRect();
      element.style.width = `${Math.max(anchorWidth, elementWidth)}px`;
    }
  }

  /**
   * get left offset in px
   * @param {Rect} rect -
   * @param {string} align -
   * @return {number} - left offset in px
   * @private
   */
  _leftOffsetForRectWithAlign(rect, align) {
    switch (align.horizontal) {
      case ALIGN.left:
        return 0;
      case ALIGN.right:
        return rect.width;
      default:
        throw new Error(`
              _leftOffsetForRectWithAlign called with (_, ${align}) align.horizontal ${align.horizontal} not implemented;
            `);
    }
  }

  /**
   * get top offset in px
   * @param {Rect} rect -
   * @param {string} align -
   * @return {number} - left offset in px
   * @private
   */
  _topOffsetForRectWithAlign(rect, align) {
    switch (align.vertical) {
      case ALIGN.top:
        return 0;
      case ALIGN.bottom:
        return rect.height;
      default:
        throw new Error(`
              _topOffsetForRectWithAlign called with (_, ${align}) align.vertical ${align.vertical} not implemented;
            `);
    }
  }

  /**
   *
   * fixme: p3 refactor to service;
   * Align {vertical: string, horizontal: string}
   *
   * @param {HTMLElement} element -
   * @param {HTMLElement} anchor -
   * @param {Align} elementAlign -
   * @param {Align} anchorAlign -
   * @returns {{x:number, y:number}} -
   */
  _getElementOffsetToAnchor(
    element,
    anchor,
    elementAlign = DEFAULT_POPOVER_ALIGN,
    anchorAlign = DEFAULT_ANCHOR_ALIGN,
  ) {
    [element, anchor, elementAlign, anchorAlign].forEach((param, i) => {
      if (!param) {
        throw new Error(`
              ._positionElementToAnchor you should provide all params to a function;
              you provide ${param} at index ${i};
            `);
      }
    });
    const anchorRect = anchor.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    // position on left top conner;
    const x =
      anchorRect.left +
      this._leftOffsetForRectWithAlign(anchorRect, anchorAlign) -
      this._leftOffsetForRectWithAlign(elementRect, elementAlign);
    const y =
      anchorRect.top +
      this._topOffsetForRectWithAlign(anchorRect, anchorAlign) -
      this._topOffsetForRectWithAlign(elementRect, elementAlign);
    return {
      x,
      y,
    };
  }

  /**
   *
   */
  $onDestroy() {
    const {
      throttledNeedToRepositionSelfEventHandler,
      $window,
      EventListener,
    } = this;
    EventListener.removeEventListener(
      $window,
      'resize scroll',
      throttledNeedToRepositionSelfEventHandler,
    );

    if (this._sensor) {
      this._sensor.detach();
    }
  }
}
export default ng.module('app.ui.cfPopover', []).component('cfPopover', {
  template: () => template,
  controller: PopoverController,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    /**
     * @bindings: popoverYOffset: number
     * @optional
     * @default: 0;
     * @description: allow to position popover; should be in pixels;
     */
    popoverYOffset: '<',

    /**
     *
     * @description: we will position popover to this anchor by default parent element used for positioning;
     * @default: $element[0].parentNode
     *
     */
    anchor: '<',
    // boolean as a string?? :)
    cfOpen: '@',
    /**
     *
     * @type: boolean
     * @default: false;
     *
     */
    fullWidth: '@',

    /**
     *@property: {*} width - set width hard!
     */

    width: '<',

    /**
     *
     *  @type: {Align} {vertical: top | bottom, horizontal: left | right}
     *
     *  @description:
     *  we positioning popover near anchor; you do it by specifying two dots that should align one on the anchor another on the popover
     *  @example:
     *  anchorOrigin: {vertical: bottom, horizontal: left } &
     *  popoverOrigin: {vertical: top: horizontal: left}
     *
     *  will align left bottom conner of the anchor and left top conner of popover;
     *
     */

    /**
     * @type: {Align}
     * @default: {vertical: bottom, horizontal: left }
     */
    anchorOrigin: '<',
    /**
     * @type: {Align}
     * @default: {vertical: top, horizontal: left }
     */
    popoverOrigin: '<',
  },
}).name;
