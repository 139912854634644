import React from 'react';
import { ApolloError } from 'apollo-client';
import { Query } from '@apollo/react-components';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { BOT_FEATURES_QUERY } from './BotFeaturesQuery';
import {
  BotFeaturesQuery,
  BotFeaturesQuery_bot_features,
  BotFeaturesQueryVariables,
} from './@types/BotFeaturesQuery';

export function useBotFeatures(botId: string | undefined) {
  const { data, loading, error } = useQuery<
    BotFeaturesQuery,
    BotFeaturesQueryVariables
  >(BOT_FEATURES_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  return {
    botFeatures: data?.bot.features,
    botFeaturesLoading: loading,
    botFeaturesError: error,
  };
}

export function useBotFeaturesLazy() {
  const [query, { data, loading, error, called }] = useLazyQuery<
    BotFeaturesQuery,
    BotFeaturesQueryVariables
  >(BOT_FEATURES_QUERY);

  return {
    getBotFeatures: (botId: string) =>
      query({
        variables: {
          botId,
        },
      }),
    botFeatures: data?.bot.features,
    botFeaturesLoading: loading,
    botFeaturesError: error,
    botFeaturesCalled: called,
  };
}

interface BotFeaturesProps {
  botId: string;
  children: (data: {
    botFeaturesLoading: boolean;
    botFeaturesError: ApolloError | undefined;
    botFeatures: BotFeaturesQuery_bot_features | undefined;
  }) => JSX.Element | null;
}

export const BotFeatures: React.FC<BotFeaturesProps> = ({
  botId,
  children,
}) => (
  <Query<BotFeaturesQuery, BotFeaturesQueryVariables>
    query={BOT_FEATURES_QUERY}
    variables={{ botId }}
    skip={!botId}
  >
    {({ data, loading, error }) => {
      return (
        children &&
        children({
          botFeatures: data?.bot.features,
          botFeaturesLoading: loading,
          botFeaturesError: error,
        })
      );
    }}
  </Query>
);
