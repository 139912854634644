import { useCallback, useEffect } from 'react';
import { map, prop } from 'ramda';
import { MutationResult, useMutation } from 'react-apollo';
import { sendEvent } from '@utils/Analytics';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { CREATE_NEW_BOT_BY_TEMPLATE_MUTATION } from '../../../common/services/GQLqueries/BotGQService.const';
import { createNewBotByTemplateMutation } from '../../../common/services/GQLqueries/@types/createNewBotByTemplateMutation';
import { getIncrementName } from '../../../common/services/NamingService';
import { BotsListQuery } from '../../../common/services/GQLqueries/@types/BotsListQuery';
import { addToBotListCache } from './utils';

export const useCreateBotFromTemplate = (
  botListQuery?: BotsListQuery,
): [
  (id: string, name: string) => void,
  MutationResult<createNewBotByTemplateMutation>,
] => {
  const [createBot, queryResult] = useMutation<createNewBotByTemplateMutation>(
    CREATE_NEW_BOT_BY_TEMPLATE_MUTATION,
  );

  useEffect(() => {
    if (queryResult.error) {
      toaster.show({
        type: ServiceMessageType.error,
        payload: { message: Messages.couldNotCreateBot },
      });

      log.error({
        error: queryResult.error,
        data: {
          label: 'create_bot',
          requestId: getRequestIdFromApolloError(queryResult.error),
        },
      });
    }
  }, [queryResult]);

  const createBotFromTemplate = useCallback(
    async (id: string, name: string) => {
      const titles = botListQuery ? map(prop('title'), botListQuery.bots) : [];
      const newTemplateName = getIncrementName(name, titles);

      sendEvent({
        category: 'bot',
        action: 'add from template',
        propertyBag: {
          idFrom: id,
        },
      });

      await createBot({
        variables: {
          title: newTemplateName,
          idFrom: id,
        },
        update: (store, { data }) => {
          const newBot = data?.createBotByTemplate;
          if (newBot) addToBotListCache(newBot, undefined, undefined, store);
        },
      });
    },
    [botListQuery, createBot],
  );

  return [createBotFromTemplate, queryResult];
};
