import memoize from 'lodash-es/memoize';
import { sendEvent as sendAnalyticsEvent } from 'cf-common/src/analytics';

const pushEventToGTM = (event: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

interface CustomEvent {
  event: string;
  eventType?: 'event' | 'click' | 'show';

  [others: string]: any;
}

const CUSTOM_USER_HIT = 'custom_user_hit';
const CUSTOM_USER_EVENT = 'custom_user_event';
const EVENT_TYPE_DEPRECATED_HIT = 'deprecated_hit';
const DEFAULT_EVENT_TYPE = 'event';

export const prepareTextForEvent = (text?: string) =>
  text
    ?.replace(/<\/?[^>]+(>|$)/g, '')
    .trim()
    .substr(0, 255) || '';

export const sendEvent = (
  params: CustomEvent | PureAnalyticsEvent | string,
  immediate: boolean = false,
) => {
  if (typeof params === 'string') {
    // XXX: deprecated
    pushEventToGTM({
      event: CUSTOM_USER_HIT,
      eventType: EVENT_TYPE_DEPRECATED_HIT,
      url_hit: params,
    });
  } else if (params.category && params.action) {
    sendAnalyticsEvent(params as PureAnalyticsEvent, immediate);
  } else {
    const {
      event,
      eventType = DEFAULT_EVENT_TYPE,
      ...vars
    } = params as CustomEvent;
    pushEventToGTM({
      eventType,
      event: CUSTOM_USER_EVENT,
      eventName: event,
      ...vars,
    });
  }
};

export const sendEventOnceByCategoryAndAction = memoize(
  sendEvent,
  ({ category, action }) => `${category}${action}`,
);

const sendClientId = () => {
  window.ym(
    window.CHATFUEL_CONFIG.YA_METRIKA_ID,
    'getClientID',
    (clientId: string) => {
      sendEventOnceByCategoryAndAction({
        category: 'yandex metrika',
        action: 'client id loaded',
        propertyBag: {
          clientId,
        },
      });
    },
  );
};

export const sendClientIdViaYaMetrika = () => {
  if (window.ym) {
    sendClientId();
  } else {
    document.addEventListener(
      `yacounter${window.CHATFUEL_CONFIG.YA_METRIKA_ID}inited`,
      () => {
        sendClientId();
      },
    );
  }
};

export const sendHitYaMetrika = (hit: string) => {
  if (!window.ym) {
    return;
  }
  window.ym(window.CHATFUEL_CONFIG.YA_METRIKA_ID, 'hit', `#${hit}`);
};

export const sendTagClarity = (tag: string, value: string | string[]) => {
  if (!window.clarity) {
    return;
  }
  window.clarity('set', tag, value);
};

export const sendHitOnce = memoize((hit: string) => {
  sendHitYaMetrika(hit);
  sendTagClarity(hit, 'true');
});

interface SendEventToGAArgs {
  type: string;
  name: string;
  label: string;
}

export const sendEventToGA = ({ type, name, label }: SendEventToGAArgs) => {
  sendEvent({
    category: type,
    action: name,
    label,
  });
  pushEventToGTM({
    eventType: type,
    event: CUSTOM_USER_EVENT,
    eventName: name,
  });
};
