import gql from 'graphql-tag';

const STRIPE_STATUS_FRAGMENT = gql`
    fragment stripeStatusFragment on Bot  {
        id
        payments_stripe
    }
`

export const STRIPE_STATUS_QUERY = gql`
    query StripeStatusQuery($botId: String!) {
        bot(id: $botId) {
            ...stripeStatusFragment
        }
    }
    ${STRIPE_STATUS_FRAGMENT}
`;

export const STRIPE_CONNECT_URL_QUERY = gql`
    query StripeConnectUrlQuery {
        stripeConnectUrl
    }
`;

export const STRIPE_CONNECT_CONFIRM_MUTATION = gql`
    mutation StripeConnectConfirmMutation($botId: String!, $code: String!) {
        stripeConnectConfirm(botId: $botId, code: $code) {
            ...stripeStatusFragment
        }
    }
    ${STRIPE_STATUS_FRAGMENT}
`;

export const STRIPE_DISCONNECT_MUTATION = gql`
    mutation StripeDisconnectMutation($botId: String!) {
        stripeDisconnect(botId: $botId) {
            ...stripeStatusFragment
        }
    }
    ${STRIPE_STATUS_FRAGMENT}
`;