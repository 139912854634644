import { useLayoutEffect } from 'react';
import * as css from './useHideIntercom.css';

export const useHideIntercom = () => {
  useLayoutEffect(() => {
    document.body.classList.add(css.hideIntercom);
    return () => {
      document.body.classList.remove(css.hideIntercom);
    };
  }, []);
};
