import { useMutation } from 'react-apollo';
import {
  CreateWorkspaceInviteMutation,
  CreateWorkspaceInviteMutationVariables,
} from './@types/CreateWorkspaceInviteMutation';
import { CREATE_WORKSPACE_INVITE_MUTATION } from './mutations';

interface UseCreateWorkspaceInvitePayload {
  workspaceId: string;
  roleId: string;
}

export const useCreateWorkspaceInvite = (
  payload?: UseCreateWorkspaceInvitePayload,
) => {
  return useMutation<
    CreateWorkspaceInviteMutation,
    CreateWorkspaceInviteMutationVariables
  >(CREATE_WORKSPACE_INVITE_MUTATION, { variables: payload });
};
