import { useQuery } from 'react-apollo';
import { LightBotsQuery } from './@types/LightBotsQuery';
import { LightWorkspaceBotsQuery } from './@types/LightWorkspaceBotsQuery';
import { LIGHT_BOTS_QUERY, LIGHT_WORKSPACE_BOTS_QUERY } from './queries';
import { getFlatBots } from './utils';

export const useLightBots = () => {
  const { data, ...queryResult } = useQuery<LightBotsQuery>(LIGHT_BOTS_QUERY);

  return { lightBots: data?.lightBots, ...queryResult };
};

export const useLightWorkspaceBots = () => {
  const { data, ...queryResult } = useQuery<LightWorkspaceBotsQuery>(
    LIGHT_WORKSPACE_BOTS_QUERY,
  );

  return {
    flatBots: getFlatBots(data?.lightWorkspaceBots),
    lightWorkspaceBots: data?.lightWorkspaceBots,
    ...queryResult,
  };
};
