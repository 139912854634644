import gql from 'graphql-tag';

export const BOTS_SELECTOR_QUERY = gql`
  query BotsSelectorQuery {
    bots {
      id
      title
      allowedPlatforms
      status {
        status
        page_info {
          id
          picture
        }
      }
      pro {
        is_paused
        status
        debt
        days_before_cancel
        millis_before_cancel
        manual
      }
    }
  }
`;
