import {
  isWhatsappEnabled,
  IsWhatsappEnabledBot,
} from '@utils/Whatsapp/isWhatsappEnabled';
import Maybe from 'graphql/tsutils/Maybe';

export interface CalculateAutomateEnabledArgs {
  bot: IsWhatsappEnabledBot;
  adminFeatures: Maybe<{ disable_automate_tab: boolean }>;
}

export const calculateAutomateEnabled = ({
  bot,
  adminFeatures,
}: CalculateAutomateEnabledArgs) =>
  !(isWhatsappEnabled(bot) || adminFeatures?.disable_automate_tab);
