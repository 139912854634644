import gql from 'graphql-tag';
import { ADMIN_FEATURES_FRAGMENT } from '../Data/Admin';

// Workaround for https://github.com/apollographql/apollo-link-state/issues/140
export const LOCAL_DATA_QUERY = gql`
  query LocalDataQuery {
    token @client
    successAuth @client
  }
`;

export const LOCAL_SUCCESS_AUTH_MUTATION = gql`
  mutation LocalSuccessAuthMutation {
    successAuthRemove @client
  }
`;

export const ENV_QUERY = gql`
  query EnvQuery {
    env {
      APP_ID
      CF_BOT_TEST_PAGE_ID
      GTM_CONTAINER_ID
      LOG_LEVEL_SEND
      LOG_LEVEL_CONSOLE
      INTERCOM_APP_ID
      INTERCOM_CHAT_HIDDEN
      CF_ENV
      BOT_ANALYTICS_HOST
      STRIPE_PUBLIC_KEY
      FB_SDK_SRC
      GOOGLE_SDK_SRC
      GOOGLE_MAPS_API_KEY
    }
  }
`;

/**
 * TODO REMOVE "AdminFeaturesFragment" fragment after end ABt experiment "onboardingWithPendingPermissions"
 * we need preload abt_serialized field for prevent additional Me query for Abt (always happens right after)
 */
export const INIT_QUERY = gql`
  query InitQuery {
    me {
      id
      constructor_user_id
      invited
      terms_accepted
      fbPermissions {
        hasMinimalPermissions
      }
      facebook_account_connected
      original_account_type
      sanction_status {
        status
      }
    }
    ...AdminFeaturesFragment
  }
  ${ADMIN_FEATURES_FRAGMENT}
`;

export const SET_AUTH_MUTATION = gql`
  mutation SetAuth($token: String!) {
    tokenSet(token: $token) @client
    successAuthSet @client
  }
`;

export const ACCEPT_TERMS_MUTATION = gql`
  mutation AcceptTermsMutation {
    acceptTerms {
      id
      terms_accepted
    }
  }
`;
