export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_CURRENCY = 'USD';

export const LOCALES_FOR_CURRENCIES = {
  USD: 'en-US', // $
  RUB: 'ru-RU', // ₽
  BRL: 'pt-BR', // R$
  ARS: 'en-US', // ARS
  BDT: 'bn', // ৳
  KHR: 'km', // KHR
  CLP: 'en-US', // CLP
  COP: 'en-US', // COP
  EGP: 'ar-EG', // ج.م
  EUR: 'de-DE', // €
  HKD: 'en', // HK$
  IDR: 'id-ID', // Rp
  MXN: 'en-US', // MX$
  MNT: 'mn-MN', // MNT
  PEN: 'es-PE', // S/
  RON: 'ro-RO', // RON
  TWD: 'en-US', // NT$
  AUD: 'en-US', // A$
  MYR: 'en-MY', // RM
  THB: 'th-TH', // ฿
  PHP: 'en-PH', // ₱
  GBP: 'en-GB', // £
  PLN: 'pl-PL', // zł
  VND: 'vi-VN', // ₫
  ILS: 'en-IL', // ₪
  AED: 'ar-AE', // .د.إ
  SGD: 'en-US', // SGD
  MMK: 'my-MM', // MMK
  CAD: 'en-US', // CA$
  JOD: 'ar-JO', // 	د.أ
  CZK: 'cs-CZ', // Kč
  HUF: 'hu-HU', // Ft
  UAH: 'uk-UA', // грн
  INR: 'en-IN', // ₹
  GTQ: 'es-GT', // Q
} as const;
