import { PERMISSIONS_FRAGMENT } from '@utils/Data/Permissions/fragments';
import gql from 'graphql-tag';

export const WORKSPACE_BOT_FRAGMENT = gql`
  fragment workspaceBotFragment on Bot {
    id
    title
    last_opened_date
    last_opened_date_for_current_user
    date_added
    allowedPlatforms
    connectedShop {
      id
    }
    currentRole {
      permissions {
        ...permissionsFragment
      }
    }
    status {
      page_info {
        id
        title
        picture
        whatsapp_business_account {
          id
          name
        }
        whatsapp_phone {
          id
          display_phone_number
        }
      }
    }
    pro {
      is_paused
      status
      manual
    }
    dialogsPricing {
      current_tier_limit
      reached_limit
      charging_entity_type
      status
      hasDebt
    }
    time_limited_trial
  }

  ${PERMISSIONS_FRAGMENT}
`;

export const WORKSPACE_FRAGMENT = gql`
  fragment workspaceFragment on Workspace {
    id
    name
    createdAt
    updatedAt
    permission
    limitation {
      reached
      limit
    }
    subscription {
      status
      chargingEntityType
    }
    connectedPages {
      reached
      limit
    }
  }
`;
