import { useQuery } from 'react-apollo';
import {
  BotAllowedPlatformsQuery,
  BotAllowedPlatformsQueryVariables,
} from './@types/BotAllowedPlatformsQuery';
import { BOT_ALLOWED_PLATFORMS_QUERY } from './queries';
import Maybe from 'graphql/tsutils/Maybe';

export const useBotAllowedPlatforms = (botId: Maybe<string>) => {
  const { data, ...queryState } = useQuery<
    BotAllowedPlatformsQuery,
    BotAllowedPlatformsQueryVariables
  >(BOT_ALLOWED_PLATFORMS_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  return {
    allowedPlatforms: data?.bot.allowedPlatforms,
    ...queryState,
  };
};
