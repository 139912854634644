import ng from 'angular';
import './modal.less';
import template from './template.html';

class ModelController {
  /**
   *
   * @param {*} $element -
   * @param {*} $document -
   * @param {*} $transclude -
   */
  constructor($element, $document, $transclude) {
    'ngInject';

    this.$document = $document;
    this.$transclude = $transclude;
    this.modalContent = $element.find('modal-content');
    this.$element = $element;
    if (this.context) {
      this.context.getContentViewRect = this.getContentViewRect.bind(this);
    }
  }

  /**
   * depending on are we showing modal action return curren view size;
   * @return {{top: ClientRect.top, left: ClientRect.left, width: ClientRect.width, height: number}} - rect
   */
  getContentViewRect() {
    const { modalContent, $element } = this;
    this.modalActions =
      this.modalActions || $element.find('internal-modal-actions');
    const { modalActions } = this;

    const actionsProvided = this.isModalActionsProvided();
    const modalContentRect = modalContent[0].getBoundingClientRect();
    let modalContentHeight = 0;
    if (actionsProvided && modalActions) {
      const actionsRect = modalActions[0].getBoundingClientRect();
      modalContentHeight = actionsRect.height;
    }
    // modalContentRect content rect is read only and you cannot destruct it (eg. {...modalContent} so doing it field by field;
    const { top, left, height, width } = modalContentRect;
    return {
      top,
      left,
      width,
      height: height - modalContentHeight,
    };
  }

  /**
   *
   * @return {boolean} - if user actions ( place for buttons in the bottom) provided or not
   */
  isModalActionsProvided() {
    return this.$transclude.isSlotFilled('modalActions');
  }

  /**
   * we add class to body to prevent scrolling of background on win
   */
  $onInit() {
    this.$document[0].body.classList.add('no-scroll-modal-open');
  }

  /**
   * clean up
   */
  $onDestroy() {
    this.$document[0].body.classList.remove('no-scroll-modal-open');
  }

  /**
   * depending in theme get cross button style;
   * @return {string} - cross button type;
   */
  getCloseButtonIconType() {
    const themesUseWhiteCloseButton = ['blue', 'transparent'];
    return themesUseWhiteCloseButton.includes(this.theme)
      ? 'cross-white'
      : 'cross';
  }
}

export default ng.module('app.ui.modal', []).component('modal', {
  controllerAs: 'vm',
  transclude: {
    modalActions: '?modalactions',
  },
  bindings: {
    onRequestClose: '&',
    modalContainerStyle: '@',
    modalContentClass: '@',
    modalActionContainerClass: '@',
    /**
     *
     * @description: Theme of modal popup, need for window and close button style
     */
    theme: '@',
    /**
     *
     * @description: very strange approach ... but listen the story;
     *
     * Some times you need to call function on a child element, for example in modal I need to know the size of the content view => getContentViewRect();
     *
     * in angular there no know to me way to call method on child element controller instanse;
     *
     * Solution: so you pass object as attribute and on init modal will add getContentViewRect function to this context object so you can call it;
     *
     */
    context: '<',
  },
  template: () => template,
  controller: ModelController,
}).name;
