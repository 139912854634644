import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  portalElementId?: string;
  portalElement?: HTMLElement;
}

export class Portal extends React.PureComponent<PortalProps> {
  wrapperElement: HTMLDivElement;
  portalElement: HTMLElement;

  constructor(props: PortalProps) {
    super(props);
    this.wrapperElement = document.createElement('div');
    const { portalElementId } = props;
    const portalElement =
      props.portalElement ??
      (portalElementId ? document.getElementById(portalElementId) : null);

    if (!portalElement) {
      throw new Error(
        `element not found by portalElementId=${portalElementId}`,
      );
    }

    this.portalElement = portalElement;
  }

  componentDidMount() {
    this.portalElement.appendChild(this.wrapperElement);
  }

  componentWillUnmount() {
    this.portalElement.removeChild(this.wrapperElement);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.wrapperElement);
  }
}
