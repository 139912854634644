import gql from 'graphql-tag';

export const BOT_PAGE_STATUS_QUERY = gql`
  query BotPageStatusQuery($botId: String!) {
    bot(id: $botId) {
      id
      status {
        status
      }
    }
  }
`;
