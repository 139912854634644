import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import gql from 'graphql-tag';
import { log } from 'cf-common/src/logger';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import {
  IntegrationZapierOauthUrlQuery,
  IntegrationZapierOauthUrlQueryVariables,
} from './@types/IntegrationZapierOauthUrlQuery';
import client from '../../../common/services/ApolloService';

const INTEGRATION_ZAPIER_OAUTH_URL = gql`
  query IntegrationZapierOauthUrlQuery($state: String!) {
    getIntegrationZapierOauthUrl(state: $state)
  }
`;

export interface StartIntegrationZapierOAuthState {
  botId: string;
  flowId: string;
  blockId: string;
}

export const startIntegrationZapierOAuth = async (
  oAuthState: StartIntegrationZapierOAuthState,
) => {
  try {
    const { data } = await client.query<
      IntegrationZapierOauthUrlQuery,
      IntegrationZapierOauthUrlQueryVariables
    >({
      query: INTEGRATION_ZAPIER_OAUTH_URL,
      variables: {
        state: JSON.stringify(oAuthState),
      },
    });
    if (data) {
      window.location.replace(data.getIntegrationZapierOauthUrl);
    }
  } catch (error) {
    log.error({
      error,
      msg: `Error get Zapier oAuth url`,
      data: {
        label: 'zapier',
        requestId: getRequestIdFromApolloError(error as any),
      },
    });
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: Messages.somethingWentWrong,
      },
    });
  }
};
