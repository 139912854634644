import client from '@common/services/ApolloService';
import { WorkspacesAvailableQuery } from '@utils/Data/Workspaces/@types/WorkspacesAvailableQuery';
import { WORKSPACES_AVAILABLE_QUERY } from '@utils/Data/Workspaces/queries';

export const updateWorkspaceAvailableCache = (workspacesAvailable: boolean) => {
  const data = client.readQuery<WorkspacesAvailableQuery>({
    query: WORKSPACES_AVAILABLE_QUERY,
  });
  if (!data) return;
  client.writeQuery<WorkspacesAvailableQuery>({
    query: WORKSPACES_AVAILABLE_QUERY,
    data: { me: { ...data.me, workspacesAvailable } },
  });
};
