import noop from 'lodash-es/noop';

interface OnMousemoveCallback {
  (p: { deltaX: number; deltaY: number }): void;
}

interface OnMouseupCallback {
  (): void;
}

interface DistanceToEdgeLimitations {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

function normalize(n: number, min: number, max: number): number {
  return Math.max(Math.min(n, max), min);
}

export class MousemoveTracker {
  protected initialX: number | null = null;
  protected initialY: number | null = null;
  protected onMousemoveCallback: OnMousemoveCallback | null = null;
  protected onMouseupCallback: OnMouseupCallback | null = null;
  protected distanceToEdgeLimitations: DistanceToEdgeLimitations | null = null;
  private removeListener: VoidFunction = noop;

  /**
   * onMousemoveCallback will call on each mousemove.
   * Argument is delta coordinates between start point and current.
   */
  startTrack = (
    onMousemoveCallback: OnMousemoveCallback,
    onMouseupCallback: OnMouseupCallback,
    distanceToEdgeLimitations: DistanceToEdgeLimitations,
    touchEvent?: boolean,
  ): void => {
    this.onMousemoveCallback = onMousemoveCallback;
    this.onMouseupCallback = onMouseupCallback;
    this.distanceToEdgeLimitations = distanceToEdgeLimitations;
    if (touchEvent) {
      const start = (e: TouchEvent) => {
        this.startResizing(e.touches[0]);
      };
      window.addEventListener('touchmove', start);
      window.addEventListener('touchend', this.stopResizing);
      this.removeListener = () => {
        window.removeEventListener('touchmove', start);
        window.removeEventListener('touchend', this.stopResizing);
      };
    } else {
      window.addEventListener('mousemove', this.startResizing);
      window.addEventListener('mouseup', this.stopResizing);
      this.removeListener = () => {
        window.removeEventListener('mousemove', this.startResizing);
        window.removeEventListener('mouseup', this.stopResizing);
      };
    }
  };

  stopTrack = () => {
    this.stopResizing();
  };

  protected startResizing = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }): void => {
    if (this.initialX === null || this.initialY === null) {
      this.initialX = clientX;
      this.initialY = clientY;
      return;
    }
    if (!this.onMousemoveCallback) {
      return;
    }
    const normalizedX = normalize(
      clientX,
      this.distanceToEdgeLimitations?.left ?? 0,
      document.body.clientWidth - (this.distanceToEdgeLimitations?.right ?? 0),
    );
    const normalizedY = normalize(
      clientY,
      this.distanceToEdgeLimitations?.top ?? 0,
      document.body.clientHeight -
        (this.distanceToEdgeLimitations?.bottom ?? 0),
    );
    this.onMousemoveCallback({
      deltaX: normalizedX - this.initialX,
      deltaY: normalizedY - this.initialY,
    });
  };

  protected stopResizing = (): void => {
    this.initialX = null;
    this.initialY = null;
    this.onMousemoveCallback = null;
    this.distanceToEdgeLimitations = null;
    this.removeListener();
    this.removeListener = noop;
    if (this.onMouseupCallback) {
      this.onMouseupCallback();
      this.onMouseupCallback = null;
    }
  };
}
