import gql from 'graphql-tag';

export const CONNECT_HUBSPOT_MUTATION = gql`
  mutation ConnectHubSpotMutation($botId: String!, $code: String!) {
    connectHubSpot(botId: $botId, code: $code)
  }
`;

export const DISCONNECT_HUBSPOT_MUTATION = gql`
  mutation DisconnectHubSpotMutation($botId: String!) {
    disconnectHubSpot(botId: $botId)
  }
`;
