import gql from 'graphql-tag';

export const CURRENCIES_QUERY = gql`
  query CurrenciesQuery {
    currencies {
      code
      country
      decimal_digits
      digitalCode
      name
      symbol
      stripeMinimalAmountUnits
    }
  }
`;
