import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from 'cf-common/src/analytics';
import React, { useCallback, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { clearMyShopifyDomain } from '../clearMyShopifyDomain';
import { ShopifyDomainInput } from '../ShopifyDomainInput';
import { useShopifyAccount } from '../useShopifyAccount';
import * as css from './ShopifyAccount.css';

export interface ShopifyAccountProps {
  interruptedHref?: string;
}

enum ShopifyAccountStatus {
  loading = 'loading',
  error = 'error',
  connectStore = 'connectStore',
  disconnectStore = 'disconnectStore',
}
const getShopifyAccountStatus = ({
  account,
  loading,
  connectAccountInProgress,
  connectAccountError,
  connectAccountInCalled,
}: {
  account: any;
  loading: boolean;
  connectAccountInProgress: boolean;
  connectAccountError: any;
  connectAccountInCalled: boolean;
}) => {
  if (
    connectAccountInProgress ||
    loading ||
    (connectAccountInCalled && !connectAccountError)
  ) {
    return ShopifyAccountStatus.loading;
  }
  if (account) {
    return ShopifyAccountStatus.disconnectStore;
  }
  if (
    !loading &&
    !account &&
    (!connectAccountInCalled || connectAccountError)
  ) {
    return ShopifyAccountStatus.connectStore;
  }
  return ShopifyAccountStatus.error;
};

export const ShopifyAccount: React.FC<ShopifyAccountProps> = ({
  interruptedHref,
}) => {
  const { t } = useSafeTranslation();
  const [domain, setDomain] = useState<string>('');
  const {
    account,
    loading,
    goToConnectAccount,
    connectAccountError,
    connectAccountInProgress,
    connectAccountInCalled,
  } = useShopifyAccount();

  const connectAccount = useCallback(
    (domain: string) => {
      goToConnectAccount(domain, interruptedHref);
    },
    [interruptedHref, goToConnectAccount],
  );

  const status = getShopifyAccountStatus({
    account,
    loading,
    connectAccountInCalled,
    connectAccountError,
    connectAccountInProgress,
  });

  return (
    <div style={{ height: 36 }}>
      {status === ShopifyAccountStatus.loading && <CenteredLoader />}

      {status === ShopifyAccountStatus.disconnectStore && (
        <Flex alignItems="center">
          <Type size="15px_DEPRECATED">{account!.myshopify_domain}</Type>
          <Spacer horizontalFactor={2} />
          <Button intent="secondary">{t('shopify.disconnect')}</Button>
        </Flex>
      )}

      {status === ShopifyAccountStatus.connectStore && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (domain) {
              connectAccount(clearMyShopifyDomain(domain));
            }
            sendEvent({
              category: 'flows',
              label: 'shopify account',
              action: 'connect click',
            });
          }}
        >
          <Flex>
            <ShopifyDomainInput
              value={domain}
              onChange={setDomain}
              error={!!connectAccountError}
              className={css.domainInput}
              data-testid="connect-shopify-account-dialog_domain-input"
            />
            <Spacer horizontalFactor={2} />
            <Button
              style={{
                width: 150,
              }}
              intent="primary"
              type="submit"
              disabled={!domain}
              data-testid="connect-shopify-account-dialog_connect"
            >
              {t('shopify.connect')}
            </Button>
          </Flex>
        </form>
      )}
    </div>
  );
};
