import gql from 'graphql-tag';

export const GET_ADS_JSON_QUERY = gql`
  query GetAdsJsonQuery($botId: String!, $blockId: String!) {
    bot(id: $botId) {
      id
      adsJson(blockId: $blockId) {
        json
      }
    }
  }
`;
