import { useQuery } from 'react-apollo';
import { KOMMO_INTEGRATION_STATUS_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';
import { QueryHookOptions } from '@apollo/react-hooks';
import {
  KommoIntegrationStatusQuery,
  KommoIntegrationStatusQueryVariables,
} from './@types/KommoIntegrationStatusQuery';
import client from '@common/services/ApolloService';

export const useKommoIntegrationStatus = (
  payload?: QueryHookOptions<
    KommoIntegrationStatusQuery,
    KommoIntegrationStatusQueryVariables
  >,
) => {
  const botId = useCurrentBotId();
  return useQuery<
    KommoIntegrationStatusQuery,
    KommoIntegrationStatusQueryVariables
  >(KOMMO_INTEGRATION_STATUS_QUERY, {
    variables: {
      botId: botId!,
    },
    ...payload,
  });
};

export const queryKommoIntegrationStatus = ({ botId }: { botId: string }) =>
  client.query<
    KommoIntegrationStatusQuery,
    KommoIntegrationStatusQueryVariables
  >({
    query: KOMMO_INTEGRATION_STATUS_QUERY,
    variables: {
      botId,
    },
  });
