import { useLazyQuery } from 'react-apollo';
import {
  KommoOauthUrlQuery,
  KommoOauthUrlQueryVariables,
} from './@types/KommoOauthUrlQuery';
import { KOMMO_OAUTH_URL_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';
import { LazyQueryHookOptions } from '@apollo/react-hooks';

export const useKommoOauthUrl = (
  payload?: LazyQueryHookOptions<
    KommoOauthUrlQuery,
    KommoOauthUrlQueryVariables
  >,
) => {
  const botId = useCurrentBotId();
  return useLazyQuery<KommoOauthUrlQuery, KommoOauthUrlQueryVariables>(
    KOMMO_OAUTH_URL_QUERY,
    {
      variables: {
        botId: botId!,
      },
      ...payload,
    },
  );
};
