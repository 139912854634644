import ng from 'angular';

export default ng.module('app.ui.addIconBig', []).component('addIconBig', {
  controllerAs: 'vm',
  template: () => `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 40" xml:space="preserve">
      <rect x="19"  width="2" height="40"/>
      <rect y="19"  width="40" height="2"/>
      </svg>
    `,
}).name;
