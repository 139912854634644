import { Platform } from '@globals';
import { FilesMimeTypes } from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_PDF_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [FilesMimeTypes.pdf],
  [Platform.instagram]: [FilesMimeTypes.pdf],
  [Platform.whatsapp]: [FilesMimeTypes.pdf],
};

export const PDF_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 15,
  [Platform.instagram]: 15,
  [Platform.whatsapp]: 15,
};
