import gql from 'graphql-tag';
import { ADMIN_FEATURES_FRAGMENT } from '../Data/Admin';
import { FullAdminDataQuery } from './@types/FullAdminDataQuery';
import client from '../../common/services/ApolloService';

const FULL_ADMIN_DATA_QUERY = gql`
  query FullAdminDataQuery {
    me {
      id
      name
      picture
      email
      email_verification_status
      constructor_user_id
      support_available
      invited
      terms_accepted
      pages_access
      fbPermissions {
        hasMinimalPermissions
        hasAllPermissions
        hasValidToken
        hasManagePagesPermissions
        hasMarketingPermissions
        hasInstagramAuthPermissions
      }
      facebook_account_connected
    }
    ...AdminFeaturesFragment
  }
  ${ADMIN_FEATURES_FRAGMENT}
`;

export const useRefetchAdminData = () => () =>
  client.query<FullAdminDataQuery>({
    query: FULL_ADMIN_DATA_QUERY,
    fetchPolicy: 'network-only',
  });
