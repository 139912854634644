import { useAlvis } from '@utils/Alvis/useAlvis';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { useEffect } from 'react';

export const useTemplateCheckList = () => {
  const { showChecklist, hideChecklist } = useAlvis();

  const [templateCheckListId] = useServerStorage<string>(
    ServerStorageItemKeys.templateCheckListId,
  );

  useEffect(() => {
    if (templateCheckListId) showChecklist(templateCheckListId);
  }, [templateCheckListId, showChecklist]);

  useEffect(
    () => () => {
      hideChecklist();
    },
    [hideChecklist],
  );
};
