const BYTE_TO_MB_COEF = 1e-6;

export const isFileSizeSameOrLess = (file: File | Blob, maxSizeMb: number) =>
  file.size * BYTE_TO_MB_COEF <= maxSizeMb;

export enum ImageMIMEType {
  default = 'image/*',
  jpeg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+xml',
  tiff = 'image/tiff',
  webp = 'image/webp',
}

export const getExtFromFileName = (name: string) => name.split('.').pop();

export const getImageMIMETypeByExt = (extension: string) => {
  switch (extension) {
    case 'png':
      return ImageMIMEType.png;
    case 'jpeg':
    case 'jpg':
      return ImageMIMEType.jpeg;
    case 'svg':
      return ImageMIMEType.svg;
    case 'tiff':
      return ImageMIMEType.tiff;
    case 'webp':
      return ImageMIMEType.webp;
    default:
      return undefined;
  }
};
