/**
 * Formats number to thousands.
 *  12 -> "0K"
 *  123 -> "0.1K"
 *  1234 -> "1.2K"
 *  12345 -> "12.3K"
 */
export const formatThousands = (x: number) => {
  if (Number.isNaN(x)) {
    throw new Error(`Cannot format NaN`);
  }

  const sign = x < 0 ? '-' : '';
  const thousands = Math.trunc(Math.abs(x) / 1000);
  const decimal = Math.abs(x) % 1000;

  if (!decimal || decimal <= 99) {
    return `${sign}${thousands}K`;
  }

  const hundreds = Math.trunc(decimal / 100);

  return `${sign}${thousands}.${hundreds}K`;
};
