import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import {
  JUST_SENT_LIVECHAT_MESSAGES_ADD_MUTATION,
  JUST_SENT_LIVECHAT_MESSAGES_QUERY,
  JUST_SENT_LIVECHAT_MESSAGES_REMOVE_MUTATION,
} from './queries';
import { JustSentLivechatMessagesQuery } from './@types/JustSentLivechatMessagesQuery';
import {
  JustSentLivechatMessagesAddMutation,
  JustSentLivechatMessagesAddMutationVariables,
} from './@types/JustSentLivechatMessagesAddMutation';
import {
  JustSentLivechatMessagesRemoveMutation,
  JustSentLivechatMessagesRemoveMutationVariables,
} from './@types/JustSentLivechatMessagesRemoveMutation';

export const useJustSentLivechatMessages = () => {
  const { data } = useQuery<JustSentLivechatMessagesQuery>(
    JUST_SENT_LIVECHAT_MESSAGES_QUERY,
  );

  return data?.justSentLivechatMessageIds ?? [];
};

export const useJustSentLivechatMessagesActions = () => {
  const [invokeJustSentLivechatMessageIdAdd] = useMutation<
    JustSentLivechatMessagesAddMutation,
    JustSentLivechatMessagesAddMutationVariables
  >(JUST_SENT_LIVECHAT_MESSAGES_ADD_MUTATION, {
    refetchQueries: [{ query: JUST_SENT_LIVECHAT_MESSAGES_QUERY }],
  });

  const [invokeJustSentLivechatMessageIdRemove] = useMutation<
    JustSentLivechatMessagesRemoveMutation,
    JustSentLivechatMessagesRemoveMutationVariables
  >(JUST_SENT_LIVECHAT_MESSAGES_REMOVE_MUTATION, {
    refetchQueries: [{ query: JUST_SENT_LIVECHAT_MESSAGES_QUERY }],
  });

  const addJustSentLivechatMessageId = useCallback(
    (messageId: string) =>
      invokeJustSentLivechatMessageIdAdd({
        variables: {
          messageId,
        },
      }),
    [invokeJustSentLivechatMessageIdAdd],
  );

  const removeJustSentLivechatMessageId = useCallback(
    (messageId: string) =>
      invokeJustSentLivechatMessageIdRemove({
        variables: {
          messageId,
        },
      }),
    [invokeJustSentLivechatMessageIdRemove],
  );

  return {
    addJustSentLivechatMessageId,
    removeJustSentLivechatMessageId,
  };
};
