interface ResizeImageParams {
  inputImage: File;
  maxImageSize?: number;
}

export const resizeImage = async ({
  inputImage,
  maxImageSize = 1800,
}: ResizeImageParams) => {
  return new Promise<string>((resolve, reject) => {
    if (!inputImage.type.match(/image.*/)) {
      reject(new Error('File not an image'));
    }

    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
      let { width, height } = image;
      if (width > height) {
        if (width > maxImageSize) {
          height *= maxImageSize / width;
          width = maxImageSize;
        }
      } else if (height > maxImageSize) {
        width *= maxImageSize / height;
        height = maxImageSize;
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      return canvas.toDataURL('image/jpeg');
    };

    reader.onload = (readerEvent) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target?.result as string;
    };
    reader.readAsDataURL(inputImage);
  });
};
