import { useState } from 'react';
import {
  ParameterFilterValueOperator,
  ParametersOperator,
  SegmentationInput,
  SegmentationParameterInput,
  UserAttributeType,
} from '@globals';

export const DEFAULT_SEGMENTATION_PARAMETER: SegmentationParameterInput = {
  name: '',
  values: [],
  operation: ParameterFilterValueOperator.is,
  type: UserAttributeType.system,
};

export const DEFAULT_SEGMENTATION: SegmentationInput = {
  operation: ParametersOperator.and,
  parameters: [DEFAULT_SEGMENTATION_PARAMETER],
};

export const useSegmentation = (initialSegmentation: SegmentationInput) => {
  const [segmentation, setSegmentation] =
    useState<SegmentationInput>(initialSegmentation);

  const createNewParameter = () =>
    setSegmentation((segmentation) => ({
      ...segmentation,
      parameters: [
        ...(segmentation.parameters || []),
        DEFAULT_SEGMENTATION_PARAMETER,
      ],
    }));

  const changeSegmentationOperation = (operation: ParametersOperator) =>
    setSegmentation((segmentation) => ({ ...segmentation, operation }));

  const removeParameterAt = (index: number) =>
    setSegmentation((segmentation) => ({
      ...segmentation,
      parameters: segmentation.parameters?.filter((_, i) => i !== index),
    }));

  const replaceParameter = (
    newParam: SegmentationParameterInput,
    index: number,
  ) =>
    setSegmentation((segmentation) => ({
      ...segmentation,
      parameters: segmentation.parameters?.map((param, i) =>
        index === i ? newParam : param,
      ),
    }));

  return {
    segmentation,
    replaceParameter,
    removeParameterAt,
    createNewParameter,
    changeSegmentationOperation,
  };
};
