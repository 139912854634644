// tslint:disable no-increment-decrement
/**
 * Initial list:
 *                      -----???
 * +--------------------+    |
 * | message_date: 9    |    |      New item arrives. Where does it go?
 * | updated: 9         |    |     +--------------------+
 * +--------------------+    +---- | message_date: 4    |
 * +--------------------+    +---- | updated: 12        |
 * | message_date: 5    |    |     +--------------------+
 * | updated: 5         |    |
 * +--------------------+ ___|???
 * +--------------------+
 * | message_date: 3    |
 * | updated: 3         |
 * +--------------------+
 *
 *
 *
 * Answer:
 *
 * It should be inserted to the initial list ordered by `message_date`
 * But if we see that it should be inserted to the end, we
 * do *not* insert the item (and just discard it), *unless* we know that
 * there are no more items on the server at the end of the list.
 */

// NOTE:
// only works for sorting === 'reverse-chronological'
// TODO: complete code for sorting === 'chronological'
export function insertUpdatedItems({
  currentItems,
  newItems,
  sortedByKey,
  sorting,
  endOfListReached,
}: {
  currentItems: any[];
  newItems: any[];
  sortedByKey: string;
  sorting: 'chronological' | 'reverse-chronological';
  endOfListReached: boolean;
}) {
  /**
   * NOTE:
   * Both lists are expected to be sorted by the `sortedByKey`
   */
  if (!currentItems.length || !newItems.length) {
    return [...currentItems, ...newItems];
  }

  const currentItemsCopy = currentItems.slice();

  // "Replacers" are items in `newItems` that exist in `currentItems`.
  // We remove from `currentItems` items with such ids.
  // We keep a map of these ids so that we know that such new items *must*
  // be inserted to the final list.
  const idsOfReplacers: any = {};
  for (let i = currentItemsCopy.length - 1; i >= 0; i--) {
    const item = currentItemsCopy[i];
    const willBeReplaced = newItems.some((newItem) => newItem.id === item.id);
    if (willBeReplaced) {
      idsOfReplacers[item.id] = null;
      currentItemsCopy.splice(i, 1);
    }
  }

  if (sorting === 'reverse-chronological') {
    // TODO: use "upper bound" to search a sorted array, which is O(log(n))
    let startIndex = 0;
    newItems.forEach((newItem) => {
      // find insertion point
      let insertionIndex = currentItemsCopy.length;
      for (let index = startIndex; index < currentItemsCopy.length; index++) {
        if (currentItemsCopy[index][sortedByKey] < newItem[sortedByKey]) {
          insertionIndex = index;
          startIndex = index + 1;
          break;
        }
      }

      if (insertionIndex === currentItemsCopy.length) {
        if (endOfListReached || newItem.id in idsOfReplacers) {
          currentItemsCopy.push(newItem);
        } else {
          // skip this item
        }
      } else {
        // insert at insertionIndex
        currentItemsCopy.splice(insertionIndex, 0, newItem);
      }
    });
  }
  return currentItemsCopy;
}
