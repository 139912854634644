import { useMutation } from '@apollo/react-hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { log } from 'cf-common/src/logger';

import { SET_LOCALE_DATA_MUTATION } from './queries';
import { SetLocaleDataMutation } from './@types/SetLocaleDataMutation';

export const useLocaleSetter = (silent: boolean = false) => {
  const { i18n } = useSafeTranslation();
  const [setLocaleData] = useMutation<SetLocaleDataMutation>(
    SET_LOCALE_DATA_MUTATION,
  );
  const [fastI18Nrefresh] = useLocalStorage(LSKey.fastI18Nrefresh);
  return (locale: string) =>
    setLocaleData({ variables: { locale } })
      .then(() => {
        if (!silent && i18n.language !== locale) {
          if (fastI18Nrefresh) {
            i18n.changeLanguage(locale);
          } else {
            window.location.reload();
          }
        }
      })
      .catch((error) => log.warn({ error, msg: 'Could not set locale data' }));
};

export type SetLocale = ReturnType<typeof useLocaleSetter>;
