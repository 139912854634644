import gql from 'graphql-tag';

export const WHITE_LABEL_AGENCY_QUERY = gql`
  query WhiteLabelAgencyQuery($domain: String) {
    whiteLabelAgency(domain: $domain) {
      id
      color
    }
    me {
      id
      premiumAccount {
        id
        name
      }
    }
  }
`;
