import { useQuery } from '@apollo/react-hooks';
import { useCurrentBotId } from '@utils/Routing';
import { isBotPaused, isBotPro, isBotProDebt } from '@utils/Pro';
import { BOT_PRO_STATUS_QUERY } from './queries';
import {
  BotProStatusQuery,
  BotProStatusQueryVariables,
} from './@types/BotProStatusQuery';

export const useBotProStatus = () => {
  const botId = useCurrentBotId() ?? '';

  const { data, loading } = useQuery<
    BotProStatusQuery,
    BotProStatusQueryVariables
  >(BOT_PRO_STATUS_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  return {
    botId,
    botIsPro: isBotPro(data?.bot),
    botInDebt: !!isBotProDebt(data?.bot),
    botIsPaused: isBotPaused(data?.bot),
    proDaysLeft: data?.bot?.pro?.days_before_cancel ?? 0,
    proMillisLeft: data?.bot.pro?.millis_before_cancel ?? 0,
    botIsManualPro: !!data?.bot.pro?.manual,
    loading: loading || !data || data?.bot.id !== botId,
  };
};
