import { useQuery } from 'react-apollo';
import { WorkspacesAvailableQuery } from './@types/WorkspacesAvailableQuery';
import { WORKSPACES_AVAILABLE_QUERY } from './queries';
import client from '@common/services/ApolloService';

export const useWorkspacesAvailable = () => {
  const { data, loading } = useQuery<WorkspacesAvailableQuery>(
    WORKSPACES_AVAILABLE_QUERY,
  );

  return [data?.me.workspacesAvailable, loading] as const;
};

export const getWorkspacesAvailable = () =>
  client.query<WorkspacesAvailableQuery>({ query: WORKSPACES_AVAILABLE_QUERY });
