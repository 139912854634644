import gql from 'graphql-tag';

export const KOMMO_OAUTH_URL_QUERY = gql`
  query KommoOauthUrlQuery($botId: String!) {
    kommoOauthUrl(botId: $botId)
  }
`;

export const KOMMO_INTEGRATION_STATUS_QUERY = gql`
  query KommoIntegrationStatusQuery($botId: String!) {
    kommoIntegrationStatus(botId: $botId) {
      accountLink
      accountName
      status
    }
  }
`;
