const COPY_NAME_POSTFIX = '_copy';
const COPY_REGEXP = new RegExp(`(.*?)${COPY_NAME_POSTFIX}([0-9]*)$`);

export const getNextItemName = (
  currentName: string,
  names: string[],
): string => {
  if (!names.includes(currentName)) {
    return currentName;
  }
  const [_, name, num] = currentName.match(COPY_REGEXP) || [];
  return getNextItemName(
    name
      ? `${name}${COPY_NAME_POSTFIX}${(num ? parseInt(num, 10) : 0) + 1}`
      : `${currentName}${COPY_NAME_POSTFIX}`,
    names,
  );
};
