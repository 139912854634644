import i18next from 'i18next';
import { Subject, Subscription } from 'rxjs/Rx';
import { ApolloQueryResult, ObservableQuery } from 'apollo-client';
import { Observable } from 'rxjs';
import { isSyntheticLogin } from '@utils/syntheticLogin';

import client from '../../common/services/ApolloService';

import {
  USER_DATA_FOR_INTERCOM_QUERY,
  USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY,
  USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE,
} from './queries';
import { prepareUserDataForIntercom } from './helpers/prepareData';

import { USER_DATA_FOR_INTERCOM_QUERY as USER_DATA_FOR_INTERCOM_QUERY_TYPE } from './@types/USER_DATA_FOR_INTERCOM_QUERY';
import { isUserHasAuthToken } from '../Auth/isUserHasAuthToken';
import { globalConfigAsync } from '../globalConfigAsync';
import { OriginalAccountType } from '@globals';
import { IntercomEvents } from './consts';
import { isLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { isMobileDeviceOrLayout } from '@utils/DOM/useDeviceMedia';

const userDataQueryObservable = client.watchQuery({
  query: USER_DATA_FOR_INTERCOM_QUERY,
}) as ObservableQuery<USER_DATA_FOR_INTERCOM_QUERY_TYPE>;
const userServerStorageDataQueryObservable = client.watchQuery({
  query: USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY,
}) as ObservableQuery<USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE>;
const intercomDataQueryObservable = Observable.combineLatest<
  [
    ApolloQueryResult<USER_DATA_FOR_INTERCOM_QUERY_TYPE>,
    ApolloQueryResult<USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE>,
  ]
>([userDataQueryObservable, userServerStorageDataQueryObservable]);
const preloadUserDataForIntercom = () => {
  if (isUserHasAuthToken()) {
    client.query({ query: USER_DATA_FOR_INTERCOM_QUERY });
    client.query({ query: USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY });
  }
};

window.intercomBooted = false;

/**
 * We have to use this hack, because in frontend we currently use short code for Portuguese (just pt),
 * but Intercom wants full code with region "pt-BR"
 */
const i18nextToIntercomLanguage = (language: string): string => {
  return language === 'pt' ? 'pt-BR' : language;
};

let intercomDataQuerySubscription: Subscription;

export const bootIntercom = async (intercomSettings?: Record<string, any>) => {
  const { INTERCOM_APP_ID, INTERCOM_CHAT_HIDDEN } = await globalConfigAsync;

  if (INTERCOM_CHAT_HIDDEN) return;

  const bootIntercomWithoutUserData = (method = 'boot') => {
    window.Intercom(method, { app_id: INTERCOM_APP_ID, ...intercomSettings });
    window.intercomBooted = true;
  };

  if (isUserHasAuthToken()) {
    if (!intercomDataQuerySubscription) {
      intercomDataQuerySubscription = intercomDataQueryObservable.subscribe(
        ([userData, serverStorageData]) => {
          if (!window.Intercom) {
            return;
          }
          const method = window.intercomBooted ? 'update' : 'boot';

          const isGuest =
            userData.data.me.original_account_type ===
            OriginalAccountType.guest;
          const isFacebookUser = userData.data.me.facebook_account_connected;

          if (isGuest && !isFacebookUser) {
            bootIntercomWithoutUserData(method);
          } else {
            window.Intercom(method, {
              app_id: INTERCOM_APP_ID,
              language_override: i18nextToIntercomLanguage(i18next.language),
              ...prepareUserDataForIntercom({
                ...userData.data,
                ...serverStorageData.data,
              }),
              ...intercomSettings,
            });
            window.intercomBooted = true;
          }
        },
      );
    }
  } else if (!window.intercomBooted) {
    bootIntercomWithoutUserData();
  }
};

export const $onUnreadCountChange: Subject<number> = new Subject();
const setUnreadMessageCountListener = () => {
  if (window.Intercom) {
    window.Intercom('onUnreadCountChange', (unreadCount: number) =>
      $onUnreadCountChange.next(unreadCount),
    );
  }
};

export const $intercomOpenStatus = new Subject<boolean>();
const setIntercomOpenStatusListener = () => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom('onShow', () => {
    $intercomOpenStatus.next(true);
  });
  window.Intercom('onHide', () => {
    $intercomOpenStatus.next(false);
  });
};

export const loadIntercom = (intercomSettings?: Record<string, any>) => {
  if (
    isSyntheticLogin() ||
    isLiveChatWebView() ||
    isMobileDeviceOrLayout() ||
    window.Intercom
  ) {
    return;
  }
  const w = window;
  const ic = w.Intercom;
  w.intercomSettings = {
    app_id: window.CHATFUEL_CONFIG.INTERCOM_APP_ID,
    horizontal_padding: 16,
    vertical_padding: 0,
    ...intercomSettings,
  };
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    // eslint-disable-next-line func-names
    const i = function () {
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments);
    } as any;
    i.q = [];
    // eslint-disable-next-line func-names
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${window.CHATFUEL_CONFIG.INTERCOM_APP_ID}`;
    const x = d.getElementsByTagName('script')[0];
    x.parentNode!.insertBefore(s, x);
  }
  window.Intercom('shutdown');
  if (!window.intercomBooted) {
    setUnreadMessageCountListener();
    setIntercomOpenStatusListener();
    preloadUserDataForIntercom(); // preload data for fast boot intercom
  }
};

export const logoutIntercom = () => {
  if (typeof window.Intercom === 'function') {
    window.Intercom('shutdown');
    window.intercomBooted = false;
  } else {
    const dayMs = 24 * 60 * 60 * 1000;
    const dayAgo = new Date(Date.now() - dayMs).toUTCString();
    document.cookie = `intercom-session-${window.CHATFUEL_CONFIG.INTERCOM_APP_ID}=;path=/;expire=${dayAgo};`;
  }
};

export const showIntercom = () => {
  if (!window.Intercom) {
    loadIntercom();
  }
  if (!window.intercomBooted) {
    bootIntercom();
  }
  if (window.Intercom) {
    window.Intercom('show');
  }
};

export const hideIntercom = () => {
  if (!window.Intercom) {
    loadIntercom();
  }
  if (window.Intercom) {
    window.Intercom('hide');
  }
};

export const refetchIntercomData = () => {
  window.Intercom?.('update');
};

export const setHideIntercomDefaultLauncher = (value: boolean) => {
  window.Intercom?.('update', {
    hide_default_launcher: value,
  });
};

export const sendIntercomEvent = (
  event: IntercomEvents,
  metadata?: Record<string, string | undefined>,
) => {
  if (!window.Intercom) {
    loadIntercom();
  }
  if (!window.intercomBooted) {
    bootIntercom();
  }
  window.Intercom?.('trackEvent', event, metadata);
};
