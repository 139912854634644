import gql from 'graphql-tag';
import { WORKSPACE_BOT_FRAGMENT, WORKSPACE_FRAGMENT } from './fragments';

export const WORKSPACES_BOTS_QUERY = gql`
  query WorkspacesBots {
    workspacesBots {
      workspaces {
        workspace {
          ...workspaceFragment
        }
        bots {
          ...workspaceBotFragment
        }
      }
      botsWithoutWorkspace {
        ...workspaceBotFragment
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
  ${WORKSPACE_BOT_FRAGMENT}
`;

export const WORKSPACES_QUERY = gql`
  query WorkspacesQuery {
    workspaces {
      ...workspaceFragment
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const WORKSPACES_AVAILABLE_QUERY = gql`
  query WorkspacesAvailableQuery {
    me {
      id
      workspacesAvailable
    }
  }
`;
