import React, { useEffect, useState } from 'react';
import {
  ServerStorageItemKeys,
  SetCallbackType,
  useServerStorage,
} from './ServerStorage';

export interface IShowNTimesProps {
  showLimit: number;
  storageKey: ServerStorageItemKeys;
  children: (params: {
    show: boolean;
    loading: boolean;
    setCount: SetCallbackType<number>;
  }) => React.ReactElement | null;
}

export const useShowNTimes = (
  storageKey: ServerStorageItemKeys,
  showLimit: number,
  autoIncrementDisabled: boolean = false,
) => {
  const [count, setCount, { loading }] = useServerStorage<number>(storageKey);
  const [show, setShow] = useState<boolean>(false);
  const [needUpdate, setNeedUpdate] = useState<boolean>(true);
  const safeCount = count || 0;

  useEffect(() => {
    if (loading || !needUpdate) {
      return;
    }
    const updatedShow = safeCount < showLimit;
    if (updatedShow && !show && !autoIncrementDisabled) {
      setCount(safeCount + 1);
    }
    setShow(updatedShow);
    setNeedUpdate(false);
  }, [
    loading,
    showLimit,
    safeCount,
    show,
    setCount,
    needUpdate,
    setNeedUpdate,
    autoIncrementDisabled,
  ]);

  return {
    loading,
    show,
    count: safeCount,
    setCount: (n: number) => {
      setNeedUpdate(true);
      return setCount(n);
    },
  };
};

export const ShowNTimes: React.FC<IShowNTimesProps> = ({
  showLimit,
  storageKey,
  children,
}) => children(useShowNTimes(storageKey, showLimit));
