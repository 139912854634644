import Maybe from 'graphql/tsutils/Maybe';
import { CurrenciesQuery_currencies } from './@types/CurrenciesQuery';

export const calcStripeMinimalAmount = (
  currency: Maybe<CurrenciesQuery_currencies>,
) => {
  if (!currency) {
    return 0;
  }
  const { decimal_digits: decimalDigits, stripeMinimalAmountUnits } = currency;

  return (stripeMinimalAmountUnits || 0) / 10 ** decimalDigits;
};
