import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { BOT_WORKSPACE_ID_QUERY } from '../Bot/useBotWorkspaceId';
import {
  RemoveFromWorkspaceMutation,
  RemoveFromWorkspaceMutationVariables,
} from './@types/RemoveFromWorkspaceMutation';
import { REMOVE_FROM_WORKSPACE_MUTATION } from './mutations';
import { WORKSPACES_BOTS_QUERY } from './queries';

export const useRemoveFromWorkspace = () => {
  const [removeFromWorkspaceMutation, options] = useMutation<
    RemoveFromWorkspaceMutation,
    RemoveFromWorkspaceMutationVariables
  >(REMOVE_FROM_WORKSPACE_MUTATION);

  const removeFromWorkspace = useCallback(
    (botId: string) => {
      return removeFromWorkspaceMutation({
        variables: { botId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: WORKSPACES_BOTS_QUERY,
          },
          { query: BOT_WORKSPACE_ID_QUERY, variables: { botId } },
        ],
      });
    },
    [removeFromWorkspaceMutation],
  );

  return [removeFromWorkspace, options] as const;
};
