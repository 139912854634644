import gql from 'graphql-tag';

export const AI_INTENT_ACTION_FRAGMENT = gql`
  fragment aiIntentActionFragment on AiIntentAction {
    random
    items {
      item_type
      text
      blocks {
        id
        title
        removed
        is_flow
      }
    }
  }
`;

export const AI_INTENT_FRAGMENT = gql`
  fragment aiIntentFragment on AiIntent {
    id
    intent_id
    name
    lines
    goto_block
    filter_type
    action {
      ...aiIntentActionFragment
    }
    ig_action {
      ...aiIntentActionFragment
    }
    wa_action {
      ...aiIntentActionFragment
    }
  }
  ${AI_INTENT_ACTION_FRAGMENT}
`;

export const AI_INTENTS_GROUP_FRAGMENT = gql`
  fragment aiIntentsGroupFragment on AiGroup {
    id
    title
    intents {
      ...aiIntentFragment
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const AI_PLUGIN_CONFIG_FRAGMENT = gql`
  fragment aiPluginConfigFragment on AIPluginConfig {
    groups {
      ...aiIntentsGroupFragment
    }
  }
  ${AI_INTENTS_GROUP_FRAGMENT}
`;

export const AI_BLOCK_FRAGMENT = gql`
  fragment aiBlockFragment on Block {
    id
    cards {
      ... on AIPlugin {
        config {
          ...aiPluginConfigFragment
        }
      }
    }
  }
  ${AI_PLUGIN_CONFIG_FRAGMENT}
`;

export const FIRST_SESSION_AI_INTENTS_QUERY = gql`
  query FirstSessionAiIntentsQuery(
    $botId: String!
    $locale: String!
    $forceUseTemplate: Boolean
  ) {
    firstSessionAiIntents(
      botId: $botId
      locale: $locale
      forceUseTemplate: $forceUseTemplate
    ) @connection(key: "firstSessionAiIntents", filter: ["botId"]) {
      intents {
        ...aiIntentFragment
      }
      type
    }
  }
  ${AI_INTENT_FRAGMENT}
`;
