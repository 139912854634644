import { Modal } from '@services/index';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { sendEvent } from 'cf-common/src/analytics';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ShopifyAccount } from '../ShopifyAccount';
import * as css from './ConnectShopifyAccountDialog.css';

interface ShowConnectShopifyAccountDialogProps {
  interruptedHref?: string;
}

interface ConnectShopifyAccountDialogProps
  extends ShowConnectShopifyAccountDialogProps {
  onDismiss: () => void;
}

const ConnectShopifyAccountDialog: React.FC<ConnectShopifyAccountDialogProps> =
  ({ interruptedHref, onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultDialog
        dialogStyle={{
          width: 640,
        }}
        header={t('shopify.connectAShopifyStoreToActivateThisBot')}
        onDismiss={onDismiss}
        footer={() => (
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            className={css.footer}
          >
            <Button
              intent="secondary"
              onClick={() => {
                window.open(
                  'https://www.shopify.com/',
                  '_blank',
                  'noopener noreferrer',
                );
                sendEvent({
                  category: 'flows',
                  label: 'shopify account',
                  action: 'create shopify store click',
                });
              }}
              data-testid="connect-shopify-account-dialog_create-shopify-store"
            >
              {t('shopify.createShopifyStore')}
            </Button>
          </Flex>
        )}
      >
        <ShopifyAccount interruptedHref={interruptedHref} />
      </DefaultDialog>
    );
  };

export const showConnectShopifyAccountDialog = ({
  interruptedHref,
}: ShowConnectShopifyAccountDialogProps) =>
  Modal.show<string>(({ close }) => (
    <ConnectShopifyAccountDialog
      interruptedHref={interruptedHref}
      onDismiss={close}
    />
  ));
