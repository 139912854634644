import { FetchPolicy } from 'apollo-client';
import client from '../../../common/services/ApolloService';
import {
  BotFeaturesQuery,
  BotFeaturesQueryVariables,
} from './@types/BotFeaturesQuery';
import { BOT_FEATURES_QUERY } from './BotFeaturesQuery';

export async function getBotFeatures(botId: string, fetchPolicy?: FetchPolicy) {
  const res = await client.query<BotFeaturesQuery, BotFeaturesQueryVariables>({
    query: BOT_FEATURES_QUERY,
    variables: {
      botId,
    },
    fetchPolicy,
  });

  return res.data.bot.features;
}
