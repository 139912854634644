import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DateType, Granularity } from './types';

export namespace DateUtils {
  export const fromDate = (date: DateType) => moment(date).fromNow();
  export const daysPassedFromDate = (date1: DateType, date2: DateType) =>
    moment(date1).diff(moment(date2), 'days');
  export const isAfterCurrentDayInclusive = (date: DateType): boolean =>
    moment(date).isAfter(moment().subtract(1, 'day'), 'day');
  export const daysPassedFromCurrrentDate = (date: DateType) =>
    moment().diff(moment(date), 'days');
  export const isMonthNotPassed = (date: DateType) =>
    daysPassedFromCurrrentDate(date) < 30;
  export const isSame = (date: DateType, granularity: Granularity) =>
    moment(date).isSame(moment(), granularity);
  export const isSameDay = (date1: DateType, date2: DateType) =>
    moment(date1).isSame(moment(date2), Granularity.day);
  export const mapDDtoDDD = (date: string) => moment(date, 'dd').format('ddd');
  export const backToNMonth = (date: DateType, n: number) =>
    moment(date).subtract(n, 'months').toDate();
  /**
   * shifts the date forward by utcOffset
   */
  export const forwardUtcOffset = (date: DateType) => {
    const tempDate = momentTimezone.isMoment(date)
      ? date.toDate()
      : new Date(date);

    return new Date(
      Date.UTC(
        tempDate.getFullYear(),
        tempDate.getMonth(),
        tempDate.getDate(),
        tempDate.getHours(),
        tempDate.getMinutes(),
      ),
    );
  };
  /**
   * shifts the date back by utcOffset
   */
  export const backUtcOffset = (date: DateType) => {
    const tempDate = momentTimezone.isMoment(date)
      ? date.toDate()
      : new Date(date);

    return new Date(
      tempDate.getUTCFullYear(),
      tempDate.getUTCMonth(),
      tempDate.getUTCDate(),
      tempDate.getUTCHours(),
      tempDate.getUTCMinutes(),
    );
  };
  export const isTommorow = (date: DateType) =>
    moment(date).isSame(moment().add(1, Granularity.day), Granularity.day);

  export const formatFullDate = (date: DateType) => moment(date).format('llll');
}

export const getTimestampInSeconds = (date: Date) => {
  return date.valueOf() / 1000;
};

export const fromTimestampInSeconds = (timestampInSec: number) => {
  return new Date(timestampInSec * 1000);
};

export const getUtcOffset = () => {
  const offset = moment().utcOffset() / 60;
  return offset > 0 ? `+${offset}` : offset.toString();
};
