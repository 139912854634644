import { Platform } from '@globals';
import { SUPPORTED_AUDIO_MIME_TYPES } from './audioSupportedTypes';
import { SUPPORTED_IMAGE_MIME_TYPES } from './imageSupportedTypes';
import { SUPPORTED_VIDEO_MIME_TYPES } from './videoSupportedTypes';
import { SUPPORTED_DOCUMENT_MIME_TYPES } from './documentSupportedTypes';
import { SUPPORTED_PDF_MIME_TYPES } from './pdfSupportedTypes';
import { FileAttachmentType } from '../UploadService/types';
import { SUPPORTED_MP4_MIME_TYPES } from './mp4SupportedTypes';

const SUPPORTED_TYPES = {
  [FileAttachmentType.image]: SUPPORTED_IMAGE_MIME_TYPES,
  [FileAttachmentType.video]: SUPPORTED_VIDEO_MIME_TYPES,
  [FileAttachmentType.audio]: SUPPORTED_AUDIO_MIME_TYPES,
  [FileAttachmentType.file]: SUPPORTED_DOCUMENT_MIME_TYPES,
  [FileAttachmentType.pdf]: SUPPORTED_PDF_MIME_TYPES,
  [FileAttachmentType.mp4]: SUPPORTED_MP4_MIME_TYPES,
};

export const getSupportedTypes = (
  type: FileAttachmentType,
  platform: Platform,
) => SUPPORTED_TYPES[type][platform];

export const getSupportedImageTypes = (platform: Platform) =>
  getSupportedTypes(FileAttachmentType.image, platform);
