/**
 * Wait for number of ms
 * @param {Number} ms
 * @returns {Promise<any>}
 */
export default function timer(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}
