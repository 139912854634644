import { createSubscription } from 'create-subscription';

export const DynamicListSubscription = createSubscription({
  getCurrentValue(source: any) {
    return source.getValue();
  },
  subscribe(source, callback) {
    const subscription = source.subscribe({
      next: callback,
      error: () => {
        callback(source.getValue());
      },
    });
    return () => subscription.unsubscribe();
  },
});
