import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';
import { ApolloError } from 'apollo-client';
import {
  AdminFeaturesQuery,
  AdminFeaturesQuery_me_features,
} from './@types/AdminFeaturesQuery';
import { ADMIN_FEATURES_QUERY } from './AdminFeaturesQuery';

export function useAdminFeatures({ skip } = { skip: false }) {
  const { data, loading, error } = useQuery<AdminFeaturesQuery>(
    ADMIN_FEATURES_QUERY,
    { skip },
  );

  return {
    adminFeatures: data?.me.features,
    adminFeaturesLoading: loading,
    adminFeaturesError: error,
  };
}

interface AdminFeaturesProps {
  children: (data: {
    adminFeaturesLoading: boolean;
    adminFeaturesError: ApolloError | undefined;
    adminFeatures: AdminFeaturesQuery_me_features | undefined;
  }) => JSX.Element | null;
}

export const AdminFeatures: React.FC<AdminFeaturesProps> = ({ children }) => (
  <Query<AdminFeaturesQuery> query={ADMIN_FEATURES_QUERY}>
    {({ data, loading, error }) => {
      return (
        children &&
        children({
          adminFeatures: data?.me.features,
          adminFeaturesLoading: loading,
          adminFeaturesError: error,
        })
      );
    }}
  </Query>
);

interface WithAdminFeatureProps {
  adminFeature: keyof Omit<AdminFeaturesQuery_me_features, '__typename'>;
}
export const WithAdminFeature: React.FC<WithAdminFeatureProps> = ({
  adminFeature,
  children,
}) => (
  <AdminFeatures>
    {({ adminFeatures }) => {
      const isAdminFeatureEnabled =
        adminFeatures && adminFeatures[adminFeature];
      return isAdminFeatureEnabled ? <>{children}</> : null;
    }}
  </AdminFeatures>
);

interface AdminFeatureProps {
  showIf?: (queryResult?: AdminFeaturesQuery_me_features) => boolean;
  hideIf?: (queryResult?: AdminFeaturesQuery_me_features) => boolean;
}

export const AdminFeature: React.FC<AdminFeatureProps> = ({
  showIf,
  hideIf,
  children = null,
}) => {
  const { adminFeatures, adminFeaturesError, adminFeaturesLoading } =
    useAdminFeatures();

  if (adminFeaturesLoading || adminFeaturesError) {
    return null;
  }

  if (showIf?.(adminFeatures)) {
    return <>{children}</>;
  }

  if (hideIf?.(adminFeatures)) {
    return null;
  }

  return <>{children}</>;
};
