import React, { useState, useEffect } from 'react';
import noop from 'lodash-es/noop';

/**
 * This a HACK for React to add his listeners before of Angular
 */
export const ReactEventHandlerForce: React.FC = () => {
  const [forced, setForced] = useState(false);

  useEffect(() => {
    if (!forced) {
      setForced(true);
    }
  }, [forced]);

  return !forced ? (
    <button
      style={{
        position: 'fixed',
        zIndex: -1,
      }}
      onClick={noop}
      onMouseDown={noop}
      onMouseUp={noop}
    />
  ) : null;
};
