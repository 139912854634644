import { useEffect, useState } from 'react';

const wheelHandler = (event: Event) => {
  event.stopPropagation();
};

export const usePreventWheel = (skip: Boolean = false) => {
  const [target, setTarget] = useState<HTMLElement | null>();
  useEffect(() => {
    if (!skip && target) {
      target.addEventListener('wheel', wheelHandler, { passive: false });
      return () => {
        target.removeEventListener('wheel', wheelHandler);
      };
    }
    return undefined;
  }, [skip, target]);

  return (el: HTMLElement | null) => {
    setTarget(el);
  };
};

export const usePreventWheelOnPage = (skip: Boolean = false) => {
  const refFunc = usePreventWheel(skip);
  useEffect(() => {
    refFunc(document.body);
  }, [refFunc]);
};

export const usePreventWheelOnRef = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  skip = false,
) => {
  const refFunc = usePreventWheel(skip);
  useEffect(() => {
    refFunc(ref.current);
  }, [ref, refFunc]);
};
