import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => {
    const baseSearchParams = new URLSearchParams(search);

    return {
      // removed other methods to avoid unexpected situations
      get: baseSearchParams.get.bind(baseSearchParams),
      has: baseSearchParams.has.bind(baseSearchParams),
    };
  }, [search]);

  const setSearchParams = useCallback(
    (newSearch: URLSearchParams) => {
      history.push({
        search: `?${newSearch}`,
      });
    },
    [history],
  );

  const deleteSearchParams = useCallback(
    (name: string) => {
      const newSearchParams = new URLSearchParams(search);
      if (!newSearchParams.has(name)) return;

      newSearchParams.delete(name);

      history.push({
        search: `?${newSearchParams}`,
      });
    },
    [history, search],
  );

  const addSearchParams = useCallback(
    (name: string, value: string) => {
      const newSearchParams = new URLSearchParams(search);
      if (newSearchParams.has(name) && newSearchParams.get(name) === value)
        return;

      newSearchParams.append(name, value);

      history.push({
        search: `?${newSearchParams}`,
      });
    },
    [history, search],
  );

  return { searchParams, addSearchParams, deleteSearchParams, setSearchParams };
};
