import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { BOT_GROUPING_FLOWS_QUERY } from './queries';
import {
  BotGroupingFlowsQuery,
  BotGroupingFlowsQueryVariables,
} from './@types/BotGroupingFlowsQuery';

export const useGroupingFlows = (botId: string) => {
  const { data, loading, error, refetch } = useQuery<
    BotGroupingFlowsQuery,
    BotGroupingFlowsQueryVariables
  >(BOT_GROUPING_FLOWS_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const flowsGroups = data?.bot.flow_groups;

  const groups = useMemo(() => {
    return (flowsGroups ?? [])
      .map((flowsGroup) => ({
        title: flowsGroup.title,
        items: flowsGroup.flows ?? [],
      }))
      .filter((flowsGroup) => flowsGroup.items.length);
  }, [flowsGroups]);

  return {
    groups,
    loading,
    error,
    refetch,
  };
};
