interface DownloadArgs {
  url: string;
  fileName: string;
  preserveObjectUrl?: boolean;
}

/**
 * По-умолчанию вызывает `revokeObjectURL` освобождая память, если URL
 * был получен с помощью вызова `createObjectURL`. Если необходимо отдельно
 * следить за жизненным циклом, необходимо указать флаг `preserveObject`
 */
export function download({
  url,
  fileName,
  preserveObjectUrl = false,
}: DownloadArgs) {
  const a = document.createElement('a');
  document.body.append(a);
  a.download = fileName;
  a.href = url;
  a.click();
  if (!preserveObjectUrl) {
    window.URL.revokeObjectURL(url);
  }
  a.remove();
}
