import { EventEmitter } from '@utils/EventEmitter';

const updatePaymentEmitter = new EventEmitter();
const paymentUpdateEventId = 'paymentUpdate';

export const notifyPaymentUpdate = () => {
  updatePaymentEmitter.emit(paymentUpdateEventId);
};

export const subscribePaymentUpdate = (cb: () => void): (() => void) => {
  updatePaymentEmitter.on(paymentUpdateEventId, cb);
  return () => {
    updatePaymentEmitter.off(paymentUpdateEventId, cb);
  };
};
