import { WORKSPACE_ADMINS_AND_INVITES_QUERY } from '@pages/BotListPage/components/WorkspaceList/Workspace/useWorkspaceUsersAndInvites';
import { ROLES_AND_INVITES_QUERY } from '../../../components/settings/admins/AdminsConst';
import { useMutation } from 'react-apollo';
import {
  DeleteInviteQuery,
  DeleteInviteQueryVariables,
} from './@types/DeleteInviteQuery';
import { DELETE_INVITE_MUTATION } from './mutations';

interface UseDeleteInvitePayload {
  botId?: string;
  workspaceId?: string;
}

const getDeleteInviteRefetchQueries = (payload?: UseDeleteInvitePayload) => {
  const { botId, workspaceId } = payload ?? {};

  const refetchQueries = [];

  if (botId)
    refetchQueries.push({
      query: ROLES_AND_INVITES_QUERY,
      variables: { botId },
    });

  if (workspaceId)
    refetchQueries.push({
      query: WORKSPACE_ADMINS_AND_INVITES_QUERY,
      variables: { workspaceId },
    });

  return refetchQueries;
};

export const useDeleteInvite = (payload?: UseDeleteInvitePayload) => {
  return useMutation<DeleteInviteQuery, DeleteInviteQueryVariables>(
    DELETE_INVITE_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: getDeleteInviteRefetchQueries(payload),
    },
  );
};
