/* global HTMLElement */

import ng from 'angular';

const isDescendant = (el, target) => {
  if (target !== null && target.parentNode) {
    return el === target || isDescendant(el, target.parentNode);
  }
  return false;
};

class OnClickAwayController {
  constructor($document, $element, $scope) {
    'ngInject';

    this.$document = $document;
    this.$element = $element;
    this.$scope = $scope;
  }

  $postLink() {
    this.$el = this.$element.find('div');
    this.binding = this.handleClickAway.bind(this);
    this.$document.bind('click', this.binding);
    // @note: hack! safari do not always fire click event when focus on content editable;
    // so we listen on focusin event which is bubbled version of focus event;
    // @warning: don't work if parent element has tabindex
    this.$document.bind('focusin', this.binding);
  }

  $onDestroy() {
    this.$document.unbind('click', this.binding);
    this.$document.unbind('focusin', this.binding);
  }

  handleClickAway(e, directTarget) {
    const target = directTarget || e.target; // support directTarget for jump click away event over stopPropagation
    if (e.isDefaultPrevented()) {
      // allow to prevent click away;
      return;
    }
    if (
      target instanceof HTMLElement &&
      document.documentElement &&
      document.documentElement.contains(target) &&
      !isDescendant(this.$element[0], target)
    ) {
      // this is native event some times you will get it on the same time as ng-click and $apply will be invoked;
      // some time you will have only native click like click on elements without handler;
      // you will have $digest already in progress isssue;
      // this solution was found during search on "detect digest in agular"
      // not sure how this really working :)
      this.$scope.$evalAsync(() => {
        this.$scope.onClickAway({ $event: e });
      });
    }
  }
}

export default ng
  .module('app.ui.onClickAway', [])
  .directive('onClickAway', () => ({
    restrict: 'A',
    scope: {
      onClickAway: '&',
    },
    controller: OnClickAwayController,
  })).name;
