import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash-es';
import Maybe from 'graphql/tsutils/Maybe';
import { QueryHookOptions, useLazyQuery, useQuery } from 'react-apollo';
import { usePrevious } from 'cf-common/src/utils/hooks';
import {
  EntryPointTipsQuery,
  EntryPointTipsQueryVariables,
  EntryPointTipsQuery_entryPointTips_sections as Sections,
} from './@types/EntryPointTipsQuery';
import { ENTRY_POINT_TIPS_QUERY } from './queries';
import { useWatchOnboardingSectionCompleted } from '@components/MiniOnboarding/hooks';
import { OnboardingTipsStatus } from '@globals';

const useUpdateOpenSections = (sections: Sections[]) => {
  const { recalculateOpenSectionState } = useWatchOnboardingSectionCompleted();

  const sectionsStates = useMemo(() => {
    return sections.map((section) =>
      section.entryPoints.every(
        (ep) => ep.status === OnboardingTipsStatus.done,
      ),
    );
  }, [sections]);

  const prevSectionCompletenessStatus = usePrevious(sectionsStates);

  useEffect(() => {
    if (!prevSectionCompletenessStatus) {
      return;
    }

    if (!isEqual(prevSectionCompletenessStatus, sectionsStates)) {
      recalculateOpenSectionState(sections);
    }
  }, [
    prevSectionCompletenessStatus,
    sections,
    recalculateOpenSectionState,
    sectionsStates,
  ]);
};

export const useEntryPointTipsQuery = (
  botId: Maybe<string>,
  options?: QueryHookOptions<EntryPointTipsQuery, EntryPointTipsQueryVariables>,
) => {
  const queryResult = useQuery<
    EntryPointTipsQuery,
    EntryPointTipsQueryVariables
  >(ENTRY_POINT_TIPS_QUERY, {
    variables: { botId: botId! },
    skip: !botId,
    ...options,
  });

  useUpdateOpenSections(queryResult.data?.entryPointTips?.sections || []);

  return queryResult;
};

export const useEntryPointTipsLazyQuery = (
  botId: Maybe<string>,
  options?: QueryHookOptions<EntryPointTipsQuery, EntryPointTipsQueryVariables>,
) => {
  return useLazyQuery<EntryPointTipsQuery, EntryPointTipsQueryVariables>(
    ENTRY_POINT_TIPS_QUERY,
    { variables: { botId: botId! }, skip: !botId, ...options },
  );
};
