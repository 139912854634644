import { MutationHookOptions, useMutation } from 'react-apollo';
import { DISCONNECT_HUBSPOT_MUTATION } from './mutation';
import { useCurrentBotId } from '@utils/Routing';
import {
  DisconnectHubSpotMutation,
  DisconnectHubSpotMutationVariables,
} from './@types/DisconnectHubSpotMutation';

export const useDisconnectHubSpot = (
  payload?: MutationHookOptions<
    DisconnectHubSpotMutation,
    DisconnectHubSpotMutationVariables
  >,
) => {
  const botId = useCurrentBotId()!;

  return useMutation<
    DisconnectHubSpotMutation,
    DisconnectHubSpotMutationVariables
  >(DISCONNECT_HUBSPOT_MUTATION, {
    variables: { botId },
    ...payload,
  });
};
