import {
  WorkspacesBots_workspacesBots_workspaces_bots,
  WorkspacesBots_workspacesBots_workspaces_bots_currentRole_permissions,
} from './@types/WorkspacesBots';
import { Platform } from '@globals';

const PERMISSIONS_MOCK: WorkspacesBots_workspacesBots_workspaces_bots_currentRole_permissions =
  {
    ai: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    configure: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    inbox: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    roles: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    broadcasting: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    people: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    analyze: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    grow: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    groups: {
      permission: 'EDIT',
      whitelistedGroupIds: null,
      __typename: 'GroupsPermission',
    },
    workspaces: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    flows: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    pro: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    bot: {
      permission: 'EDIT',
      __typename: 'Permission',
    },
    __typename: 'Permissions',
  };

export const getWorkspaceBotMock = ({
  id,
  title,
}: {
  id: string;
  title: string;
}): WorkspacesBots_workspacesBots_workspaces_bots => ({
  __typename: 'Bot',
  id,
  title,
  last_opened_date: '0',
  last_opened_date_for_current_user: '0',
  date_added: '0',
  currentRole: {
    __typename: 'CurrentRole',
    permissions: PERMISSIONS_MOCK,
  },
  status: null,
  pro: null,
  dialogsPricing: {
    __typename: 'DialogsPricing',
    current_tier_limit: null,
    reached_limit: null,
    charging_entity_type: null,
    status: null,
    hasDebt: null,
  },
  time_limited_trial: false,
  allowedPlatforms: [Platform.whatsapp],
  connectedShop: null,
});
