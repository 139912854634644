import client from '../../../common/services/ApolloService';
import { ADMIN_FEATURES_QUERY } from './AdminFeaturesQuery';
import { AdminFeaturesQuery } from './@types/AdminFeaturesQuery';

export async function getAdminFeatures() {
  const res = await client.query<AdminFeaturesQuery>({
    query: ADMIN_FEATURES_QUERY,
  });

  return res.data.me.features;
}
