import gql from 'graphql-tag';
import {
  AI_BLOCK_FRAGMENT,
  AI_INTENT_FRAGMENT,
  AI_PLUGIN_CONFIG_FRAGMENT,
} from '../common/queries';

export const AI_INTENTS_LIST_QUERY = gql`
  query AiIntentsListQuery($botId: String) {
    bot(id: $botId) {
      id
      aiBlock {
        id
        cards {
          id
          ... on AIPlugin {
            config {
              ...aiPluginConfigFragment
            }
          }
        }
      }
    }
  }
  ${AI_PLUGIN_CONFIG_FRAGMENT}
`;

export const REMOVE_AI_INTENT_MUTATION = gql`
  mutation RemoveAiIntentMutation(
    $cardId: String!
    $groupId: String!
    $intentId: String!
  ) {
    removeAiIntent(cardId: $cardId, groupId: $groupId, intentId: $intentId)
  }
`;

export const UPDATE_AI_INTENT_MUTATION = gql`
  mutation UpdateAiIntentMutation(
    $cardId: String!
    $groupId: String!
    $botId: String!
    $intent: AiIntentInput!
  ) {
    updateAiIntent(
      cardId: $cardId
      groupId: $groupId
      intent: $intent
      botId: $botId
    ) {
      ...aiIntentFragment
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const AI_INTENTS_SHORT_FRAGMENT = gql`
  fragment aiIntentsShortFragment on AiGroup {
    intents {
      id
    }
  }
`;

export const ADD_AI_INTENT_MUTATION = gql`
  mutation AddAiIntentMutation(
    $cardId: String!
    $groupId: String!
    $intent: AiIntentInput!
  ) {
    addAiIntent(cardId: $cardId, groupId: $groupId, intent: $intent) {
      ...aiIntentFragment
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const UPDATE_AI_INTENTS_BULK_MUTATION = gql`
  mutation UpdateAiIntentsBulkMutation(
    $botId: String!
    $intents: [AiIntentInput!]!
  ) {
    updateAiIntentsBulk(botId: $botId, intents: $intents) {
      ...aiBlockFragment
    }
  }
  ${AI_BLOCK_FRAGMENT}
`;

export const FILL_FIRST_SESSION_AI_INTENTS_MUTATION = gql`
  mutation FillFirestSessionAiIntentsMutation {
    fillFirestSessionAiIntents
  }
`;
