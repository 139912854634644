import gql from 'graphql-tag';
import Maybe from 'graphql/tsutils/Maybe';
import { useQuery } from 'react-apollo';
import {
  WorkspaceQuery,
  WorkspaceQueryVariables,
} from './@types/WorkspaceQuery';

export { WorkspaceQuery_workspace as Workspace } from './@types/WorkspaceQuery';

export const WORKSPACE_QUERY = gql`
  query WorkspaceQuery($workspaceId: String!) {
    workspace(workspaceId: $workspaceId) {
      id
      name
      permission
      limitation {
        reached
        limit
      }
      subscription {
        status
        hasDebt
        chargingEntityType
      }
    }
  }
`;

export const useWorkspace = (workspaceId: Maybe<string>) => {
  const { data, ...queryResult } = useQuery<
    WorkspaceQuery,
    WorkspaceQueryVariables
  >(WORKSPACE_QUERY, {
    variables: { workspaceId: workspaceId || '' },
    skip: !workspaceId,
  });

  return {
    workspace: data?.workspace,
    ...queryResult,
  };
};
