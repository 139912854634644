import { useBotFeatures } from '@utils/Data/Bot';
import { useCurrentBotId } from '@utils/Routing';

export const useAdCommentsAutoreplyEnabled = () => {
  const botId = useCurrentBotId();
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);

  return {
    isAdCommentsAutoreplyEnabled: Boolean(
      !botFeaturesLoading && botFeatures?.enable_ad_comments_reply,
    ),
    loading: botFeaturesLoading,
  };
};
