import React from 'react';
import { useAutomateEnabled } from './useAutomateEnabled';

interface AutomateEnabledProps {
  children(props: { isAutomateEnabled: boolean }): React.ReactElement;
}
export const AutomateEnabled: React.FC<AutomateEnabledProps> = ({
  children,
}) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  return children({ isAutomateEnabled });
};
