import gql from 'graphql-tag';

export const BOT_TESTING_QUERY = gql`
  query BotTestingQuery {
    botTesting {
      id
      mid
      message {
        text
        buttons
        quick_replies
        sticker_id
        attachment {
          attachment_id
          type
          url
        }
        gallery {
          type
          items {
            title
            description
            buttons
            image_url
            url
            product_id
          }
        }
        reactions {
          type
          emoji
          sender_id
        }
        receipt {
          order_number
          recipient_name
          currency
          total_cost
          subtotal_cost
          tax_cost
          payment_method
          order_status_url
          order_date
          shipping_address {
            address_1
            address_2
            country
            state
            city
            zip_code
          }
          line_items {
            title
            quantity
            price
            currency
            image_url
          }
        }
        send_to {
          title
          bot_id
          block_id
          flow_id
        }
        typing_timeout_milliseconds
        whatsapp_list {
          header_text
          body
          footer
          button
          sections {
            title
            rows {
              id
              title
              description
            }
          }
        }
        whatsapp_list_reply {
          row_title
          row_description
        }
        whatsapp_template {
          name
          body
          footer
          header_text
          header_document {
            name
            link
          }
          header_image
          header_video
          buttons {
            type
            text
            url
          }
        }
        whatsapp_location {
          longitude
          latitude
          name
          address
        }
      }
      from {
        type
      }
      date
    }
  }
`;

export const POST_BOT_TESTING_MESSAGE = gql`
  mutation PostBotTestingMessageMutation($input: BotTestingPostMessageInput!) {
    postBotTestingMessage(input: $input) {
      mid
    }
  }
`;

export const START_BOT_TESTING = gql`
  mutation StartBotTestingMutation($botTestingOptions: StartBotTestingInput!) {
    startBotTesting(botTestingOptions: $botTestingOptions) {
      sessionId
    }
  }
`;

export const CURRENT_TESTING_SESSION = gql`
  query CurrentTestingSessionQuery {
    me {
      id
      bot_testing {
        session_id
      }
    }
  }
`;
