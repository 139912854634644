import ng from 'angular';
import './ui-scroll-box-lazyload.less';

/**
 * @description get related scroll position
 * @param {Node} el -
 * @param {Boolean} startOnBottom - (if true - new elements in list bottom)
 * @return {Number} -
 * @private
 */
const _getRelateScrollPosition = (el, startOnBottom) =>
  startOnBottom
    ? el.scrollHeight - el.scrollTop - el.offsetHeight
    : el.scrollTop;

class UiScrollBoxLazyloadController {
  /**
   * @description constructor
   * @param {*} $element -
   * @param {*} $scope -
   */
  constructor($element, $scope) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.lastLoadPosition = 0;
  }

  /**
   * @description on init callback
   */
  $onInit() {
    const { ctx } = this;

    this.elScrollBox = this.$element.find('.js_scroll-box');
    this.elScrollBox.on('scroll', this._onBoxScroll.bind(this));

    if (ctx) {
      ctx.scrollToStart = this._scrollToStart.bind(this);
      ctx.getCurrentScroll = this._getCurrentScroll.bind(this);
      ctx.scrollToRelatePosition = this._scrollToRelatePosition.bind(this);
    }
  }

  /**
   * @description on destroy callback
   */
  $onDestroy() {
    this.elScrollBox.off('scroll');
  }

  /**
   * @description on scroll handler (call external onLoadMore callback by scroll delta)
   */
  _onBoxScroll() {
    const {
      elScrollBox: [el],
      loadMoreEventPosition,
      startOnBottom,
    } = this;
    const relateReverseScrollPosition = _getRelateScrollPosition(
      el,
      !startOnBottom,
    );
    const isNeedSendLoadMoreEvent =
      relateReverseScrollPosition < loadMoreEventPosition;

    if (isNeedSendLoadMoreEvent && this.lastLoadPosition < el.scrollHeight) {
      this.onLoadMore();
      this.lastLoadPosition = el.scrollHeight;
    }
  }

  /**
   * @description scroll to items flow start
   * @private
   */
  _scrollToStart() {
    const {
      elScrollBox: [el],
      startOnBottom,
    } = this;
    el.scrollTop = startOnBottom ? el.scrollHeight - el.clientHeight : 0;
  }

  /**
   * @description get current relate scroll
   * @return {Number} -
   * @private
   */
  _getCurrentScroll() {
    const {
      elScrollBox: [el],
      startOnBottom,
    } = this;
    return _getRelateScrollPosition(el, startOnBottom);
  }

  /**
   * @description set scroll by related position
   * @param {Number} newPosition -
   * @private
   */
  _scrollToRelatePosition(newPosition) {
    const {
      elScrollBox: [el],
      startOnBottom,
    } = this;
    el.scrollTop = startOnBottom
      ? el.scrollHeight - el.offsetHeight - newPosition
      : newPosition;
  }
}

export default ng
  .module('app.ui.uiScrollBoxLazyload', [])
  .component('uiScrollBoxLazyload', {
    controllerAs: 'vm',
    bindings: {
      /**
       *  {Number} loadMoreEventPosition - relative position of scrolling when called onLoadMore callback
       *  {Boolean} startOnBottom - (if true - new elements in list bottom)
       *  {Object} ctx - context for
       *      {
       *        scrollToStart - scroll list to relative start
       *        getCurrentScroll - get relative scroll position
       *        scrollToRelatePosition - scroll to relative position
       *      }
       *  {Callback} onLoadMore - load more callback
       */
      loadMoreEventPosition: '<',
      startOnBottom: '<',
      ctx: '<',
      onLoadMore: '&',
      scrollBoxClass: '@',
    },
    transclude: true,
    template: `
      <scroll-box
         data-ng-transclude
         class="js_scroll-box scroll-box_height {{vm.scrollBoxClass}}"
      ></scroll-box>
    `,
    controller: UiScrollBoxLazyloadController,
  }).name;
