import { useMutation } from '@apollo/react-hooks';

import { connectPageEvent } from 'cf-common/src/conversionTracking';
import { log } from 'cf-common/src/logger';

import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';

import { sendEvent } from '@utils/Analytics';
import {
  extractGQLErrorData,
  getRequestIdFromApolloError,
} from '@utils/GQL/utils';
import { PermissionGroup } from '@utils/Permissions';
import { getWorkspaceErrorMessage } from '@utils/Data/Workspaces/getWorkspaceErrorMessage';

import {
  BindBotToPageMutation,
  BindBotToPageMutation_bindBotToPage,
  BindBotToPageMutationVariables,
} from './@types/BindBotToPageMutation';
import { BIND_BOT_TO_PAGE_MUTATION } from './queries';
import { useIsFirstSessionTab } from '@utils/Routing';
import { queriesNeedToBeRefetched } from './consts';

export enum FacebookPageBindError {
  facebookPageNotAdmin = 'facebookPageNotAdmin',
}

export const useBindBotToPage = ({
  botId,
  onCompleted,
  onError,
}: {
  botId: string;
  onCompleted(bot: BindBotToPageMutation_bindBotToPage): void;
  onError(error: string): void;
}) => {
  const isFirstSessionTab = useIsFirstSessionTab();
  const [bindBotToPageMutation, { loading: bindBotToPageLoading }] =
    useMutation<BindBotToPageMutation, BindBotToPageMutationVariables>(
      BIND_BOT_TO_PAGE_MUTATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: queriesNeedToBeRefetched(botId),
        onCompleted: (data) => {
          sendEvent({
            category: 'grow tools',
            action: 'page connected',
          });
          connectPageEvent(botId!);
          onCompleted(data.bindBotToPage);
        },
        onError: (error) => {
          const { status, message: apiMessage } =
            extractGQLErrorData(error) || {};
          log.error({
            error,
            msg: 'Bind page error',
            data: {
              label: 'fb_page',
              requestId: getRequestIdFromApolloError(error),
            },
          });

          if (typeof apiMessage === 'string') {
            const errorMessage =
              status === 422
                ? apiMessage.replace('Bad Data: ', '')
                : Messages.somethingWentWrong;
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: errorMessage,
              },
            });
            onError(errorMessage);
            return;
          }

          if (typeof apiMessage === 'object') {
            switch (apiMessage.subtype) {
              case 'not_admin':
                /**
                 * backend sends an error_text field that contains full text errors,
                 * but may not be very up to date
                 */
                onError(FacebookPageBindError.facebookPageNotAdmin);
                break;
              default:
                onError(getWorkspaceErrorMessage(apiMessage.subtype));
            }
            return;
          }

          onError(Messages.somethingWentWrong);
        },
      },
    );
  const bindBotToPage = (pageId: string, features: PermissionGroup[]) => {
    bindBotToPageMutation({
      variables: {
        botId,
        pageId,
        features,
        firstSession: isFirstSessionTab,
      },
    });
  };
  return { bindBotToPage, bindBotToPageLoading };
};
