export const plurals = (
  n: number | string,
  textOne: string,
  textOther: string,
  textOnly: boolean = false,
) => {
  let out = '';
  const nn = Number(n);
  if (!Number.isNaN(nn)) {
    out = nn.toLocaleString();
    if (textOne) {
      out = `${textOnly ? '' : `${out} `}${
        (nn > 1 || nn === 0) && textOther ? textOther : textOne
      }`;
    }
  }
  return out;
};
