import { compose, defaultTo, flip, pickBy, prop } from 'ramda';

import { USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE } from '../queries';

import {
  USER_DATA_FOR_INTERCOM_QUERY as USER_DATA_FOR_INTERCOM_QUERY_TYPE,
  USER_DATA_FOR_INTERCOM_QUERY_me_page_stats as PAGE_STATS,
} from '../@types/USER_DATA_FOR_INTERCOM_QUERY';

const SET_INTERCOM_30MINS_FLAG_TIMEOUT = 30 * 60 * 1000;

const safeGetPropFactory = compose(flip(prop), defaultTo({})) as any; // flip  ((
const removeNullProps = pickBy((prop) => prop !== null);
const getAbtDataChunkName = (abtChunkIndex: number) =>
  `abt_flags${abtChunkIndex > 0 ? `_${abtChunkIndex}` : ''}`;
const prepareAbt = (abtJSON: string) => {
  const ABT_CHUNK_MAX_LENGTH = 255;
  const abt = JSON.parse(abtJSON) || {};
  // eslint-disable-next-line no-underscore-dangle
  delete abt.__ab_buckets__;
  let abtChunkIndex = 0;
  return Object.keys(abt)
    .sort()
    .reduce((abtData, curKey: string) => {
      const abtFlagStr = `"${curKey}":${abt[curKey]},`;
      if (
        abtData[getAbtDataChunkName(abtChunkIndex)]?.length +
          abtFlagStr.length >
        ABT_CHUNK_MAX_LENGTH
      ) {
        abtChunkIndex++;
      }
      const chunkName = getAbtDataChunkName(abtChunkIndex);
      return {
        ...abtData,
        [chunkName]: `${abtData[chunkName] || ''}${abtFlagStr}`,
      };
    }, {} as Record<string, string>);
};
const getUserCreateDelta = (userData: USER_DATA_FOR_INTERCOM_QUERY_TYPE) =>
  SET_INTERCOM_30MINS_FLAG_TIMEOUT -
  (Date.now() - Number(userData.me.date_added));

const getJsonValue = ({ data }: { data: string | null }) =>
  JSON.parse(data || 'null');

const hasAnyValue = (field: { data: string | null }) => {
  return getJsonValue(field) !== null;
};

export const prepareUserDataForIntercom = (
  userData: USER_DATA_FOR_INTERCOM_QUERY_TYPE &
    USER_SERVER_STORAGE_DATA_FOR_INTERCOM_QUERY_TYPE,
) => {
  const {
    me: user,
    bots,
    emailCollectedFromOptinMonster: {
      data: emailCollectedFromOptinMonsterJSON,
    },
    isBlockAdded,
    isEntryPointAdded,
    isEntryPointEnabled,
    isEntryPointManuallyDisabled,
    isFlowTested,
    isBasicTemplateAdded,
    isAdvancedTemplateAdded,
    entryPointsAdded,
  } = userData;
  const { page_stats: pageStats, lastCancelProReason } = user;

  const emailCollectedFromOptinMonster = JSON.parse(
    emailCollectedFromOptinMonsterJSON || 'null',
  );

  const getPathFromPageStats = safeGetPropFactory(pageStats as PAGE_STATS);
  return removeNullProps<Object, Object>({
    name: user.name,
    email: user.email,
    user_id: user.id,
    user_hash: user.HMAC,
    pages: getPathFromPageStats('pages'),
    likes: getPathFromPageStats('likes'),
    pro_page_users: getPathFromPageStats('pro_page_users'),
    connected_pages: getPathFromPageStats('connected_pages'),
    connected_page_likes: getPathFromPageStats('connected_page_likes'),
    fb_email: user.email,
    google_email: user.google_email,
    '30-min-after-signup': getUserCreateDelta(userData) > 0 ? undefined : 'Yes',
    pro_cancel_reason: lastCancelProReason?.reason ?? null,
    pro_cancel_text: lastCancelProReason?.text ?? null,
    pro_cancel_date: lastCancelProReason?.date ?? null,
    email_collect_from_optin_monster: !!emailCollectedFromOptinMonster,
    user_limit_reached: bots.some(prop('user_limit_reached')),
    is_block_added: getJsonValue(isBlockAdded),
    is_entry_point_added:
      hasAnyValue(entryPointsAdded) || getJsonValue(isEntryPointAdded),
    is_entry_point_enabled: getJsonValue(isEntryPointEnabled),
    is_entry_point_manually_disabled: getJsonValue(
      isEntryPointManuallyDisabled,
    ),
    is_flow_tested: getJsonValue(isFlowTested),
    is_basic_template_added: getJsonValue(isBasicTemplateAdded),
    is_advanced_template_added: getJsonValue(isAdvancedTemplateAdded),
    entry_points_added: getJsonValue(entryPointsAdded),
    ...prepareAbt(user.abt_serialized || 'null'),
  });
};
