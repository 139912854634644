import { BOT_STATUS_DATA_QUERY } from '@utils/Bot';
import { CURRENT_BOT_QUERY } from '@utils/Data/Bot/CurrentBot/query';
import { BOT_FEATURES_QUERY, BOT_LIVELINESS_QUERY } from '@utils/Data/Bot';
import { USER_DATA_FOR_INTERCOM_QUERY } from '@utils/Intercom';
import { ADMIN_PHONES_QUERY } from '@pages/BotPage/HomeTab/components/WhatsappAccount/components/WhatsappBuyPhoneDialog/hooks/queries';
import { ENTRY_POINT_TIPS_QUERY } from '@utils/Data/MiniOnboarding/queries';
import { WHATSAPP_SETTINGS_QUERY } from '@utils/Data/Whatsapp/queries';

export const queriesNeedToBeRefetched = (botId: string) => [
  { query: BOT_STATUS_DATA_QUERY, variables: { botId } },
  { query: CURRENT_BOT_QUERY, variables: { botId } },
  { query: BOT_FEATURES_QUERY, variables: { botId } },
  { query: USER_DATA_FOR_INTERCOM_QUERY },
  { query: BOT_LIVELINESS_QUERY, variables: { botId } },
  { query: ENTRY_POINT_TIPS_QUERY, variables: { botId: botId! } },
  { query: WHATSAPP_SETTINGS_QUERY, variables: { botId } },
  { query: ADMIN_PHONES_QUERY },
];
