import { Level, log } from 'cf-common/src/logger';
import { CURRENCIES_QUERY } from './queries';
import client from '../../../common/services/ApolloService';
import { CurrenciesQuery } from './@types/CurrenciesQuery';

export const getCurrencies = async () => {
  const { data, errors } = await client.query<CurrenciesQuery>({
    query: CURRENCIES_QUERY,
  });
  if (errors?.length) {
    log({
      msg: 'Could not fetch currencies!',
      level: Level.error,
    });
  }
  return data.currencies;
};
