import Maybe from 'graphql/tsutils/Maybe';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { sendEvent } from 'cf-common/src/analytics';
import {
  createNewBlankBotMutation,
  createNewBlankBotMutationVariables,
  createNewBlankBotMutation_createBlankBot,
} from '../../../common/services/GQLqueries/@types/createNewBlankBotMutation';
import { CREATE_NEW_BLANK_BOT_MUTATION } from '../../../common/services/GQLqueries/BotGQService.const';
import { getIncrementName } from '../../../common/services/NamingService';
import { BLANK_BOT_NAME } from './constants';
import { addToBotListCache } from './utils';
import { addToWorkspacesBotsCache } from '../Workspaces/addToWorkspacesBotsCache';
import { useWorkspacesAvailable } from '../Workspaces/useWorkspacesAvailable';
import { addToBotListPermissionsCache } from './addToBotListPermissionsCache';
import { Platform } from '@globals';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { BotTabs, getTabLink } from '@utils/Routing';
import { useHistory } from 'react-router-dom';

interface BotTitles {
  title: string;
}

export interface BotTitlesQuery {
  bots: BotTitles[];
}

export function useCreateBlankBot(
  botListQuery?: BotTitlesQuery,
  updateCacheManually?: (
    newBot: createNewBlankBotMutation_createBlankBot,
  ) => void,
) {
  const history = useHistory();
  const [isWorkspaceAvailable] = useWorkspacesAvailable();
  const shouldShowErrorRef = useRef(true);
  const [firstWABotId, setFirstWABotId] = useServerStorage<String>(
    ServerStorageItemKeys.firstWABotId,
  );
  const [createBlankBot, queryResult] = useMutation<
    createNewBlankBotMutation,
    createNewBlankBotMutationVariables
  >(CREATE_NEW_BLANK_BOT_MUTATION, {
    onCompleted(data) {
      const { allowedPlatforms, id } = data.createBlankBot;
      if (allowedPlatforms?.includes(Platform.whatsapp) && !firstWABotId) {
        setFirstWABotId(id);
        history.push(getTabLink(BotTabs.waMigrationSetup, id));
      }
    },
  });

  useEffect(() => {
    if (queryResult.error && shouldShowErrorRef.current) {
      toaster.show({
        type: ServiceMessageType.error,
        payload: { message: Messages.couldNotCreateBot },
      });

      shouldShowErrorRef.current = false;

      log.error({
        error: queryResult.error,
        data: {
          label: 'create_bot',
          requestId: getRequestIdFromApolloError(queryResult.error),
        },
      });
    }
  }, [queryResult, shouldShowErrorRef]);

  const onCreateBlankBot = useCallback(
    (workspaceId?: Maybe<string>, platforms?: Platform[]) => {
      const titles = botListQuery
        ? botListQuery.bots.map(({ title }) => title)
        : [];
      const newBlankBotName = getIncrementName(BLANK_BOT_NAME, titles);

      sendEvent({ category: 'bot', action: 'add' });

      return createBlankBot({
        variables: { title: newBlankBotName, workspaceId, platforms },
        update: (store, { data }) => {
          const newBot = data?.createBlankBot;
          if (newBot) {
            if (updateCacheManually) {
              updateCacheManually(newBot);
            } else {
              addToBotListCache(
                newBot,
                workspaceId,
                isWorkspaceAvailable,
                store,
              );
              addToWorkspacesBotsCache(newBot, workspaceId, store);
              addToBotListPermissionsCache(newBot, store);
            }
          }
        },
      });
    },
    [botListQuery, createBlankBot, isWorkspaceAvailable, updateCacheManually],
  );

  return [onCreateBlankBot, queryResult] as const;
}
