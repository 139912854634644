import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { DndState, useDnd, DND_PLACEHOLDER_TYPE } from './DndContext';
import * as css from './DndPlaceholder.css';

interface dndPlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  droppableId: string;
}

export const DndPlaceholder: React.FC<dndPlaceholderProps> = ({
  type,
  className,
  droppableId,
  ...props
}) => {
  const [topOffset, setTopOffset] = useState(-1);
  const ref = useRef<HTMLDivElement | null>(null);
  const needShowPlaceholder = useCallback(
    (state?: DndState) => state?.isDragging && state.hoveredId === droppableId,
    [droppableId],
  );
  const { dndState } = useDnd();

  useEffect(() => {
    if (
      needShowPlaceholder(dndState) &&
      ref.current &&
      dndState?.shadowPosition !== undefined
    ) {
      if (dndState.shadowPosition === 0) {
        setTopOffset(0);
      } else {
        const rect = ref.current.parentElement?.children[
          dndState.shadowPosition - 1
        ]?.getBoundingClientRect();
        setTopOffset(
          (rect?.top || 0) +
            (rect?.height || 0) -
            (ref.current.parentElement?.getBoundingClientRect().top || 0),
        );
      }
    } else {
      setTopOffset(-1);
    }
  }, [dndState, needShowPlaceholder]);

  if (type !== dndState?.draggingType) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={css.dndPlaceholderWrapper}
      data-dnd-anchor-type={DND_PLACEHOLDER_TYPE}
      style={{
        top: `${topOffset}px`,
      }}
    >
      {needShowPlaceholder(dndState) && topOffset >= 0 && (
        <div {...props} className={cn(css.dndPlaceholder, className)} />
      )}
    </div>
  );
};
