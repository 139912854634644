import ng from 'angular';
import TextField from './text-field';
import AvatarStack from './avatar-stack';
import ChipInput from './chip-input';
import SpecialButton from './buttons/button-with-icon-special-case';
import './two-column-list/index.less';
import AutoComplete from './auto-complete';
import ClickAway from './click-away';
import Checkbox from './checkbox';
import Switcher from './switcher';
import SwitcherLabel from './switcher-label';
import SwitcherLabelInteractive from './switcher-label-interactive';
import FbButton from './fb-button';
import Modal from './modal';
import PopupDatepicker from './popup-datepicker';
import CfYoutubeVideo from './cf-youtube-video';
import Popover from './cf-popover';
import LinkHighlighter from './link-highlighter';
import DropDown from './dropdown-menu';
import UIPickerView from './ui-picker-view';
import SpriteImg from './sprite-img';
import Tabs from './tabs';
import FbUniButton from './fb-uni-button';
import cfCommonTile from './cf-common-tile';
import uiCounter from './ui-counter';
import bigAddIcon from './icon/add-icon-big';
import mesIcon from './icon/mes-icon';
import uiNotification from './ui-notification';
import uiScrollBoxLazyload from './ui-scroll-box-lazyload';
import { ngUiUserAttributeEditor } from '../modern-components/UiUserAttributeEditor/UiUserAttributeEditorFU';
import { ngUiUserAttributeEditorModal } from '../modern-components/UiUserAttributeEditor/UiUserAttributeEditorModal';
import avatar from './avatar';

// just custom tags without logic;
import './chip/index.less';
import './sub-header/sub-header.less';
import './dum-container/dum-container.less';
import './text-field-box/text-field-box.less';
import './cf-menu/cf-menu.less';
import './white-card/white-card.less';
import './buttons/index.less';
import './icon/icon.less';
import './input-native/input-native.less';
import './scroll-box/scroll-box.less';
import './modal-popup/modal-popup.less';
import './loader/loader.less';
import './cf-tiles/cf-tiles.less';
import './cf-tile-item/cf-tile-item.less';
import './cf-popover-like/cf-popover-like.less';
import './cf-cols/index';

import '../modern-ui/_common/colors/colors.css';
import './_common/typography.less';
import './_common/z-index.less';
import './_common/border-rounded.less';

export default ng.module('app.ui', [
  uiNotification,
  bigAddIcon,
  mesIcon,
  uiCounter,
  cfCommonTile,
  Modal,
  ClickAway,
  ChipInput,
  TextField,
  AvatarStack,
  AutoComplete,
  Checkbox,
  Switcher,
  SwitcherLabel,
  SwitcherLabelInteractive,
  FbButton,
  PopupDatepicker,
  CfYoutubeVideo,
  LinkHighlighter,
  DropDown,
  SpecialButton,
  UIPickerView,
  SpriteImg,
  Tabs,
  FbUniButton,
  uiScrollBoxLazyload,
  ngUiUserAttributeEditor,
  ngUiUserAttributeEditorModal,
  avatar,
]).name;
