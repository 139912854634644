import gql from 'graphql-tag';

export const LOCALE_DATA_QUERY = gql`
  query LocaleDataQuery {
    me {
      id
      locale
    }
  }
`;

export const SET_LOCALE_DATA_MUTATION = gql`
  mutation SetLocaleDataMutation($locale: String!) {
    setLocale(locale: $locale)
  }
`;

export const COUNTRY_DATA_QUERY = gql`
  query AdminCountryAndCurrencyDataQuery {
    me {
      id
      pricing_localization {
        country_code
        currency
      }
    }
  }
`;
