import gql from 'graphql-tag';

export const CONNECT_KOMMO_MUTATION = gql`
  mutation ConnectKommoMutation(
    $botId: String!
    $code: String!
    $referer: String!
    $clientId: String!
  ) {
    connectKommo(
      botId: $botId
      code: $code
      referer: $referer
      clientId: $clientId
    )
  }
`;

export const DISCONNECT_KOMMO_MUTATION = gql`
  mutation DisconnectKommoMutation($botId: String!) {
    disconnectKommo(botId: $botId)
  }
`;
