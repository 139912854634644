import i18next from 'i18next';

import { getPluginName } from '../../common/pluginNames';

// prettier-ignore
const ATTRIBUTES_DESCRIPTION_MAP = {
  'first name': () => i18next.t('AttributesDescription.users-first-name'),
  'last name': () => i18next.t('AttributesDescription.users-last-name'),
  'timezone': () => i18next.t('AttributesDescription.users-timezone'),
  'locale': () => i18next.t('AttributesDescription.users-messenger-language-setting'),
  'gender': () => i18next.t('AttributesDescription.users-gender'),
  'profile pic url': () => i18next.t('AttributesDescription.users-profile-picture-url'),
  'messenger user id': () => i18next.t('AttributesDescription.users-unique-persistent-id-also-known-as-facebooks-page-scoped-user-id-useful-for-the-json-plugin-and-broadcasting-api'),
  'chatfuel user id': () => i18next.t('AttributesDescription.chatfuels-user-id-previously-called-fb-id',
    { skipInterpolation: true },
  ),
  'last clicked button name': () => i18next.t('AttributesDescription.name-of-the-button-user-clicked-last'),
  'last visited block name': () => i18next.t('AttributesDescription.name-of-the-block-user-visited-last'),
  'last visited block id': () => i18next.t('AttributesDescription.id-of-the-block-user-visited-last'),
  'rss and search subscriptions': () => i18next.t('AttributesDescription.all-subscription-titles'),
  'ref': () => i18next.t('AttributesDescription.value-of-ref-parameter-from-m-me-link-user-clicked-last'),
  'zip': () => i18next.t(
    'AttributesDescription.zip-code-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'address': () => i18next.t(
    'AttributesDescription.address-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'country': () => i18next.t(
    'AttributesDescription.country-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'state': () => i18next.t(
    'AttributesDescription.state-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'latitude': () => i18next.t(
    'AttributesDescription.latitude-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'longitude': () => i18next.t(
    'AttributesDescription.longitude-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'last purchased item': () => i18next.t('AttributesDescription.title-of-the-item-user-purchased-last'),
  'within 24h window': () => i18next.t('AttributesDescription.users-interaction-status-with-the-bot-within-а-24-hour-window'),
  'last freeform input': () => i18next.t('AttributesDescription.users-last-free-typed-message'),
  'last payment charge id': () => i18next.t('AttributesDescription.customer-charge-id-at-last-payment'),
  'last payment name': () => i18next.t('AttributesDescription.customer-name-used-at-last-payment'),
  'last payment email': () => i18next.t('AttributesDescription.customer-email-address-used-at-last-payment'),
  'last payment phone': () => i18next.t('AttributesDescription.customer-phone-number-used-at-last-payment'),
  'last payment address': () => i18next.t('AttributesDescription.customer-delivery-address-used-at-last-payment'),
  'last user freeform input': () => i18next.t('AttributesDescription.users-last-free-typed-message'),
  'city': () => i18next.t(
    'AttributesDescription.city-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'map url': () => i18next.t(
    'AttributesDescription.map-url-from',
    { plugin_name: getPluginName('location_quick_reply_plugin') },
  ),
  'picture': () => i18next.t('AttributesDescription.users-profile-picture-url'),
  'source': () => null,
};

export const getAttributeDescription = (name: string) =>
  // @ts-ignore
  ATTRIBUTES_DESCRIPTION_MAP[name.toLowerCase()]?.();
