import gql from 'graphql-tag';

export const HUBSPOT_OAUTH_URL_QUERY = gql`
  query HubSpotOauthUrlQuery($botId: String!) {
    hubSpotOauthUrl(botId: $botId)
  }
`;

export const HUBSPOT_INTEGRATION_STATUS_QUERY = gql`
  query HubSpotIntegrationStatusQuery($botId: String!) {
    hubSpotIntegrationStatus(botId: $botId) {
      accountLink
      accountName
      status
    }
  }
`;
