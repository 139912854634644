import { useQuery } from '@apollo/react-hooks';
import { GOOGLE_SHEETS_QUERY, GOOGLE_USERS_QUERY } from './queries';
import {
  GoogleUsersQuery,
  GoogleUsersQuery_googleUsers,
  GoogleUsersQueryVariables,
} from './@types/GoogleUsersQuery';
import {
  GoogleSpreadsheets,
  GoogleSpreadsheets_bot_googleSpreadsheets,
  GoogleSpreadsheetsVariables,
} from './@types/GoogleSpreadsheets';

export const useGoogleUsers = (botId: string) => {
  const {
    data: usersData,
    loading: googleUsersLoading,
    ...rest
  } = useQuery<GoogleUsersQuery, GoogleUsersQueryVariables>(
    GOOGLE_USERS_QUERY,
    { variables: { botId } },
  );

  return {
    googleUsers:
      usersData?.googleUsers ?? ([] as GoogleUsersQuery_googleUsers[]),
    googleUsersLoading,
    ...rest,
  };
};

export const useGoogleSpreadsheets = (
  botId: string,
  ownerId?: string | null,
) => {
  const {
    data: spreadsheetsData,
    loading,
    ...rest
  } = useQuery<GoogleSpreadsheets, GoogleSpreadsheetsVariables>(
    GOOGLE_SHEETS_QUERY,
    {
      variables: { botId, ownerId },
      skip: !ownerId,
      fetchPolicy: 'cache-and-network',
    },
  );

  const googleSpreadsheets =
    spreadsheetsData?.bot.googleSpreadsheets ??
    ([] as GoogleSpreadsheets_bot_googleSpreadsheets[]);

  return {
    googleSpreadsheets,
    googleSpreadsheetsLoading: loading && !spreadsheetsData,
    ...rest,
  };
};
