import { cond } from 'ramda';
import { isInRangeInclusive } from './isInRangeInclusive';

const formatter = new Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  notation: 'compact',
});

export const formatNumberShort = cond([
  [isInRangeInclusive(0, 1000), String],
  [isInRangeInclusive(1000, 10000000), formatter.format],
  [isInRangeInclusive(10000000, Number.MAX_SAFE_INTEGER), () => `> 10 M`],
]);
