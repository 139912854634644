import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Dragula from 'react-dragula';
import noop from 'lodash-es/noop';

export const DND_PLACEHOLDER_TYPE = 'dnd-placeholder';

const filterDndPlaceholder = (child: ChildNode) => {
  // @ts-ignore
  return child.dataset.dndAnchorType !== DND_PLACEHOLDER_TYPE;
};

const getHTMLElementIndex = (el: HTMLElement) =>
  [...el.parentElement!.childNodes].filter(filterDndPlaceholder).indexOf(el);

export const DND_NOT_DROPPABLE_CONTAINER_CLASS = 'dnd-non-droppable-container';
export const DND_PLACEHOLDER_CLASS = 'dnd-placeholder-id';

export type DndState = {
  draggingType?: string;
  hoveredId?: string;
  shadowPosition?: number;
  draggingId?: string;
  isDragging?: boolean;
  draggingSource?: string;
  draggingSourcePosition?: number;
};

export interface DndContextProps {
  dndState: DndState;
  setDndState: React.Dispatch<React.SetStateAction<DndState>>;
  isDisabledDnd: boolean;
  setDisabledDnd: React.Dispatch<React.SetStateAction<boolean>>;
  ref: (el: HTMLDivElement | null) => void;
}

export const DndContext = React.createContext<DndContextProps>({
  dndState: {},
  setDndState: noop,
  isDisabledDnd: false,
  setDisabledDnd: noop,
  ref: noop,
});

export type onDropParams = {
  type?: string;
  draggingId: string;
  sourceId: string;
  targetId: string;
  position: number;
};

interface DndProviderProps {
  onDrop: (params: onDropParams) => void;
  disabled?: boolean;
}

export const DndProvider: React.FC<DndProviderProps> = ({
  disabled = false,
  onDrop,
  children,
}) => {
  const [dndState, setDndState] = useState<DndState>({});
  const [disabledDnd, setDisabledDnd] = useState(disabled);
  const dropCallback = useRef(onDrop);

  const dragula = useRef(
    Dragula([], {
      moves: (_: HTMLElement, __: HTMLElement, handle: HTMLElement) => {
        let disabled = true;
        setDisabledDnd((currentDisabledState) => {
          disabled = currentDisabledState;
          return currentDisabledState;
        });
        const elementStackHasDraggableType =
          handle.dataset.dndDraggableType ||
          handle.parentElement?.dataset.dndDraggableType ||
          handle.parentElement?.parentElement?.dataset.dndDraggableType ||
          handle.parentElement?.parentElement?.parentElement?.dataset
            .dndDraggableType ||
          handle.parentElement?.parentElement?.parentElement?.parentElement
            ?.dataset.dndDraggableType;
        return !disabled && elementStackHasDraggableType;
      },
      accepts: (el: HTMLElement, target: HTMLElement) => {
        return target.dataset.dndDroppableType === el.dataset.dndDraggableType;
      },
    })
      .on('shadow', (el: HTMLElement, container: HTMLElement) => {
        if (
          container.dataset.dndDroppableType !== el.dataset.dndDraggableType
        ) {
          return;
        }
        setDndState((currentState) => ({
          ...currentState,
          shadowPosition: getHTMLElementIndex(el),
        }));
      })
      .on('over', (el: HTMLElement, container: HTMLElement) => {
        if (
          container.dataset.dndDroppableType !== el.dataset.dndDraggableType
        ) {
          return;
        }
        setDndState((currentState) => ({
          ...currentState,
          hoveredId: container.dataset.dndDroppableId,
        }));
      })
      .on('dragend', () => {
        setDndState((currentState) => ({
          ...currentState,
          draggingType: undefined,
          isDragging: false,
          draggingId: undefined,
          hoveredId: undefined,
          draggingSource: undefined,
          draggingSourcePosition: undefined,
          shadowPosition: undefined,
        }));
      })
      .on('drag', (el: HTMLElement, source: HTMLElement) => {
        const position = getHTMLElementIndex(el);
        setDndState((currentState) => ({
          ...currentState,
          draggingType: el.dataset.dndDraggableType,
          isDragging: true,
          draggingId: el.id,
          draggingSource: source.dataset.dndDroppableId,
          draggingSourcePosition: position,
          shadowPosition: position,
        }));
      })
      .on(
        'drop',
        (
          el: HTMLElement,
          target: HTMLElement,
          source: HTMLElement,
          sibling?: HTMLElement,
        ) => {
          if (sibling?.classList.contains(DND_PLACEHOLDER_CLASS)) {
            return;
          }
          let draggingId: string = '';
          let position: number = 0;
          let type: string | undefined;
          let targetId: string = '';
          let sourceId: string = '';

          setDndState((currentState) => {
            type = currentState.draggingType;
            position = currentState.shadowPosition || 0;
            draggingId = currentState.draggingId || '';
            targetId = currentState.hoveredId || '';
            sourceId = currentState.draggingSource || '';
            return currentState;
          });
          if (target.dataset.dndDroppableId === source.dataset.dndDroppableId) {
            setDndState((currentState) => {
              if ((currentState.draggingSourcePosition || 0) + 1 < position) {
                position -= 1;
              }
              return currentState;
            });
          }

          // move El (block) to Source node for correct destroy El in React
          // (by GQL initial re render)
          if (
            target.dataset?.dndDroppableId !== source.dataset?.dndDroppableId
          ) {
            source.appendChild(el);
          }

          dropCallback.current?.({
            type,
            draggingId,
            targetId,
            sourceId,
            position,
          });
        },
      )
      .on('cancel', () => {
        let draggingId: string = '';
        let position: number = 0;
        let type: string | undefined;
        let targetId: string = '';
        let sourceId: string = '';

        setDndState((currentState) => {
          type = currentState.draggingType;
          position = currentState.shadowPosition || 0;
          draggingId = currentState.draggingId || '';
          targetId = currentState.hoveredId || '';
          sourceId = currentState.draggingSource || '';
          return currentState;
        });

        dropCallback.current?.({
          type,
          draggingId,
          targetId,
          sourceId,
          position,
        });
      }),
  );

  const ref = useCallback((el: HTMLDivElement | null) => {
    if (!el) {
      return;
    }

    if (
      !dragula.current.containers.some(
        (container: HTMLElement) => container.dataset.dndDroppableId === el.id,
      )
    ) {
      dragula.current.containers.push(el);
    }
  }, []);

  useLayoutEffect(() => {
    dropCallback.current = onDrop;
  });

  return (
    <DndContext.Provider
      value={{
        dndState,
        setDndState,
        isDisabledDnd: disabledDnd,
        setDisabledDnd,
        ref,
      }}
    >
      {children}
    </DndContext.Provider>
  );
};

export const useDnd = () => useContext<DndContextProps>(DndContext);
