import nanoid from 'nanoid';

export class RouteCallbackService {
  private handlerId: string | null = null;
  private handler: (() => void) | null = null;
  static queryParamName = 'callbackId';

  /**
   * save handler and return new route with query parameter for the hander
   */
  registerHandlerForRoute(handler: () => void, route: string): string {
    this.handler = handler;
    const handlerId = nanoid(6);
    this.handlerId = handlerId;

    const url = new URL(route, document.location.origin);
    const queryParams = url.searchParams;
    queryParams.append(RouteCallbackService.queryParamName, handlerId);
    url.search = queryParams.toString();
    return url.pathname + url.search + url.hash;
  }

  /**
   * check current location, and call handler if query parameter match
   * remove query parameter
   */
  emit(): void {
    const queryParams = new URLSearchParams(document.location.search.slice(1));
    const handlerId = queryParams.get(RouteCallbackService.queryParamName);
    if (this.handlerId === handlerId && this.handler) {
      this.handler();
    }

    this.handlerId = null;
    this.handler = null;

    queryParams.delete(RouteCallbackService.queryParamName);
    window.history.replaceState(
      null,
      '',
      queryParams.toString() === '' ? undefined : `?${queryParams}`,
    );
  }
}
