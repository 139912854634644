import { useAdminId } from '@utils/Admin/useAdminId';
import { useCallback } from 'react';
import './alvisScript';

const hideChecklist = () => window.Alvis?.('dismiss_checklist');

// checklist.getalvis.com
export const useAlvis = () => {
  const { id } = useAdminId();

  const showChecklist = useCallback(
    (checklistId: string) => {
      if (id) {
        /**
         * the window.Alvis initialization function happens synchronously in useEffect above,
         * window.Alvis stores the order of actions in window._Alviss,
         * which will be executed after the asynchronous package initialization
         */
        window.Alvis?.('identify', id, { show_checklist_id: checklistId });
      }
    },
    [id],
  );

  return {
    showChecklist,
    hideChecklist,
  };
};
