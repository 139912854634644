import client from '@common/services/ApolloService';
import memoize from 'lodash-es/memoize';
import {
  GetAdsJsonQuery,
  GetAdsJsonQueryVariables,
} from './@types/GetAdsJsonQuery';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';
import { GET_ADS_JSON_QUERY } from './query';
import { FLOW_BLOCK_FRAGMENT } from '@components/Plugins/common/PluginGQL';
import Maybe from 'graphql/tsutils/Maybe';
import { getEntryPointCard } from '@components/FlowBuilder/views/entry-points/common/utils';
import { FlowBlockFragment } from '@components/Plugins/common/@types/FlowBlockFragment';
import { isFacebookAdsJson } from '@components/FlowBuilder/views/utils';

export const getAdsJsonDataObservable = memoize(
  (botId: string, blockId: string) => {
    const watchQuery = client.watchQuery<
      GetAdsJsonQuery,
      GetAdsJsonQueryVariables
    >({
      query: GET_ADS_JSON_QUERY,
      notifyOnNetworkStatusChange: true,
      variables: {
        botId,
        blockId,
      },
    });
    return {
      observable: Observable.from(
        watchQuery as ObservableInput<ApolloQueryResult<GetAdsJsonQuery>>,
      ).map(({ data, loading }) => ({
        json: data?.bot.adsJson.json,
        loading,
      })),
      watchQuery,
    };
  },
  (...args) => Object.values(args).join('_'),
);

export const isNeedJsonAdsData = (blockId: string) => {
  let blockData: Maybe<FlowBlockFragment>;
  try {
    blockData = client.readFragment<FlowBlockFragment>({
      fragment: FLOW_BLOCK_FRAGMENT,
      fragmentName: 'FlowBlockFragment',
      id: `FlowBlock:${blockId}`,
    });
  } catch {
    blockData = null;
  }
  const cards = blockData?.cards || null;
  return cards && isFacebookAdsJson(getEntryPointCard(cards)?.plugin_id);
};

export const refetchJsonAdsDataIfNeed = async (
  botId: string,
  blockId: string,
) => {
  if (isNeedJsonAdsData(blockId)) {
    return getAdsJsonDataObservable(botId, blockId).watchQuery.refetch();
  }
  return undefined;
};
