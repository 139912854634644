export const waitFoTheReturnToTab = (waitTimeout: number) => {
  const controller = new AbortController();
  const { signal } = controller;

  const promise = new Promise((resolve, reject) => {
    const startWaitTimestamp = Date.now();
    const returnHandler = () => {
      if (document.visibilityState === 'visible') {
        document.removeEventListener('visibilitychange', returnHandler);
        const waitTime = Date.now() - startWaitTimestamp;
        if (waitTime > waitTimeout) {
          resolve(waitTime);
        }
      }
    };

    document.addEventListener('visibilitychange', returnHandler);

    signal.onabort = () => {
      document.removeEventListener('visibilitychange', returnHandler);
      reject();
    };
  });

  // @ts-expect-error
  promise.abort = () => controller.abort();

  return promise as Promise<void> & { abort: VoidFunction };
};
