import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import { AdminId } from './@types/AdminId';

const ADMIN_ID_QUERY = gql`
  query AdminId {
    me {
      id
    }
  }
`;

export const useAdminId = (options?: QueryHookOptions<AdminId, {}>) => {
  const { data, ...queryResult } = useQuery<AdminId>(ADMIN_ID_QUERY, options);

  return {
    id: data?.me.id,
    ...queryResult,
  };
};
