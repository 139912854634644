import { Platform } from '@globals';
import { AUDIO_FILE_SIZE_LIMIT_MB } from './audioSupportedTypes';
import { IMAGE_FILE_SIZE_LIMIT_MB } from './imageSupportedTypes';
import { VIDEO_FILE_SIZE_LIMIT_MB } from './videoSupportedTypes';
import { DOCUMENT_FILE_SIZE_LIMIT_MB } from './documentSupportedTypes';
import { FileAttachmentType } from '../UploadService/types';
import { PDF_FILE_SIZE_LIMIT_MB } from './pdfSupportedTypes';
import { MP4_FILE_SIZE_LIMIT_MB } from './mp4SupportedTypes';

const FILE_SIZE_LIMIT = {
  [FileAttachmentType.image]: IMAGE_FILE_SIZE_LIMIT_MB,
  [FileAttachmentType.video]: VIDEO_FILE_SIZE_LIMIT_MB,
  [FileAttachmentType.audio]: AUDIO_FILE_SIZE_LIMIT_MB,
  [FileAttachmentType.file]: DOCUMENT_FILE_SIZE_LIMIT_MB,
  [FileAttachmentType.pdf]: PDF_FILE_SIZE_LIMIT_MB,
  [FileAttachmentType.mp4]: MP4_FILE_SIZE_LIMIT_MB,
};

export const MB_MULTIPLIER = 1024 * 1024;

export const getFileSizeLimit = (
  type: FileAttachmentType,
  platform: Platform,
) => FILE_SIZE_LIMIT[type][platform];

export const getImageFileSizeLimit = (platform: Platform) =>
  getFileSizeLimit(FileAttachmentType.image, platform);
