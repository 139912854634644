import debounce from 'lodash-es/debounce';
import { Editor } from 'slate-react';
import Maybe from 'graphql/tsutils/Maybe';

export const wrapText = debounce(
  // debounce call for insert emoji after Editor component will be ready
  (wrapPart: string, editorInstance: Maybe<Editor>) => {
    if (!editorInstance) {
      return;
    }
    editorInstance.wrapText(wrapPart);
  },
);
