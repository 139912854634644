import ng from 'angular';
import './index.less';
import animate from 'angular-animate';
// console.log(animate);

export default ng
  .module('app.ui.uiNotification', [animate])
  .config(($animateProvider) => {
    'ngInject';

    $animateProvider.classNameFilter(/angular-animate/);
  })
  .component('uiNotification', {
    controllerAs: 'vm',
    replace: true,
    bindings: {
      text: '<',
      show: '<',
      buttonText: '<',
      onButtonClick: '&',
    },
    template: `
      <ui-notification-body class="angular-animate" ng-if="vm.show">
        <span class="text">{{vm.text}}</span>
        <cf-button
          theme="dark"
          short="true"
          narrow="true"
          ng-if="vm.buttonText"
          ng-click="vm.onButtonClick({$event:$event})"
        >
          <cf-button-label>
            {{vm.buttonText}}
          </cf-button-label>
        </cf-button>
      </ui-notification-body>
    `,
  }).name;
