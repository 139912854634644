import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';

import client from '../../common/services/ApolloService';

import { BOT_STATUS_DATA_QUERY } from './queries';
import { getBotStatus } from './getBotStatus';
import {
  BotStatusDataQuery,
  BotStatusDataQueryVariables,
} from './@types/BotStatusDataQuery';

export const getConnectedPageObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery<BotStatusDataQuery, BotStatusDataQueryVariables>({
      query: BOT_STATUS_DATA_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: {
        botId,
      },
    }) as ObservableInput<ApolloQueryResult<BotStatusDataQuery>>,
  ).map(({ data }) => {
    return {
      pageId: data?.bot.status?.page_info?.id,
      status: data?.bot ? getBotStatus(data.bot) : undefined,
    };
  }),
);
