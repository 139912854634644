import Maybe from 'graphql/tsutils/Maybe';

export const strictFn = <T extends unknown[], U>(
  fn: (...args: T) => Maybe<U>,
  getMessage?: (result: Maybe<U>) => string,
) => (...args: T) => {
  const result = fn(...args);

  if (result === null || result === undefined) {
    const message =
      getMessage?.(result) ||
      `${window.i18next.t(
        'strictFn-Template-1052-this-function-supposed-to-return-a-value-but-it-returned',
      )}${result}`;

    throw new Error(message);
  }

  return result;
};
