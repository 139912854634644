export const UTC_LIST = [
  { offset: -43200, name: 'UTC-12:00' },
  { offset: -39600, name: 'UTC-11:00' },
  { offset: -36000, name: 'UTC-10:00' },
  { offset: -34200, name: 'UTC-09:30' },
  { offset: -32400, name: 'UTC-09:00' },
  { offset: -28800, name: 'UTC-08:00' },
  { offset: -25200, name: 'UTC-07:00' },
  { offset: -21600, name: 'UTC-06:00' },
  { offset: -18000, name: 'UTC-05:00' },
  { offset: -14400, name: 'UTC-04:00' },
  { offset: -12600, name: 'UTC-03:30' },
  { offset: -10800, name: 'UTC-03:00' },
  { offset: -7200, name: 'UTC-02:00' },
  { offset: -3600, name: 'UTC-01:00' },
  { offset: 0, name: 'UTC+00:00' },
  { offset: 3600, name: 'UTC+01:00' },
  { offset: 7200, name: 'UTC+02:00' },
  { offset: 10800, name: 'UTC+03:00' },
  { offset: 12600, name: 'UTC+03:30' },
  { offset: 14400, name: 'UTC+04:00' },
  { offset: 16200, name: 'UTC+04:30' },
  { offset: 18000, name: 'UTC+05:00' },
  { offset: 19800, name: 'UTC+05:30' },
  { offset: 20700, name: 'UTC+05:45' },
  { offset: 21600, name: 'UTC+06:00' },
  { offset: 23400, name: 'UTC+06:30' },
  { offset: 25200, name: 'UTC+07:00' },
  { offset: 28800, name: 'UTC+08:00' },
  { offset: 30600, name: 'UTC+08:30' },
  { offset: 31500, name: 'UTC+08:45' },
  { offset: 32400, name: 'UTC+09:00' },
  { offset: 34200, name: 'UTC+09:30' },
  { offset: 36000, name: 'UTC+10:00' },
  { offset: 37800, name: 'UTC+10:30' },
  { offset: 39600, name: 'UTC+11:00' },
  { offset: 43200, name: 'UTC+12:00' },
  { offset: 45900, name: 'UTC+12:45' },
  { offset: 46800, name: 'UTC+13:00' },
  { offset: 48600, name: 'UTC+13:45' },
  { offset: 50400, name: 'UTC+14:00' },
];
