import { getCurrentDomain } from '@utils/UrlUtils';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useQuery } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import { WHITE_LABEL_AGENCY_QUERY } from './queries';
import {
  WhiteLabelAgencyQuery,
  WhiteLabelAgencyQueryVariables,
} from './@types/WhiteLabelAgencyQuery';

export const useWhiteLabelAgencyData = () => {
  const isWhiteLabel = isWhiteLabelDomain();
  const queryResult = useQuery<
    WhiteLabelAgencyQuery,
    WhiteLabelAgencyQueryVariables
  >(WHITE_LABEL_AGENCY_QUERY, {
    onError: (error) => {
      log.warn({ error, msg: 'White label agency query error' });
    },
    variables: {
      domain: getCurrentDomain(),
    },
    skip: !isWhiteLabel,
  });

  return queryResult;
};
