import { Level, log } from 'cf-common/src/logger';
import client from '../../../common/services/ApolloService';
import { CLIPBOARD_DATA_QUERY, SET_CLIPBOARD_DATA_MUTATION } from './queries';
import { ClipboardData } from './@types/ClipboardData';
import {
  SetClipboardDataMutation,
  SetClipboardDataMutationVariables,
} from './@types/SetClipboardDataMutation';

export const getClipboardData = async () => {
  const { data, errors } = await client.query<ClipboardData>({
    query: CLIPBOARD_DATA_QUERY,
    fetchPolicy: 'no-cache',
  });
  if (errors?.length) {
    log({
      msg: 'Could not get clipboard data!',
      level: Level.warn,
    });
  }
  return data.clipboardData;
};

export const setClipboardData = async (data: string) => {
  const response = await client.mutate<
    SetClipboardDataMutation,
    SetClipboardDataMutationVariables
  >({
    mutation: SET_CLIPBOARD_DATA_MUTATION,
    variables: { data },
  });
  if (response.errors?.length) {
    log({
      msg: 'Could not set clipboard data!',
      level: Level.warn,
    });
  }

  return !!response.data;
};
