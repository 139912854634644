import { useCallback } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { useRefetchWhatsappPhoneEntries } from './useUserWhatsappPhoneEntries';
import { useRefetchAdminPhones } from '@pages/BotPage/HomeTab/components/WhatsappAccount/components/WhatsappBuyPhoneDialog/hooks/useRefreshAdminPhones';
import { useEntryPointTipsQuery } from '../MiniOnboarding/useEntryPointTipsQuery';

export const useRefreshWhatsappData = (botId: Maybe<string>) => {
  const { refetch: refetchPhones, loading: phonesLoading } =
    useRefetchWhatsappPhoneEntries(botId);
  const { refetch: refetchAdminPhones, loading: adminPhonesLoading } =
    useRefetchAdminPhones();
  const { refetch: refetchEntryPointTips } = useEntryPointTipsQuery(botId);

  const refetchWhatsappData = useCallback(() => {
    refetchPhones();
    refetchAdminPhones();
    refetchEntryPointTips();
  }, [refetchAdminPhones, refetchEntryPointTips, refetchPhones]);

  return {
    refetchWhatsappData,
    loading: phonesLoading || adminPhonesLoading,
  };
};
