import { AssignedPromoCodeStatus, TierType } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';
import { BotPromoCodeQuery } from './@types/BotPromoCodeQuery';

export const checkPromoCodeAvailableFreeMonth = (
  botPromoCodeData: Maybe<BotPromoCodeQuery>,
  tierType: TierType,
) => {
  const { promoCode, status: promoCodeStatus } =
    botPromoCodeData?.bot.assignedPromoCode || {};
  const isAvailableFreeMonth =
    promoCodeStatus === AssignedPromoCodeStatus.assigned &&
    promoCode?.availableTiers.includes(tierType);

  return Boolean(isAvailableFreeMonth);
};
