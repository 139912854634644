import { useCurrentBotId } from '@utils/Routing';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';

export const useCurrentTestPageId = () => {
  const botId = useCurrentBotId();
  const { pageId, loading } = usePageConnected(botId);
  return {
    pageId: loading
      ? undefined
      : pageId || window.CHATFUEL_CONFIG.CF_BOT_TEST_PAGE_ID,
    isDefaultTestPage: loading ? undefined : !pageId,
  };
};
