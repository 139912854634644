import { useEffect, useState } from 'react';

const stopPropagation = (event: Event) => {
  event.stopPropagation();
};

export const usePreventEvents = (events: Array<keyof HTMLElementEventMap>) => {
  const [target, setTarget] = useState<HTMLElement | null>();
  useEffect(() => {
    if (target) {
      events.forEach((event) => {
        target.addEventListener(event, stopPropagation, { passive: false });
      });
      return () => {
        events.forEach((event) => {
          target.removeEventListener(event, stopPropagation);
        });
      };
    }
    return undefined;
  }, [target, events]);

  return (el: HTMLElement | null) => {
    setTarget(el);
  };
};
