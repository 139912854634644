import { ApolloQueryResult } from 'apollo-client';
import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import client from '../../../common/services/ApolloService';
import {
  ShopifyConnectedAccountStateQuery,
  ShopifyConnectedAccountStateQueryVariables,
} from './@types/ShopifyConnectedAccountStateQuery';
import { SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY } from './queries';

export const getShopifyConnectedAccountObservableQuery = memoize(
  (botId: string) =>
    client.watchQuery<
      ShopifyConnectedAccountStateQuery,
      ShopifyConnectedAccountStateQueryVariables
    >({
      query: SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY,
      variables: {
        botId,
      },
    }),
);

export const getShopifyConnectedAccountObservable = memoize((botId: string) =>
  Observable.from(
    getShopifyConnectedAccountObservableQuery(botId) as ObservableInput<
      ApolloQueryResult<ShopifyConnectedAccountStateQuery>
    >,
  ).map(({ data }) => {
    return data.bot.connectedShop;
  }),
);
