import { DocumentNode } from 'graphql';
import { ObservableInput } from 'rxjs/Observable';
import { Observable } from 'rxjs';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';

export interface CacheManager<TData, TParameters> {
  read(params: TParameters): TData | null;
  write(data: TData, params: TParameters): void;
  watch(params: TParameters): Observable<ApolloQueryResult<TData>>;
}

export class GraphqlCacheManager<Query, Variables = {}>
  implements CacheManager<Query, Variables>
{
  constructor(
    private readonly client: ApolloClient<any>,
    private readonly documentNode: DocumentNode,
  ) {}

  read(params?: Variables): Query | null {
    return this.client.readQuery(
      {
        query: this.documentNode,
        variables: params,
      },
      true,
    );
  }

  write(data: Query, params?: Variables): void {
    return this.client.writeQuery({
      query: this.documentNode,
      variables: params,
      data,
    });
  }

  watch(params?: Variables): Observable<ApolloQueryResult<Query>> {
    return Observable.from(
      this.client.watchQuery({
        query: this.documentNode,
        variables: params,
        fetchPolicy: 'cache-only',
      }) as ObservableInput<ApolloQueryResult<Query>>,
    );
  }
}
