import gql from 'graphql-tag';

export const BOT_LIVELINESS_QUERY = gql`
  query BotLivelinessQuery($botId: String!) {
    bot(id: $botId) {
      id
      botLivelinessStatus
    }
  }
`;
