import { useMutation } from 'react-apollo';
import {
  ChangeWorkspaceMutation,
  ChangeWorkspaceMutationVariables,
} from './@types/ChangeWorkspaceMutation';
import { CHANGE_WORKSPACE_MUTATION } from './mutations';

export const useChangeWorkspace = () => {
  return useMutation<ChangeWorkspaceMutation, ChangeWorkspaceMutationVariables>(
    CHANGE_WORKSPACE_MUTATION,
  );
};
