const ENTRY_POINTS_DELIMITER = ',';

const toEntryPointName = (pluginId: string) => {
  return pluginId.replace('entry_point/', '');
};

export const hasEntryPointBeenAdded = (
  entryPoints: string | null,
  pluginId: string,
) => {
  if (!entryPoints) return false;
  const entryPoint = toEntryPointName(pluginId);
  return entryPoints.includes(entryPoint);
};

export const appendEntryPoint = (
  entryPoints: string | null,
  ...pluginIds: string[]
) => {
  if (pluginIds.length === 0) return entryPoints;
  const newEntryPoints = pluginIds
    .map((pluginId) => toEntryPointName(pluginId))
    .join(ENTRY_POINTS_DELIMITER);
  return entryPoints
    ? `${newEntryPoints}${ENTRY_POINTS_DELIMITER}${entryPoints}`
    : newEntryPoints;
};
