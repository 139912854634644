import Maybe from 'graphql/tsutils/Maybe';
import { BotStatusName } from '../../../@types/globalTypes';
import { BotProStatus, BotStatus } from './types';

interface Pro {
  manual: Maybe<boolean>;
  is_paused: Maybe<boolean>;
  debt: Maybe<number>;
  millis_before_cancel: Maybe<number>;
  status: string;
}

interface PricingData {
  currentUsers: number | undefined;
}

interface Status {
  status: BotStatusName;
}

interface Limits {
  usersLimit: number;
  notificationsLimit: number;
}

interface DialogsPricing {
  reached_limit: Maybe<number>;
}

interface Bot {
  pro: Maybe<Pro>;
  pricingData: Maybe<PricingData>;
  status: Maybe<Status>;
  limits: Limits;
  dialogsPricing: Maybe<DialogsPricing>;
}

export function getBotStatus(bot: Bot): BotStatus {
  const { pro, pricingData, status, limits, dialogsPricing } = bot;

  const { currentUsers = 0 } = pricingData || {};

  if (!pro) {
    return BotStatus.amateur;
  }

  if (pro.manual && (!status || status!.status !== BotStatusName.draft)) {
    return BotStatus.premium;
  }

  if (pro.is_paused) {
    const isWithDebt =
      pro.status === BotProStatus.enabled_with_debt ||
      (pro.status === BotProStatus.disabled && pro.debt! > 0);
    if (isWithDebt) {
      return BotStatus.pausedAndExpiredDebt;
    }
    return BotStatus.paused;
  }

  switch (pro.status) {
    case BotProStatus.trial:
      if (pro.debt! > 0) {
        return BotStatus.expiredDebt;
      }
      if (status?.status === BotStatusName.draft) {
        return BotStatus.noPage;
      }
      return BotStatus.trial;
    case BotProStatus.enabled:
      return pro.millis_before_cancel ? BotStatus.amateur : BotStatus.pro;
    case BotProStatus.enabled_with_debt:
      return BotStatus.debt;
    case BotProStatus.disabled:
      if (pro.debt! > 0) {
        return BotStatus.expiredDebt;
      }
      if (status && status.status === BotStatusName.draft) {
        return BotStatus.noPage;
      }
      if (
        (dialogsPricing && (dialogsPricing.reached_limit ?? 0) > 100) ||
        currentUsers >= limits.usersLimit
      ) {
        return BotStatus.elapsed;
      }
      if (currentUsers >= limits.notificationsLimit) {
        return BotStatus.elapsing;
      }
      return BotStatus.amateur;
    default:
      return BotStatus.amateur;
  }
}
