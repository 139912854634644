import { ApolloQueryResult } from 'apollo-client';
import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import client from '../../../common/services/ApolloService';
import {
  WhatsappSettingsQuery,
  WhatsappSettingsQueryVariables,
} from './@types/WhatsappSettingsQuery';
import { isWhatsappConnected } from './isWhatsappConnected';
import { WHATSAPP_SETTINGS_QUERY } from './queries';

export const getWhatsappSettingsObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery<WhatsappSettingsQuery, WhatsappSettingsQueryVariables>({
      query: WHATSAPP_SETTINGS_QUERY,
      variables: {
        botId,
      },
    }) as ObservableInput<ApolloQueryResult<WhatsappSettingsQuery>>,
  ).map(({ data }) => ({
    whatsappSettings: data?.whatsappMessagingSettings,
    isWhatsappConnected: isWhatsappConnected(data?.whatsappMessagingSettings),
  })),
);
