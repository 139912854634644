import { useQuery } from '@apollo/react-hooks';

import { BotStatus } from '@utils/Bot';
import { useCurrentBotId } from '@utils/Routing';

import { BOT_PAGE_STATUS_QUERY } from './queries';
import {
  BotPageStatusQuery,
  BotPageStatusQueryVariables,
} from './@types/BotPageStatusQuery';

export const useBotPageStatus = () => {
  const botId = useCurrentBotId() ?? '';

  const { data, loading } = useQuery<
    BotPageStatusQuery,
    BotPageStatusQueryVariables
  >(BOT_PAGE_STATUS_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  return {
    botId,
    botPageStatus: data?.bot.status?.status as BotStatus | null | undefined,
    loading: loading || !data || data?.bot.id !== botId,
  };
};
