import { getPluginsDefaultData } from '@components/Plugins/common/pluginConst';

export const getPluginGQLInputFieldByPluginType = (pluginType: string) => {
  const pluginDefaultData = getPluginsDefaultData()[pluginType];

  if (!pluginDefaultData) {
    throw new Error(`Cannot find default data for plugin type ${pluginType}`);
  }

  return Object.keys(pluginDefaultData)[0];
};
