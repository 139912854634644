enum SuspenseResourceStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export const createSuspenseResource = <T>(
  promise: Promise<T>,
): { read: () => T } => {
  let status = SuspenseResourceStatus.pending;
  let result: any;

  const suspender = promise
    .then((res) => {
      status = SuspenseResourceStatus.success;
      result = res;
    })
    .catch((err) => {
      status = SuspenseResourceStatus.error;
      result = err;
    });

  return {
    read() {
      if (status === SuspenseResourceStatus.pending) {
        throw suspender;
      } else if (status === SuspenseResourceStatus.error) {
        throw result;
      }
      return result;
    },
  };
};
