import { toaster, ServiceMessageType } from '@services/MessageService';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { BOT_WORKSPACE_ID_QUERY } from '../Bot/useBotWorkspaceId';
import {
  MoveToWorkspaceMutation,
  MoveToWorkspaceMutationVariables,
} from './@types/MoveToWorkspaceMutation';
import { getWorkspaceErrorMessage } from './getWorkspaceErrorMessage';
import { MOVE_TO_WORKSPACE_MUTATION } from './mutations';
import { WORKSPACES_BOTS_QUERY } from './queries';

interface MoveToWorkspaceArgs {
  botId: string;
  workspaceId: string;
}

export const useMoveToWorkspace = () => {
  const [moveToWorkspaceMutation, { loading }] = useMutation<
    MoveToWorkspaceMutation,
    MoveToWorkspaceMutationVariables
  >(MOVE_TO_WORKSPACE_MUTATION, {
    onError(error) {
      const errorType = extractGQLErrorData(error)?.message;

      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: getWorkspaceErrorMessage(errorType),
        },
      });
    },
  });

  const moveToWorkspace = useCallback(
    ({ botId, workspaceId }: MoveToWorkspaceArgs) => {
      moveToWorkspaceMutation({
        variables: {
          workspaceId,
          botId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: WORKSPACES_BOTS_QUERY,
          },
          { query: BOT_WORKSPACE_ID_QUERY, variables: { botId } },
        ],
      });
    },
    [moveToWorkspaceMutation],
  );

  return [moveToWorkspace, loading] as const;
};
