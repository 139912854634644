import { MutationHookOptions, useMutation, useQuery } from 'react-apollo';
import {
  CreateRequestDemoMutation,
  CreateRequestDemoMutationVariables,
} from './@types/CreateRequestDemoMutation';
import { RequestDemoQuery } from './@types/RequestDemoQuery';
import { CREATE_REQUEST_DEMO_MUTATION } from './mutations';
import { REQUEST_DEMO_QUERY } from './queries';

export const useCreateRequestDemo = ({
  onCompleted,
  onError,
}: Pick<MutationHookOptions, 'onCompleted' | 'onError'> | undefined = {}) =>
  useMutation<CreateRequestDemoMutation, CreateRequestDemoMutationVariables>(
    CREATE_REQUEST_DEMO_MUTATION,
    {
      onCompleted,
      onError,
      refetchQueries: [
        {
          query: REQUEST_DEMO_QUERY,
        },
      ],
    },
  );

export const useRequestDemo = () => {
  const { data, loading } = useQuery<RequestDemoQuery>(REQUEST_DEMO_QUERY);
  return [data?.requestDemo, loading] as const;
};
