import { useMutation, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Level, log } from 'cf-common/src/logger';
import { useBotsTitlesMap } from '@utils/Data/Bot/useBotsTitlesMap';
import {
  extractGQLErrorData,
  getRequestIdFromApolloError,
} from '../../GQL/utils';
import { useCurrentBotId } from '../../Routing';
import { redirect } from '../../UrlUtils';
import {
  GetShopifyConnectLinkMutation,
  GetShopifyConnectLinkMutationVariables,
} from './@types/GetShopifyConnectLinkMutation';
import {
  ShopifyConnectedAccountStateQuery,
  ShopifyConnectedAccountStateQueryVariables,
} from './@types/ShopifyConnectedAccountStateQuery';
import {
  GET_SHOPIFY_CONNECT_LINK_MUTATION,
  SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY,
} from './queries';
import { handleShopifyConnectionError } from './utils/handleShopifyConnectionError';
import { WatchQueryFetchPolicy } from 'apollo-client';
import client from '@common/services/ApolloService';

export const useShopifyAccount = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const botId = useCurrentBotId()!;
  const botIdToTitle = useBotsTitlesMap();
  const [currentConnectedDomain, setCurrentConnectedDomain] = useState<
    string | undefined
  >();

  const { data, loading, error } = useQuery<
    ShopifyConnectedAccountStateQuery,
    ShopifyConnectedAccountStateQueryVariables
  >(SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY, {
    fetchPolicy,
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  const [
    getConnectLinkMutation,
    {
      data: connectLinkData,
      loading: connectAccountInProgress,
      error: connectAccountErrorData,
      called: connectAccountInCalled,
    },
  ] = useMutation<
    GetShopifyConnectLinkMutation,
    GetShopifyConnectLinkMutationVariables
  >(GET_SHOPIFY_CONNECT_LINK_MUTATION);

  const goToConnectAccount = useCallback(
    (myshopifyDomain: string, interruptedHref?: string) => {
      if (!botId) {
        return;
      }
      setCurrentConnectedDomain(myshopifyDomain);
      getConnectLinkMutation({
        variables: {
          botId,
          myshopifyDomain,
          interruptedHref,
        },
      });
    },
    [botId, getConnectLinkMutation],
  );

  const connectAccountError = useMemo(
    () => extractGQLErrorData(connectAccountErrorData),
    [connectAccountErrorData],
  );

  const connectLink = connectLinkData?.getShopifyRedirectConnectLink.url;

  useEffect(() => {
    if (connectLink) {
      redirect(connectLink);
    }
  }, [connectLink]);

  useEffect(() => {
    if (connectAccountError && currentConnectedDomain) {
      log({
        msg: 'Error get Shopify redirect connect link',
        level: Level.error,
        error: connectAccountErrorData,
        data: {
          label: 'shopify_account',
          requestId: getRequestIdFromApolloError(connectAccountErrorData),
        },
      });

      handleShopifyConnectionError({
        botId,
        connectAccountError,
        doesBotBelongToUser: (botId) => Boolean(botIdToTitle[botId]),
        myshopifyDomain: currentConnectedDomain,
      });

      setCurrentConnectedDomain(undefined);
    }
  }, [
    botId,
    botIdToTitle,
    connectAccountError,
    connectAccountErrorData,
    currentConnectedDomain,
  ]);

  const refetchShopifyAccount = useCallback(() => {
    if (!botId) {
      return;
    }
    client.query({
      query: SHOPIFY_CONNECTED_ACCOUNT_STATE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        botId,
      },
    });
  }, [botId]);

  const account = data?.bot.connectedShop;

  return {
    account,
    hasShopifyAccount: loading ? undefined : Boolean(account),
    loading,
    error,
    goToConnectAccount,
    connectAccountInProgress,
    connectAccountError,
    connectAccountInCalled,
    refetchShopifyAccount,
  };
};
