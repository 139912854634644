import gql from 'graphql-tag';
import { MutationHookOptions, useMutation } from 'react-apollo';
import {
  SetWhatsappMigrationStatusMutation,
  SetWhatsappMigrationStatusMutationVariables,
} from './@types/SetWhatsappMigrationStatusMutation';

export const SET_WHATSAPP_MIGRATION_STATUS_MUTATION = gql`
  mutation SetWhatsappMigrationStatusMutation(
    $botId: String!
    $status: WhatsappMigrationStatus!
  ) {
    setWhatsappMigrationStatus(botId: $botId, status: $status)
  }
`;

export const useWhatsappMigrationStatusMutation = (
  options?: MutationHookOptions<
    SetWhatsappMigrationStatusMutation,
    SetWhatsappMigrationStatusMutationVariables
  >,
) => {
  return useMutation<
    SetWhatsappMigrationStatusMutation,
    SetWhatsappMigrationStatusMutationVariables
  >(SET_WHATSAPP_MIGRATION_STATUS_MUTATION, options);
};
