import gql from 'graphql-tag';

export const DIALOGS_PRICING_FRAGMENT = gql`
  fragment dialogsPricingFragment on DialogsPricing {
    pricing_plan_id
    trialSecondsTotal
    current_tier
    next_tier
    previous_tier
    next_billing_date
    next_invoice_date
    currency
    next_subscription_period
    current_subscription_period
    next_prepayment_date
    reached_limit
    discount_months_left
    charging_entity_type
    current_tier_limit
    adjustment_fee {
      currency
      price
    }
    invoice_total
    status
    hasDebt
    hasPaymentMethod
    subscriptionId
    stripe_error {
      description
      code
      decline_code
    }
    parts {
      type
      limit
      amount
      pricePerEntity
      from
      to
    }
  }
`;

export const DIALOGS_PRICING_QUERY = gql`
  query DialogsPricingQuery($botId: String!) {
    bot(id: $botId) {
      id
      status {
        page
      }
      dialogsPricing {
        ...dialogsPricingFragment
      }
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const PREMIUM_PRICING_INFO_QUERY = gql`
  query PremiumPricingInfoQuery {
    premiumPricingInfo {
      ...dialogsPricingFragment
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const PRICING_PLAN_TIERS_QUERY = gql`
  query PricingPlanTiersQuery(
    $botId: String!
    $pricingPlanId: String!
    $currency: String!
  ) {
    bot(id: $botId) {
      id
      pricingPlanTiers(pricingPlanId: $pricingPlanId, currency: $currency) {
        type
        tier_price
        tier_dialogs
        price_per_dialog
        adjustment_fee_per_dialog
        full_featured
        priority_support
      }
    }
  }
`;

export const PREMIUM_PRICING_PLAN_TIERS_QUERY = gql`
  query PremiumPricingPlanTiersQuery {
    premiumPricingPlanTiers {
      pricingPlanId
      currency
      chargingEntityType
      pricingObjective
      workspaceId
      workspaceName
      tiers {
        type
        customName
        subType
        limit
        pagesLimit
        price
        pricePerEntity
        adjustmentFeePerEntity
      }
    }
  }
`;

export const PREMIUM_TIERS_PRICE_PERIOD_LIST_QUERY = gql`
  query PremiumTiersPricePeriodListQuery($tierType: String!) {
    premiumTiersPricePeriodList(tierType: $tierType) {
      period
      pricePerMonth
      priceTotalWithDiscounts
      priceTotal
      priceToUpgrade
      save
    }
  }
`;

export const BILLING_INFO_QUERY = gql`
  query BillingInfoQuery($botId: String!) {
    bot(id: $botId) {
      id
      billingDetails {
        id
        city
        company_name
        country
        email
        postcode
        state
        street
        tax_id
      }
    }
  }
`;

export const PAYMENT_INFO_QUERY = gql`
  query PaymentInfoQuery($botId: String!) {
    bot(id: $botId) {
      id
      paymentInformation {
        nextBillingDate
        status
        active_card {
          card4LastDigits
        }
        currency
      }
    }
  }
`;

const CHARGE_INFO_FRAGMENT = gql`
  fragment chargeInfoFragment on ChargeInfo {
    amount {
      price
      currency
    }
    reachedLimit
    currentPeriodLimit
    nextPeriodLimit
    currentPeriodPrice {
      price
      currency
    }
    nextPeriodPrice {
      price
      currency
    }
    adjustmentFeeCount
    adjustmentFeeAmount {
      price
      currency
    }
    prepaymentAmount {
      price
      currency
    }
    prepaymentCharge
    adjustmentFeeCharge
  }
`;

export const PAYMENT_HISTORY_QUERY = gql`
  query PaymentHistoryQuery($botId: String!) {
    bot(id: $botId) {
      id
      paymentInformation {
        currency
        payments {
          currency
          activeUsers
          amount
          last4CardDigits
          date
          stripeResult {
            id
            failed
          }
          chargeInfo {
            ...chargeInfoFragment
          }
        }
      }
    }
  }

  ${CHARGE_INFO_FRAGMENT}
`;

export const BILLING_PAYMENT_HISTORY_QUERY = gql`
  query BillingPaymentHistoryQuery($subscriptionId: String!) {
    premiumPricingPaymentList(subscriptionId: $subscriptionId) {
      id
      amount
      currency
      lastAttemptDate
      chargingEntityType
      lastAttemptStripeResult {
        id
        failed
      }
      parts {
        type
        limit
        amount
        pricePerEntity
        from
        to
      }
    }
  }
`;

export const GET_INVOICE_QUERY = gql`
  query GetInvoiceQuery($id: String!, $botId: String) {
    invoice(id: $id, botId: $botId) {
      id
      html
    }
  }
`;

export const PRO_PLAN_UNPAUSE_MUTATION = gql`
  mutation ProPlanUnpauseMutation($botId: String!) {
    unpauseProPlan(botId: $botId)
  }
`;

export const GET_CONFIGURE_TAB_BOT_DATA = gql`
  query getConfigureTabBotData($botId: String!) {
    bot(id: $botId) {
      id
      workspace_id
      statistic {
        current_week_total_subscribed_users
        last_week_total_subscribed_users
        current_week_active_users
        last_week_active_users
      }
      pricingData {
        currency
        currentPrice
        currentUsers
        reached_limit
        currentMaxUsers
        pricing
      }
      pausePricing
      pro {
        is_paused
        status
        debt
        days_before_cancel
        millis_before_cancel
        manual
      }
      dialogsPricing {
        ...dialogsPricingFragment
      }
      limits {
        usersLimit
        notificationsLimit
      }
      paymentInformation {
        nextBillingDate
      }
      botLivelinessStatus
      status {
        status
        page_info {
          id
          title
          picture
        }
      }
      user_limit_reached
    }
  }
  ${DIALOGS_PRICING_FRAGMENT}
`;

export const PRICE_PERIOD_LIST_QUERY = gql`
  query PricePeriodListQuery(
    $botId: String!
    $pricingPlanId: String!
    $currency: String!
    $tierType: TierType!
  ) {
    bot(id: $botId) {
      id
      pricePeriodList(
        pricingPlanId: $pricingPlanId
        currency: $currency
        tierType: $tierType
      ) {
        period
        pricePerMonth
        priceTotalWithDiscounts
        priceTotal
        priceToUpgrade
        save
      }
    }
  }
`;

export const HAS_PAYMENT_DEBT_QUERY = gql`
  query HasPaymentDebtQuery($botId: String!) {
    bot(id: $botId) {
      id
      paymentInformation {
        has_adjustment_fee_debt
        has_prepayment_debt
      }
    }
  }
`;

export const PAUSE_PRICING_QUERY = gql`
  query PausePricingQuery($botId: String!) {
    bot(id: $botId) {
      id
      pausePricing
    }
  }
`;

export const PREMIUM_PRICING_PAYMENT_LIST_QUERY = gql`
  query PremiumPricingPaymentListQuery($subscriptionId: String!) {
    premiumPricingPaymentList(subscriptionId: $subscriptionId) {
      id
      amount
      currency
      lastAttemptDate
      chargingEntityType
      lastAttemptStripeResult {
        id
        failed
      }
      parts {
        type
        limit
        amount
        pricePerEntity
        from
        to
      }
    }
  }
`;

export const WORKSPACES_BILLING_INFO_QUERY = gql`
  query WorkspacesBillingInfoQuery {
    workspacesBillingDetails {
      id
      city
      company_name
      country
      email
      postcode
      state
      street
      tax_id
      cardType
      card4LastDigits
    }
  }
`;

export const PREMIUM_PRICING_INVOICE_QUERY = gql`
  query PremiumPricingInvoiceQuery(
    $subscriptionId: String!
    $invoiceId: String!
  ) {
    premiumPricingInvoice(
      subscriptionId: $subscriptionId
      invoiceId: $invoiceId
    ) {
      id
      html
    }
  }
`;

export const PREMIUM_PRICING_USAGE_QUERY = gql`
  query PremiumPricingUsageQuery($subscriptionId: String!, $year: Int) {
    premiumPricingUsage(subscriptionId: $subscriptionId, year: $year) {
      subscriptionId
      pages {
        id
        title
      }
      usage {
        billingDate
        limit
        reachedLimit
        pages {
          pageId
          reachedLimit
        }
      }
      years
    }
  }
`;

export const CHURN_SURVEY_ADVICE_QUERY = gql`
  query ChurnSurveyAdviceQuery($botId: String!) {
    churnSurveyAdvice(botId: $botId) {
      advice
      data {
        ... on ChurnSurveyAdviceRemoveUsers {
          totalUsers
          currentAmount
          futureAmount
          currency
        }
        ... on ChurnSurveyAdvicePauseBot {
          totalUsers
          monthlyUsers
          totalDialogs
          monthlyDialogs
          pauseAmount
          pauseCurrency
        }
        ... on ChurnSurveyAdviceDowngradePlan {
          tier {
            type
            tier_price
            tier_dialogs
            price_per_dialog
            adjustment_fee_per_dialog
            full_featured
            priority_support
          }
          saveAmount
          currency
        }
      }
    }
  }
`;

export const TRIAL_STATS_QUERY = gql`
  query TrialStatsQuery($pageId: String!) {
    trialStats(pageId: $pageId) {
      reachedDialogs
      reachedUsers
      trialSeconds
    }
  }
`;
