import { Platform } from '@globals';
import { assert } from '@utils/Assert';
import { FileAttachmentType, UploadMimeTypes } from './types';

export interface ChooseFileOptions {
  beforeUpload?(file: File): void;
  accept: UploadMimeTypes | Array<UploadMimeTypes>;
  dataTestId: string;
  platform: Platform;
  filetype: FileAttachmentType;
  maxFileSize?: number;
}

export enum ErrorTypes {
  fileSizeLimit = 'fileSizeLimit',
}

export interface RestAPI {
  uploadFile(
    data: FormData,
    success: Function,
    failed: Function,
    query?: Record<string, string>,
  ): void;
}

export interface UploadFileResult {
  url: string;
  filename: string;
}

export interface UploadServiceInterface {
  chooseFile(options?: ChooseFileOptions): Promise<UploadFileResult>;
}

export class UploadService implements UploadServiceInterface {
  constructor(private readonly restAPI: RestAPI) {}

  chooseFile(options: ChooseFileOptions): Promise<UploadFileResult> {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = (
      Array.isArray(options.accept) ? options.accept : [options.accept]
    ).join(', ');
    input.setAttribute('data-testid', options.dataTestId);
    document.body.appendChild(input);

    return new Promise((resolve, reject) => {
      input.addEventListener('change', ({ target }) => {
        const formData = new FormData();
        const { maxFileSize, beforeUpload, platform, filetype } = options;
        const file = (target as HTMLInputElement)?.files?.[0];

        assert(file, { msg: 'Files must not be empty' });

        if (maxFileSize && file.size > maxFileSize) {
          reject(ErrorTypes.fileSizeLimit);
          return;
        }

        beforeUpload?.(file);

        formData.append('file', file, file.name);
        const filename = file.name;
        this.restAPI.uploadFile(
          formData,
          (result: string) => {
            resolve({
              url: result,
              filename: file.name,
            });
          },
          reject,
          { filename, platform, filetype },
        );
        input.parentNode!.removeChild(input);
      });
      input.click();
    });
  }
}
