import gql from 'graphql-tag';
import { PLUGINS_FRAGMENT } from '@components/Plugins/common/PluginGQL';

export const FLOW_FRAGMENT = gql`
  fragment FlowFragment on Flow {
    title
    entry_points {
      id
      entry_point_type
      title
      enabled
    }
    sharing_params {
      allow_cloning
      sharing_enabled
    }
    collapsed
    platform
  }
`;

export const FLOW_GROUP_FRAGMENT = gql`
  fragment FlowGroupFragment on FlowGroup {
    title
  }
`;

export const FLOW_GROUP_TITLES_QUERY = gql`
  query FlowGroupTitlesQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        title
      }
    }
  }
`;

export const FLOW_ITEM_QUERY = gql`
  query FlowItemQuery($botId: String!, $flowId: ID!) {
    bot(id: $botId) {
      id
      flow(id: $flowId) {
        id
        ...FlowFragment
      }
    }
  }
  ${FLOW_FRAGMENT}
`;

export const FLOW_GROUP_QUERY = gql`
  query FlowGroupQuery($botId: String!, $flowGroupId: ID!) {
    bot(id: $botId) {
      id
      flowGroup(id: $flowGroupId) {
        id
        ...FlowGroupFragment
      }
    }
  }
  ${FLOW_GROUP_FRAGMENT}
`;

export const FLOWS_QUERY = gql`
  query FlowsQuery($botId: String!) {
    bot(id: $botId) {
      id
      botLivelinessStatus
      flows {
        id
        ...FlowFragment
      }
    }
  }
  ${FLOW_FRAGMENT}
`;

export const FLOW_GROUPS_QUERY = gql`
  query FlowGroupsQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        title
        position
        collapsed
        flow_ids
        sharing_params {
          sharing_enabled
          allow_cloning
        }
      }
    }
  }
`;

export const BOT_FLOWS_QUERY = gql`
  query BotFlowsQuery($botId: String!) {
    bot(id: $botId) {
      id
      flows {
        id
      }
      flow_groups {
        id
        flow_ids
      }
    }
  }
`;

export const FLOW_BLOCKS_QUERY = gql`
  query FlowBlocksQuery($botId: String!, $flowId: MongoObjectId!) {
    bot(id: $botId) {
      id
      flowBlocks(id: $flowId) {
        id
        cards {
          ...pluginsFragment
        }
      }
    }
  }
  ${PLUGINS_FRAGMENT}
`;
