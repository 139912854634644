import client from '@common/services/ApolloService';
import Maybe from 'graphql/tsutils/Maybe';
import { useQuery } from 'react-apollo';
import { FlowTourQuery, FlowTourQueryVariables } from './@types/FlowTourQuery';
import { FLOW_TOUR_QUERY } from './queries';

export const useFlowTourQuery = (flowId: Maybe<string>) => {
  return useQuery<FlowTourQuery, FlowTourQueryVariables>(FLOW_TOUR_QUERY, {
    variables: { flowId: flowId! },
    skip: !flowId,
  });
};

export const flowTourQuery = (flowId: string) =>
  client.query<FlowTourQuery, FlowTourQueryVariables>({
    query: FLOW_TOUR_QUERY,
    variables: { flowId },
  });
