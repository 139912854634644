import ng from 'angular';
import './index.less';

export default ng
  .module('app.ui.buttonWithIconSpecialCase', [])
  .component('buttonWithIconSpecialCase', {
    controllerAs: 'vm',
    bindings: {
      // button title
      label: '@',
    },
    template: `
        <cf-button caps="true" theme="filled-gray">
          <cf-button-label>{{vm.label}}</cf-button-label>
        </cf-button>
        <span class="icon_width test-button-special-case">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
           <path class="icon" fill-rule="evenodd" d="M2 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
        </svg>
        </span>
   `,
  }).name;
