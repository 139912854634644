import noop from 'lodash-es/noop';
import { useEffect, useState } from 'react';
import { QRCodeGenerator } from '@classes/QRCodeGenerator';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import {
  QR_CODE_RENDERER_OPTIONS,
  QR_CODE_WHITE_LABEL_RENDERER_OPTIONS,
} from '@utils/QrCodes/consts';

export const useQrCodeUrl = (url: string | null) => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string>();

  useEffect(() => {
    if (!url) return noop;

    new QRCodeGenerator(
      isWhiteLabelDomain()
        ? QR_CODE_WHITE_LABEL_RENDERER_OPTIONS
        : QR_CODE_RENDERER_OPTIONS,
    )
      .getRawData(url)
      .then((blob) => {
        setQrCodeUrl(URL.createObjectURL(blob));
      });

    return () => {
      if (qrCodeUrl) URL.revokeObjectURL(qrCodeUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { qrCodeUrl };
};
