import { useQuery } from '@apollo/react-hooks';
import { canView, Permission } from '../../../common/services/RoleService';
import { BlockWithPermissions } from '../../../pages/AiSetupPage';
import {
  BlockGroupsQuery,
  BlockGroupsQuery_bot_blocksGroups as BlocksGroup,
  BlockGroupsQueryVariables,
} from '../../../pages/AiSetupPage/@types/BlockGroupsQuery';
import { BLOCK_GROUPS_QUERY } from '../../../pages/AiSetupPage/queries';
import { useRolePermission } from '../../Roles';
import { useCurrentBotId } from '../../Routing';

export const useBlocksGroupsWithPermissions = () => {
  const botId = useCurrentBotId()!;
  const {
    allowed: isViewAllowed,
    loading: loadingIsViewAllowed,
  } = useRolePermission({
    domain: 'groups',
    can: Permission.VIEW,
  });

  const { data } = useQuery<BlockGroupsQuery, BlockGroupsQueryVariables>(
    BLOCK_GROUPS_QUERY,
    {
      variables: { botId },
      skip: !isViewAllowed,
    },
  );

  const blocksGroupsWithPermissions: BlockWithPermissions[] =
    data?.bot.blocksGroups.map((group: BlocksGroup) => ({
      ...group,
      hidden:
        !loadingIsViewAllowed &&
        isViewAllowed &&
        !canView('groups', {
          botId,
          groupId: group.id,
        }),
    })) || [];

  return { blocksGroupsWithPermissions };
};
