import { removeEntity } from '@components/FlowBuilder/components';
import {
  DisconnectShopifyAccountMutation,
  DisconnectShopifyAccountMutationVariables,
} from '@components/FlowBuilder/views/components/ShopifyAccount/@types/DisconnectShopifyAccountMutation';
import { DISCONNECT_SHOPIFY_ACCOUNT_MUTATION } from '@components/FlowBuilder/views/components/ShopifyAccount/queries';
import { log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import i18next from 'i18next';
import client from '../../../../common/services/ApolloService';
import { sendEvent } from '../../../Analytics';

export const goDisconnectShopifyAccount = (botId: string) => {
  removeEntity({
    onSubmit: () => {
      try {
        client.mutate<
          DisconnectShopifyAccountMutation,
          DisconnectShopifyAccountMutationVariables
        >({
          mutation: DISCONNECT_SHOPIFY_ACCOUNT_MUTATION,
          variables: {
            botId,
          },
        });
      } catch (error) {
        log.warn({ error, msg: 'Error disconnect Shopify account' });
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: i18next.t('common.ToasterMessages.somethingWentWrong'),
          },
        });
      }

      sendEvent({
        category: 'flows',
        label: 'shopify account manager',
        action: 'Confirm disconnect click',
      });
    },
    renderHeading: () => i18next.t('shopify.disconnectShopify'),
    renderActionText: () => i18next.t('shopify.yesDisconnect'),
    renderNoteText: () => i18next.t('shopify.areYouSureYouWantToDisconnect'),
  });
};
