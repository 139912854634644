import { getDefaultTab } from './Routing';
import { redirect } from './UrlUtils';

// TODO router Need rewrite this after remove angular router

export const getAutomateTabUrl = (botId: string, blockId?: string) =>
  `/bot/${botId}/structure${blockId ? `/${blockId}` : ''}`;

export const getBaseBotUrl = (botId: string) => getDefaultTab(botId);

export const redirectToDefaultTab = (botId: string) =>
  redirect(getDefaultTab(botId));
