import { Platform } from '@globals';
import {
  FilesMimeTypes,
  GroupMimeTypes,
} from '@utils/PlatformSupportedFiles/mimeGroups';
import { UploadMimeTypes } from '@utils/UploadService/types';

export const SUPPORTED_DOCUMENT_MIME_TYPES: Record<Platform, UploadMimeTypes[]> = {
  [Platform.facebook]: [GroupMimeTypes.all],
  [Platform.instagram]: [GroupMimeTypes.all],
  [Platform.whatsapp]: [
    FilesMimeTypes.odf,
    FilesMimeTypes.opf,
    FilesMimeTypes.osf,
    FilesMimeTypes.text,
    FilesMimeTypes.pdf,
    FilesMimeTypes.xls,
    FilesMimeTypes.doc,
    FilesMimeTypes.ppt,
  ],
};

export const DOCUMENT_FILE_SIZE_LIMIT_MB: Record<Platform, number> = {
  [Platform.facebook]: 15,
  [Platform.instagram]: 15,
  [Platform.whatsapp]: 15,
};
