import gql from 'graphql-tag';
import { ASSIGNED_PROMO_CODE_FRAGMENT } from './fragments';

export const BOT_PROMO_CODE_QUERY = gql`
  query BotPromoCodeQuery($botId: String!) {
    bot(id: $botId) {
      id
      assignedPromoCode {
        ...assignedPromoCodeFragment
      }
    }
  }
  ${ASSIGNED_PROMO_CODE_FRAGMENT}
`;
