import { AudioMimeTypes, FilesMimeTypes, GroupMimeTypes, ImageMimeTypes, VideoMimeTypes } from './mimeGroups';

const groupMimeTypesAudio = ['aac', 'amr', 'mp3', 'm4a', 'mp4', 'wav', 'ogg'];
const groupMimeTypesVideo = ['avi', 'mov', 'mp4', 'ogv', 'ogm', 'webm'];
const groupMimeTypesDocument = [
  'odf',
  'opf',
  'osf',
  'text',
  'pdf',
  'xls',
  'doc',
  'ppt',
];

export const MIME_TO_EXT: Record<string, string | string[]> = {
  [GroupMimeTypes.all]: [
    ...groupMimeTypesAudio,
    ...groupMimeTypesVideo,
    ...groupMimeTypesDocument,
  ],
  [GroupMimeTypes.audio]: groupMimeTypesAudio,
  [GroupMimeTypes.video]: groupMimeTypesVideo,
  [AudioMimeTypes.aac]: 'aac',
  [AudioMimeTypes.amr]: 'amr',
  [AudioMimeTypes.mp3]: 'mp3',
  [AudioMimeTypes.m4a]: 'm4a',
  [AudioMimeTypes.mp4]: 'mp4',
  [AudioMimeTypes.wav]: 'wav',
  [AudioMimeTypes.ogg]: 'ogg',
  [AudioMimeTypes.mpeg]: 'ogg',
  [AudioMimeTypes.m4ax]: 'm4a',
  [VideoMimeTypes.avi]: 'avi',
  [VideoMimeTypes.mov]: 'mov',
  [VideoMimeTypes.mp4]: 'mp4',
  [VideoMimeTypes.ogg]: ['ogv', 'ogm'],
  [VideoMimeTypes.webm]: 'webm',
  [VideoMimeTypes.gpp]: '3gp',
  [FilesMimeTypes.odf]: 'odf',
  [FilesMimeTypes.opf]: 'opf',
  [FilesMimeTypes.osf]: 'osf',
  [FilesMimeTypes.text]: 'text',
  [FilesMimeTypes.pdf]: 'pdf',
  [FilesMimeTypes.xls]: 'xls',
  [FilesMimeTypes.doc]: 'doc',
  [FilesMimeTypes.ppt]: 'ppt',
  [ImageMimeTypes.jpeg]: 'jpg',
  [ImageMimeTypes.png]: 'png',
  [ImageMimeTypes.gif]: 'gif',
  [ImageMimeTypes.avif]: 'avif',
};
