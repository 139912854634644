import './assets/less/tabs.less';

import ng from 'angular';
import template from './tabs.html';
import controller from './controller';

export default ng.module('app.ui.tabs', []).component('tabs', {
  template: () => template,
  controllerAs: 'vm',
  controller,
  bindings: {
    /**
     * @param {[String]} items - array of tabs names
     * @param {String} currentItem - current tab name
     * @callback {$item} onItemClick - callback of on tab click
     */
    items: '<',
    currentItem: '<',
    onItemClick: '&',
  },
}).name;
