// TODO rewrite as provider as soon as react will overcome
import nanoid from 'nanoid';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import i18next from 'i18next';
import { Translation } from '@translations';

export { ServiceMessageType };
export type Listener = (toastConfig: IToast) => () => void;
export type Unsubscriber = () => void;
export type Subscriber = (listener: Listener) => Unsubscriber;

export interface IToastConfig {
  buttonLabel?: string;
  onButtonClick?: () => void;
  id?: string;
  timestamp?: number;
  timeout?: number;
  type?: ServiceMessageType;
  payload: {
    [key: string]: any;
  };
}

export interface IToast extends IToastConfig {
  id: string;
  timestamp: number;
}

export interface IToaster {
  subscribe: Subscriber;
}

export class Toaster {
  listeners: Listener[] = [];

  hiders: (() => void)[] = [];

  subscribe(listener: Listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  show(toastConfig: IToastConfig) {
    const id = nanoid();
    const timestamp = Date.now();
    this.listeners.forEach((listener) => {
      this.hiders.push(
        listener({
          id,
          timestamp,
          ...toastConfig,
        } as IToast),
      );
    });
  }

  error(toastConfig: Omit<IToastConfig, 'type'>) {
    this.show({
      type: ServiceMessageType.error,
      ...toastConfig,
    });
  }

  default(toastConfig: Omit<IToastConfig, 'type'>) {
    this.show({
      type: ServiceMessageType.default,
      ...toastConfig,
    });
  }

  hide() {
    this.hiders.forEach((hider) => hider());
    this.hiders = [];
  }
}

export const toaster = new Toaster();
export const userActionsToaster = new Toaster();

export const showInfoToaster = (
  messageKey: Translation,
  showCloseButton?: boolean,
  messageKeyData?: Record<string, string>,
  buttonLabel?: string,
  onButtonClick?: () => void,
  timeout?: number,
) => {
  toaster.show({
    type: ServiceMessageType.default,
    payload: {
      buttonLabel,
      onButtonClick,
      message: i18next.t(messageKey, messageKeyData),
      showCloseButton,
      timeout: showCloseButton ? 30 * 1000 : timeout,
    },
  });
};

export const showErrorToaster = (
  errorMessageKey: string,
  showCloseButton?: boolean,
  timeout?: number,
  messageKeyData?: Record<string, string | number>,
  buttonLabel?: string,
  onButtonClick?: () => void,
) => {
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: i18next.t(errorMessageKey, messageKeyData),
      showCloseButton,
      timeout: timeout || showCloseButton ? 30 * 1000 : undefined,
      buttonLabel,
      onButtonClick,
    },
  });
};

export const showSomethingWentWrongToaster = (showCloseButton?: boolean) => {
  showErrorToaster(
    'common.ToasterMessages.somethingWentWrong',
    showCloseButton,
  );
};

export enum Messages {
  facebookSyncFailed = "Couldn't sync changes with messenger. Try again in a minute.",
  couldNotCreateBot = "Couldn't create bot. Please try again later.",
  somethingWentWrong = 'Something went wrong. Please, try again later.',
}
