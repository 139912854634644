interface ObjShape {
  [x: string]: any;
}

export enum SortingOrder {
  asc,
  desc,
}

interface CompareFunction<T> {
  (a: T, b: T): number;
}
interface KeyFn<T> {
  (object: T): string | number | null | undefined;
}

function compareValues<T>(key: string, order: SortingOrder): CompareFunction<T>;
function compareValues<T>(
  key: KeyFn<T>,
  order: SortingOrder,
): CompareFunction<T>;
function compareValues<T>(
  key: string | KeyFn<T>,
  order: SortingOrder,
): CompareFunction<T> {
  return function innerSort(a, b) {
    const aValue = typeof key === 'function' ? key(a) : (a as ObjShape)[key];
    const bValue = typeof key === 'function' ? key(b) : (b as ObjShape)[key];

    if (!aValue || !bValue) {
      if (!aValue && !bValue) {
        return 0;
      }

      if (!aValue) {
        return 1;
      }
      if (!bValue) {
        return -1;
      }
    }

    const varA = typeof aValue === 'string' ? aValue.toUpperCase() : aValue;
    const varB = typeof bValue === 'string' ? bValue.toUpperCase() : bValue;

    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === SortingOrder.desc ? comparison * -1 : comparison;
  };
}

export { compareValues };
