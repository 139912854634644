import memoize from 'lodash-es/memoize';
import client from '../../../../common/services/ApolloService';
import { LIVECHAT_SETTINGS_QUERY } from './queries';
import {
  LivechatSettingsQuery,
  LivechatSettingsQueryVariables,
} from './@types/LivechatSettingsQuery';

export const getLiveChatSettingsObserver = memoize((botId: string) =>
  client.watchQuery<LivechatSettingsQuery, LivechatSettingsQueryVariables>({
    query: LIVECHAT_SETTINGS_QUERY,
    fetchPolicy: 'cache-only',
    variables: { botId },
  }),
);
