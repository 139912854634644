import { Platform } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';

export const getAllowedPlatforms = (platform: Maybe<Platform>) => {
  switch (platform) {
    case Platform.whatsapp:
      return [Platform.whatsapp];
    case Platform.facebook:
    case Platform.instagram:
    default:
      return [Platform.facebook, Platform.instagram];
  }
};
