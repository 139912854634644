import { useQuery } from '@apollo/react-hooks';
import { BOT_FEATURE_PERMISSIONS_QUERY } from './BotFeaturePermissionsQuery';
import {
  BotFeaturePermissionsQuery,
  BotFeaturePermissionsQueryVariables,
} from './@types/BotFeaturePermissionsQuery';

export function useBotFeaturePermissions(botId: string) {
  const { data, loading, error } = useQuery<
    BotFeaturePermissionsQuery,
    BotFeaturePermissionsQueryVariables
  >(BOT_FEATURE_PERMISSIONS_QUERY, {
    variables: {
      botId,
    },
    skip: !botId,
  });

  return {
    botFeaturePermissions: data?.bot.featurePermissions,
    botFeaturePermissionsLoading: loading,
    botFeaturePermissionsError: error,
  };
}
